import sortBy from 'lodash/sortBy';
import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  vehicleTasks: [],
};

const sortByDate = tasks => sortBy(tasks, task => new Date(task.taskData.updatedAt)).reverse();

const tasksReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.SAVE_VEHICLE_TASKS:
      return {
        ...state,
        vehicleTasks: [...action.payload],
      };
    case actionStrings.CHANGE_TASK_COMPLETE: {
      const tasks = state.vehicleTasks;
      const updatedTask = action.entity;
      const updatedTasks = tasks.map(task =>
        task.taskData.id === updatedTask.taskData.id ? updatedTask : task
      );
      return {
        ...state,
        vehicleTasks: sortByDate([...updatedTasks]),
      };
    }
    case actionStrings.STORE_VEHICLE_TASK: {
      const tasks = state.vehicleTasks;
      const updatedTask = action.entity;
      return {
        ...state,
        vehicleTasks: sortByDate([...tasks, updatedTask]),
      };
    }
    case actionStrings.SELECT_TASK: {
      return {
        ...state,
        selectedTask: action.payload,
      };
    }
    default:
      return state;
  }
};

export default filterActions(tasksReducer, [
  actionStrings.SAVE_VEHICLE_TASKS,
  actionStrings.CHANGE_TASK_COMPLETE,
  actionStrings.STORE_VEHICLE_TASK,
  actionStrings.SELECT_TASK,
]);
