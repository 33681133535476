/* eslint-disable no-unused-vars */
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.MAINTENANCE_URL}/api`;

class MaintenanceService {
  static getAgentMainenanceCount(authToken, authId, params) {
    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/vehicle-maintenance-log/user/${authId}${paramString}`,
      authToken,
      'application/json'
    );
  }

  static getMaintenanceCountForBranch(authToken, branchId, params) {
    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/vehicle-maintenance-log/branch/${branchId}${paramString}`,
      authToken,
      'application/json'
    );
  }

  static getBatterySwapsForBranch(authToken, branchId, params) {
    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/vehicle-maintenance-log/branch/${branchId}/battery-swaps${paramString}`,
      authToken,
      'application/json'
    );
  }

  static getServiceAgentActivityLogs(authToken, params, serviceWorkerAuthId) {
    const filtersString = createStringFromFilter(params.filters);

    // eslint-disable-next-line no-param-reassign
    delete params.filters;

    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/vehicle-maintenance-log/user/${serviceWorkerAuthId}/details${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  static getAgentBatterySwapCount(authToken, authId, params) {
    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/vehicle-maintenance-log/user/${authId}/battery-swaps${paramString}`,
      authToken,
      'application/json'
    );
  }
}

export default MaintenanceService;
