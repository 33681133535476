import ApiService from './ApiService';
import objectToQueryString from '../../utils/objectToQueryString';
import { tenantInfo } from '../../config';
import { createStringFromParams } from './util';

const BASE_URL = ApiService.ANALYTICS_URL;

const getTenant = () => tenantInfo.uuid;

class AnalyticsService {
  /**
   * Fetches the Analytics/Core endpoints by passing along the `payload.queryString`.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {string} entityEndpoint - The entity endpoint to target for the analytics API.
   * @param {object} queryParams - Query params object to be formatted as string.
   */
  static getAnalyticsByQuery(AuthToken, entityEndpoint, queryParams, signal) {
    const queryString = createStringFromParams(queryParams, '');
    const url = `${BASE_URL}/${entityEndpoint}?${queryString}&tenant=${getTenant()}`;
    return ApiService.ApiCallAuth('GET', url, AuthToken, 'application/json', {}, signal);
  }

  /**
   * Fetches the Analytics/Core endpoints by passing along `startDate` and `endDate` inside of
   * the `payload` argument.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {payload} payload - The payload object containing any necessary properties.
   */
  static getAnalyticsData(AuthToken, branchId, payload) {
    const { entity, startDate, endDate, interval } = payload;
    const url = `${BASE_URL}/${entity}?start=${encodeURI(startDate)}&end=${encodeURI(
      endDate
    )}&branch=${branchId}&interval=${interval}&tenant=${process.env.REACT_APP_TENANT}`;
    return ApiService.ApiCallAuth('GET', url, AuthToken, 'application/json');
  }

  /**
   * Fetches the Analytics/Core `customers/registration` endpoint by passing along the
   * `startDate` and `endDate` variables inside of the payload.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {payload} payload - The payload object containing any necessary properties.
   */
  static getCustomersRegistrations(AuthToken, branchId, payload) {
    const { startDate, endDate } = payload;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/customers/registration?start=${encodeURI(startDate)}&end=${encodeURI(
        endDate
      )}&branch=${branchId}&tenant=${process.env.REACT_APP_TENANT}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetches the Analytics/Core `overview` endpoint by passing along the `startDate`
   * and `endDate variables inside of the payload.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {payload} payload - The payload object containing any necessary properties.
   */
  static getRentalsOverview(AuthToken, branchId, payload, signal) {
    const { startTime, endTime } = payload;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/overview?startTime=${encodeURI(startTime)}&endTime=${encodeURI(
        endTime
      )}&branchId=${branchId}&tenant=${process.env.REACT_APP_TENANT}`,
      AuthToken,
      'application/json',
      {},
      signal
    );
  }

  /**
   * Fetches the Analytics/Core `balance/movements` endpoint by passing along the `startDate`
   * and `endDate variables inside of the payload.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {payload} payload - The payload object containing any necessary properties.
   */
  static getBalances(AuthToken, branchId, payload) {
    const { startDate, endDate } = payload;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/balances/movements?start=${encodeURI(startDate)}&end=${encodeURI(
        endDate
      )}&branch=${branchId}&tenant=${process.env.REACT_APP_TENANT}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetches the Analytics/Core `rentals per vehicles per day` endpoint by passing
   * the `startDate` and `endDate variables inside of the payload.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {object} payload - The payload object containing any necessary properties.
   */
  static getRentalsPerVehicles(AuthToken, branchId, payload) {
    const queryString = objectToQueryString(
      {
        ...payload,
        branchId,
      },
      true
    );
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/rentals/stats?${queryString}&tenant=${
        process.env.REACT_APP_TENANT || getTenant()
      }`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetches the Analytics/Core `driving vs parking minutes` endpoint by passing
   * the `startDate`, `endDate`, `interval` and `state` variables inside of the payload.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {object} payload - The payload object containing any necessary properties.
   */
  static getDrivingParkingMinutes(AuthToken, branchId, payload) {
    const queryString = objectToQueryString(
      {
        ...payload,
        branchId,
      },
      true
    );
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/rentals/times?${queryString}&tenant=${
        process.env.REACT_APP_TENANT || getTenant()
      }`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetches the Analytics/Core `rentals revenues by rental phases` endpoint by passing
   * the `startDate`, `endDate`, `interval` and `state` variables inside of the payload.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {object} payload - The payload object containing any necessary properties.
   * @returns {Promise} promise api call function
   */
  static getRentalPhasePrices(authToken, branchId, payload) {
    const queryString = objectToQueryString(
      {
        ...payload,
        branchId,
      },
      true
    );
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/rentals/prices?${queryString}&tenant=${
        process.env.REACT_APP_TENANT || getTenant()
      }`,
      authToken,
      'application/json'
    );
  }

  /**
   * Fetches the Analytics/Core `overview` details
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @returns {Promise} promise api call function
   */
  static getOverview(authToken, branchId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/overview?branchId=${branchId}&tenant=${
        process.env.REACT_APP_TENANT || getTenant()
      }`,
      authToken,
      'application/json'
    );
  }
}

export default AnalyticsService;
