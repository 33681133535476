import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/files`;

class FileService {
  static uploadFile(AuthToken, file, isPublic) {
    const fileData = new FormData();
    fileData.append('file', file, file.name);
    fileData.append('public', isPublic);

    return ApiService.ApiCallBodyFormData(
      'POST',
      BASE_URL,
      fileData,
      AuthToken,
      'multipart/form-data'
    );
  }

  static getFile(AuthToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, AuthToken, 'application/json');
  }

  static getFileURL(AuthToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}?link`, AuthToken, 'application/json');
  }

  /**
   * Download the file with the provided id as a stream.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the file to download.
   */
  static downloadFileStream(AuthToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}?stream`, AuthToken, 'application/json');
  }

  /**
   * Update file with a PATCH request.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the file to update.
   * @param {object} data - The body of the file to update values to.
   */
  static updateFile(AuthToken, id, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method that is retreiving info of specified file.
   * @param {string} AuthToken - authentication bearer token.
   * @param {number} fileId - id of file that info is gonna be retreived.
   */
  static retreiveInfoOfFile(AuthToken, fileId) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${fileId}`, AuthToken, 'application/json');
  }

  /**
   * Upload public asset file.
   * @param {string} authToken - access token for service.
   * @param {File} file - data for file to be uploaded.
   */
  static uploadPublicAsset(authToken, file) {
    const fileData = new FormData();
    fileData.append('file', file, file.name);

    return ApiService.ApiCallBodyFormData(
      'POST',
      `${BASE_URL}/public-assets`,
      fileData,
      authToken,
      'multipart/form-data'
    );
  }
}

export default FileService;
