import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { formatAnalyticsDateTime } from '../../helpers/analyticsUtility';
import { AnalyticsDateSelect } from '../../containers/Analytics/analytics-page.style';
import IntlMessages from '../utility/intlMessages';
import rangeSelectOptions from '../../constants/range-select-options';

const { lastSevenDays, thisWeek, lastWeek, lastThirtyDays, thisMonth, lastMonth, custom } =
  rangeSelectOptions;

dayjs.extend(isoWeek);

const { Option } = Select;

/** range select options list */
const selectOptions = {
  lastSevenDays: {
    startDate: formatAnalyticsDateTime(dayjs().subtract(7, 'day').valueOf()),
    endDate: formatAnalyticsDateTime(dayjs(), true),
    selectedRange: lastSevenDays,
  },
  thisWeek: {
    startDate: formatAnalyticsDateTime(dayjs().isoWeekday(1).valueOf()),
    endDate: formatAnalyticsDateTime(dayjs().isoWeekday(1).add(6, 'day').valueOf()),
    selectedRange: thisWeek,
  },
  lastWeek: {
    startDate: formatAnalyticsDateTime(dayjs().subtract(7, 'day').isoWeekday(1).valueOf()),
    endDate: formatAnalyticsDateTime(
      dayjs().subtract(7, 'day').isoWeekday(1).add(6, 'day').valueOf(),
      true
    ),
    selectedRange: lastWeek,
  },
  lastThirtyDays: {
    startDate: formatAnalyticsDateTime(dayjs().subtract(30, 'days').valueOf()),
    endDate: formatAnalyticsDateTime(dayjs(), true),
    selectedRange: lastThirtyDays,
  },
  thisMonth: {
    startDate: formatAnalyticsDateTime(dayjs().startOf('month').valueOf()),
    endDate: formatAnalyticsDateTime(dayjs().endOf('month').valueOf(), true),
    selectedRange: thisMonth,
  },
  lastMonth: {
    startDate: formatAnalyticsDateTime(dayjs().subtract(1, 'month').startOf('month').valueOf()),
    endDate: formatAnalyticsDateTime(dayjs().subtract(1, 'month').endOf('month').valueOf(), true),
    selectedRange: lastMonth,
  },
};

const AnalyticsDateSelectOptions = ({ selectedRange, onSelectChange }) => {
  const { formatMessage } = useIntl();
  return (
    <AnalyticsDateSelect
      onChange={value => onSelectChange(selectOptions[value])}
      className="period-select"
      placeholder={<IntlMessages id="analytics.select.period" />}
      defaultValue={selectedRange}
      value={
        selectOptions[selectedRange]
          ? selectedRange
          : formatMessage({
              id: `analytics.select.${selectedRange}`,
              defaultMessage: selectedRange,
            })
      }
      size="small"
    >
      <Option value={lastSevenDays}>
        <IntlMessages id="select.last.seven.days" />
      </Option>
      <Option value={thisWeek}>
        <IntlMessages id="select.this.week" />
      </Option>
      <Option value={lastWeek}>
        <IntlMessages id="select.last.week" />
      </Option>
      <Option value={lastThirtyDays}>
        <IntlMessages id="select.last.thirty.days" />
      </Option>
      <Option value={thisMonth}>
        <IntlMessages id="select.this.month" />
      </Option>
      <Option value={lastMonth}>
        <IntlMessages id="select.last.month" />
      </Option>
    </AnalyticsDateSelect>
  );
};

AnalyticsDateSelectOptions.propTypes = {
  selectedRange: PropTypes.oneOf([
    lastSevenDays,
    thisWeek,
    lastWeek,
    lastThirtyDays,
    thisMonth,
    lastMonth,
    custom,
  ]),
  onSelectChange: PropTypes.func,
};

AnalyticsDateSelectOptions.defaultProps = {
  selectedRange: lastSevenDays,
  onSelectChange: () => null,
};

export default AnalyticsDateSelectOptions;
