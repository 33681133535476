import ApiService from './ApiService';

import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/enums`;

class EnumsService {
  static getEnums(authToken, params) {
    const filtersString = createStringFromFilter(params.filters);

    // eslint-disable-next-line no-param-reassign
    delete params.filters;

    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}${paramString}${
        paramString.length === 1 ? filtersString.substring(1) : filtersString
      }`,
      authToken,
      'application/json'
    );
  }
}

export default EnumsService;
