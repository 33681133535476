import ApiService from './ApiService';
import { createStringFromParams, createStringFromFilter } from './util';

const STATIONS_URL = `${ApiService.BACKEND_URL}/back/stations`;

export default class StationsService {
  /**
   * Method for fetching all stations.
   *
   * @param {string} authToken - Access token for identification.
   * @param {string} branchId - Current active branch.
   */
  static getAllStations(authToken, branchId, params, type) {
    const filtersString = params ? createStringFromFilter(params.filters) : '';
    if (params) {
      // eslint-disable-next-line no-param-reassign
      delete params.filters;
    }
    const paramString = params ? createStringFromParams(params) : '';
    const typeFilter = type ? `&type=${type}` : '';

    return ApiService.ApiCallAuth(
      'GET',
      `${STATIONS_URL}?branchId=${branchId}${typeFilter}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching station details
   *
   * @param {string} authToken - Access token for identification.
   * @param {string} stationId
   */
  static getStationDetails(authToken, stationId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${STATIONS_URL}/${stationId}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating new station.
   *
   * @param {string} authToken - Access token for identification.
   * @param {string} branchId - Current active branch.
   * @param {object} params - Rest station properties such as name, capacity, radius, position: { type, coordinates}
   */
  static createStation(authToken, branchId, params) {
    const data = {
      branchId,
      state: 'ACTIVE',
      type: 'VIRTUAL',
      visibility: [],
      ...params,
    };
    return ApiService.ApiCallBody('POST', `${STATIONS_URL}`, data, authToken, 'application/json');
  }

  /**
   * Method for updating station.
   * @param {string} authToken - authentication bearer token.
   * @param {number} id - of station to be updated.
   * @param {string} key - prop that is updated.
   * @param {any} value - new value og prop.
   */
  static updateStation(authToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${STATIONS_URL}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for updating station.
   *
   * @param {string} authToken - Access token for identification.
   * @param {number} stationId - id of station for being deleted
   */
  static deleteStation(authToken, stationId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${STATIONS_URL}/${stationId}`,
      authToken,
      'application/json'
    );
  }
}
