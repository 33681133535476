import { actionPayload } from '../common/commonActions';

export const actionStrings = {
  CREATE_STATION: 'CREATE_STATION',
  REFRESH_STATIONS: 'REFRESH_STATIONS',
  UPDATE_LOADING: 'UPDATE_LOADING',
  FETCH_STATIONS: 'FETCH_STATIONS',
  FETCH_STATIONS_SUCCESS: 'FETCH_STATIONS_SUCCESS',
  FETCH_STATIONS_FAIL: 'FETCH_STATIONS_FAIL',
  ADD_STATION: 'ADD_STATION',
  UPDATE_STATION_IN_PLACE: 'UPDATE_STATION_IN_PLACE',
  RESET_STATIONS: 'RESET_STATIONS',
};

export const actions = {
  createStation: actionPayload(actionStrings.CREATE_STATION),
  updateLoading: actionPayload(actionStrings.UPDATE_LOADING),
  fetchStations: actionPayload(actionStrings.FETCH_STATIONS),
  fetchStationsSuccess: actionPayload(actionStrings.FETCH_STATIONS_SUCCESS),
  addStation: actionPayload(actionStrings.ADD_STATION),
  updateStationInPlace: actionPayload(actionStrings.UPDATE_STATION_IN_PLACE),
  resetStations: () => ({ type: actionStrings.RESET_STATIONS }),
};
