import { actionPayload } from '../common/commonActions';

export const actionStrings = {
  FETCH_SETTINGS: 'FETCH_SETTINGS',
  FETCH_ALL_SETTINGS_SUCCESS: 'FETCH_ALL_SETTINGS_SUCCESS',
  FETCH_SETTINGS_SUCCESS_CHANGE_LOADING: 'FETCH_SETTINGS_SUCCESS_CHANGE_LOADING',
  CREATE_OR_UPDATE_SETTING: 'CREATE_OR_UPDATE_SETTING',
  CREATE_OR_UPDATE_SETTING_SUCCESS: 'CREATE_OR_UPDATE_SETTING_SUCCESS',
};

export default {
  fetchSettings: actionPayload(actionStrings.FETCH_SETTINGS),
  fetchAllSettingsSuccess: actionPayload(actionStrings.FETCH_ALL_SETTINGS_SUCCESS),
  fetchSettingSuccessChangeLoading: actionPayload(
    actionStrings.FETCH_SETTINGS_SUCCESS_CHANGE_LOADING
  ),
  createOrUpdateSetting: actionPayload(actionStrings.CREATE_OR_UPDATE_SETTING),
  createOrUpdateSettingSucces: actionPayload(actionStrings.CREATE_OR_UPDATE_SETTING_SUCCESS),
};
