import { Divider, Progress } from 'antd';
import styled from 'styled-components';

export const GoalItem = styled.div`
  margin: ${({ margin = '20px' }) => margin};

  .itemHeader {
    display: flex;
    justify-content: space-between;
  }

  .itemHeaderGoal {
    display: flex;
  }

  .itemText {
    margin-bottom: 0px !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    color: var(--gray-8);
  }

  .itemText.goalText {
    color: var(--gray-7);
  }
`;

export const GoalProgress = styled(Progress)``;

export const ProgressIndicator = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  background: ${props => props.color};
  border-radius: 100px;
  z-index: 10;
`;

export const ProgressIndicatorDot = styled.div`
  width: 2px;
  height: 2px;
  background: #ffffff;
  border-radius: 100px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0px 0px -1px;
`;

export const Indicator = styled.div`
  position: absolute;
  z-index: 20;
  margin-top: 5px;
  margin-left: ${props => props.percent}%;
`;

export const ProgressBarWrapper = styled.div`
  position: relative;
  height: 21px;
`;

export const DividerWrapper = styled(Divider)`
  margin: 0;
  min-height: 1px;
`;
