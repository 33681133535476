import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ThreeDots from '../skeleton/ThreeDots';
import { getCurrentMenuIcon } from '../../redux/app/selectors';
import useFetchSettings from '../../hooks/useFetchSettings';
import { actions } from '../../redux/app/actions';
import logoSmall from '../../image/logo/go.png';

import { intlType } from '../../types/intl';

const DotsContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  width: 30px;
  height: 30px;
`;

const Logo = ({ intl: { formatMessage }, onClick }) => {
  const dispatch = useDispatch();

  const dashboardMenuIcon = useSelector(getCurrentMenuIcon);

  const { isLoading } = useFetchSettings({
    formatMessage,
    key: 'dashboard.menu.image',
    onResolve: data => {
      const img = data?.[0];
      if (img) dispatch(actions.updateDashboardMenuIcon(get(img, ['value', 'logoImg'], '')));
    },
  });

  return (
    <div className="isoLogoWrapper" role="presentation" onClick={onClick}>
      {isLoading && (
        <DotsContainer>
          <ThreeDots />
        </DotsContainer>
      )}
      <div>
        <h3>
          {!isLoading && (
            <Link to="/dashboard">
              <LogoImg src={dashboardMenuIcon || logoSmall} alt="go" />
            </Link>
          )}
        </h3>
      </div>
    </div>
  );
};

Logo.propTypes = {
  intl: intlType.isRequired,
  onClick: PropTypes.func,
};

Logo.defaultProps = {
  onClick: () => {},
};

export default injectIntl(Logo);
