/* eslint-disable no-plusplus */
/* eslint-disable func-names */
import NProgress from 'nprogress';
import { notification, message as antMessage } from 'antd';
import { put, call, getContext, select } from 'redux-saga/effects';
import get from 'lodash/get';

import createNotification from '../../components/notification';
import { actions as entityActions } from '../entities/actions';
import { actions as authActions } from '../auth/actions';

import responseOk from '../../utils/responseOk';
import { getSelectedBranch } from '../app/selectors';

export function* logout() {
  yield put(authActions.logout());
}

export function* getAuthToken() {
  const state = yield select();
  return get(state, 'Auth.user.accessToken');
}

export function* getRefreshToken() {
  const state = yield select();
  return get(state, 'Auth.user.refreshToken');
}

export function* errorNotification(titleId, messageId) {
  const intl = yield getContext('intl');
  yield call(
    createNotification,
    'error',
    intl.formatMessage({ id: titleId }),
    intl.formatMessage({ id: messageId })
  );
}

export function* successNotification(titleId, messageId) {
  const intl = yield getContext('intl');
  yield call(
    createNotification,
    'success',
    intl.formatMessage({ id: titleId }),
    intl.formatMessage({ id: messageId })
  );
}

export function* makeNotification(title, message, type) {
  yield call(createNotification, type, title, message);
}

export function* handleNotification(type, response) {
  const { userMessage = '', errorCode = '' } = response;
  const intl = yield getContext('intl');
  switch (true) {
    case errorCode === 401:
      return notification[type]({
        message: 'Unauthorized',
        description: 'You have been logged out',
        placement: 'topRight',
      });
    case errorCode >= 500:
      return notification[type]({
        message: 'API Error',
        description: 'Performance or functionality may be degraded',
        placement: 'topRight',
      });
    default:
      return notification[type]({
        message: intl.formatMessage({
          id: type === 'error' ? 'feedback.alert.errorTips' : 'uiElements.badge.success',
        }),
        description: userMessage,
        placement: 'topRight',
      });
  }
}

// TODO: replace general handle methods (handleErrorSaga,handleLoadingStart,handleLoadingEnd)
// with entity related methods
// ( getFetchEntityErrorSaga,getFetchEntityStartSaga,getFetchEntityEndSaga)

/**
 * COMMON ENTITY SAGAS
 */

export function getFetchEntityErrorSaga(entity, invokerAction, callBack) {
  return function* (error) {
    yield call(createNotification, 'error', error);
    yield put(entityActions.fetchEntityError(entity, invokerAction, error));
    if (callBack) callBack();
  };
}

export function handleErrorSaga(invokerAction) {
  return function* (error) {
    yield call(createNotification, 'error', error);
    // eslint-disable-next-line no-param-reassign
    invokerAction.payload = error;
    yield put(invokerAction);
  };
}

export function getFetchEntityStartSaga(entity, invokerAction) {
  return function* () {
    // Start progress loading bar
    NProgress.start();
    yield put(entityActions.fetchEntityStart(entity, invokerAction));
  };
}

export function handleLoadingStart(invokerAction) {
  return function* () {
    // Start progress loading bar
    NProgress.start();
    yield put(invokerAction);
  };
}

export function getFetchEntityEndSaga() {
  return function* () {
    // Stop progress loading bar
    NProgress.done();
    yield false;
  };
}

export function handleLoadingEnd(invokerAction) {
  return function* () {
    // Start progress loading bar
    NProgress.done();
    yield put(invokerAction);
  };
}

/**
 * Fetch all data for a selected branch by id.
 * This data is to be consumed for the CSV Export of selected entity.
 *
 * Each request will fetch 500 requests maximum, meaning that if there
 * are more results than that -- we need to fetch subsequently as many
 * times as needed to get all the results.
 *
 * @param {Function} serviceApiCall - The function from a `Service` that will fetch data.
 * @param {object} queryParams - Query params object to pass along for the HTTP request.
 * @param {object} actionTypes - Pass action types to handle success and error actions (these also
 * need to be updated in reducers to store the data), as well as intl messages.
 */
export function* fetchAllDataForEntity(
  serviceApiCall,
  queryParams,
  { successActionType, failedActionType, failedIntlMessage }
) {
  try {
    const idToken = yield call(getAuthToken);
    const branch = yield select(getSelectedBranch);

    if (idToken && branch.id) {
      // We will store the data here.
      const data = [];
      let currentPage = 0;
      // This is the default value the header will return, if results are below 500.
      let totalPages = 1;

      /**
       * Construct a string that represents the query param string with
       * which to make a request.
       *
       * @param {number} page - the page for which to concat the string
       */
      const getQueryParams = (page = 0) =>
        Object.entries(queryParams)
          .map(params => {
            const key = String(params[0]);
            const value = String(params[1]);

            switch (key) {
              case 'page':
              case 'currentPage':
              case 'current':
                return `&${key}=${page}`;
              default:
                return `&${key}=${value}`;
            }
          })
          .join('');

      const params = getQueryParams(currentPage);

      // Initiate GET request.
      const response = yield call(serviceApiCall, idToken, branch.id, params);

      // Retrieve the number of pages there are for fetching data.
      totalPages = response.headers.get('X-Pagination-TotalPages');

      // Increment the currentPage after fetching the first time.
      currentPage++;

      if (responseOk(response)) {
        const responseData = yield response.json();
        data.push(...responseData);
      } else {
        yield call(errorNotification, 'feedback.alert.apiError.title', failedIntlMessage);
      }

      if (response.errorCode) {
        yield call(errorNotification, response.errorCode, response.userMessage);
      }

      // Create an array of requests to initiate Promise.all.
      const concurrentRequests = [];

      while (currentPage < totalPages) {
        concurrentRequests.push(
          yield call(serviceApiCall, idToken, branch.id, getQueryParams(currentPage))
        );
        // Increment the currentPage for each request made.
        currentPage++;
      }

      const allRequestResponses = yield Promise.all(concurrentRequests);

      // Using for instead of for...of because eslint is complaining, also `forEach`
      // doesn't have access to `yield` keyword since we need an anonymous function.
      for (let i = 0; i < allRequestResponses.length; i++) {
        const singleResponse = yield allRequestResponses[i].json();
        data.push(...singleResponse);
      }

      yield put({ type: successActionType, payload: data });
    }
  } catch (err) {
    if (failedActionType) yield put({ type: failedActionType, payload: err.message });
  }
}

/**
 * New handling for message notification.
 * @param {*} text of message to be shown
 * @param {*} error is error message notification
 * @param {*} duration of notification
 */
export function* handleMessage(text, error = false, duration = 2) {
  if (error) {
    yield call(antMessage.error, text, duration);
    return;
  }
  yield call(antMessage.success, text, duration);
}

/**
 * Generic function to call any of the provided antd message components.
 * @param {import('antd/lib/message').MessageApi} type - antd message type.
 * @param {string} message - The string representing the message to display to the user.
 * @param {object} options - Additional options for the antd message.
 */
export function* displayMessage(type, message, options) {
  yield call(antMessage[type], message, options);
}
