import { filterActions } from 'redux-ignore';

import getDefaultPath from '../../helpers/urlSync';
import { getView, actionStrings as actions } from './actions';
import vehicleActions from '../vehicles/actions';

const preKeys = getDefaultPath();

export const confKey = ({ search = {}, ...params }) =>
  Object.entries({ ...params, ...search })
    .sort((a, b) => a[0] > b[0])
    .map(([key, value]) => `${key}_${value}`)
    .join('|');

const initState = {
  collapsed: !(window.innerWidth > 1220),
  view: getView(window.innerWidth),
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  loadingData: 0,
  autoRefresh: true,
  focused: true,
  branch: {
    isLoading: false,
    error: {},
    selected: {},
  },
  current_user: null,
  height: window.innerHeight,
  width: window.innerWidth,

  vehicleFilters: {},

  dashboardMenuIcon: '',
  urlChanged: false,
  RFIDs: [],
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed, openDrawer: state.collapsed };
    case actions.COLLPSE_OPEN_DRAWER:
      return { ...state, openDrawer: !state.openDrawer };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: getView(action.width),
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case actions.CHANGE_CURRENT:
      return { ...state, current: action.current };
    case vehicleActions.RUNNING_COMMAND:
      return {
        ...state,
        entities: {
          ...state?.entities,
          vehicles: {
            ...state?.entities?.vehicles,
            [action.id]: { runningCommand: true },
          },
        },
      };
    case vehicleActions.FINISHED_COMMAND:
      return {
        ...state,
        entities: {
          ...state?.entities,
          vehicles: {
            ...state?.vehicles,
            [action.id]: { runningCommand: false },
          },
        },
      };
    case actions.SELECT_BRANCH: {
      if (action.selectedBranch === null) {
        return state;
      }
      return {
        ...state,
        branch: {
          ...state?.branch,
          selected: action.selectedBranch,
        },
      };
    }

    case actions.TOGGLE_AUTO_REFRESH:
      return { ...state, autoRefresh: !state.autoRefresh };

    case actions.FETCH_CURRENT_USER_SUCCESS:
      return { ...state, current_user: { ...action.payload } };
    case actions.FETCH_ALL_VEHICLE_FILTERS_SUCCESS:
      return { ...state, vehicleFilters: action.payload };
    case actions.UPDATE_FOCUS:
      return { ...state, focused: action.payload };
    case actions.SCREEN_RESIZE:
      return { ...state, height: action.height, width: action.width };
    case actions.UPDATE_DASHBOARD_MENU_ICON:
      return { ...state, dashboardMenuIcon: action.payload };
    case actions.RESET_ALL:
      return initState;
    case actions.URL_CHANGED:
      return { ...state, urlChanged: action.payload };
    case actions.FETCH_RFID_CARDS_SUCCESS:
      return { ...state, RFIDs: action.payload };

    default:
      return state;
  }
  return state;
};

export default filterActions(appReducer, [
  actions.COLLPSE_CHANGE,
  actions.COLLPSE_OPEN_DRAWER,
  actions.TOGGLE_ALL,
  actions.CHANGE_OPEN_KEYS,
  actions.CHANGE_CURRENT,
  vehicleActions.RUNNING_COMMAND,
  vehicleActions.FINISHED_COMMAND,
  actions.SELECT_BRANCH,
  actions.TOGGLE_AUTO_REFRESH,
  actions.FETCH_CURRENT_USER_SUCCESS,
  actions.FETCH_ALL_VEHICLE_FILTERS_SUCCESS,
  actions.UPDATE_FOCUS,
  actions.SCREEN_RESIZE,
  actions.UPDATE_DASHBOARD_MENU_ICON,
  actions.RESET_ALL,
  actions.URL_CHANGED,
  actions.FETCH_RFID_CARDS_SUCCESS,
]);
