const actionStrings = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_2FA: 'LOGIN_SUCCESS_2FA',
  LOGOUT: 'LOGOUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  REDIRECT_PAGE: 'REDIRECT_PAGE',
  SUCCESS_LOGIN_AFTER_2FA: 'SUCCESS_LOGIN_AFTER_2FA',
  LOGOUT_2FA: 'LOGOUT_2FA',
};

const actions = {
  loginUser: (values, setLoading, setShowForm) => ({
    type: actionStrings.LOGIN,
    values,
    setLoading,
    setShowForm,
  }),
  redirectPage: () => ({
    type: actionStrings.REDIRECT_PAGE,
  }),
  successLoginAfter2FA: data => ({
    type: actionStrings.SUCCESS_LOGIN_AFTER_2FA,
    data,
  }),
  loginUserSuccess: user => ({
    type: actionStrings.LOGIN_SUCCESS,
    user,
  }),
  loginUserSuccess2FA: data => ({
    type: actionStrings.LOGIN_SUCCESS_2FA,
    data,
  }),
  logoutUser: () => ({
    type: actionStrings.LOGOUT,
  }),
  logoutUser2FA: () => ({
    type: actionStrings.LOGOUT_2FA,
  }),
};

export { actions, actionStrings };
