import { availableEntities } from '../../../../redux-config';

const resolveTableName = pathname => {
  const entityNameFromPath = pathname.split('/')[2];

  if (entityNameFromPath === availableEntities.CUSTOMERS) return availableEntities.CUSTOMERS;
  if (entityNameFromPath === availableEntities.BUSINESS_ACCOUNTS)
    return availableEntities.BUSINESS_ACCOUNTS;
  if (entityNameFromPath === availableEntities.INVOICES) return availableEntities.INVOICES;
  if (entityNameFromPath === availableEntities.ROLES) return availableEntities.ROLES;
  if (entityNameFromPath === availableEntities.LOGS) return availableEntities.LOGS;
  if (entityNameFromPath === availableEntities.VEHICLES) return availableEntities.VEHICLES;
  if (entityNameFromPath === availableEntities.TASKS) return availableEntities.TASKS;
  if (entityNameFromPath === availableEntities.DAMAGES) return availableEntities.DAMAGES;
  if (entityNameFromPath === availableEntities.BOOKINGS) return availableEntities.BOOKINGS;
  if (entityNameFromPath === availableEntities.BOOKING_CALENDAR)
    return availableEntities.BOOKING_CALENDAR;
  if (entityNameFromPath === availableEntities.RENTALS) return availableEntities.RENTALS;
  if (entityNameFromPath === availableEntities.ISSUES) return availableEntities.ISSUES;
  if (pathname.endsWith(availableEntities.SERVICE_AGENTS)) return availableEntities.SERVICE_AGENTS;
  if (pathname.endsWith(availableEntities.PRODUCTIVITY_INSIGHTS))
    return availableEntities.PRODUCTIVITY_INSIGHTS;
  return null;
};

export default resolveTableName;
