import { all, takeEvery, call, put, getContext, select } from 'redux-saga/effects';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { actionStrings } from './actions';
import ResourceService from '../../services/coreAPI/ResourcesService';
import { handleMessage, getAuthToken, errorNotification } from '../common/commonSagas';
import { abortError } from '../common/errorNames';

import responseOk from '../../utils/responseOk';
import { getMessageIdsByKey, getMessages, getShouldReloadMessages } from './selectors';

function* addLanguage(action) {
  const intl = yield getContext('intl');
  const { locale, callBack } = action;
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(ResourceService.addLanguage, idToken, locale);
    if (responseOk(response)) {
      callBack();
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.add.language.success.message' }),
        false
      );
      yield put({
        type: actionStrings.FETCH_LANGUAGES,
      });
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage) {
      yield call(handleMessage, result.userMessage, true);
      return;
    }
    yield call(
      handleMessage,
      intl.formatMessage({ id: 'settings.internationalization.add.language.error.message' }),
      true
    );
  } catch (error) {
    if (error.name !== abortError) {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.add.language.error.message' }),
        true
      );
    }
  }
}

function* fetchLanguages() {
  const intl = yield getContext('intl');
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(ResourceService.fetchLanguages, idToken);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put({
        type: actionStrings.FETCH_LANGUAGES_SUCCESS,
        payload: result,
      });
      return;
    }
    yield call(
      handleMessage,
      intl.formatMessage({ id: 'settings.internationalization.fetch.languages.error.message' }),
      true
    );
  } catch (error) {
    if (error.name !== abortError) {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.fetch.languages.error.message' }),
        true
      );
    }
  }
}

function* searchMessages(action) {
  const intl = yield getContext('intl');
  const { search, callBack } = action;

  const shouldReloadMessage = yield select(getShouldReloadMessages);

  if (shouldReloadMessage !== !!search || shouldReloadMessage)
    try {
      const idToken = yield call(getAuthToken);
      const response = yield call(ResourceService.searchMessages, idToken, { search });
      if (responseOk(response)) {
        const result = yield call([response, response.json]);
        const messagesState = yield select(getMessages);
        if (!isEqual(result, messagesState))
          yield put({
            type: actionStrings.SEARCH_MESSAGES_SUCCESS,
            payload: result,
          });
        callBack();

        yield put({
          type: actionStrings.SHOULD_RELOAD_MESSAGES,
          payload: !!search,
        });

        return;
      }
      const result = yield call([response, response.json]);
      if (result.userMessage) {
        yield call(errorNotification, result.errorCode, result.userMessage);
      }
    } catch (error) {
      if (error.name !== abortError) {
        yield call(
          handleMessage,
          intl.formatMessage({ id: 'settings.internationalization.search.messages.error' }),
          true
        );
      }
    }
  callBack();
}

function* createMessageResource(action) {
  const intl = yield getContext('intl');
  try {
    const { payload } = action;
    const idToken = yield call(getAuthToken);
    const response = yield call(ResourceService.createMessageResource, idToken, payload);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put({
        type: actionStrings.MERGE_MESSAGE_SUCCESS,
        payload: result,
      });
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.add.translation.success.message' }),
        false
      );
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage) {
      yield call(errorNotification, result.errorCode, result.userMessage);
    } else {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.add.translation.error.message' }),
        true
      );
    }
  } catch (error) {
    if (error.name !== abortError) {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.add.translation.error.message' }),
        true
      );
    }
  }
}

function* updateMessageResource(action) {
  const intl = yield getContext('intl');
  try {
    const {
      payload: { messageId },
      payload,
    } = action;
    const data = omit(payload, 'messageId');
    const idToken = yield call(getAuthToken);
    const response = yield call(ResourceService.updateMessageResource, idToken, data, messageId);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put({
        type: actionStrings.MERGE_MESSAGE_SUCCESS,
        payload: result,
      });
      yield call(
        handleMessage,
        intl.formatMessage({
          id: 'settings.internationalization.update.translation.success.message',
        }),
        false
      );
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage) {
      yield call(errorNotification, result.errorCode, result.userMessage);
    } else {
      yield call(
        handleMessage,
        intl.formatMessage({
          id: 'settings.internationalization.update.translation.error.message',
        }),
        true
      );
    }
  } catch (error) {
    if (error.name !== abortError) {
      yield call(
        handleMessage,
        intl.formatMessage({
          id: 'settings.internationalization.update.translation.error.message',
        }),
        true
      );
    }
  }
}

function* publishChanges() {
  const intl = yield getContext('intl');
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(ResourceService.publishChanges, idToken);
    if (responseOk(response)) {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.publish.changes.success.message' }),
        false
      );
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage) {
      yield call(errorNotification, result.errorCode, result.userMessage);
    } else {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.publish.changes.error.message' }),
        true
      );
    }
  } catch (error) {
    if (error.name !== abortError) {
      yield call(
        handleMessage,
        intl.formatMessage({ id: 'settings.internationalization.publish.changes.error.message' }),
        true
      );
    }
  }
}

function* removeResourceMessages(action) {
  try {
    const { payload } = action;
    const idToken = yield call(getAuthToken);
    if (idToken) {
      // Get all tag message ids
      const messageIds = yield select(state =>
        getMessageIdsByKey(state, payload?.key, payload?.area)
      );
      // Remove all tag message translation messages
      const messagesResponse = yield all(
        messageIds.map(id => call(ResourceService.removeTranslation, idToken, id))
      );
      if (messagesResponse.some(res => !responseOk(res))) return;

      yield put({
        type: actionStrings.REMOVE_RESOURCE_MESSAGES_BY_TAG_SUCCESS,
        payload,
      });

      // Publish changes
      yield call(ResourceService.publishChanges, idToken);

      const result = yield call([messagesResponse, messagesResponse.json]);
      if (result.userMessage) {
        yield call(errorNotification, result.errorCode, result.userMessage);
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionStrings.ADD_LANGUAGE, addLanguage),
    yield takeEvery(actionStrings.FETCH_LANGUAGES, fetchLanguages),
    yield takeEvery(actionStrings.SEARCH_MESSAGES, searchMessages),
    yield takeEvery(actionStrings.CREATE_MESSAGE_RESOURCE, createMessageResource),
    yield takeEvery(actionStrings.UPDATE_MESSAGE_RESOURCE, updateMessageResource),
    yield takeEvery(actionStrings.PUBLISH_CHANGES, publishChanges),
    yield takeEvery(actionStrings.REMOVE_RESOURCE_MESSAGES_BY_TAG, removeResourceMessages),
  ]);
}
