import { actionPayload } from '../common/commonActions';

export const actionStrings = {
  ADD_LANGUAGE: 'ADD_LANGUAGE',
  FETCH_LANGUAGES: 'FETCH_LANGUAGES',
  FETCH_LANGUAGES_SUCCESS: 'FETCH_LANGUAGES_SUCCESS',
  SEARCH_MESSAGES: 'SEARCH_MESSAGES',
  SEARCH_MESSAGES_SUCCESS: 'SEARCH_MESSAGES_SUCCESS',
  CREATE_MESSAGE_RESOURCE: 'CREATE_MESSAGE_RESOURCE',
  UPDATE_MESSAGE_RESOURCE: 'UPDATE_MESSAGE_RESOURCE',
  MERGE_MESSAGE_SUCCESS: 'MERGE_MESSAGE_SUCCESS',
  PUBLISH_CHANGES: 'PUBLISH_CHANGES',
  FETCH_DEFAULT_ERROR_MESSAGES: 'FETCH_DEFAULT_ERROR_MESSAGES',
  REMOVE_RESOURCE_MESSAGES_BY_TAG: 'REMOVE_RESOURCE_MESSAGES_BY_TAG',
  REMOVE_RESOURCE_MESSAGES_BY_TAG_SUCCESS: 'REMOVE_RESOURCE_MESSAGES_BY_TAG_SUCCESS',
  SHOULD_RELOAD_MESSAGES: 'SHOULD_RELOAD_MESSAGES',
};

const addLanguage = (locale, callBack) => ({
  type: actionStrings.ADD_LANGUAGE,
  locale,
  callBack,
});

const fetchLanguagesSuccessfully = actionPayload(actionStrings.FETCH_LANGUAGES_SUCCESS);

const searchMessages = (payload, callBack) => ({
  type: actionStrings.SEARCH_MESSAGES,
  ...payload,
  callBack,
});

const mergeMessageSuccess = actionPayload(actionStrings.MERGE_MESSAGE_SUCCESS);

const createMessageResource = actionPayload(actionStrings.CREATE_MESSAGE_RESOURCE);
const updateMessageResource = actionPayload(actionStrings.UPDATE_MESSAGE_RESOURCE);

const removeResourceMessagesByTag = actionPayload(actionStrings.REMOVE_RESOURCE_MESSAGES_BY_TAG);

const actions = {
  addLanguage,
  fetchLanguagesSuccessfully,
  searchMessages,
  createMessageResource,
  updateMessageResource,
  mergeMessageSuccess,
  removeResourceMessagesByTag,
};

export default actions;
