import { filterActions } from 'redux-ignore';
import { quickviewActionTypes } from './actions';

const initialState = {
  active: [],
  /**
   * `object` for reloading keys in quickview
   * `keys` present `pane` in tab of quickview eg `Details, Notes....`
   */
  reloadKeys: {},
};

/**
 * Helper method used to prepare state when closing all quickviews.
 * returns for eg. depending on entity `{ active: [], reloadKeys: {}, [entity]: null }`
 * @param {object} state - part of the state.
 * @returns {object} object with updated state.
 */
const removeAllItemsHelper = state => {
  const retState = {
    ...state,
    ...initialState,
  };
  Object.keys(retState).forEach(key => {
    // programatically set all quickview entites to null
    // check `quickview-wrapper` compare
    if (!['active', 'reloadKeys'].includes(key)) retState[key] = null;
  });
  return retState;
};

const quickviewReducer = (state = initialState, { type, entity, payload }) => {
  switch (type) {
    /**
     * SELECT_ITEM will appropriately set the item to the `payload`.
     * REMOVE_ITEM has `null` as the payload.
     */
    case quickviewActionTypes.SELECT_ITEM:
      return {
        ...state,
        active: [...new Set([...state.active, entity])],
        [entity]: payload,
      };
    case quickviewActionTypes.REMOVE_ITEM:
      return {
        ...state,
        active: state.active.filter(v => v !== entity),
        [entity]: payload,
      };
    /** Update the item by passing along the `payload` but keeping the previous properties intact. */
    case quickviewActionTypes.UPDATE_ITEM:
      return {
        ...state,
        [entity]: {
          ...state[entity],
          ...payload,
        },
      };
    case quickviewActionTypes.REMOVE_ALL_ITEMS:
      return removeAllItemsHelper(state);
    case quickviewActionTypes.RESTART_ACTIVE_VIEWS:
      return initialState;
    case quickviewActionTypes.RELOAD_TAB_QUICKVIEW:
      return {
        ...state,
        reloadKeys: {
          ...state.reloadKeys,
          [entity]: {
            ...state.reloadKeys[entity],
            [payload.key]: payload.reload,
          },
        },
      };
    default:
      return state;
  }
};

export default filterActions(quickviewReducer, [
  quickviewActionTypes.SELECT_ITEM,
  quickviewActionTypes.REMOVE_ITEM,
  quickviewActionTypes.UPDATE_ITEM,
  quickviewActionTypes.REMOVE_ALL_ITEMS,
  quickviewActionTypes.RESTART_ACTIVE_VIEWS,
  quickviewActionTypes.RELOAD_TAB_QUICKVIEW,
]);
