// eslint-disable-next-line import/prefer-default-export
export const areas = {
  businessArea: 'businessArea',
  restrictedArea: 'restrictedArea',
  prohibitedArea: 'prohibitedArea',
  playgroundArea: 'playgroundArea',
};

export const branchStates = {
  IN_PREPARATION: 'IN_PREPARATION',
  OPERATIONAL: 'OPERATIONAL',
  MAINTENANCE: 'MAINTENANCE',
  HIBERNATION: 'HIBERNATION',
  OUT_OF_BUSINESS: 'OUT_OF_BUSINESS',
};

export const branchTypes = {
  BOOKING_TIMESLOT: 'BOOKING_TIMESLOT',
  BOOKING_FLOATING_TIME: 'BOOKING_FLOATING_TIME',
};
