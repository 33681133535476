import styled from 'styled-components';
import { Button } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const NavigationButton = styled(Button)`
  &.ant-btn {
    display: ${props => (props.cssDisplay ? props.cssDisplay : 'flex')};
    box-shadow: none;
    height: 36px;
    font: var(--font-m-2);
    align-items: center;
    text-align: center;
    color: var(--gray-6);
    background: transparent;
    border: none;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
    padding: 5px 15px;

    &:hover {
      color: var(--blue-7);
      border-bottom: 2px solid var(--blue-7);
      border-radius: 0%;
    }
  }

  &.selected {
    box-shadow: none;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    border-bottom: 2px solid var(--blue-7);
    box-sizing: border-box;
    color: var(--blue-7);
    border-radius: 0%;
  }
`;
