import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  availablePlugins: {},
  activePlugins: {},
  plugins: {},
};

const pluginsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.STORE_PLUGINS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default filterActions(pluginsReducer, [actionStrings.STORE_PLUGINS]);
