import { action } from '../common/commonActions';

export const quickviewActionTypes = {
  SELECT_ITEM: '@quickview/select_item',
  REMOVE_ITEM: '@quickview/remove_item',
  UPDATE_ITEM: '@quickview/update_item',
  REMOVE_ALL_ITEMS: '@quickview/remove_all_items',
  RESTART_ACTIVE_VIEWS: '@quickview/restart_active_views',
  RELOAD_TAB_QUICKVIEW: '@quickview/reload_tab_quickview',
};

export const quickviewActions = {
  /**
   * Closure over the `action(..)` function that passes along the `SELECT_ITEM` action type
   * which will store the provided `payload` to the `entity` property of the reducer.
   * @param {string} entity - The property of the object to store data for.
   * @param {object} payload - The `payload` (item) that will the stored to the `entity` (property).
   */
  selectItem: action(quickviewActionTypes.SELECT_ITEM),
  /**
   * Removes the item by specifying `null` as the payload.
   * @param {string} entity - The entity to set as `null`.
   */
  removeItem: entity => action(quickviewActionTypes.REMOVE_ITEM)(entity, null),
  /**
   * Update the given entity with the provided payload.
   * @param {string} entity - The entity to update with the provided payload.
   * @param {object} payload - The payload to update the given entity.
   */
  updateItem: (entity, payload) => action(quickviewActionTypes.UPDATE_ITEM)(entity, payload),

  reload: (entity, key, reload) =>
    action(quickviewActionTypes.RELOAD_TAB_QUICKVIEW)(entity, { key, reload }),
  /** Removes all active quick views. */
  removeAllItems: () => action(quickviewActionTypes.REMOVE_ALL_ITEMS)(),
};
