import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import randomColor from 'randomcolor';
import { availableEntities } from '../redux-config';
import { createStringFromParams } from '../services/coreAPI/util';
import { getMonthName, timeToSeconds, millisecondsToHoursMinutes } from './utility';
import intervals from '../constants/analytics-intervals';
import {
  analyticsChartItemFmt,
  analyticsHourlyChartItem,
  analyticsDailyChartItem,
  analyticsMonthlyChartItem,
} from '../constants/datetime';

import basicStyle from '../config/basicStyle';

dayjs.extend(utc);
dayjs.extend(weekOfYear);

const intervalObject = {
  HOUR: 'hours',
  DAY: 'days',
  WEEK: 'weeks',
  MONTH: 'months',
};

const { hourly, daily, monthly } = intervals;

export const intervalOptionParams = {
  hourly: 'HOUR',
  daily: 'DAY',
  weekly: 'WEEK',
  monthly: 'MONTH',
};

const reduceWeeklyData = (analytics, dateTimeFieldName, countFieldName) => {
  const data = {};
  let total = 0;
  analytics.forEach(item => {
    if (!data[dayjs(item[dateTimeFieldName]).utc(false).week()]) {
      data[dayjs(item[dateTimeFieldName]).utc(false).week()] = 0;
    }
    data[dayjs(item[dateTimeFieldName]).utc(false).week()] += parseFloat(item[countFieldName]);
    total += parseFloat(item[countFieldName]);
  });
  return {
    reducedData: [...Object.keys(data).map(d => [d, data[d]])],
    total,
  };
};

const reduceMonthlyData = (analytics, dateTimeFieldName, countFieldName) => {
  const data = {};
  let total = 0;
  analytics.forEach(item => {
    const month = getMonthName(dayjs(item[dateTimeFieldName]).utc(false).month());
    if (!data[month]) {
      data[month] = 0;
    } // Set 0 if property doesn't exist
    data[month] += parseFloat(item[countFieldName]);
    total += parseFloat(item[countFieldName]);
  });
  return {
    reducedData: [...Object.keys(data).map(d => [d, data[d]])],
    total,
  };
};

export const formatAnalyticsDateTime = (date, end) =>
  dayjs(date)
    .set('hours', end ? 23 : 0)
    .set('minutes', end ? 59 : 0)
    .set('seconds', end ? 59 : 0);

export const reduceAnalyticsData = (
  analytics,
  dateTimeFieldName,
  countFieldName,
  graphShow = 'daily'
) => {
  const raughData = {};
  let total = 0;
  switch (graphShow) {
    case 'weekly':
      return reduceWeeklyData(analytics, dateTimeFieldName, countFieldName);
    case 'monthly':
      return reduceMonthlyData(analytics, dateTimeFieldName, countFieldName);
    case 'daily':
    default:
      analytics.forEach(item => {
        if (!raughData[dayjs(item[dateTimeFieldName]).utc(false).format('YYYY-MM-DD').toString()]) {
          raughData[dayjs(item[dateTimeFieldName]).utc(false).format('YYYY-MM-DD').toString()] = 0;
        }
        raughData[dayjs(item[dateTimeFieldName]).utc(false).format('YYYY-MM-DD').toString()] +=
          parseFloat(item[countFieldName]);
        total += parseFloat(item[countFieldName]);
      });
      break;
  }

  return {
    reducedData: [...Object.keys(raughData).map(d => [d, raughData[d]])],
    total,
  };
};

export const calculateDistanceAverage = analytics => {
  let total = 0;
  analytics.forEach(item => {
    total += item.avg_distance;
  });
  return Math.round((total / analytics.length) * 100) / 100 || 0;
};

export const calculateDurationAverage = analytics => {
  let total = 0;
  analytics.forEach(item => {
    total += timeToSeconds(item.avg_duration) / 60; // Seconds to minutes
  });
  return Math.round((total / analytics.length) * 100) / 100 || 0;
};

export const calculatePercentage = (metric, goal) => (metric / goal) * 100;

export const calculateExpectedResult = (dayInMonth, totalGoal, numberOfDaysInMonth) =>
  (dayInMonth * totalGoal) / numberOfDaysInMonth;

/**
 * Method used to find min and max values from data provided
 * @param {Array} data - data set required for chart
 * @param {Array} clearProperties - optional parameter if there are some unexpected property names we want to exclude
 * @returns {{ min: number, max: number }} - object with min and max properties
 */
export const findMinMaxValues = (data, clearProperties = []) => {
  let min = 0;
  let max = 0;

  if (isArray(data)) {
    data.forEach(item => {
      // Each item is represented as object, so we are iterating over keys
      Object.keys(item).forEach(key => {
        // Convert value to number
        const value = +item[key];
        // Validate value and key
        if (!['dateTime', 'time', ...clearProperties].includes(key) && !Number.isNaN(value)) {
          // Assign max value if value is greater than current max value
          if (max < value) max = value;
          // Assign min value if value is less than current min value
          if (min > value) min = value;
        }
      });
    });
  }
  // Return min and max values
  return { min, max };
};

/**
 * Method used to find difference between start and end date depending on the time interval
 * @param {dayjs} startDate - dayjs start date
 * @param {dayjs} endDate - dayjs end date
 * @param {string} interval - interval is enum with values as hours, day, week or month
 * @returns {{ number }} - difference number
 */
export const calculateDateDifferenceWithInterval = (
  startDate = dayjs(),
  endDate = dayjs(),
  interval = 'day'
) => dayjs(endDate).diff(startDate, interval);

/**
 * Method used to set time property name used in analytics axis and tooltips
 * @param {dayjs} time - dayjs date time
 * @param {Function} formatMessage - interval is enum with values as hours, day, week or month
 * @param {string} dateFormat - valid date format
 * @param {string} interval - interval is enum with values as hours, day, week or month
 * @returns {{ string }} - time property name
 */
export const timePropertyName = (time, formatMessage, dateFormat, interval = 'DAY') => {
  switch (interval) {
    case 'WEEK':
      return `${formatMessage({ id: 'analytics.time.week' })} ${dayjs(time).week()},  ${dayjs(
        time
      ).year()}`;
    default:
      return dayjs(time).format(dateFormat);
  }
};

/**
 * Helper method to resolve initial object.
 * @param {string[]} keys - array of the keys.
 * @param {String|Number} defaultValue - default value for the keys.
 * @returns object with specified keys and default value.
 */
const formDefaultValue = (keys = [], defaultValue = 0) => {
  const retObj = {};
  keys.forEach(key => {
    retObj[key] = defaultValue;
  });
  return retObj;
};

/**
 * Args for the resolve keys for default object.
 * @typedef {Object} ResolveKeysForDefaultObjectArgs
 * @property {Array<Object>} sortedData - array of sorted data.
 * @property {string[]} objectKeys - keys of the object.
 */

/**
 * Method to return possible key.
 * @param {ResolveKeysForDefaultObjectArgs} args - args object
 * @returns {string[]} array of possible key.
 */
const resolveKeysForDefaultObject = ({ sortedData = [], objectKeys = [] }) => {
  const retKeys = [];
  sortedData.forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (objectKeys.includes(key)) retKeys.push(key);
    });
  });
  return [...new Set(retKeys)];
};

export const fillMissingTimeGaps = ({
  interval = '',
  sortedData = [],
  timeProp,
  dateTimeProp = 'dateTime',
  formatMessage,
  intervalDateStringFmt,
  objectKeys = [],
}) => {
  // case when there's only one element in array
  if (sortedData.length <= 1) return sortedData;
  /** `oldest` element because data is sent sorted */
  const minElement = sortedData[0];
  /** `younger` element in the array of the sorted data */
  const maxElement = sortedData[sortedData.length - 1];

  /** `difference` calculated depends on interval */
  const difference =
    // In some situations there is problem with calculating time interval (I think problem is connected to dayjs library)
    interval !== 'HOUR'
      ? dayjs(dayjs(maxElement[dateTimeProp]).format('YYYY-MM-DD')).diff(
          dayjs(minElement[dateTimeProp]).format('YYYY-MM-DD'),
          intervalObject[interval]
        )
      : dayjs(maxElement[dateTimeProp]).diff(minElement[dateTimeProp], intervalObject[interval]);

  /** `default` value when there's no data */
  const defaultValue = formDefaultValue(
    resolveKeysForDefaultObject({
      sortedData,
      objectKeys,
    })
  );

  /** `returned` array of the data */
  const dataArray = range(1, difference, 1).map(number => {
    // Edge case when `HOUR` interval
    // If `endOf` applies for the hour interval
    // it will take day and set the time at the end of day instead of hour
    const dateTime =
      interval === 'HOUR'
        ? dayjs(minElement[dateTimeProp]).add(number, intervalObject[interval]).toISOString()
        : dayjs(minElement[dateTimeProp])
            .add(number, intervalObject[interval])
            .endOf(intervalObject[interval])
            .toISOString();

    const formattedTime = timePropertyName(
      dayjs(dateTime),
      formatMessage,
      intervalDateStringFmt,
      interval
    );

    const value = sortedData.find(v => v[timeProp] === formattedTime) || defaultValue;

    return {
      [dateTimeProp]: dateTime,
      [timeProp]: formattedTime,
      ...value,
    };
  });

  return [minElement, ...dataArray, maxElement];
};

/**
 * Helper method to replace analytics page URL.
 * @returns {String} - entity from the analytics url
 */
export const entityFromUrl = () => {
  // Parse url and return active entity
  const pathEntities = window.location.pathname
    .split('/')
    .filter(v => !['dashboard', 'analytics'].includes(v)) // Filter `dashboard` and `analytics`
    .filter(Boolean); // Filter falsy values
  return pathEntities[0] || availableEntities.RENTALS;
};

/**
 * Helper method to return url param.
 * @param {String} paramName - optional param name we want to retrieve
 * @returns {String} - param value
 */
export const paramFromUrl = (paramName = 'tab') =>
  new URLSearchParams(window.location.search).get(paramName);

/**
 * Helper method to replace analytics page URL.
 * @param {String} entity - entity part from url.
 * @param {String} urlParams - url params we want to add to url.
 */
export const replaceAnalyticsUrl = (entity, params) => {
  window.history.replaceState(
    null,
    null,
    `/dashboard/analytics/${entity}${
      !isEmpty(params) ? `${createStringFromParams(params, '?')}` : ''
    }`
  );
};

/**
 * Helper method for generating random color.
 * @param {number} count - number of the colors
 * @returns {Array<string>} array of the hex values of the generated colors.
 */
export const generateRandomColors = (count = 0) => {
  const { analyticsColors } = basicStyle;

  // case when count is smaller or equal than fixed analytics colors
  // return analyticsColors
  if (count <= analyticsColors.length) return analyticsColors;

  /** `random` colors that are generated like difference between fixed colors and number of the colors needed */
  const randomColors = randomColor({
    count: count - analyticsColors.length,
    hue: 'random',
    luminosity: 'dark',
  });

  // return combined array of the colors and random colors
  return [...analyticsColors, ...randomColors];
};

/**
 * Helper method to round the decimal number to 2.
 * @param {number} value - number to round
 * @returns {number} decimal or integer number.
 */
export const roundValue = value => Math.round(value * 100) / 100;

export const dateStringByInterval = interval => {
  switch (interval) {
    case hourly:
      return analyticsHourlyChartItem;
    case daily:
      return analyticsDailyChartItem;
    case monthly:
      return analyticsMonthlyChartItem;
    default:
      return analyticsChartItemFmt;
  }
};

/**
 * `avgMaintenanceTime` helper method which converts seconds to days, hours, minutes and seconds
 * @param {number} time - duration in seconds
 * @returns {string} labeled time
 */
export const avgMaintenanceTime = (time = 0) => {
  const { d, h, m, s } = millisecondsToHoursMinutes(time * 1000);
  if (time < 60) return `0m ${s}s`;
  return `${d ? `${d}d` : ''} ${h ? `${h}h` : ''} ${m ? `${m}m` : ''} ${s ? `${s}s` : ''}`.trim();
};
