import omit from 'lodash/omit';
import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/branches`;

class BranchService {
  static getBranch(id, authToken) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json').then(
      response => response.json()
    );
  }

  static getAuthorizedBranches(authToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?businessAreas=true&prohibitedAreas=true&restrictedAreas=true`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for updating branch by key, value pair.
   * @param {string} authToken - access token.
   * @param {Number} branchId - id of branch to be updated,
   * @param {object} branch - branch which will be updated.
   * @param {Boolean} isBillable - Branch is billable or not.
   */
  static updateBranchById(authToken, branchId, branch, isBillable) {
    const branchForUpdate = omit(branch, 'currency');
    const billableUrl = isBillable ? '/billable' : '';
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}${billableUrl}/${branchId}`,
      branchForUpdate,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating branch.
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - data for creating branch.
   * @param {boolean} isBillable - indicator is branch `billable`.
   */
  static createBranch(authToken, data, isBillable) {
    const isBillableUrl = isBillable ? '?billable' : '';
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}${isBillableUrl}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for deleting branch by id.
   * @param {string} authToken - access token.
   * @param {number} branchId - id of branch to be deleted.
   */
  static deleteBranch(authToken, branchId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${branchId}`,
      authToken,
      'application/json'
    );
  }
}
export default BranchService;
