import { all, call, select, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';

import { availableEntities } from '../../redux-config';
import { actionStrings, actions } from './actions';
import appActions, { actionStrings as appActionStrings } from '../app/actions';
import branchActions from '../branches/actions';
import customerActions from '../customers/actions';
import authorityActions from '../authority/actions';
import settingsActions from '../settings/actions';
import { actions as pluginActions } from '../plugins/actions';
import { BranchService } from '../../services/coreAPI';
import {
  getSelectedBranchId,
  branchesToArrayForState,
  resolveCurrenciesForBranches,
} from '../../helpers/utility';

import { fetchData, handleResponse } from '../common/commonFlowSagas';
import {
  getFetchEntityErrorSaga,
  getFetchEntityStartSaga,
  getFetchEntityEndSaga,
  getAuthToken,
} from '../common/commonSagas';
import { fetchTable, fetchDetail, CallDownloadInvoice } from '../common';
import { ROLES } from '../../constants/roles';
import { getAllBranches as getBranchesFromState } from '../branches/selectors';
import createNotification from '../../components/notification';
import { branchStates } from '../../constants/branch';

function successHandler(entity) {
  return function* handleSuccesss(result) {
    yield put(actions.fetchEntitySuccess(entity, result));
  };
}

function* fetchDetails(action) {
  const actionDetails = availableEntities.DETAILS;
  yield call(
    fetchData,
    fetchDetail[action.entity],
    successHandler(actionDetails),

    getFetchEntityErrorSaga(actionDetails, action.type), // On fetch error
    getFetchEntityStartSaga(actionDetails, action.type), // On fetch start
    getFetchEntityEndSaga(actionDetails, action.type), // On fetch success
    {
      entity: actionDetails,
      payload: action.payload,
    }
  );
}

function* downloadInvoiceRequest(action) {
  try {
    const invoiceId = action.id;
    const idToken = yield call(getAuthToken);

    if (!idToken) {
      return false;
    }

    const handledResponse = yield call(CallDownloadInvoice, idToken, invoiceId);
    const res = yield call([handledResponse, handledResponse.json]);

    if (res?.errorCode) {
      yield call(createNotification, 'error', res?.userMessage);
      return false;
    }

    if (res) {
      const win = window.open(res, '_blank');
      win.focus();
    }

    yield delay(1000);
    return true;
  } catch (error) {
    // TODO: Move error into a separate reducer and saga
    // Do not use branches
    yield put(appActions.fetchBranchesError(error));
    return false;
  }
}

// used to pull entities and details when page is refreshed
// TODO: research redux persist to persist everything in localstorage
// TODO: research how to resolve '403' error from auth
function* selectWhatToFetch() {
  const pathname = window.location.pathname.split('/');
  const lastPathnameParam = pathname[pathname.length - 1];
  if (Number(lastPathnameParam)) {
    const entityId = Number(lastPathnameParam);
    const entity = pathname[pathname.length - 2];
    switch (entity) {
      case availableEntities.CUSTOMERS: {
        // USED because only customer quickview is using sagas for fetch
        yield fetchDetails({ type: 'FETCH_DETAILS', entity, payload: { id: entityId } });
        break;
      }
      default:
    }
  }
}

const getFirstAvailableNonBillable = branches => {
  const firstBranch = branches.find(
    branch => !branch.billable && branch.state !== branchStates.OUT_OF_BUSINESS
  );
  return firstBranch.id;
};

export function* selectBranch() {
  const allBranchesFromState = yield select(getBranchesFromState);
  const branchIdFromStorage =
    getSelectedBranchId() || getFirstAvailableNonBillable(allBranchesFromState);
  // Get selected branch depending on selected branch id and current user
  const idToken = yield call(getAuthToken);
  if (idToken) {
    const branchDetails = yield call(BranchService.getBranch, branchIdFromStorage, idToken);

    yield put(appActions.selectBranch(branchDetails));
    // Check if user has following roles
    yield put(authorityActions.checkIfUserHasRole([ROLES.ROOT, ROLES.ADMIN, ROLES.FLEET_MANAGER]));

    yield put(authorityActions.fetchUserPermissions());

    yield put(settingsActions.fetchSettings());
    yield put(pluginActions.fetchPlugins());
    yield selectWhatToFetch();
  }
}

function* getAllBranches(action) {
  try {
    const idToken = yield call(getAuthToken);
    yield getFetchEntityStartSaga(availableEntities.BRANCHES, action.type); // On fetch start

    const response = yield call(BranchService.getAuthorizedBranches, idToken);

    const branches = yield call(
      handleResponse,
      response,
      () => null,
      getFetchEntityErrorSaga(availableEntities.BRANCHES, action.type),
      availableEntities.BRANCHES,
      true,
      true
    );

    if (!branches) {
      return;
    }

    const flattenedBranches = resolveCurrenciesForBranches(branchesToArrayForState(branches));

    yield put(branchActions.fetchAllBranchesSuccess(flattenedBranches));
    yield selectBranch();

    yield selectWhatToFetch();
    yield delay(1000);
  } catch (error) {
    yield put(appActions.fetchBranchesError(error));
  } finally {
    yield getFetchEntityEndSaga(availableEntities.BRANCHES, action.type);
  }
}

function* fetchEntity(action) {
  yield call(
    fetchData,
    fetchTable[action.entity],
    successHandler(action.entity),

    getFetchEntityErrorSaga(action.entity, action.type), // On fetch error
    getFetchEntityStartSaga(action.entity, action.type), // On fetch start
    getFetchEntityEndSaga(action.entity, action.type), // On fetch success

    {
      entity: action.entity,
      payload: action.payload,
    }
  );
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionStrings.FETCH_ENTITY, fetchEntity),
    takeLatest(actionStrings.FETCH_ENTITY_TABLE, fetchEntity),
    takeLatest(actionStrings.FETCH_DETAILS, fetchDetails),
    takeEvery(appActionStrings.FETCH_BRANCHES, getAllBranches),
    takeEvery(customerActions.DOWNLOAD_INVOICE, downloadInvoiceRequest),
  ]);
}
