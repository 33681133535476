import { actionPayload } from '../common/commonActions';

const actionStrings = {
  ADD_ROLE: 'ADD_ROLE',

  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',

  FETCH_ROLE_ASSIGNMENTS: 'FETCH_ROLE_ASSIGNMENTS',
  SAVE_ROLE_ASSIGNMENTS: 'SAVE_ROLE_ASSIGNMENTS',

  REMOVE_ROLE: 'REMOVE_ROLE',
  REMOVE_ROLE_SUCCESS: 'REMOVE_ROLE_SUCCESS',

  SAVE_NEW_ROLE_SUCCESS: 'SAVE_NEW_ROLE_SUCCESS',

  FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
  FETCH_USER_PERMISSIONS_SUCCESS: 'FETCH_USER_PERMISSIONS_SUCCESS',
  FETCH_USER_PERMISSIONS_ERROR: 'FETCH_USER_PERMISSIONS_ERROR',

  CHECK_IF_USER_HAS_ROLE: 'CHECK_IF_USER_HAS_ROLE',
  CHECK_IF_USER_HAS_ROLE_SUCCESS: 'CHECK_IF_USER_HAS_ROLE_SUCCESS',
  CHECK_IF_USER_HAS_ROLE_FAIL: 'CHECK_IF_USER_HAS_ROLE_FAIL',
};

const actions = {
  fetchRolesSuccess: actionPayload(actionStrings.FETCH_ROLES_SUCCESS),
  fetchRolesAssignment: actionPayload(actionStrings.FETCH_ROLE_ASSIGNMENTS),
  saveRoleAssignment: actionPayload(actionStrings.SAVE_ROLE_ASSIGNMENTS),
  removeRoleSuccess: actionPayload(actionStrings.REMOVE_ROLE_SUCCESS),
  saveNewRoleSuccess: actionPayload(actionStrings.SAVE_NEW_ROLE_SUCCESS),
  fetchUserPermissions: () => ({
    type: actionStrings.FETCH_USER_PERMISSIONS,
  }),
  fetchUserPermissionsSuccess: actionPayload(actionStrings.FETCH_USER_PERMISSIONS_SUCCESS),
  checkIfUserHasRole: actionPayload(actionStrings.CHECK_IF_USER_HAS_ROLE),
  checkIfUserHasRoleSuccess: actionPayload(actionStrings.CHECK_IF_USER_HAS_ROLE_SUCCESS),
  checkIfUserHasRoleFail: actionPayload(actionStrings.CHECK_IF_USER_HAS_ROLE_FAIL),
};

export default { ...actionStrings, ...actions };
