import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/annotations`;

class AnnotationsService {
  /**
   * Method for fetching all tags.
   * @param {string} AuthToken - access token for method.
   * @param {object} params - query parameters `ex..{size : 500, page: 0}...`.
   */
  static getAllAnnotations(AuthToken, params = {}) {
    const filtersString = createStringFromFilter(params.filters);
    const paramString = createStringFromParams(omit(params, 'filters'), '');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }
}

export default AnnotationsService;
