export default {
  OPEN: 'OPEN',
  PAID: 'PAID',
  CLOSED: 'CLOSED',
  BILLED: 'BILLED',
  EXCEPTION_PAYING: 'EXCEPTION_PAYING',
  EXCEPTION_BILLING: 'EXCEPTION_BILLING',
  EXCEPTION_SEPA: 'EXCEPTION_SEPA',
  EXCEPTION_OPENING: 'EXCEPTION_OPENING',
  IGNORE: 'IGNORE',
  DISPUTED: 'DISPUTED',
  IN_PAYMENT: 'IN_PAYMENT',
  PUSH_WAITING_FOR_USER: 'PUSH_WAITING_FOR_USER',
  PUSH_CANCELLED_BY_USER: 'PUSH_CANCELLED_BY_USER',
  PUSH_BANK_REFUSED: 'PUSH_BANK_REFUSED',
  PUSH_ERROR_INITIALISING: 'PUSH_ERROR_INITIALISING',
  REFUND_IN_PROGRESS: 'REFUND_IN_PROGRESS',
  REFUNDED: 'REFUNDED',
  EXCEPTION_REFUND: 'EXCEPTION_REFUND',
  COLLECTION: 'COLLECTION',
  PAID_EXTERNALLY: 'PAID_EXTERNALLY',
  REFUNDED_EXTERNALLY: 'REFUNDED_EXTERNALLY',
  BILLED_EXTERNAL_COLLECTION: 'BILLED_EXTERNAL_COLLECTION',
  CANCELLED_BY_REFUND: 'CANCELLED_BY_REFUND',
  REFUNDED_BY_CANCELLATION: 'REFUNDED_BY_CANCELLATION',
};
