import { filterActions } from 'redux-ignore';

import { actionStrings } from './actions';

const initState = {
  mainGoal: {
    id: -1,
    area: 'DASHBOARD',
    key: 'metrics.goals.monthly.main',
    value: {
      goal: 'rentals',
    },
  },
  goals: {
    id: -1,
    branchId: -1,
    area: 'DASHBOARD',
    key: 'metrics.goals.monthly',
    value: {
      months: {},
    },
  },
  analytics: {
    isLoading: false,
  },
};

const goalsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_MAIN_GOAL_SUCCESS:
    case actionStrings.UPDATE_MAIN_GOAL_SUCCESS:
      return {
        ...state,
        mainGoal: action.payload,
      };

    case actionStrings.FETCH_GOALS_SUCCESS:
    case actionStrings.ADD_GOAL_SUCCESS:
    case actionStrings.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        goals: action.payload,
      };

    case actionStrings.RESET_GOALS:
      return {
        ...state,
        goals: {
          ...initState.goals,
        },
      };

    case actionStrings.FETCH_GOALS_ANALYTICS_SUCCESS: {
      const { month, value } = action.payload;
      return {
        ...state,
        analytics: {
          ...state.analytics,
          [month]: value,
          isLoading: false,
        },
      };
    }
    case actionStrings.FETCH_GOALS_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          isLoading: true,
        },
      };

    case actionStrings.RESET_GOALS_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...initState.analytics,
        },
      };

    default:
      return state;
  }
};

export default filterActions(goalsReducer, [
  actionStrings.FETCH_MAIN_GOAL_SUCCESS,
  actionStrings.UPDATE_MAIN_GOAL_SUCCESS,
  actionStrings.FETCH_GOALS_SUCCESS,
  actionStrings.ADD_GOAL_SUCCESS,
  actionStrings.UPDATE_GOAL_SUCCESS,
  actionStrings.RESET_GOALS,
  actionStrings.FETCH_GOALS_ANALYTICS_SUCCESS,
  actionStrings.FETCH_GOALS_ANALYTICS,
  actionStrings.RESET_GOALS_ANALYTICS,
]);
