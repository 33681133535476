import omit from 'lodash/omit';

import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/packages`;

class PackagesService {
  /**
   * Retreives all packages for selected branch.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - Id of the branch to fetch for.
   */
  static getPackages(authToken, branchId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Creates a settings package.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - branchId.
   * @param {object} payload - values of the fields
   */
  static createPackage(authToken, branchId, payload) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}`,
      { ...omit(payload, ['branch']), branchId, type: 'Package' },
      authToken,
      'application/json'
    );
  }

  /**
   * Updates a settings package.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - branchId.
   * @param {object} payload - values of the fields
   */
  static updatePackage(authToken, branchId, payload) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${payload.id}`,
      { ...omit(payload, ['branch']), branchId: payload.branch, type: 'Package' },
      authToken,
      'application/json'
    );
  }
}
export default PackagesService;
