import { createSelector } from 'reselect';

const getIsStationsLoadingState = state => state.Stations.isLoading;

/**
 * Selector of `loading` of stations.
 * @param {object} state - redux state.
 */
export const getIsStationsLoading = createSelector(
  getIsStationsLoadingState,
  stationsLoadingState => stationsLoadingState
);

export default {
  getIsStationsLoading,
};
