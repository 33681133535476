import { all, fork, takeEvery, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions, { actionStrings } from './actions';
import { actions as entitiesActions } from '../entities/actions';
import { BookingService } from '../../services/coreAPI';
import { fetchData } from '../common/commonFlowSagas';
import {
  handleErrorSaga,
  handleLoadingStart,
  handleLoadingEnd,
  successNotification,
  errorNotification,
  getAuthToken,
} from '../common/commonSagas';
import { availableEntities } from '../../redux-config';

function addBooking(AuthToken, branchId, params) {
  return BookingService.addBooking(AuthToken, branchId, params);
}

function updateBooking(authToken, params) {
  return BookingService.updateBooking(authToken, params);
}

function pickupBooking(authToken, params) {
  return BookingService.pickupBooking(authToken, params.id);
}

function* successAddBookingHandler(booking) {
  if (booking) yield put(entitiesActions.addItem(availableEntities.BOOKINGS, booking));
  yield put(push('/dashboard/bookings'));
}

function* createBooking(action) {
  yield call(
    fetchData,
    addBooking,
    successAddBookingHandler,
    handleErrorSaga(actions.bookingsError(action)),

    handleLoadingStart(actions.bookingsLoaderStart(action)),
    handleLoadingEnd(actions.bookingsLoaderEnd(action)),
    {
      payload: action.payload,
    }
  );
}

function* pickupBookingItem(action) {
  const idToken = yield call(getAuthToken);
  const response = yield call(pickupBooking, idToken, action.payload);
  if (response && response.errorCode) {
    yield call(errorNotification, response.errorCode, response.userMessage);
  } else if (response) {
    yield put(actions.updateBookingTable({ response }));
    yield call(
      successNotification,
      'feedback.alert.successTitle',
      'booking.alert.successUpdateText'
    );
  }
}

function* updateBookingItem(action) {
  const idToken = yield call(getAuthToken);
  const response = yield call(updateBooking, idToken, action.payload);

  if (response) {
    yield put(actions.updateBookingTable({ response }));
    yield call(
      successNotification,
      'feedback.alert.successTitle',
      'booking.alert.successUpdateText'
    );
  }
}

function* watchCreateBooking() {
  yield takeEvery(actionStrings.ADD_BOOKING, createBooking);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateBooking),
    yield takeEvery(actionStrings.UPDATE_BOOKING, updateBookingItem),
    yield takeEvery(actionStrings.PICKUP_BOOKING, pickupBookingItem),
  ]);
}
