/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import { actionStrings } from './auth/actions';

/**
 * Middleware used for intercept action for refreshing token.
 */
export const authMiddleware = store => next => async action => {
  const result = next(action);
  const decodedJWT = await get(store.getState(), 'Auth.decodedJWT');

  // Check if user tries to hit authenticated urls before auth process
  if (global.location.pathname !== '/' && !decodedJWT) {
    // Set redirect to url that will redirect user after successfull login
    localStorage.setItem('redirect-to-url', global.location.pathname.replace('/dashboard', ''));
  }

  if (
    (action.type === 'persist/REHYDRATE' ||
      action.type === actionStrings.LOGIN_SUCCESS ||
      action.type === actionStrings.REFRESH_TOKEN_SUCCESS) &&
    decodedJWT?.payload
  ) {
    const decodedTime = decodedJWT.payload.exp * 1000;
    setTimeout(() => {
      const refreshToken = get(store.getState(), 'Auth.user.refreshToken', '');
      store.dispatch({ type: actionStrings.REFRESH_TOKEN, refreshToken });
    }, decodedTime - new Date().getTime() - 10000);
  }
  return result;
};
