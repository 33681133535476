import ApiService from './ApiService';

import { createStringFromParams, createStringFromFilter } from './util';

const SETTINGS_URL = `${ApiService.BACKEND_URL}/back/settings-management`;

const SETTINGS_URL_M = `${ApiService.BACKEND_URL}/back/settings`;

export default class SettingsService {
  /**
   * Method for fetching all settings.
   *
   * @param {string} authToken - Access token for identification.
   */
  static fetchSettings(authToken, params = { size: 500 }) {
    const filtersString = createStringFromFilter(params);
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params, '');
    return ApiService.ApiCallAuth(
      'GET',
      `${SETTINGS_URL}?${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching setting by key.
   *
   * @param {string} authToken - Access token for identification.
   * @param {string} [queryString] - (optional) Additional query string to be passed to endpoint.
   * @param {AbortController} [signal] - (optional) AbortController signal for request
   * cancellation.
   */
  static fetchSettingByQueryString(authToken, queryString, signal) {
    const options = {
      method: 'GET',
      signal,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };
    return ApiService.ApiCallAbortable(`${SETTINGS_URL_M}${queryString}`, options);
  }

  /**
   * Method for creating a new seting.
   *
   * @param {string} authToken - Access token for identification.
   * @param {object} data - Data for the new setting.
   */
  static createSetting(authToken, data) {
    return ApiService.ApiCallBody('POST', SETTINGS_URL, data, authToken, 'application/json');
  }

  /**
   * Method for updating an existing seting.
   *
   * @param {string} authToken - Access token for identification.
   * @param {number} id - Id of setting to be updated.
   * @param {object} data - Data for the setting to be updated with.
   */
  static updateSetting(authToken, id, data) {
    return ApiService.ApiCallBody(
      'PUT',
      `${SETTINGS_URL}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for updating an existing setting which allows to reset the settingValueType.
   *
   * @param {string} authToken - Access token for identification.
   * @param {number} id - Id of setting to be updated.
   * @param {object} data - Data for the setting to be updated with.
   */
  static updateSettingWithSettingValueTypeUpdate(authToken, id, data) {
    return ApiService.ApiCallBody(
      'PUT',
      `${SETTINGS_URL}/${id}?updateSettingValueType=true`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method to get setting details
   * @param {string} authToken - authentication bearer token.
   * @param {number} id - setting id.
   */
  static getSetting(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${SETTINGS_URL}/${id}`, authToken, 'application/json');
  }

  /**
   * Method for getting all settings.
   * @param {string} authToken - access token for identification.
   */
  static getAllSettings(authToken, params = { size: 500 }) {
    const filtersString = createStringFromFilter(params);
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params, '');
    return ApiService.ApiCallAuth(
      'GET',
      `${SETTINGS_URL}?${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for delete setting
   * @param {string} authToken - Access token for identification.
   * @param {number} id - of setting to be deleted.
   */
  static deleteSetting(authToken, id) {
    return ApiService.ApiCallAuth('DELETE', `${SETTINGS_URL}/${id}`, authToken, 'application/json');
  }

  /**
   * Method for fetching public setting by key.
   * @param {string} key - key for the setting we want to fetch.
   */
  static fetchPublicSettingByKeys(key) {
    return ApiService.ApiCallNoAuth('GET', `${SETTINGS_URL_M}?keys=${key}`);
  }

  /**
   * Method for reload settings.
   * @param {string} authToken - bearer access token.
   */
  static reloadSettings(authToken) {
    return ApiService.ApiCallBody(
      'POST',
      `${SETTINGS_URL}/reload`,
      {},
      authToken,
      'application/json'
    );
  }
}
