import { all, call, takeEvery, put, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import actions from './actions';
import AuthorityService from '../../services/coreAPI/AuthorityService';
import CustomersService from '../../services/coreAPI/CustomersService';
import { createStringFromParams } from '../../services/coreAPI/util';
import { errorNotification, getAuthToken } from '../common/commonSagas';
import { ROLES } from '../../constants/roles';

import responseOk from '../../utils/responseOk';
import { getBranchId } from '../app/selectors';
import { getAllBranches } from '../branches/selectors';

function* getRoles() {
  const token = yield call(getAuthToken);
  try {
    const response = yield call(AuthorityService.getRoles, token);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put(actions.fetchRolesSuccess(result));
    }
  } catch (error) {
    yield call(errorNotification, 'feedback.alert.errorTips');
  }
}

// form all roles from current branch to parent for user
function* getRoleAssignments(action) {
  const { guserId, branchId } = action.payload;
  const token = yield call(getAuthToken);
  try {
    const response = yield call(
      AuthorityService.getRolesAssignmentMatrix,
      token,
      createStringFromParams({ id: guserId, branchId }, '?')
    );

    const allRoles = yield call([response, response.json]);
    const userRole = allRoles[0] || {};
    yield put(actions.saveRoleAssignment(get(userRole, 'roles', [])));
  } catch (error) {
    yield call(errorNotification, 'feedback.alert.errorTips');
  }
}

function* fetchPermissions() {
  /** `id` of selected branch */
  const branchId = yield select(getBranchId);
  // if branch exists fetch user permissions
  const token = yield call(getAuthToken);

  if (branchId) {
    try {
      const response = yield call(AuthorityService.getUserPersmissions, token, branchId);
      const result = yield call([response, response.json]);
      yield put(actions.fetchUserPermissionsSuccess(result));
    } catch (error) {
      yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.errorTips');
    }
  }
}

const getAncestorBranchIds = ({ branches = [], branchId }) => {
  const buffer = [branchId];
  const retArr = [];

  while (buffer.length) {
    const id = buffer.pop();
    const branch = branches.find(b => b.id === id);
    if (branch?.parentId) buffer.push(branch?.parentId);
    retArr.push(id);
  }

  return retArr;
};

function* checkIfUserHasRole(action) {
  const branchId = yield select(getBranchId);
  const branches = yield select(getAllBranches);
  const idToken = yield call(getAuthToken);

  const currUserResponse = yield call(CustomersService.getCurrentUserDetails, idToken);

  if (!responseOk(currUserResponse)) {
    yield put(actions.checkIfUserHasRoleFail(action.payload));
    return;
  }

  const {
    guser: { id: guserId },
  } = { ...(yield call([currUserResponse, currUserResponse.json])) };

  /** `responses` for all user assignments for the specified role */
  const assignmentResponses = yield all(
    action.payload.map(roleName =>
      call(AuthorityService.getRolesAssignment, idToken, {
        guserId,
        size: 500,
        filters: [{ roleName: [roleName] }],
      })
    )
  );

  if (!assignmentResponses.every(responseOk)) {
    yield put(actions.checkIfUserHasRoleFail(action.payload));
    return;
  }

  /** `list` of all user assignments */
  const userAssignments = flatten(yield all(assignmentResponses.map(res => call([res, res.json]))));

  /** `ids` of the ancestor branches */
  const anchestorBranches = getAncestorBranchIds({ branchId, branches });

  /** `existing` roles for the current selected branch */
  const existingRoles = [
    ...new Set(
      userAssignments
        .filter(assignment => {
          // case when root user
          if (assignment?.roleName === ROLES.ROOT) return true;

          return (
            anchestorBranches.includes(assignment?.branchId) &&
            action.payload.includes(assignment?.roleName)
          );
        })
        .map(assignment => assignment?.roleName)
    ),
  ];

  yield put(actions.checkIfUserHasRoleSuccess(existingRoles));
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ROLES, getRoles),
    takeLatest(actions.FETCH_ROLE_ASSIGNMENTS, getRoleAssignments),
    takeEvery(actions.FETCH_USER_PERMISSIONS, fetchPermissions),
    takeEvery(actions.CHECK_IF_USER_HAS_ROLE, checkIfUserHasRole),
  ]);
}
