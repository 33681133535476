import { actionEntity, actionWithKey } from '../common/commonActions';

const actionStrings = {
  CLEAR_TABLE: 'CLEAR_TABLE',
  SORT_TABLE: 'SORT_TABLE',
  SET_TABLE: 'SET_TABLE',
};

const actions = {
  clearTable: actionEntity(actionStrings.CLEAR_TABLE),
  setTable: actionWithKey(actionStrings.SET_TABLE),
};

export { actions, actionStrings };
