import { action } from '../common/commonActions';

export const actionStrings = {
  EXPORT_CSV: 'EXPORT_CSV',
};

const actions = {
  exportCSV: action(actionStrings.EXPORT_CSV),
};

export default actions;
