const useTenantInfo = () => {
  const { tenantInfo } = window;

  const includesFeature = feature => {
    return tenantInfo?.features?.some(f => f.key === feature);
  };

  return { tenantInfo, includesFeature };
};

export default useTenantInfo;
