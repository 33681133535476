import notification from './feedback/notification';

const createNotification = (type, message, description, duration = 4.5, key = null) => {
  notification[type]({
    message,
    description,
    duration,
    key,
  });
};
export default createNotification;
