import ApiService from './ApiService';

const BASE_URL_BACK = `${ApiService.BACKEND_URL}/back/verification`;

class VerificationService {
  /**
   * Mehod for fetching user verification attempts.
   * @param {string} AuthToken access token for identification
   * @param {*} userId id of user for fetching attempts
   */
  static getVerificationAttempts(AuthToken, userId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL_BACK}?guserId=${userId}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for fetching media data of attempt
   * @param {*} AuthToken access token for identification
   * @param {*} mediaUuid uuid od media to fetch
   */
  static getVerificationMedia(AuthToken, mediaUuid) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL_BACK}/media/${mediaUuid}`,
      AuthToken,
      'application/json'
    );
  }
}
export default VerificationService;
