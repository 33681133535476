import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createIntl, createIntlCache } from 'react-intl';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import rootSaga from './sagas';
import { authMiddleware } from './middlewares';

import AppLocale from '../languageProvider';
import config, { getCurrentLanguage } from '../containers/LanguageSwitcher/config';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];
const cache = createIntlCache();

const { locale, messages } = currentAppLocale;
const intl = createIntl(
  {
    locale,
    messages,
  },
  cache
);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({ context: { intl } });
const routeMiddleware = connectRouter(history);

const middlewares = [authMiddleware, sagaMiddleware, routerMiddleware(history)];

const authPersistConfig = {
  key: 'Auth',
  storage,
};

const store = createStore(
  combineReducers({
    ...reducers,
    router: routeMiddleware,
    Auth: persistReducer(authPersistConfig, reducers.Auth),
  }),
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

export { store, history };
