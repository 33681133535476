/**
 * Converts an object with key-value pairs into a fully formed query string.
 * Optionally the query string can be without the `?` separator by passing along
 * the second argument to the function.
 * @param {object} params - The key-value pairs to return as a query string.
 * @param {boolean} omitSeparator - Boolean operator that omits the '?' separator if specified.
 */
const objectToQueryString = (params, omitSeparator = false) => {
  const queryString = Object.entries(params).reduce((prev, curr, index) => {
    const query = `${curr[0]}=${curr[1]}`;
    if (index !== 0) {
      return `${prev}&${query}`;
    }
    return `${prev}${query}`;
  }, '');
  if (omitSeparator) return queryString;
  return `?${queryString}`;
};

export default objectToQueryString;
