import * as Sentry from '@sentry/react';
import React, { useCallback } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import DashAppHolder from './dashAppStyle';
import FallBack from './fallback';
import InternationalizationProvider from './internationalization-provider';
import AppLocale from './languageProvider';
import { store } from './redux/store';
import PublicRoutes from './router';
import { themeConfig } from './settings';
import themes from './settings/themes';

/**
 * New colors and styling
 * Refer to: https://www.figma.com/file/Ckxbd5Tdg2nda5yFp3TuZH/Dashboard?node-id=2850%3A6279
 */
const CSSVariables = () => {
  const primaryColorVariations = useCallback(() => {
    return `
      --blue-1: #E6F7FF;
      --blue-2: #BAE7FF;
      --blue-3: #91D5FF;
      --blue-4: #69C0FF;
      --blue-5: #40A9FF;
      --blue-6: #1890ff;
      --blue-7: #096DD9;
      --blue-8: #0050B3;
      --blue-9: #003A8C;
      --blue-10: #002766;
    `;
  }, []);

  const volcanoColorVariations = useCallback(() => {
    return `
      --volcano-1: #FFF2E8;
      --volcano-2: #FFD8BF;
      --volcano-3: #FFBB96;
      --volcano-4: #FF9C6E;
      --volcano-5: #FF7A45;
      --volcano-6: #FA541C;
      --volcano-7: #D4380D;
      --volcano-8: #AD2102;
      --volcano-9: #871400;
      --volcano-10: #610B00;
    `;
  }, []);

  const dustRedColorVariations = useCallback(() => {
    return `
      --red-1: #FFF1F0;
      --red-2: #FFCCC7;
      --red-3: #FFA39E;
      --red-4: #FF7875;
      --red-5: #FF4D4F;
      --red-6: #F5222D;
      --red-7: #CF1322;
      --red-8: #A8071A;
      --red-9: #820014;
      --red-10: #5C0011;
    `;
  }, []);

  const sunsetOrangeColorVariations = useCallback(() => {
    return `
      --orange-1: #FFF7E6;
      --orange-2: #FFE7BA;
      --orange-3: #FFD591;
      --orange-4: #FFC069;
      --orange-5: #FA8C16;
      --orange-6: #FA8C16;
      --orange-7: #D46B08;
      --orange-8: #AD4E00;
      --orange-9: #873800;
      --orange-10: #612500;
    `;
  }, []);

  const calendulaGoldColorVariations = useCallback(() => {
    return `
      --gold-1: #FFFBE6;
      --gold-2: #FFF1B8;
      --gold-3: #FFE58F;
      --gold-4: #FFD666;
      --gold-5: #FFC53D;
      --gold-6: #FAAD14;
      --gold-7: #D48806;
      --gold-8: #AD6800;
      --gold-9: #874D00;
      --gold-10: #613400;
    `;
  }, []);

  const sunriseYellowColorVariations = useCallback(() => {
    return `
      --yellow-1: #FEFFE6;
      --yellow-2: #FFFFB8;
      --yellow-3: #FFFB8F;
      --yellow-4: #FFF566;
      --yellow-5: #FFEC3D;
      --yellow-6: #FADB14;
      --yellow-7: #D4B106;
      --yellow-8: #AD8B00;
      --yellow-9: #876800;
      --yellow-10: #614700;
    `;
  }, []);

  const limeColorVariations = useCallback(() => {
    return `
      --lime-1: #FCFFE6;
      --lime-2: #F4FFB8;
      --lime-3: #EAFF8F;
      --lime-4: #D3F261;
      --lime-5: #BAE637;
      --lime-6: #A0D911;
      --lime-7: #7CB305;
      --lime-8: #5B8C00;
      --lime-9: #3F6600;
      --lime-10: #254000;
    `;
  }, []);

  const polarGreenColorVariations = useCallback(() => {
    return `
      --green-1: #F6FFED;
      --green-2: #D9F7BE;
      --green-3: #B7EB8F;
      --green-4: #95DE64;
      --green-5: #73D13D;
      --green-6: #52C41A;
      --green-7: #389E0D;
      --green-8: #237804;
      --green-9: #135200;
      --green-10: #092B00;
      --green-11: #13c2c2;
    `;
  }, []);

  const cyanColorVariations = useCallback(() => {
    return `
      --cyan-1: #E6FFFB;
      --cyan-2: #B5F5EC;
      --cyan-3: #87E8DE;
      --cyan-4: #5CDBD3;
      --cyan-5: #36CFC9;
      --cyan-6: #13C2C2;
      --cyan-7: #08979C;
      --cyan-8: #006D75;
      --cyan-9: #00474F;
      --cyan-10: #002329;
    `;
  }, []);

  const geekBlueColorVariations = useCallback(() => {
    return `
      --geekblue-1: #F0F5FF;
      --geekblue-2: #D6E4FF;
      --geekblue-3: #ADC6FF;
      --geekblue-4: #85A5FF;
      --geekblue-5: #597EF7;
      --geekblue-6: #2F54EB;
      --geekblue-7: #1D39C4;
      --geekblue-8: #10239E;
      --geekblue-9: #061178;
      --geekblue-10: #030852;
    `;
  }, []);

  const goldenPurpleColorVariations = useCallback(() => {
    return `
      --purple-1: #F9F0FF;
      --purple-2: #EFDBFF;
      --purple-3: #D3ADF7;
      --purple-4: #B37FEB;
      --purple-5: #9254DE;
      --purple-6: #722ED1;
      --purple-7: #531DAB;
      --purple-8: #391085; 
      --purple-9: #22075E;
      --purple-10: #120338;
    `;
  }, []);

  const magentaColorVariations = useCallback(() => {
    return `
      --magenta-1: #FFF0F6;
      --magenta-2: #FFD6E7;
      --magenta-3: #FFADD2;
      --magenta-4: #FF85C0;
      --magenta-5: #F759AB;
      --magenta-6: #EB2F96;
      --magenta-7: #C41D7F;
      --magenta-8: #9E1068; 
      --magenta-9: #780650;
      --magenta-10: #520339;
    `;
  }, []);

  const extraColorVariations = useCallback(() => {
    return `
      --background-1: #F0F2F5;
      --background-2: #001529;
      --background-3: #000C17;
    `;
  }, []);

  const neutralColorVariations = useCallback(() => {
    return `
      --gray-1: #FFFFFF;
      --gray-2: #F6F8FC;
      --gray-3: #F1F4F9;
      --gray-4: #E2E8F0;
      --gray-5: #CBD4E1;
      --gray-6: #94A3B8;
      --gray-7: #64748B;
      --gray-8: #475569; 
      --gray-9: #27364B;
      --gray-10: #0F1A2A;
    `;
  }, []);

  const regularWeightFont = useCallback(() => {
    return `
      --font-r-0: normal normal 400 10px/14px 'Inter', sans-serif;
      --font-r-1: normal normal 400 12px/20px 'Inter', sans-serif;
      --font-r-2: normal normal 400 14px/22px 'Inter', sans-serif;
      --font-r-3: normal normal 400 16px/24px 'Inter', sans-serif;
      --font-r-4: normal normal 400 20px/28px 'Inter', sans-serif;
      --font-r-5: normal normal 400 24px/32px 'Inter', sans-serif;
      --font-r-6: normal normal 400 30px/38px 'Inter', sans-serif;
      --font-r-7: normal normal 400 38px/46px 'Inter', sans-serif;
      --font-r-8: normal normal 400 46px/54px 'Inter', sans-serif;
      --font-r-9: normal normal 400 56px/64px 'Inter', sans-serif;
    `;
  }, []);

  const mediumWeightFont = useCallback(() => {
    return `
      --font-sb-0: normal normal 600 10px/14px 'Inter', sans-serif;
      --font-sb-1: normal normal 600 12px/20px 'Inter', sans-serif;
      --font-sb-2: normal normal 600 14px/22px 'Inter', sans-serif;
      --font-sb-3: normal normal 600 16px/24px 'Inter', sans-serif;
      --font-sb-4: normal normal 600 20px/28px 'Inter', sans-serif;
      --font-sb-5: normal normal 600 24px/32px 'Inter', sans-serif;
      --font-sb-6: normal normal 600 30px/38px 'Inter', sans-serif;
      --font-sb-7: normal normal 600 38px/46px 'Inter', sans-serif;
      --font-sb-8: normal normal 600 46px/54px 'Inter', sans-serif;
      --font-sb-9: normal normal 600 56px/64px 'Inter', sans-serif;
    `;
  }, []);

  const semiBoldWeightFont = useCallback(() => {
    return `
      --font-m-0: normal normal 500 10px/14px 'Inter', sans-serif;
      --font-m-1: normal normal 500 12px/20px 'Inter', sans-serif;
      --font-m-2: normal normal 500 14px/22px 'Inter', sans-serif;
      --font-m-3: normal normal 500 16px/24px 'Inter', sans-serif;
      --font-m-4: normal normal 500 20px/28px 'Inter', sans-serif;
      --font-m-5: normal normal 500 24px/32px 'Inter', sans-serif;
      --font-m-6: normal normal 500 30px/38px 'Inter', sans-serif;
      --font-m-7: normal normal 500 38px/46px 'Inter', sans-serif;
      --font-m-8: normal normal 500 46px/54px 'Inter', sans-serif;
      --font-m-9: normal normal 500 56px/64px 'Inter', sans-serif;
    `;
  }, []);

  const boldWeightFont = useCallback(() => {
    return `
      --font-b-1: normal normal 700 10px/18px 'Inter', sans-serif;
    `;
  }, []);

  const minifyCssString = useCallback(css => {
    return css.replace(/\n/g, '').replace(/\s\s+/g, ' ');
  }, []);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: minifyCssString(`:root {
            ${primaryColorVariations()}
            ${neutralColorVariations()}
            ${volcanoColorVariations()}
            ${dustRedColorVariations()}
            ${sunsetOrangeColorVariations()}
            ${calendulaGoldColorVariations()}        
            ${sunriseYellowColorVariations()}
            ${limeColorVariations()}
            ${polarGreenColorVariations()}
            ${cyanColorVariations()}
            ${geekBlueColorVariations()}
            ${goldenPurpleColorVariations()}
            ${magentaColorVariations()}
            ${extraColorVariations()}
            ${regularWeightFont()}
            ${mediumWeightFont()}
            ${semiBoldWeightFont()}
            ${boldWeightFont()}
  }
  
   .ant-input {
    border-color: var(--gray-5);
    font: var(--font-r-2) !important;
  }

  .ant-input::placeholder {
    color: var(--gray-6);
    font: var(--font-r-2);
  }
  
  .ant-picker-input input::placeholder {
       color: var(--gray-6);
       font: var(--font-r-2) !important;
  } 
  
  .ant-picker-input input {
     font: var(--font-r-2);
  }

  .ant-form-item-label label {
    font: var(--font-m-2) !important;
    height: auto !important;
    color: var(--gray-9);
  }

  .ant-form .ant-select .ant-select-selector {
    border-color: var(--gray-5);
  }
  
  .ant-input-number {
    border-color: var(--gray-5);
  }
  
  .ant-modal-title {
    font: var(--font-m-4);
  }
  
  .ant-dropdown-menu-item .ant-dropdown-menu-item, 
  .ant-dropdown-menu-item:has(.ant-dropdown-menu-title-content:empty) {
    padding: 0;
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-checkbox-menu .ant-dropdown-menu-title-content {
    justify-content: flex-start;
  }

  .dropdown-checkbox-menu .ant-dropdown-menu-item {
    padding: 3px 0;
    background: transparent;
  }

  .dropdown-checkbox-menu .ant-dropdown-menu-item .ant-dropdown-menu-item {
    padding: 0;
  }

  .dropdown-checkbox-menu .ant-dropdown-menu-item:has(.ant-divider) {
    background: transparent;
    pointer-events: none;
    padding: 0;
  }

  .dropdown-checkbox-menu .ant-dropdown-menu-item .ant-divider {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ant-tooltip:has(.ant-tooltip-inner:empty) {
    display: none !important;
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-item, 
  .ant-dropdown-menu-item:hover .ant-dropdown-menu-item,
  .ant-dropdown-menu-item span:not(.ant-checkbox):not(.ant-checkbox-inner) {
    background: transparent;
    width: 100%;
  }

  .ant-dropdown-menu-item:has(.ant-dropdown-menu-item-divider) {
    pointer-events: none;
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
    padding: 0;
  }
  
  .ant-dropdown-trigger > .anticon.anticon-down, 
  .ant-dropdown-link > .anticon.anticon-down, 
  .ant-dropdown-button > .anticon.anticon-down {
    vertical-align: middle;
  }

  .service-state-dropdown .ant-dropdown-menu-item-group-title {
    font: var(--font-b-1);
    color: var(--gray-9);
  }
  
  .ant-btn.icon-btn > .anticon {
    margin-right: 8px;
  }

  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    background: var(--gray-4);
    border-color: var(--gray-4);
    color: var(--gray-6);
  }
  .ant-radio-wrapper {
    width: 20px;
  }
  `),
        }}
      />
    </>
  );
};

const DashApp = () => (
  <Sentry.ErrorBoundary fallback={FallBack}>
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <DashAppHolder>
        <Provider store={store}>
          <CSSVariables />
          <PersistGate loading={null} persistor={persistStore(store)}>
            <InternationalizationProvider>
              <HttpsRedirect disabled={process.env.REACT_APP_DISABLED_HTTPS_REDIRECT === 'true'}>
                <PublicRoutes />
              </HttpsRedirect>
            </InternationalizationProvider>
          </PersistGate>
        </Provider>
      </DashAppHolder>
    </ThemeProvider>
  </Sentry.ErrorBoundary>
);

export default DashApp;
export { AppLocale };
