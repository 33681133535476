/*
  TODO Refactoring will come with new design.
  W
  We agreed with Bojan about it
*/
import { call, put, all, fork, takeEvery, select, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import fileDownload from 'js-file-download';
import actions from './actions';
import appActions from '../app/actions';
import IssuesService from '../../services/coreAPI/IssuesService';
import InvoiceService from '../../services/coreAPI/InvoiceService';
import FileService from '../../services/coreAPI/FileService';
import { actions as entityActions } from '../entities/actions';
import { quickviewActions } from '../quickview/actions';
import { availableEntities as _ } from '../../redux-config';
import { successNotification, errorNotification, getAuthToken } from '../common/commonSagas';
import { replaceUrl } from '../../helpers/utility';

import responseOk from '../../utils/responseOk';
import { getSelectedBranch } from '../app/selectors';

function handleNotification(type, message, statusCode) {
  switch (true) {
    case statusCode === 401:
      return notification[type]({
        message: 'Unauthorized',
        description: 'You have been logged out',
        placement: 'topRight',
      });
    case statusCode >= 500:
      return notification[type]({
        message: 'API Error',
        description: 'Performance or functionality may be degraded',
        placement: 'topRight',
      });
    default:
      return notification[type]({
        message,
        placement: 'topRight',
      });
  }
}

function* getBranch() {
  const branch = yield select(getSelectedBranch);
  return branch;
}

function checkErrorsInResponseArray(arr) {
  for (let i = 0; i < arr.length; i + 1) {
    if (arr[i].errorCode) {
      return false;
    }
  }
  return true;
}

function createIssue(AuthToken, branchId, data) {
  return IssuesService.createIssue(AuthToken, branchId, data)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'Issue failed to create' : 'Issue successfully created'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'Issue failed to create');
      return err;
    });
}

function createComment(AuthToken, issueId, state, comment) {
  return IssuesService.createComment(AuthToken, issueId, state, comment)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'Comment failed to create' : 'Comment successfully created'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'Comment failed to create');
      return err;
    });
}

function createUserInvoice(AuthToken, branchId, data) {
  return IssuesService.createUserInvoice(AuthToken, data)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'User invoice failed to create' : 'User invoice successfully created'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'User invoice failed to create');
      return err;
    });
}

function updateUserInvoice(AuthToken, branchId, data) {
  return InvoiceService.updateInvoice(AuthToken, data)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'User invoice failed to update' : 'User invoice successfully updated'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'User invoice failed to update');
      return err;
    });
}

function createInvoiceLineItem(AuthToken, branchId, data) {
  return IssuesService.createInvoiceLineItem(AuthToken, data)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'Line item failed to create' : 'Line item successfully created'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'Line item failed to create');
      return err;
    });
}

export function uploadFile(AuthToken, file, isPublic) {
  return FileService.uploadFile(AuthToken, file, isPublic)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'File failed to upload' : 'File sucessfully uploaded'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'File failed to upload');
      return err;
    });
}

function billInvoice(AuthToken, branchId, data) {
  return IssuesService.billInvoice(AuthToken, data.issueId, data.data)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'Bill invoice failed' : 'Bill invoice successfully created'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'Bill invoice failed');
      return err;
    });
}

function linkFileToComment(AuthToken, commentId, fileId) {
  return IssuesService.linkFileToComment(AuthToken, commentId, fileId)
    .then(res => {
      handleNotification(
        !res.ok ? 'error' : 'success',
        !res.ok ? 'Link file to comment failed' : 'File successfully added to comment'
      );
      return res;
    })
    .catch(err => {
      handleNotification('error', 'Link file to comment failed');
      return err;
    });
}

function downloadFileByUrl(url) {
  return IssuesService.downloadFileByUrl(url);
}

function downloadFile(AuthToken, id) {
  return FileService.getFileURL(AuthToken, id);
}

// eslint-disable-next-line consistent-return
function* customApiCall(apiCall, updateState, action) {
  const idToken = yield call(getAuthToken);
  const branch = yield call(getBranch);
  if (idToken) {
    const response = yield call(apiCall, idToken, branch.id, action.data);
    const res = yield call([response, response.json]);
    if (updateState && !res.errorCode) {
      yield put(actions.setIssue(res));
    }
    return res;
  }
}

function* uploadFiles(idToken, commentId, files, isPublic) {
  // Upload files
  const filesResponse = yield all(files.map(file => call(uploadFile, idToken, file, isPublic)));
  // Handle each file response
  const uploadedFiles = yield all(
    filesResponse.map(fileResponse => {
      // Convert fileResponse to a valid object
      const file = call([fileResponse, fileResponse.json]);
      return file;
    })
  );
  // Link files to comment
  const commentFilesResponse = yield all(
    uploadedFiles.map(uploadedFile => call(linkFileToComment, idToken, commentId, uploadedFile.id))
  );

  // Handle each file response
  const commentFiles = yield all(
    commentFilesResponse.map(res => {
      // Convert fileResponse to a valid object
      const result = call([res, res.json]);
      return result;
    })
  );
  return commentFiles;
}

function* createCommentHandler(action) {
  try {
    const { state, comment, issueId, files, isPublic } = action.data;
    const idToken = yield call(getAuthToken);
    if (idToken) {
      const commentResponse = yield call(createComment, idToken, issueId, state, comment);
      const commentResult = yield call([commentResponse, commentResponse.json]);
      if (commentResult) {
        const commentId = commentResult.comments[commentResult.comments.length - 1].id;
        if (files.length > 0) {
          handleNotification('info', `${files.length === 1 ? 'File' : 'Files'} started uploading`);
          const result = yield call(uploadFiles, idToken, commentId, files, isPublic);
          // Update state depending on files
          yield put(actions.setIssue(result[0]));
        } else {
          yield put(actions.setIssue(commentResult));
        }
      }
    }
    yield delay(1000);
  } catch (error) {
    yield put(appActions.fetchBranchesError(error));
  }
}

function* downloadFileHandler(action) {
  try {
    const idToken = yield call(getAuthToken);
    if (idToken && action.fileId) {
      const handledResponse = yield call(downloadFile, idToken, action.fileId);
      const url = yield call([handledResponse, handledResponse.text]);

      if (url) {
        const handledBlobResponse = yield call(downloadFileByUrl, url);
        const blob = yield call([handledBlobResponse, handledBlobResponse.blob]);

        const fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
        // start downloading a file
        fileDownload(blob, fileName);
      }
    }
  } catch (error) {
    yield put(appActions.fetchBranchesError(error));
  }
}

function* createDirectBilling(action) {
  const { issue, comment, invoice, openQuickview = false, cb = () => null } = action.data;
  const idToken = yield call(getAuthToken);
  if (idToken) {
    yield put(actions.loaderStart());
    const issueResult = yield call(customApiCall, createIssue, true, { data: issue });
    if (issueResult && issueResult.id) {
      // Create comment after issue is created
      if (comment && (comment.comment || comment.files.length > 0)) {
        yield call(createCommentHandler, { data: { ...comment, issueId: issueResult.id } });
      }
      if (invoice) {
        const createUserInvoiceResponse = yield call(customApiCall, createUserInvoice, true, {
          data: {
            data: {
              title: invoice.title,
              description: invoice.description,
            },
            issueId: issueResult.id,
          },
        });
        const createInvoiceLineItemsResponse = yield all(
          invoice.lineItems.map(lineItem =>
            call(customApiCall, createInvoiceLineItem, true, {
              data: {
                data: lineItem,
                issueId: issueResult.id,
              },
            })
          )
        );
        if (
          createUserInvoiceResponse &&
          checkErrorsInResponseArray(createInvoiceLineItemsResponse)
        ) {
          yield call(customApiCall, billInvoice, true, {
            data: {
              data: {
                message: '',
              },
              issueId: issueResult.id,
            },
          });
        }
      }
      yield put(actions.loaderEnd());
      yield put(entityActions.addItem(_.ISSUES, issueResult));
      // open quickview after issue creation
      if (openQuickview && issueResult.id) {
        replaceUrl(_.ISSUES, issueResult.id);
        yield put(quickviewActions.selectItem(_.ISSUES, issueResult.id));
      }
      cb(issueResult);
    }
  }
}

function* refundInvoice({ payload, onSuccess, onError }) {
  const { lineItemRefundGrossAmounts, invoiceId, reason } = payload;
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(InvoiceService.refundInvoice, idToken, invoiceId, {
      lineItemRefundGrossAmounts,
      reason,
    });
    if (responseOk(response)) {
      yield call(onSuccess);
      yield call(successNotification, 'feedback.alert.successTitle', 'refund.success.message');
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage && result.errorCode) {
      yield call(
        errorNotification,
        'feedback.alert.errorTips',
        `Code: ${result.errorCode}; ${result.userMessage}`
      );
    }
    if (onError) yield call(onError);
  } catch (error) {
    yield call(handleNotification, 'error', error);
  }
}

function* watchCreateUserInvoice() {
  yield takeEvery(actions.CREATE_USER_INVOICE, customApiCall, createUserInvoice, true);
}

function* watchUpdateUserInvoice() {
  yield takeEvery(actions.UPDATE_USER_INVOICE, customApiCall, updateUserInvoice, false);
}

function* watchCreateInvoiceLineItem() {
  yield takeEvery(actions.CREATE_INVOICE_LINE_ITEM, customApiCall, createInvoiceLineItem, true);
}

function* watchBillInvoice() {
  yield takeEvery(actions.BILL_INVOICE, customApiCall, billInvoice, true);
}

function* watchDownloadFile() {
  yield takeEvery(actions.DOWNLOAD_FILE, downloadFileHandler);
}

function* watchCreateDirectBilling() {
  yield takeEvery(actions.DIRECT_BILLING, createDirectBilling);
}

function* watchRefundInvoice() {
  yield takeEvery(actions.REFUND_INVOICE, refundInvoice);
}

/*
  End Watchers
*/

export default function* rootSaga() {
  yield all([
    fork(watchCreateUserInvoice),
    fork(watchUpdateUserInvoice),
    fork(watchBillInvoice),
    fork(watchCreateInvoiceLineItem),
    fork(watchDownloadFile),
    fork(watchCreateDirectBilling),
    fork(watchRefundInvoice),
  ]);
}
