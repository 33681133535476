import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = { allBranches: [] };

const branchesReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_ALL_BRANCHES_SUCCESS: {
      return {
        ...state,
        allBranches: action.payload,
      };
    }
    case actionStrings.UPDATE_BRANCH: {
      return {
        ...state,
        allBranches:
          state?.allBranches?.reduce((acc, curr) => {
            if (curr?.id === action?.payload.id) {
              acc.push({ ...curr, ...action.payload });
            } else {
              acc.push(curr);
            }

            return acc;
          }, []) || [],
      };
    }
    default:
      return state;
  }
};

export default filterActions(branchesReducer, [
  actionStrings.FETCH_ALL_BRANCHES_SUCCESS,
  actionStrings.UPDATE_BRANCH,
]);
