import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const jwtJsDecode = require('jwt-js-decode');

const initState = {
  user: undefined,
  decodedJWT: undefined,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        decodedJWT: jwtJsDecode.jwtDecode(action.user.accessToken),
      };
    case actionStrings.LOGIN_SUCCESS_2FA: {
      // TODO: Remove this when we switch to new dashboard
      // Update new dashboard local Storage as well
      const newDashboardLocalStorageAuthInfo =
        localStorage.getItem('persist:auth') && JSON.parse(localStorage.getItem('persist:auth'));

      localStorage.setItem(
        'persist:auth',
        JSON.stringify({
          ...(newDashboardLocalStorageAuthInfo ?? {}),
          authInfo: JSON.stringify(state.user),
        })
      );

      return {
        ...state,
        userIsLogged: action.data,
      };
    }
    case actionStrings.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        decodedJWT: jwtJsDecode.jwtDecode(action.user.accessToken),
      };
    case actionStrings.LOGOUT:
    case actionStrings.LOGOUT_2FA:
    case actionStrings.REFRESH_TOKEN_FAIL:
      return initState;
    default:
      return state;
  }
};

export default filterActions(authReducer, [
  actionStrings.LOGIN_SUCCESS,
  actionStrings.LOGIN_SUCCESS_2FA,
  actionStrings.REFRESH_TOKEN_SUCCESS,
  actionStrings.LOGOUT,
  actionStrings.LOGOUT_2FA,
  actionStrings.REFRESH_TOKEN_FAIL,
]);
