const TenantFeatures = {
  USER_API: 'user-api',
  MDS_API: 'mds-api',
  DASHBOARD: 'dashboard',
  GO_DASH_BETA: 'ft:go-dash-beta',
  HIDE_OLD_PRICING: 'ft:hide-old-pricing',
  MAINTENANCE_TOOLS: 'maintenance-tools',
};

export default TenantFeatures;
