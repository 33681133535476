import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import get from 'lodash/get';
import App from './containers/App/App';
import { history } from './redux/store';
import PublicContainer from './containers/Public';
import useTenantInfo from './hooks/useTenantInfo';
import TenantFeatures from './constants/features';
import useGoDashIntegration from './hooks/useGoDashIntegration';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ isLoggedIn }) => {
  const { tenantInfo, includesFeature } = useTenantInfo();
  const { redirectToGoDash } = useGoDashIntegration();

  if (tenantInfo && !includesFeature(TenantFeatures.DASHBOARD)) {
    // New dashboard handles the no dashboard feature flag logic, no need to implement it here, just redirect there
    return redirectToGoDash('', { newWindow: false });
  }

  return (
    <ConnectedRouter history={history}>
      <PublicContainer />
      <RestrictedRoute path="" component={App} isLoggedIn={isLoggedIn} />
    </ConnectedRouter>
  );
};

PublicRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
};

PublicRoutes.defaultProps = {
  isLoggedIn: false,
};

// TODO: Move restricted route to separate component
RestrictedRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  // eslint-disable-next-line
  location: PropTypes.any,
  // eslint-disable-next-line
  component: PropTypes.any.isRequired,
};

RestrictedRoute.defaultProps = {
  isLoggedIn: false,
};

const mapStateToProps = state => ({
  isLoggedIn: get(state, 'Auth.userIsLogged'),
});

export default connect(mapStateToProps)(PublicRoutes);
