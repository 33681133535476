export const MAP_VIEW = 'map.view';
export const SUPPORT_METHODS = 'support.methods';
export const AREAS = 'areas';
export const NAVIGATION = 'navigation';
export const THEME = 'theme';
export const THEME_COLORS_MAIN = 'theme.colors.main';
export const THEME_COLORS_MAIN_DARK = 'theme.colors.main.dark';
export const THEME_COLORS_BALANCE_HISTORY = 'theme.colors.balancehistory';
export const THEME_COLORS_BALANCE_HISTORY_DARK = 'theme.colors.balancehistory.dark';
export const THEME_COLORS_WALLET = 'theme.colors.wallet';
export const THEME_COLORS_WALLET_DARK = 'theme.colors.wallet.dark';
export const THEME_COLORS_PACKAGE_MEMBERSHIPS = 'theme.colors.packages.memberships';
export const THEME_COLORS_PACKAGE_MEMBERSHIPS_DARK = 'theme.colors.packages.memberships.dark';
export const THEME_SPLASHSCREEN = 'theme.splashScreen';
export const SCREENS_LOGIN = 'screens.login';
export const USER_REQUIRED_FIELDS = 'user.requiredFields';
export const APP_MAP = 'map';
export const APP_RENTAL = 'rental';
export const APP_MAP_CLUSTER_IMAGE = 'map.clusterImage';
export const THEME_COLORS_MAP = 'theme.colors.map';
export const THEME_COLORS_MAP_DARK = 'theme.colors.map.dark';
export const CUSTOM_LOGIN = 'custom.login';
export const VEHICLES = 'vehicles';
export const DRAWER = 'drawer';
export const USER_FEEDBACK = 'user.feedback';

export const PRICING = 'pricing';
export const INSURANCE = 'insurance';
export const GOALS = 'metrics.goals.monthly';
export const NOTIFICATION = 'notification';
export const MAIN_GOAL = 'metrics.goals.monthly.main';
export const APP_MAIN = 'app.main';
export const ABOUT_APP = 'aboutApp';
export const RENTAL = 'rental';
export const MINIMUM_REQUIRED_APP_VERSION = 'minimumRequiredAppVersion';
export const PACKAGES = 'packages';
export const BOOKING = 'booking.setting';
export const BOOKING_AREA = 'booking';
export const CURRENCY = 'currency';
export const QR_CODE = 'qr.code';
export const PAYMENT_METHODS = 'payment.methods';
export const USER_ACCOUNTS = 'user.accounts';
export const MONEY_DEPOSIT_SETTING = 'rental.money-deposit';
export const USER_DATA_UPDATE_PERMISSIONS = 'user.data.update.permissions';
export const APPLE_PAY_SETTING = 'payment.applepay';

export const APP_SETTINGS_MENU_QUICKVIEW = 'menu';
export const APP_SETTINGS_THEME_QUICKVIEW = 'theme';
export const APP_SETTINGS_GENERAL_QUICKVIEW = 'general';
export const APP_SETTINGS_TUTORIALS_QUICKVIEW = 'tutorials';
export const APP_SETTINGS_TUTORIALS_QUICKVIEW_ITEM = 'tutorialInfo';
export const RENTAL_REQUIREMENTS_QUICKVIEW = 'rental-requirements';
export const REFERRAL_REQUIREMENTS_QUICKVIEW = 'referral-requirements';
export const SUBSCRIPTIONS_PLUGIN_QUICKVIEW = 'subscriptions-plugin';

export const VOUCHER_CASHBACK_KEY = 'voucher.cashback';

export const VOUCHER_TRIGGERED_KEY = 'voucher.triggered';

export const BRANCH_BALANCE_SETTING = 'balance.setting';

export const availableSettingsQuickviews = [
  APP_SETTINGS_MENU_QUICKVIEW,
  APP_SETTINGS_THEME_QUICKVIEW,
  APP_SETTINGS_GENERAL_QUICKVIEW,
  APP_SETTINGS_TUTORIALS_QUICKVIEW,
  RENTAL_REQUIREMENTS_QUICKVIEW,
  REFERRAL_REQUIREMENTS_QUICKVIEW,
];

export default {
  app: [
    SUPPORT_METHODS,
    AREAS,
    NAVIGATION,
    THEME,
    THEME_COLORS_MAIN,
    THEME_COLORS_MAIN_DARK,
    THEME_SPLASHSCREEN,
    THEME_COLORS_BALANCE_HISTORY,
    THEME_COLORS_BALANCE_HISTORY_DARK,
    THEME_COLORS_WALLET,
    THEME_COLORS_WALLET_DARK,
    THEME_COLORS_PACKAGE_MEMBERSHIPS,
    THEME_COLORS_PACKAGE_MEMBERSHIPS_DARK,
    SCREENS_LOGIN,
    APP_MAP,
    APP_RENTAL,
    APP_MAP_CLUSTER_IMAGE,
    THEME_COLORS_MAP,
    THEME_COLORS_MAP_DARK,
    ABOUT_APP,
    APP_MAIN,
    RENTAL,
    MINIMUM_REQUIRED_APP_VERSION,
    PACKAGES,
    CUSTOM_LOGIN,
    VEHICLES,
    BOOKING_AREA,
    CURRENCY,
    QR_CODE,
    PAYMENT_METHODS,
    USER_REQUIRED_FIELDS,
    DRAWER,
    USER_ACCOUNTS,
    APPLE_PAY_SETTING,
    USER_FEEDBACK,
  ],
  core: [USER_DATA_UPDATE_PERMISSIONS],
  map: [MAP_VIEW],
  GOALS,
  MAIN_GOAL,
};
