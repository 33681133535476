import React from 'react';
import { Route } from 'react-router-dom';

const PublicContainer = () => {
  return (
    <React.Suspense fallback={null}>
      <Route
        exact
        path="/"
        component={React.lazy(() => import('../../components/public/signin'))}
      />
    </React.Suspense>
  );
};

export default PublicContainer;
