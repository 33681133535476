/* eslint-disable no-plusplus */
import { all, fork, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import actions, { actionStrings } from './actions';
import { actions as entityActions } from '../entities/actions';
import { availableEntities, defaultAjaxParams } from '../../redux-config';
import { submitData } from '../common/commonFlowSagas';
import {
  getFetchEntityStartSaga,
  getFetchEntityEndSaga,
  errorNotification,
  successNotification,
  fetchAllDataForEntity,
  getAuthToken,
} from '../common/commonSagas';
import { CallUpdateCustomer } from '../common';
import { getBranch } from '../common/sagaHelpers';
import CustomersService from '../../services/coreAPI/CustomersService';
import { uploadFile } from '../issues/sagas';

import responseOk from '../../utils/responseOk';

function updateCustomerSuccess(callback) {
  // eslint-disable-next-line func-names
  return function* (result) {
    if (callback) callback(result);
    yield call(
      successNotification,
      'feedback.alert.successTitle',
      'feedback.alert.loadingdata.userUpdateSuccess'
    );
    yield call(getFetchEntityEndSaga(null, null));
  };
}

function getUpdateCustomerError(customer) {
  return function* errorHandler() {
    yield call(getFetchEntityEndSaga(null, null));
    yield put(entityActions.fetchEntityError(availableEntities.DETAILS, customer));
    yield call(errorNotification, 'feedback.alert.errorTips', 'customer.alert.failedUpdateText');
  };
}

function* updateCustomer(action) {
  const { id, key, value, callback } = action;
  yield call(getFetchEntityStartSaga(availableEntities.DETAILS, action));
  const customer = { guser: { id } };

  yield call(
    submitData,
    CallUpdateCustomer,
    updateCustomerSuccess(callback),
    getUpdateCustomerError(),
    customer,
    key,
    value
  );
}

function* createCustomer(action) {
  try {
    const { id } = yield call(getBranch);
    const token = yield call(getAuthToken);
    const payload = { ...action.payload, branchId: id };
    const res = yield call(CustomersService.createCustomer, payload, token);
    if (responseOk(res)) {
      yield call(successNotification, 'feedback.alert.successTitle', 'feedback.alert.userCreated');

      yield put(actions.createCustomerSuccess());

      // Refetch the customers after successfully creating one
      // to update the table.
      yield put(
        entityActions.fetchEntity(
          availableEntities.CUSTOMERS,
          defaultAjaxParams[availableEntities.CUSTOMERS]
        )
      );

      if (action.onSuccess) {
        action.onSuccess();
      }
    } else {
      const result = yield call([res, res.json]);
      if (result.userMessage) {
        if (!action.onError) yield call(errorNotification, result.errorCode, result.userMessage);
        if (action.onError) {
          action.onError(result);
        }
      } else {
        yield call(
          errorNotification,
          'feedback.alert.errorTips',
          'feedback.alert.errorWhenCreatingUser'
        );
      }
    }
  } catch (error) {
    yield put(actions.createCustomerError(action.error));
    yield call(
      errorNotification,
      'feedback.alert.errorTips',
      'feedback.alert.errorWhenCreatingUser'
    );
  }
}

/**
 * File upload function that receives an array of files to upload
 * to the REST api, attaching the uploaded files to a particular customer.
 *
 * @param {Array|File} files - an array of file (blob) types
 * @param {object} customer - the customer for which to connect the files to
 * @param {boolean} isPublic - query param to set the files as private or public
 * @returns {Array|object} - response messages for the requests
 */
function* uploadCustomerFiles(files, customer, isPublic = false) {
  // The authentication bearer token for making an authenticated request.
  const idToken = yield call(getAuthToken);

  // An array of file upload responses.
  const filesResponses = yield all(
    files.map(file => call(uploadFile, idToken, file.data, isPublic))
  );

  // Handle each file response.
  const uploadedFiles = yield all(
    filesResponses.map(fileResponse => {
      // Convert fileResponse to a valid object
      const file = call([fileResponse, fileResponse.json]);
      return file;
    })
  );

  const fileIds = uploadedFiles.map(file => file.id);

  // Link files to vehicle.
  const patchResponse = yield call(
    CustomersService.updateCustomerMultipleFields,
    idToken,
    customer,
    { fileIds }
  );

  // Handle each file response
  const vehicleFiles = yield call([patchResponse, patchResponse.json]);

  return vehicleFiles;
}

/**
 * An upload files watcher that calls the `uploadCustomerFiles` saga, while creating
 * notification for the success or failure of them.
 */
function* uploadVehicleFilesSaga(action) {
  // Files passed along from the action creator.
  const filesToUpload = action.payload;

  if (filesToUpload.length > 0) {
    // Begin file upload, `uploadCustomerFiles` handles notifications
    yield call(uploadCustomerFiles, action.payload, action.customer, false);
  }
}

/**
 * Fetch all customers for a selected branch by id.
 * This data is to be consumed for the CSV Export of customers.
 */
function* fetchAllCustomers() {
  yield call(
    fetchAllDataForEntity,
    CustomersService.getAllCustomers,
    {
      page: 0,
      current: 0,
      size: 500,
      sort: 'id,DESC',
    },
    {
      successActionType: actions.FETCH_ALL_CUSTOMERS_SUCCESSFUL,
      failedActionType: actions.FETCH_ALL_CUSTOMERS_FAILED,
      failedIntlMessage: 'feedback.alert.loadingdata.fetchCustomersFailed',
    }
  );
}

function* watchUpdateCustomer() {
  yield takeEvery(actions.UPDATE_CUSTOMER, updateCustomer);
}

function* watchCreateCustomer() {
  yield takeLatest(actions.CREATE_CUSTOMER_REQ, createCustomer);
}

function* watchFetchAllCustomers() {
  yield takeEvery(actions.FETCH_ALL_CUSTOMERS, fetchAllCustomers);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateCustomer),
    fork(watchCreateCustomer),
    fork(watchFetchAllCustomers),
    takeEvery(actionStrings.UPLOAD_FILES, uploadVehicleFilesSaga),
  ]);
}
