import omit from 'lodash/omit';
import ApiService from './ApiService';

import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/subscriptions`;

class SubscriptionsService {
  /**
   * Method to fetch all subscriptions.
   * @param {string} authtoken - access token.
   * @param {number} branchId - id for branches.
   * @param {object} params - object with filter and param values.
   */
  static getSubscriptions(authtoken, branchId, params) {
    const filtersString = createStringFromFilter(params.filters);

    const paramString = createStringFromParams(omit(params, 'filters'));

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}${paramString}${filtersString}`,
      authtoken,
      'application/json'
    );
  }

  /**
   * Method for creating subscription.
   * @param {string} authtoken -authentication bearer token.
   * @param {object} data - subscription data.
   */
  static createSubscription(authtoken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authtoken, 'application/json');
  }

  /**
   * Update damage with `PATCH` request for each key - value property.
   * @param {string} authtoken - Authentication bearer token.
   * @param {number|string} id - the id of subscription to be updated.
   * @param {string} key - key of property that is gonna be changed.
   * @param {any} value - value for specified key.
   */
  static updateSubscription(authtoken, id, key, value) {
    const data = { [key]: value, type: 'Subscription' };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      authtoken,
      'application/json'
    );
  }

  /**
   * Method used to fetch subscription details by id
   * @param {string} authToken - authentication bearer token.
   * @param {string} id - subscription id.
   */
  static getSubscriptionById(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json');
  }
}

export default SubscriptionsService;
