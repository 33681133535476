import { Button } from 'antd';
import locale from 'antd/lib/date-picker/locale/en_US';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { dateTimeWithSeconds } from '../../constants/datetime';
import AnalyticsDateSelectOptions from '../analytics/analytics-date-select-options';
import IntlMessages from '../utility/intlMessages';
import DatePicker from './DatePicker';
import './datepicker.css';
import rangeSelectOptions from '../../constants/range-select-options';

const { lastSevenDays, thisWeek, lastWeek, lastThirtyDays, thisMonth, lastMonth, custom } =
  rangeSelectOptions;

const RangePickerContainer = styled(DatePicker.RangePicker)``;

const AnalyticsDateSelectOptionsContainer = styled.div`
  width: 100%;
`;

const ClearButton = styled(Button)`
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  padding: 0;
  color: var(--gray-6);
`;

// reset data on same value as default datepicker reset
const resetDates = [dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')];

const RangePicker = ({
  onDatePickerChange,
  onSelectChange,
  getDatePickerValues,
  selectedRange,
  showSelectOptions,
}) => {
  const [rangePickerValue, setRangePickerValue] = useState([]);

  /** `resetRangePickerValue` callback function to reset range picker values to the initial */
  const resetRangePickerValue = useCallback(() => {
    setRangePickerValue(resetDates);
    onSelectChange({
      startDate: resetDates?.[0],
      endDate: resetDates?.[1],
      selectedRange: lastSevenDays,
    });
  }, [onSelectChange]);

  /**
   * `extraFooter` callback method used to generate range picker extra footer value
   * @returns {JSX} element which shows up in the footer
   */
  const extraFooter = useCallback(
    () => (
      <>
        {showSelectOptions && (
          <AnalyticsDateSelectOptionsContainer>
            <AnalyticsDateSelectOptions
              selectedRange={selectedRange}
              onSelectChange={onSelectChange}
            />
          </AnalyticsDateSelectOptionsContainer>
        )}
        <ClearButton type="link" size="small" onClick={() => resetRangePickerValue()}>
          <IntlMessages id="calendar.clear" />
        </ClearButton>
      </>
    ),
    [onSelectChange, resetRangePickerValue, selectedRange, showSelectOptions]
  );

  useEffect(() => {
    setRangePickerValue(getDatePickerValues);
  }, [getDatePickerValues]);

  return (
    <RangePickerContainer
      className="styled-range-picker"
      popupClassName="styled-range-picker-dropdown"
      style={{ width: '100%' }}
      format={dateTimeWithSeconds}
      onChange={onDatePickerChange}
      value={rangePickerValue}
      showTime={{ format: 'HH:mm' }}
      renderExtraFooter={extraFooter}
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          ok: 'Apply',
        },
      }}
    />
  );
};

RangePicker.propTypes = {
  onDatePickerChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func,
  getDatePickerValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRange: PropTypes.oneOf([
    lastSevenDays,
    thisWeek,
    lastWeek,
    lastThirtyDays,
    thisMonth,
    lastMonth,
    custom,
  ]),
  showSelectOptions: PropTypes.bool,
};

RangePicker.defaultProps = {
  onSelectChange: () => null,
  selectedRange: lastSevenDays,
  showSelectOptions: false,
};

export default RangePicker;
