import { actionPayload } from '../common/commonActions';

const actionStrings = {
  FETCH_RECURRING_TASKS: 'FETCH_RECURRING_TASKS',
  SAVE_RECURRING_TASKS: 'SAVE_RECURRING_TASKS',
  CREATE_RECURRING_TASK: 'CREATE_RECURRING_TASK',
  UPDATE_RECURRING_TASKS: 'UPDATE_RECURRING_TASKS',
  UPDATE_RECURRING_TASK: 'UPDATE_RECURRING_TASK',
  DELETE_RECURRING_TASK: 'DELETE_RECURRING_TASK',
};

const actions = {
  fetchRecurringTasks: actionPayload(actionStrings.FETCH_RECURRING_TASKS),
  createRecurringTask: actionPayload(actionStrings.CREATE_RECURRING_TASK),
  saveRecurringTasks: actionPayload(actionStrings.SAVE_RECURRING_TASKS),
  updateRecurringTasks: actionPayload(actionStrings.UPDATE_RECURRING_TASKS),
  updateRecurringTask: actionPayload(actionStrings.UPDATE_RECURRING_TASK),
  deleteRecurringTask: actionPayload(actionStrings.DELETE_RECURRING_TASK),
};

export { actions, actionStrings };
