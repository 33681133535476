import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/balances`;

class BalanceService {
  /**
   * Method to settle customer balance, using `balanceId`
   * @param {string} authToken - access user token.
   * @param {number} balanceId - id of balance.
   */
  static settleBalance(authToken, balanceId) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${balanceId}/settle`,
      {},
      authToken,
      'application/json'
    );
  }

  /**
   * Helper method to fetch balances.
   * @param {string} authToken - authentication bearer token
   * @param {number|string} id - id of the customer / user group
   * @param {boolean} isGroup - indicator is group or customer
   */
  static fetchBalances(authToken, id, isGroup = false) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${isGroup ? 'groupId' : 'userId'}=${id}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching balance events
   * @param {string} authToken - authentication bearer token
   * @param {number|string} id - id of the customer / user group
   * @param {object} params - params to be included in the URL of the endpoint
   */
  static fetchBalanceEvents(authToken, id, params = {}) {
    const paramString = createStringFromParams(params, '');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${id}/events?${paramString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Helper method to retrieve list of the update history of the specific balance.
   * @param {string} authToken - authentication bearer token.
   * @param {number|string} branchId - id of the branch
   * @param {*} params - params and filters.
   */
  static fetchBalanceUpdates(authToken, branchId, params) {
    const { balanceId } = params;
    const filtersString = createStringFromFilter(params.filters);

    const paramString = createStringFromParams(omit(params, ['filters', 'branchId', 'balanceId']));

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${balanceId}/updates?branchId=${branchId}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Helper method to set balance a invoice collection
   * @param {string} authToken - authentication bearer token.
   * @param {*} params - params and filters.
   */
  static generateInvoiceForCollection(authToken, params) {
    const { balanceId } = params;

    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_URL}/${balanceId}/settle-by-collection-invoice`,
      authToken,
      'application/json'
    );
  }
}

export default BalanceService;
