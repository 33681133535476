import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back`;

class CSVService {
  static fetchCSV(AuthToken, tableName, urlQueryParams) {
    const urlParams = new URLSearchParams(urlQueryParams).toString();
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${tableName}/export?${urlParams}`,
      AuthToken,
      'application/json'
    );
  }
}

export default CSVService;
