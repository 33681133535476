import { all, takeEvery, call, put, getContext, select } from 'redux-saga/effects';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import range from 'lodash/range';

import actions, { actionStrings } from './actions';
import { actions as goalsActions } from '../goals/actions';
import { SettingsService } from '../../services/coreAPI';
import { handleMessage, getAuthToken } from '../common/commonSagas';
import { getBranch } from '../common/sagaHelpers';
import responseOk from '../../utils/responseOk';
import settings, { GOALS, MAIN_GOAL } from '../../constants/settings';
import { convertArrayToObject } from '../../helpers/utility';
import { getGoalsState, getMainGoalsState } from '../goals/selectors';
import { getSettings } from './selectors';

/**
 * Fetch goals generator function.
 */
function* fetchGoals() {
  const idToken = yield call(getAuthToken);
  const { id: branchId } = yield call(getBranch);
  if (idToken && branchId) {
    const response = yield call(
      SettingsService.fetchSettingByQueryString,
      idToken,
      `?keys=${GOALS},${MAIN_GOAL}&branchIds=${branchId}`
    );
    if (responseOk(response)) {
      const goals = yield call([response, response.json]);
      if (goals.length) {
        const mainGoal = goals.find(s => s.key === MAIN_GOAL);
        const normalGoal = goals.find(
          s => s.key === GOALS && get(s, 'branchIds', []).includes(branchId)
        );
        if (normalGoal) {
          yield put(goalsActions.fetchGoalsSuccess(normalGoal));
        }
        if (mainGoal) {
          yield put(goalsActions.fetchMainGoalSuccess(mainGoal));
        }
      } else {
        yield put(goalsActions.resetGoals());
      }
    }
  }
}

/**
 * Helper generator function for fetching settings.
 * @param {object} params - query param object `eg. { page: 0, size: 300 }`.
 * @param {string} idToken - access token.
 */
function* fetchSettingsPage(idToken, params) {
  const response = yield call(SettingsService.fetchSettings, idToken, params);
  if (responseOk(response)) {
    return yield call([response, response.json]);
  }
  // return object inidcating `error`
  return { error: true };
}

/**
 * Generator function for fetching all settings.
 * @param {object} params - params for settings to be fetched.
 * @param {string} idToken - access token.
 * @returns {array} fetched settings.
 */
function* fetchAllSettings(params, idToken) {
  const intl = yield getContext('intl');
  try {
    const response = yield call(SettingsService.fetchSettings, idToken, params);
    if (responseOk(response)) {
      /** `returned` data from fetching */
      const totalPages = response.headers.get('X-Pagination-TotalPages');
      const size = response.headers.get('X-Pagination-Size');
      let data = [];
      if (responseOk(response)) {
        const firstSettingsData = yield call([response, response.json]);
        data = firstSettingsData;
      } else {
        handleMessage(intl.formatMessage({ id: 'fetch.fail.settings.all' }), true, 2);
        return { data: [], error: true };
      }

      /** array of rest pages for `settings` to be fetched */
      const restPages = totalPages > 1 ? range(1, totalPages) : [];
      const restSettings = yield all(
        restPages.map(page => call(fetchSettingsPage, idToken, { ...params, page, size }))
      );

      if (restSettings.find(settingsData => get(settingsData, 'error', false))) {
        handleMessage(intl.formatMessage({ id: 'fetch.fail.settings.all' }), true, 2);
        return { data: [], error: true };
      }

      restSettings.forEach(settingsArray => {
        data.push(...settingsArray);
      });

      return { data, error: false };
    }
  } catch (error) {
    console.error(error);
  }
  return { data: [], error: true };
}

function* fetchSettings() {
  const idToken = yield call(getAuthToken);
  const { id: branchId } = yield call(getBranch);

  if (idToken && branchId) {
    /** `params` for fetching settings */
    const params = {
      page: 0,
      size: 500,
    };
    const responseObject = yield call(fetchAllSettings, params, idToken);

    if (responseObject.error) {
      yield call(fetchGoals);
      return;
    }
    const result = responseObject.data;

    const mainGoal = result.find(s => s.key === MAIN_GOAL);
    const normalGoal = result.find(
      s => s.key === GOALS && get(s, 'branchIds', []).includes(branchId)
    );
    if (normalGoal) {
      const stateGoals = yield select(getGoalsState);
      if (!isEqual(stateGoals, normalGoal)) yield put(goalsActions.fetchGoalsSuccess(normalGoal));
    } else {
      yield put(goalsActions.resetGoals());
    }
    if (mainGoal) {
      const stateMainGoals = yield select(getMainGoalsState);
      if (!isEqual(stateMainGoals, mainGoal))
        yield put(goalsActions.fetchMainGoalSuccess(mainGoal));
    }
    const stateSettings = yield select(getSettings);

    if (stateSettings.app.isLoading) yield put(actions.fetchSettingSuccessChangeLoading(false));

    const listOfSettings = {
      app: {
        ...convertArrayToObject(
          [...settings.app.map(key => result.find(setting => setting.key === key)).filter(Boolean)],
          'key'
        ),
      },
      map: {
        'map.view': settings.map
          .map(key => result.find(setting => setting.key === key))
          // remove falsy values
          .filter(Boolean),
      },
      core: {
        ...convertArrayToObject(
          [
            ...settings.core
              .map(key => result.find(setting => setting.key === key))
              .filter(Boolean),
          ],
          'key'
        ),
      },
    };

    // If there are any changes, we will call action
    if (
      !isEqual(omit(stateSettings.app, 'isLoading'), listOfSettings.app, 'isLoading') ||
      !isEqual(omit(stateSettings.map, 'isLoading'), listOfSettings.map, 'isLoading') ||
      !isEqual(omit(stateSettings.core, 'isLoading'), listOfSettings.core, 'isLoading')
    )
      yield put(actions.fetchAllSettingsSuccess({ ...listOfSettings }));
  }
}

function* createSetting(data, stateKey) {
  const intl = yield getContext('intl');
  const idToken = yield call(getAuthToken);

  const response = yield call(SettingsService.createSetting, idToken, data);

  if (response.status !== 200) {
    yield call(
      handleMessage,
      intl.formatMessage({ id: 'settings.app.create.error.message' }),
      true
    );
    return;
  }

  const result = yield call([response, response.json]);
  yield put(actions.createOrUpdateSettingSucces({ ...result, stateKey }));
  yield call(handleMessage, intl.formatMessage({ id: 'settings.app.create.success.message' }));
}

function* updateSetting(id, data, stateKey) {
  const intl = yield getContext('intl');
  const idToken = yield call(getAuthToken);

  const response = yield call(SettingsService.updateSetting, idToken, id, data);

  if (response.status !== 200) {
    yield call(
      handleMessage,
      intl.formatMessage({ id: 'settings.app.update.error.message' }),
      true
    );
    return;
  }

  const result = yield call([response, response.json]);
  yield put(actions.createOrUpdateSettingSucces({ ...result, stateKey }));
  yield call(handleMessage, intl.formatMessage({ id: 'settings.app.update.success.message' }));
}

function* createOrUpdateSetting(action) {
  const { id, value, stateKey } = action.payload;
  if (id === -1) {
    yield call(createSetting, value, stateKey);
  } else {
    yield call(updateSetting, id, value, stateKey);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionStrings.FETCH_SETTINGS, fetchSettings),
    yield takeEvery(actionStrings.CREATE_OR_UPDATE_SETTING, createOrUpdateSetting),
  ]);
}
