import { createSelector } from 'reselect';

const getGoalsAnalyticsState = state => state.Goals.analytics;
const getGoalsFromState = state => state.Goals?.goals?.value?.months ?? {};
const getAllGoals = state => state.Goals.goals;
const getMainGoals = state => state.Goals.mainGoal;

const getIsGoalsAnalyticsLoadingState = state => state.Goals?.analytics?.isLoading ?? false;

const getGoalsStatePart = state => state.Goals;

/**
 * Selector to get analytics from goals
 * @param {object} state - Redux state
 */
export const getGoalsAnalytics = createSelector(getGoalsAnalyticsState, analytics => analytics);
/**
 * Selector to get analytics from goals
 * @param {object} state - Redux state
 */
export const getGoals = createSelector(getGoalsFromState, goals => goals);
/**
 * Selector to get months from goals
 * @param {object} state - Redux state
 */
export const getGoalsState = createSelector(getAllGoals, goalsState => goalsState);
/**
 * Selector to get mainGoal from goals
 * @param {object} state - Redux state
 */
export const getMainGoalsState = createSelector(getMainGoals, mainGoals => mainGoals);

/**
 * Selector to check are goals analytics loading
 */
export const getIsGoalsLoading = createSelector(
  getIsGoalsAnalyticsLoadingState,
  loading => loading
);

/** `Selector` to get whole part of the goals */
export const getWholeGoalsState = createSelector(getGoalsStatePart, goals => goals);
