// IMPORTANT
// TODO: Every path should be added here for app router
const paths = {
  NOT_FOUND: 'dashboard/404',
  FORBIDDEN: 'dashboard/406',
  DEFAULT: 'dashboard/',
  MAP: 'dashboard/map',
  VEHICLES_MAP: 'dashboard/map/vehicles/',
  VEHICLES_MAP_ID: 'dashboard/map/vehicles/:id',
  CUSTOMERS: 'dashboard/customers',
  PRODUCTIVITY_INSIGHTS: 'dashboard/productivity-insights',
  SERVICE_AGENTS: 'dashboard/service-agents',
  CUSTOMERS_ID: 'dashboard/customers/:id',
  BUSINESS_ACCOUNTS: 'dashboard/business-accounts',
  BUSINESS_ACCOUNTS_ID: 'dashboard/business-accounts/:id',
  INVOICES: 'dashboard/invoices',
  VEHICLES: 'dashboard/vehicles',
  VEHICLES_ID: 'dashboard/vehicles/:id',
  RENTALS: 'dashboard/rentals',
  RENTALS_ID: 'dashboard/rentals/:id',
  TASKS: 'dashboard/tasks',
  TASKS_ID: 'dashboard/tasks/:id',
  DAMAGES: 'dashboard/damages',
  DAMAGES_ID: 'dashboard/damages/:id',
  POI: 'dashboard/category/:id/poi',
  POI_ID: 'dashboard/category/:id/poi/:id',
  POI_CAT: 'dashboard/poi-cat',
  POI_CAT_ID: 'dashboard/poi-cat/:id',
  SETTINGS_ROLES: 'dashboard/settings/roles',
  SETTINGS: 'dashboard/settings',
  SETTINGS_SETTING: 'dashboard/settings/:setting',
  SETTINGS_ADVANCED: 'dashboard/settings/advanced',
  ISSUES: 'dashboard/issues',
  ISSUES_ID: 'dashboard/issues/:id',
  BOOKINGS: 'dashboard/bookings',
  BOOKINGS_ID: 'dashboard/bookings/:id',
  ANALYTICS: 'dashboard/analytics',
  ANALYTICS_ENTITY: 'dashboard/analytics/:entity',
  BOOKING_CALENDAR: 'dashboard/booking-calendar',
  LOGS: 'dashboard/logs',
  ROLES: 'dashboard/roles',
  OVERVIEW: 'dashboard/overview',
  APPS: 'dashboard/apps',
  SERVICE_AGENTS_ID: 'dashboard/service-agents/:id',
};

export default paths;
