import { action, actionEntity, actionWithInvokerAction } from '../common/commonActions';

/**
 * Action notation
 *
 * FETCH/DO     - Action from component/saga that goes through saga to reducer.
 * FETCH_START  - Actual start of the request/business logic
 * FETCH_SUCCESS- End of method/call which resolved successfully
 * FETCH_ERROR  - End of method/call which resolved with an error
 */

const actionStrings = {
  FETCH_ENTITY: 'FETCH_ENTITY',
  FETCH_ENTITY_TABLE: 'FETCH_ENTITY_TABLE',
  FETCH_ENTITY_START: 'FETCH_ENTITY_START',
  FETCH_ENTITY_SUCCESS: 'FETCH_ENTITY_SUCCESS',
  FETCH_ENTITY_ERROR: 'FETCH_ENTITY_ERROR',

  MERGE_ENTITY_SUCCESS: 'MERGE_ENTITY_SUCCESS',

  FETCH_DETAILS: 'FETCH_DETAILS',

  CLEAR_ENTITY: 'CLEAR_ENTITY',
  CLEAR_ENTITY_DATA: 'CLEAR_ENTITY_DATA',

  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  CLEAR_LOADING: 'CLEAR_LOADING',
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_ITEM_IN_PLACE: 'UPDATE_ITEM_IN_PLACE',
  REMOVE_ITEM: 'REMOVE_ITEM',

  UPDATE_PROPERTY_OF_ITEM_IN_PLACE: 'UPDATE_PROPERTY_OF_ITEM_IN_PLACE',

  APPEND_ENTITIES: 'APPEND_ENTITIES',

  REFRESH_ENTITY_TABLE: 'REFRESH_ENTITY_TABLE',
};

const actions = {
  fetchEntity: action(actionStrings.FETCH_ENTITY),
  fetchEntityTable: action(actionStrings.FETCH_ENTITY_TABLE),
  fetchEntityStart: actionWithInvokerAction(actionStrings.FETCH_ENTITY_START),
  fetchEntitySuccess: action(actionStrings.FETCH_ENTITY_SUCCESS),
  fetchEntityError: actionWithInvokerAction(actionStrings.FETCH_ENTITY_ERROR),

  mergeEntitySuccess: action(actionStrings.MERGE_ENTITY_SUCCESS),
  updateDetails: action(actionStrings.UPDATE_DETAILS),
  addItem: action(actionStrings.ADD_ITEM),
  updateItem: action(actionStrings.UPDATE_ITEM),
  updateItemInPlace: action(actionStrings.UPDATE_ITEM_IN_PLACE),
  removeItem: action(actionStrings.REMOVE_ITEM),

  fetchDetails: action(actionStrings.FETCH_DETAILS),
  clearEntity: actionEntity(actionStrings.CLEAR_ENTITY),
  clearEntityData: actionEntity(actionStrings.CLEAR_ENTITY_DATA),

  clearError: action(actionStrings.CLEAR_ERROR),
  clearErrors: action(actionStrings.CLEAR_ERRORS),
  clearLoading: action(actionStrings.CLEAR_LOADING),

  refreshEntityTable: action(actionStrings.REFRESH_ENTITY_TABLE),

  updatePropertyOfItemInPlace: (entity, id, property, value) => ({
    type: actionStrings.UPDATE_PROPERTY_OF_ITEM_IN_PLACE,
    entity,
    id,
    property,
    value,
  }),
};

export { actions, actionStrings };
