import { actionPayload } from '../common/commonActions';

const simpleAction = type => data => ({ type, data });

const actionStrings = {
  FETCH_ISSUES: 'FETCH_ISSUES',
  CREATE_USER_INVOICE: 'CREATE_USER_INVOICE',
  UPDATE_USER_INVOICE: 'UPDATE_USER_INVOICE',
  CREATE_INVOICE_LINE_ITEM: 'CREATE_INVOICE_LINE_ITEM',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  BILL_INVOICE: 'BILL_INVOICE',
  REFUND_INVOICE: 'REFUND_INVOICE',
  DIRECT_BILLING: 'DIRECT_BILLING',
  SET_ISSUE: 'SET_ISSUE',
  ISSUE_LOADER_START: 'ISSUE_LOADER_START',
  ISSUE_LOADER_END: 'ISSUE_LOADER_END',
};

const downloadFile = (commentId, fileId) => ({
  type: actionStrings.DOWNLOAD_FILE,
  commentId,
  fileId,
});

const setIssue = issue => ({
  type: actionStrings.SET_ISSUE,
  issue,
});

const actions = {
  fetchIssues: simpleAction(actionStrings.FETCH_ISSUES),
  createUserInvoice: simpleAction(actionStrings.CREATE_USER_INVOICE),
  updateUserInvoice: simpleAction(actionStrings.UPDATE_USER_INVOICE),
  createInvoiceLineItem: simpleAction(actionStrings.CREATE_INVOICE_LINE_ITEM),
  billInvoice: simpleAction(actionStrings.BILL_INVOICE),
  directBilling: simpleAction(actionStrings.DIRECT_BILLING),
  loaderStart: simpleAction(actionStrings.ISSUE_LOADER_START),
  loaderEnd: simpleAction(actionStrings.ISSUE_LOADER_END),
  downloadFile,
  setIssue,
  refundInvoice: actionPayload(actionStrings.REFUND_INVOICE),
};

export default { ...actionStrings, ...actions };
