import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import styled, { ThemeProvider } from 'styled-components';

import Sidebar from '../Sidebar/Sidebar';
import AppRouter from './AppRouter';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import AppHolder from './commonStyle';
import Topbar from '../Topbar/Topbar';
import appActions from '../../redux/app/actions';
import { actions as goalsActions } from '../../redux/goals/actions';
import { actions as vehicleActions } from '../../redux/vehicles/actions';
import { usePermissions } from '../../hooks';
import permissions from '../../constants/permissions';
import { getBranchId, getCurrentHeight } from '../../redux/app/selectors';

import './global.css';
import { getIsPluginActive } from '../../redux/plugins/selectors';
import { GOALS_PLUGIN_NAME } from '../../constants/settings-plugins';
import { getCSSVariable } from '../../utils/cssVariables';

import { logEvtToFirebase, NAVIGATION } from '../../config/firebase';
import TableNavigation from '../Tables/common/table-navigation/navigation';
import resolveTableName from '../Tables/common/table-navigation/ResolveTableName';

const ScrollContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  background-color: var(--gray-1);
  height: 100%;
  position: relative;
`;

const App = ({
  fetchAllBranches,
  fetchCurrentUserDetails,
  fetchAllVehiclesFilters,
  match: { url },
  updateFocus,
  fetchCategoriesAndCommands,
  fetchGoalsAnalytics,
  resetGoalsAnalytics,
  resize,
  isGoalPluginActive,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  /** `permissions` of the user */
  const userPermissions = usePermissions();

  /** `height` of the app */
  const appHeight = useSelector(getCurrentHeight);

  /** `id` of selected branch */
  const branchId = useSelector(getBranchId);

  /** `permission for analytics` read */
  const analyticsReadPermission = get(userPermissions, permissions.ANALYTICS_READ, false);

  /** `useEffect` hook for fetching goals for analytics */
  React.useEffect(() => {
    if (branchId) {
      if (analyticsReadPermission && isGoalPluginActive) fetchGoalsAnalytics();
      else resetGoalsAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsReadPermission, branchId, isGoalPluginActive]);

  React.useEffect(() => {
    fetchCurrentUserDetails();
    fetchAllVehiclesFilters();
    fetchAllBranches();
    fetchCategoriesAndCommands();

    const onListen = arg => {
      logEvtToFirebase(NAVIGATION, { path: arg.pathname });
    };

    const unlisten = history.listen(onListen);

    return () => {
      unlisten();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Debounce handler to avoid calling method to many times
  const debouncedChangeHandler = React.useMemo(() => debounce(resize, 500), [resize]);
  const shouldRenderTableNavigation = React.useMemo(() => resolveTableName(pathname), [pathname]);

  /** `resize` handler for the app */
  const onResizeHandler = React.useCallback(() => {
    debouncedChangeHandler(window.innerWidth, window.innerHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** `onBlur` handler  */
  const onBlur = React.useCallback(() => {
    updateFocus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** `onFocus` */
  const onFocus = React.useCallback(() => {
    updateFocus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** `onVisibility` */
  const onVisibility = React.useCallback(() => {
    updateFocus(!document.hidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** `useEffect` hook for specify when is tab blurred or in the focus */
  React.useEffect(() => {
    document.addEventListener('focus', onFocus);
    document.addEventListener('blur', onBlur);
    document.addEventListener('visibilitychange', onVisibility);
    window.addEventListener('resize', onResizeHandler);
    // cleanup
    return () => {
      document.removeEventListener('focus', onFocus);
      document.removeEventListener('blur', onBlur);
      document.removeEventListener('visibilitychange', onVisibility);
      window.removeEventListener('resize', onResizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <AppHolder>
        <Layout style={{ height: appHeight }}>
          <Sidebar url={url} />
          <Layout
            style={{
              flexDirection: 'column',
              overflowX: 'hidden',
              height: '100%',
              backgroundColor: getCSSVariable('--blue-6'),
            }}
          >
            <Topbar url={url} />
            <ScrollContainer>
              <Layout className="isoContentMainLayout">
                {shouldRenderTableNavigation && (
                  <TableNavigation tableName={shouldRenderTableNavigation} />
                )}
                <AppRouter url={url} />
              </Layout>
            </ScrollContainer>
          </Layout>
        </Layout>
      </AppHolder>
    </ThemeProvider>
  );
};

App.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  fetchAllBranches: PropTypes.func.isRequired,
  fetchCurrentUserDetails: PropTypes.func.isRequired,
  fetchAllVehiclesFilters: PropTypes.func.isRequired,
  fetchCategoriesAndCommands: PropTypes.func.isRequired,
  updateFocus: PropTypes.func.isRequired,
  fetchGoalsAnalytics: PropTypes.func.isRequired,
  resetGoalsAnalytics: PropTypes.func.isRequired,
  resize: PropTypes.func.isRequired,
  isGoalPluginActive: PropTypes.bool,
};

App.defaultProps = {
  isGoalPluginActive: false,
};

const mapStateToProps = state => ({
  isGoalPluginActive: getIsPluginActive(state, GOALS_PLUGIN_NAME),
});

const mapDispatchToProps = dispatch => ({
  fetchAllBranches: () => {
    dispatch(appActions.fetchBranches());
  },
  fetchCurrentUserDetails: () => {
    dispatch(appActions.fetchCurrentUser());
  },
  fetchAllVehiclesFilters: () => {
    dispatch(appActions.fetchAllVehiclesFilters());
  },
  fetchCategoriesAndCommands: () => {
    dispatch(vehicleActions.fetchVehicleCategories());
  },
  updateFocus: payload => dispatch(appActions.updateFocus(payload)),
  fetchGoalsAnalytics: () => dispatch(goalsActions.fetchGoalsAnalytics()),
  resetGoalsAnalytics: () => dispatch(goalsActions.resetGoalsAnalytics()),
  resize: (width, height) => dispatch(appActions.screenResize(width, height)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
