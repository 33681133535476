import { all, fork, takeEvery, put, call } from 'redux-saga/effects';

import { actionStrings, actions } from './actions';
import { quickviewActions } from '../quickview/actions';
import { submitData } from '../common/commonFlowSagas';
import {
  handleNotification,
  successNotification,
  fetchAllDataForEntity,
} from '../common/commonSagas';
import RecurringTasksService from '../../services/coreAPI/RecurringTasksService';
import { availableEntities } from '../../redux-config';

function* recurringTasksError(error) {
  yield call(handleNotification, 'error', error);
}

function* createRecurringTaskSuccess(payload) {
  yield put(actions.updateRecurringTasks(payload));
  yield call(
    successNotification,
    'feedback.alert.successTitle',
    'settings.recurringTasks.createTaskSuccess'
  );
}

function* updateTaskSuccess(payload) {
  yield put(actions.updateRecurringTasks(payload));
  yield put(quickviewActions.updateItem(availableEntities.RECURRING_TASKS, payload));
  yield call(
    successNotification,
    'feedback.alert.successTitle',
    'settings.recurringTasks.updateTaskSuccess'
  );
}

function* deleteTaskSuccess() {
  yield call(
    successNotification,
    'feedback.alert.successTitle',
    'settings.recurringTasks.deleteTaskSuccess'
  );
}

function* fetchRecurringTasks() {
  yield call(
    fetchAllDataForEntity,
    RecurringTasksService.getRecurringTasks,
    {
      page: 0,
      current: 0,
      size: 500,
      sort: 'id,DESC',
    },
    {
      successActionType: actionStrings.SAVE_RECURRING_TASKS,
      failedActionType: null,
      failedIntlMessage: 'settings.recurringTasks.fetch.failed',
    }
  );
}

function* createRecurringTask(action) {
  yield call(
    submitData,
    RecurringTasksService.createRecurringTask,
    createRecurringTaskSuccess,
    recurringTasksError,
    action.payload
  );
}

function* updateRecurringTask(action) {
  yield call(
    submitData,
    RecurringTasksService.updateRecurringTask,
    updateTaskSuccess,
    recurringTasksError,
    action.payload
  );
}

function* deleteRecurringTask(action) {
  yield call(
    submitData,
    RecurringTasksService.deleteRecurringTask,
    deleteTaskSuccess,
    recurringTasksError,
    action.payload
  );
}

function* watchFetchRecurringTasks() {
  yield takeEvery(actionStrings.FETCH_RECURRING_TASKS, fetchRecurringTasks);
}

function* watchCreateRecurringTask() {
  yield takeEvery(actionStrings.CREATE_RECURRING_TASK, createRecurringTask);
}

function* watchUpdateRecurringTask() {
  yield takeEvery(actionStrings.UPDATE_RECURRING_TASK, updateRecurringTask);
}

function* watchDeleteRecurringTask() {
  yield takeEvery(actionStrings.DELETE_RECURRING_TASK, deleteRecurringTask);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRecurringTasks),
    fork(watchCreateRecurringTask),
    fork(watchUpdateRecurringTask),
    fork(watchDeleteRecurringTask),
  ]);
}
