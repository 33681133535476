/** Used in quickviews — e.g. December 27th, 2019, 17:12. */
export const localeDateTimeWithHoursFmt = 'MMMM DD, YYYY [at] HH:mm';

/** `used` in pane info - e.g. 22 Apr, 2021 at 12:44 */
export const localeDateTimeFormat = 'DD MMM, YYYY [at] HH:mm';

/** Used in quickviews — e.g. December 27th, 2019 */
export const localeDateTimeWithOutHoursFmt = 'MMMM DD, YYYY';

/** Used in date-range picker for displaying analytics date-range picker. - e.g. 2020-01-01 00:00:00. */
export const dateRangeFmt = 'YYYY-MM-DD HH:mm:ss';
/** Used in date-range picker for submitting analytics endpoints. - e.g. 2020-01-01T00:00:00. */
export const dateRangeFmtSubmit = 'YYYY-MM-DDTHH:mm:ss';

/** Used in quickviews — e.g. 2019-12-27 17:12:00. */
export const dateTimeWithSeconds = 'YYYY-MM-DD HH:mm:ss';

/** Used in tables and vehicle tasks - e.g. 06.02.2019, 20:58 */
export const dayMonthYearFmt = 'DD.MM.YYYY, HH:mm';

/** Used in tables and vehicle tasks - e.g. 06.02.2019, 20:58.21 */
export const dayMonthYearHourMinuteSecondFmt = 'DD.MM.YYYY, HH:mm:ss';

/** Used in rental tasks - e.g. 06.02.2019 */
export const dayMonthYear = 'DD.MM.YYYY';

/** Used in vehicle details pane - e.g. 06.02.2019 at 20:58 */
export const dayMonthYearAtFmt = 'DD.MM.YYYY [at] HH:mm';

/** Used in vehicle details pane - e.g. 06.02.2019. at 20:58 */
export const dayMonthYearPointAtFmt = 'DD.MM.YYYY. [at] HH:mm';

/** Used in goals - e.g. January 2020 */
export const longMonthYearFmt = 'MMMM YYYY';

/** Used in goals - e.g. 2020-01 */
export const longYearNumericMonthFmt = 'YYYY-MM';

export const timeUnits = {
  days: 'DAYS',
  weeks: 'WEEKS',
  months: 'MONTHS',
  years: 'YEARS',
};

// used in recurring tasks
export const TASK_DATE_FORMAT = 'MMMM D, YYYY, HH:mm';

/** Used in customer quickview for user birthdate */
export const birthDateFormat = 'YYYY-MM-DD';

/** Used for month picker */
export const monthDateFormat = 'YYYY/MM';

/** Used for displaying hourly chart items inside of analytics - e.g. Sep 22, 18:00.  */
export const analyticsHourlyChartItem = 'MMM DD, HH:mm';
/** Used for displaying hourly chart items inside of analytics - e.g. Friday, Sep 22.  */
export const analyticsDailyChartItem = 'dddd, MMM DD';
/** Used for displaying hourly chart items inside of analytics - e.g. Sep 2020.  */
export const analyticsMonthlyChartItem = 'MMM YYYY';
/** Used for displaying chart items inside of analytics - e.g. Aug 16/19.  */
export const analyticsChartItemFmt = 'MMM DD/YY';
/** Used for displaying chart items inside of analytics sorted by hourly - e.g. Aug 16/19 20:00. */
export const analyticsChartItemHourFmt = 'MMM DD/YY HH:mm';
/** Used in analytics for sorting by datetime - e.g. 20200101. */
export const analyticsSortFmt = 'YYYYMMDDHH';
/** Used in app general settings for date format option - 12.31.2020. */
export const monthFirstDateFormat = 'MM-DD-YYYY';
/** Used in poi working days interval - 12:22. */
export const baseTimeFormat = 'HH:mm';

export default {
  localeDateTimeWithHoursFmt,
  dateTimeWithSeconds,
  dayMonthYearFmt,
  dayMonthYearAtFmt,
  birthDateFormat,
  monthDateFormat,
  monthFirstDateFormat,
  baseTimeFormat,
};
