import { filterActions } from 'redux-ignore';
import actions from './actions';

const initState = {
  roles: [],
  userRoles: [],
  roleAssignmentsUser: [],
  permissions: {},
};

const rolesReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.filter(v => v.roleType !== 'ROOT'),
      };

    case actions.SAVE_ROLE_ASSIGNMENTS:
      return {
        ...state,
        roleAssignmentsUser: action.payload,
      };

    case actions.REMOVE_ROLE_SUCCESS:
      return {
        ...state,
        roleAssignmentsUser: state.roleAssignmentsUser.filter(
          v => v.assignmentId !== action.payload
        ),
      };

    case actions.SAVE_NEW_ROLE_SUCCESS:
      return {
        ...state,
        roleAssignmentsUser: [...state.roleAssignmentsUser, action.payload],
      };

    case actions.FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };

    case actions.CHECK_IF_USER_HAS_ROLE_SUCCESS: {
      return {
        ...state,
        userRoles: action.payload,
      };
    }

    case actions.CHECK_IF_USER_HAS_ROLE_FAIL:
      return {
        ...state,
        userRoles: state.userRoles.filter(role => !action.payload?.includes(role)),
      };

    default:
      return state;
  }
};

export default filterActions(rolesReducer, [
  actions.FETCH_ROLES_SUCCESS,
  actions.SAVE_ROLE_ASSIGNMENTS,
  actions.REMOVE_ROLE_SUCCESS,
  actions.SAVE_NEW_ROLE_SUCCESS,
  actions.FETCH_USER_PERMISSIONS_SUCCESS,
  actions.CHECK_IF_USER_HAS_ROLE_SUCCESS,
  actions.CHECK_IF_USER_HAS_ROLE_FAIL,
]);
