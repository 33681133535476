import dayjs from 'dayjs';
import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  auto_refresh: false,
  /**
   * Result of a response for retrieving all vehicles through an HTTP request.
   */
  allVehicles: {},
  categories: [],
  /** `indicator` are all vehicles fetched */
  allVehiclesFetched: false,
  /** `indicator` are vehicles currently fetched */
  isFetchingAllVehicles: false,
  /** `indicator` of last time fetched */
  lastTimeFetched: '',
  isBulkUpdating: false,
};

/**
 * Helper function to replace `changed` vehicles.
 * @param {Array} allVehicles - current vehicles.
 * @param {Array} changedVehicles - changed vehicles.
 * @returns {Array} array with combined replaced and current vehicles.
 */
const replaceVehicles = (allVehicles = {}, changedVehicles = {}) => {
  return { ...allVehicles, ...changedVehicles };
};

const vehicleReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.AUTO_REFRESH:
      return { ...state, auto_refresh: action.enableAutoRefresh };
    case actionStrings.FETCH_ALL_VEHICLES_SUCCESSFUL:
      return {
        ...state,
        allVehicles: action.payload,
        allVehiclesFetched: true,
        isFetchingAllVehicles: false,
        lastTimeFetched: dayjs().toISOString(),
      };
    case actionStrings.FETCH_VEHICLES_CATEGORIES_SUCCESSFUL:
      return { ...state, categories: action.payload };
    case actionStrings.START_OR_STOP_FETCHING_VEHICLES:
      return { ...state, isFetchingAllVehicles: action.payload };
    case actionStrings.FETCH_CHANGED_VEHICLES_SUCCESFUL: {
      return {
        ...state,
        allVehicles: replaceVehicles(state.allVehicles, action.payload),
        lastTimeFetched: dayjs().toISOString(),
      };
    }
    case actionStrings.UPDATE_VEHICLES_CATEGORY: {
      const newCategories = (state.categories ?? []).map(category => {
        if (category.id === action.payload.id) {
          return action.payload;
        }
        return category;
      });
      return { ...state, categories: newCategories };
    }
    case actionStrings.RESTART_VEHICLES:
      return {
        ...state,
        allVehicles: [],
        allVehiclesFetched: false,
        isFetchingAllVehicles: false,
        lastTimeFetched: '',
      };
    case actionStrings.UPDATE_IS_BULK_UPDATING: {
      return {
        ...state,
        isBulkUpdating: action.isUpdating,
      };
    }
    default:
      return state;
  }
};

export default filterActions(vehicleReducer, [
  actionStrings.AUTO_REFRESH,
  actionStrings.FETCH_ALL_VEHICLES_SUCCESSFUL,
  actionStrings.FETCH_VEHICLES_CATEGORIES_SUCCESSFUL,
  actionStrings.START_OR_STOP_FETCHING_VEHICLES,
  actionStrings.FETCH_CHANGED_VEHICLES_SUCCESFUL,
  actionStrings.UPDATE_VEHICLES_CATEGORY,
  actionStrings.RESTART_VEHICLES,
  actionStrings.UPDATE_IS_BULK_UPDATING,
]);
