import { isEmpty } from 'lodash';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams, resolveTagsAndLabelsParams } from './util';

const BASE_URL_CATEGORY = `${ApiService.BACKEND_URL}/smart-stations/categories`;
const BASE_URL_POI = `${ApiService.BACKEND_URL}/smart-stations/pois`;

class PoiService {
  /**
   * Method for fetching point of interests
   */
  static getAllPoi(AuthToken, branchId, params) {
    const filtersString = createStringFromFilter(resolveTagsAndLabelsParams(params));
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL_POI}?${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for creating poi.
   * @param {string} authToken - access token.
   * @param {object} data - data send for creation.
   */
  static createPoi(authToken, data) {
    return ApiService.ApiCallBody('POST', `${BASE_URL_POI}`, data, authToken, 'application/json');
  }

  /**
   * Method for fetching point of interest by id
   * @param {string} authToken - access token.
   */
  static getPoiById(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL_POI}/${id}`, authToken, 'application/json');
  }

  /**
   * Method for updating point of interest
   * @param {string} authToken - access token.
   */
  static updatePoi(authToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL_POI}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for deleting point of interest
   * @param {string} authToken - access token.
   */
  static deletePoi(authtoken, id) {
    return ApiService.ApiCallAuth('DELETE', `${BASE_URL_POI}/${id}`, authtoken, 'application/json');
  }

  /**
   * Method fetching point of interest categories
   */
  static getAllPoiCategories(AuthToken, branchId, params = {}) {
    let filtersString = '';
    let paramString = '';

    if (!isEmpty(params)) {
      filtersString = createStringFromFilter(resolveTagsAndLabelsParams(params));
      // eslint-disable-next-line no-param-reassign
      delete params.filters;
      paramString = createStringFromParams(params);
    }
    const branch = branchId ? `branchId=${branchId}` : '';

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL_CATEGORY}?${branch}${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for creating poi category.
   * @param {string} authToken - access token.
   * @param {object} data - data send for creation.
   */
  static createPoiCategory(authToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL_CATEGORY}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method fetching point of interest categories
   * @param {string} authToken - access token.
   */
  static getPoiCategoryById(authToken, id) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL_CATEGORY}/${id}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method fetching point of interest categories
   * @param {string} authToken - access token.
   */
  static updatePoiCategory(authToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL_CATEGORY}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method fetching point of interest categories
   * @param {string} authToken - access token.
   */
  static deletePoiCategory(authtoken, id) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL_CATEGORY}/${id}`,
      authtoken,
      'application/json'
    );
  }
}
export default PoiService;
