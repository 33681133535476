import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import appSagas from './app/sagas';
import customerSagas from './customers/sagas';
import vehicleSagas from './vehicles/sagas';
import issuesSagas from './issues/sagas';
import authoritySaga from './authority/sagas';
import entitySaga from './entities/sagas';
import tableSaga from './table/sagas';
import bookingSaga from './bookings/sagas';
import paymentsSaga from './payments/sagas';
import tasksSaga from './tasks/sagas';
import verificationSaga from './verification/sagas';
import CSVSaga from './csv/sagas';
import resourcesSaga from './resources/sagas';
import settingsSaga from './settings/sagas';
import annotationsSaga from './annotations/sagas';
import recurringTasksSaga from './recurringTasks/sagas';
import goalsSaga from './goals/sagas';
import stationsSagas from './stations/sagas';
import pluginsSagas from './plugins/sagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    appSagas(),
    customerSagas(),
    vehicleSagas(),
    issuesSagas(),
    authoritySaga(),
    entitySaga(),
    tableSaga(),
    bookingSaga(),
    paymentsSaga(),
    tasksSaga(),
    verificationSaga(),
    CSVSaga(),
    resourcesSaga(),
    settingsSaga(),
    annotationsSaga(),
    recurringTasksSaga(),
    goalsSaga(),
    stationsSagas(),
    pluginsSagas(),
  ]);
}
