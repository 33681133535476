import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import AppLocale from '../languageProvider';

import { getCurrentUserDetails } from '../redux/app/selectors';
import { actions as appActions } from '../redux/app/actions';

const InternationalizationProvider = ({ children }) => {
  const language = get(
    useSelector(getCurrentUserDetails, isEqual),
    ['customProperties', 'language'],
    'en'
  );

  /** `dispatch` used for dispatching action to the store */
  const dispatch = useDispatch();

  const currentAppLocale = AppLocale[language];

  /** `useEffect` hook for change languages in redux saga */
  React.useEffect(() => {
    dispatch(appActions.changeLanguage(language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
        onError={() => null}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

InternationalizationProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

InternationalizationProvider.defaultProps = {
  children: null,
};

export default InternationalizationProvider;
