import { createSelector } from 'reselect';
import get from 'lodash/get';
import { availableEntities } from '../../redux-config';

const getEntities = state => state.Entities;
export const getEntityData = (state, entity) => state.Entities?.[entity];

const getAnnotationsState = state => get(state.Entities, [availableEntities.ANNOTATIONS], []);
const getRefreshEntityTableState = state => state.Entities.refresh;

export const getAnnotations = createSelector(getAnnotationsState, ann => ann);
export const getEntitiesState = createSelector(getEntities, entities => entities);
export const getRefreshEntityTable = createSelector(getRefreshEntityTableState, r => r);
