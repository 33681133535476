// TODO: wtf is this and why
/* eslint-disable func-names */
/* eslint-disable consistent-return */
import { call, put } from 'redux-saga/effects';

import { getBranch } from './sagaHelpers';
import { errorNotification, getAuthToken } from './commonSagas';

import { actions as tablesActions } from '../table/actions';

function* parseHeaders(response, entity) {
  const { headers } = response;
  yield put(tablesActions.setTable(entity, 'total', +headers.get('X-Pagination-Totalelements')));
  return response;
}

export function* handleResponse(
  response,
  successHandler,
  errorHandler,
  entity,
  shouldParseJson = true,
  shouldReturnResult = false,
  shouldParseHeaders = true
) {
  if (response.status === 401) {
    yield call(
      errorNotification,
      'feedback.alert.apiUnauthorized.title',
      'feedback.alert.apiUnauthorized.message'
    );
    return;
  }

  if (response.status >= 500) {
    if (!errorHandler) {
      yield call(
        errorNotification,
        'feedback.alert.apiError.title',
        'feedback.alert.apiError.message'
      );
      return;
    }
  }

  if (response.status === 403) {
    if (!errorHandler) {
      yield call(
        errorNotification,
        'feedback.alert.apiForbidden.title',
        'feedback.alert.apiForbidden.message'
      );
      return;
    }
  }

  const handledResponse = shouldParseHeaders
    ? yield call(parseHeaders, response, entity)
    : response;

  let result;
  if (shouldParseJson) {
    try {
      result = yield call([handledResponse, handledResponse.json]);
    } catch (e) {
      // problem was sometimes response don't have json obj for parse
    }
  } else return true;

  if (response.status >= 200 && response.status <= 300) {
    if (successHandler) yield successHandler(result);
    if (shouldReturnResult) return result;
    return true;
  }
  if (errorHandler && result)
    yield call(errorHandler, `Code: ${result.errorCode};   ${result.userMessage}`);
  return false;
}

function handleRequest(
  apiCall,
  successHandler,
  errorHandler,
  startLoadingHandler,
  endLoadingHandler,
  entity,
  shouldParseJson = true,
  shouldReturnResult = false,
  shouldParseHeaders = true,
  ...args
) {
  return function* () {
    try {
      if (startLoadingHandler) yield startLoadingHandler();
      const idToken = yield call(getAuthToken);
      const branch = yield call(getBranch);
      if (idToken && branch && branch.id) {
        const response = yield call(apiCall, idToken, branch.id, ...args);
        const result = yield call(
          handleResponse,
          response,
          successHandler,
          errorHandler,
          entity,
          shouldParseJson,
          shouldReturnResult,
          shouldParseHeaders
        );
        return result;
      }
    } catch (e) {
      if (errorHandler) yield errorHandler(e);
      return false;
    } finally {
      if (endLoadingHandler) yield endLoadingHandler();
    }
  };
}

export function* fetchData(
  apiCall,
  successHandler,
  errorHandler,
  startLoadingHandler,
  endLoadingHandler,
  action,
  shouldParseHeaders = true
) {
  yield call(
    handleRequest(
      apiCall,
      successHandler,
      errorHandler,
      startLoadingHandler,
      endLoadingHandler,
      action.entity,
      true,
      true,
      shouldParseHeaders,
      action.payload
    )
  );
}

export function* submitData(apiCall, successHandler, errorHandler, ...args) {
  yield call(
    handleRequest(
      apiCall,
      successHandler,
      errorHandler,
      null,
      null,
      null,
      true,
      true,
      true,
      ...args
    )
  );
}

export function* submitDataWithoutResponse(apiCall, ...args) {
  yield call(handleRequest(apiCall, null, null, null, null, null, false, false, true, ...args));
}
