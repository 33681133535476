import set from 'lodash/set';
import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/tasks/recurring`;

class RecurringTasksService {
  /**
   * Retreives all recurring tasks for selected branch.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - Id of the branch to fetch for.
   * @param {string} params - params for fetching
   */
  static getRecurringTasks(authToken, branchId, params) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}${params}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Creates a recourring task.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - branchId.
   * @param {object} payload - values of the fields
   */
  static createRecurringTask(authToken, branchId, payload) {
    const {
      title,
      description,
      vehicleCategoryId,
      recurrenceCriteria,
      recurrenceTimeUnit,
      recurrenceStep,
    } = payload;
    const data = {
      taskData: {
        title,
        description,
        priority: 'NORMAL',
        taskState: 'NEW',
        branchId,
      },
      taskObject: {
        vehicleCategoryId,
        vehicleId: null,
        issueId: null,
      },
      recurrenceData: {
        recurrenceCriteria,
        recurrenceStep,
        recurrenceEndCriteria: recurrenceCriteria,
        recurrenceEndAt: '',
        // TODO: why
        recurrenceEndPoint: 999999999,
      },
    };
    if (recurrenceCriteria === 'TIME') {
      data.recurrenceData.recurrenceTimeUnit = recurrenceTimeUnit;
      data.recurrenceData.recurrenceEndTimeUnit = recurrenceTimeUnit;
    }
    return ApiService.ApiCallBody('POST', `${BASE_URL}`, data, authToken, 'application/json');
  }

  /**
   * Updates a recurring task.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - branchId.
   * @param {object} payload - values of the fields
   */
  static updateRecurringTask(authToken, branchId, payload) {
    const { id, property, propertyValue } = payload;
    const data = set({}, property, propertyValue);
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Deletes a recurring task.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - branchId.
   * @param {object} payload - values of the fields
   */
  static deleteRecurringTask(authToken, branchId, payload) {
    const { id, children } = payload;
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${id}?children=${!!children}`,
      authToken,
      'application/json'
    );
  }
}
export default RecurringTasksService;
