import { action } from '../common/commonActions';

export const actionStrings = {
  SET_TABLE_FILTERS: 'SET_TABLE_FILTERS',
  RESET_TABLE_FILTERS: 'RESET_TABLE_FILTERS',
};

const actions = {
  setTableFilters: action(actionStrings.SET_TABLE_FILTERS),
  resetFilters: action(actionStrings.RESET_TABLE_FILTERS),
};

export default actions;
