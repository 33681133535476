const simpleAction = type => id => ({ type, id });
const commandErrorAction = type => (id, command, error) => ({
  type,
  id,
  command,
  error,
});

// added update tags
export const actionStrings = {
  VEHICLE_COMMAND: 'VEHICLE_COMMAND',
  RUNNING_COMMAND: 'RUNNING_COMMAND',
  FINISHED_COMMAND: 'FINISHED_COMMAND',
  COMMAND_ERROR: 'COMMAND_ERROR',
  UPDATE_VEHICLE: 'UPDATE_VEHICLE',
  UPDATE_VEHICLE_W_DATA: 'UPDATE_VEHICLE_W_DATA',
  AUTO_REFRESH: 'AUTO_REFRESH',
  UPDATE_TAGS: 'UPDATE_TAGS',
  /**
   * Describes that we're fetching vehicles in the `Vehicles` redux state variable.
   */
  FETCH_ALL_VEHICLES_SUCCESSFUL: '@vehicles/fetch_all_success',
  FETCH_ALL_VEHICLES_FAILED: '@vehicles/fetch_all_failed',
  UPLOAD_FILES: '@vehicles/upload_files',
  FETCH_ALL_VEHICLES_REFRESH: 'FETCH_ALL_VEHICLES_REFRESH',
  FETCH_CHANGED_VEHICLES_SUCCESFUL: 'FETCH_CHANGED_VEHICLES_SUCCESFUL',
  RESTART_VEHICLES: 'RESTART_VEHICLES',

  START_OR_STOP_FETCHING_VEHICLES: 'START_FETCHING_VEHICLES',

  FETCH_VEHICLES_CATEGORIES: 'FETCH_VEHICLES_CATEGORIES',
  FETCH_VEHICLES_CATEGORIES_SUCCESSFUL: 'FETCH_VEHICLES_CATEGORIES_SUCCESSFUL',

  UPDATE_VEHICLES_CATEGORY: 'UPDATE_VEHICLES_CATEGORY',
  // TODO: dont delete this
  SEND_COMMAND: 'SEND_COMMAND',
  SEND_COMMAND_SUCCESS: 'SEND_COMMAND_SUCCESS',
  UPDATE_IS_BULK_UPDATING: 'UPDATE_IS_BULK_UPDATING',
};

// TODO: Delete almost everything
export const commands = {
  SET_OPERATIONAL: 'SET_OPERATIONAL',
  SET_OUT_OF_ORDER: 'SET_OUT_OF_ORDER',
  UPDATE_STATUS: 'UPDATE_STATUS',
  RETIRED: 'RETIRED',
  // These commands are specific to `NINEBOT_ES2_TLT` and `NINEBOT_ES2_SEGWAY_IOT` as of PR:3658.
  SET_STANDARD_MODE: 'SET_STANDARD_MODE',
  SET_SPEED_LIMIT_MODE: 'SET_SPEED_LIMIT_MODE',
  SET_SPEED_LIMIT_MODE_25: 'SET_SPEED_LIMIT_MODE_25',
  DISABLE_MODE_CHANGE: 'DISABLE_MODE_CHANGE',
  // These commands are specific to `NINEBOT_ES2_TLT` as of PR:3658.
  REBOOT: 'REBOOT',
  // These commands are specific to `NINEBOT_ES2_SEGWAY_IOT` as of PR:3658.
  POWER_ON: 'POWER_ON',
  POWER_OFF: 'POWER_OFF',
};

export const actions = {
  fetchAllVehicles: branchId => ({
    type: actionStrings.FETCH_ALL_VEHICLES_REFRESH,
    branchId,
  }),
  updateTags: (id, data) => ({
    type: actionStrings.UPDATE_TAGS,
    id,
    data,
  }),
  updateVehicle: (id, key, value) => ({
    type: actionStrings.UPDATE_VEHICLE,
    id,
    key,
    value,
  }),
  updateVehicleWithData: (id, data) => ({
    type: actionStrings.UPDATE_VEHICLE_W_DATA,
    id,
    data,
  }),
  runningCommand: simpleAction(actionStrings.RUNNING_COMMAND),
  finishedCommand: simpleAction(actionStrings.FINISHED_COMMAND),
  vehicleCommand: (id, command, callBack) => ({
    type: actionStrings.VEHICLE_COMMAND,
    id,
    command,
    callBack,
  }),
  vehicleCommandWithoutStateUpdate: (id, command, entity) => ({
    type: actionStrings.VEHICLE_COMMAND_WITHOUT_STATE_UPDATE,
    id,
    command,
    entity,
  }),
  commandError: commandErrorAction(actionStrings.COMMAND_ERROR),
  autoRefresh: enableAutoRefresh => ({
    type: actionStrings.AUTO_REFRESH,
    enableAutoRefresh,
  }),
  uploadFiles: (files, entityId) => ({
    type: actionStrings.UPLOAD_FILES,
    payload: files,
    id: entityId,
  }),
  sendCommand: (vehicleId, commandData, callBack) => ({
    type: actionStrings.SEND_COMMAND,
    payload: { commandData, vehicleId, callBack },
  }),
  fetchVehicleCategories: () => ({
    type: actionStrings.FETCH_VEHICLES_CATEGORIES,
  }),
  updateVehiclesCategory: category => ({
    type: actionStrings.UPDATE_VEHICLES_CATEGORY,
    category,
  }),
  updateBulkIsUpdating: isUpdating => ({
    type: actionStrings.UPDATE_IS_BULK_UPDATING,
    isUpdating,
  }),
};

const vehicleCommand = command => (id, callBack) => actions.vehicleCommand(id, command, callBack);

export const vehicleCommands = {
  setOperational: vehicleCommand(commands.SET_OPERATIONAL),
  setOutOfOrder: vehicleCommand(commands.SET_OUT_OF_ORDER),
  updateStatus: vehicleCommand(commands.UPDATE_STATUS),
  setRetired: vehicleCommand(commands.RETIRED),
};

export default { ...actionStrings, ...actions, ...vehicleCommands };
