import dayjs from 'dayjs';
import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const getLoadingObject = invokerAction => ({
  startTime: new Date().getTime(),
  invokerAction,
  loading: true,
});

const initState = {
  bookingChildren: [],
  bookingUtilization: {},
  selectedBookingDate: dayjs(new Date()).startOf('day'),
  selectedBranch: undefined,
  loading: [],
  errors: [],
};

const bookingReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.BOOKINGS_LOADER_START: {
      return {
        ...state,
        loading: [...state.loading, getLoadingObject(action.invokerAction)],
      };
    }
    case actionStrings.BOOKINGS_LOADER_END: {
      const filteredLoading = state.loading.filter(
        l => l.invokerAction.type !== action.invokerAction.type
      );
      return {
        ...state,
        loading: filteredLoading,
      };
    }
    case actionStrings.BOOKINGS_ERROR: {
      const { errors } = state;
      errors.push({ error: action, invokerAction: action.invokerAction });
      return {
        ...state,
        errors: [...errors, { error: action, invokerAction: action.invokerAction }],
      };
    }
    default:
      return state;
  }
};

export default filterActions(bookingReducer, [
  actionStrings.BOOKINGS_LOADER_START,
  actionStrings.BOOKINGS_LOADER_END,
  actionStrings.BOOKINGS_ERROR,
]);
