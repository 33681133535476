import omit from 'lodash/omit';
import set from 'lodash/set';

import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/tasks`;

export default class TaskService {
  static getTasks(authToken, branch, params) {
    // TODO Backend needs to accept array of enums insted of accepting strings one by one
    const filtersString = createStringFromFilter(params.filters || '');
    /** `prepared params depending is there includeChildren` */
    const preparedParams = params.includeChildren
      ? set(omit(params, ['includeChildren', 'filters']), 'includeChildBranches', true)
      : omit(params, 'filters');
    const paramString = createStringFromParams(preparedParams);

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${paramString}${filtersString}&sort=id,DESC`,
      authToken,
      'application/json'
    );
  }

  static getAgentClosedTasks(authToken, authId, params) {
    const paramString = createStringFromParams(params, '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/user/${authId}/completed-tasks${paramString}`,
      authToken,
      'application/json'
    );
  }

  static getVehicleTasks(authToken, vehicleId, branchId) {
    return TaskService.getTasks(authToken, branchId, { vehicleId }).then(response =>
      response.json()
    );
  }

  static getBranchClosedTasks(authToken, branch, params) {
    const paramString = createStringFromParams(params, '?');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/branch/${branch}/completed-tasks${paramString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Retrieve vehicles with provided filters through query params.
   *
   * If we need to sort by a different order or paginate, pass along `size`, `page`, `sort`.
   *
   * @param {string} authToken - Authentication bearer token.
   * @param {object} options - Request cancellation signal, as well as query parameters
   * that signify which tasks for which vehicle we're retrieving the tasks.
   */
  static getVehicleTasksAbortable(
    authToken,
    {
      signal,
      queryParams: {
        size = 20,
        sort = 'id,DESC',
        page = 0,
        vehicleId,
        branchId,
        includeChildBranches = false,
      },
    }
  ) {
    const options = {
      method: 'GET',
      signal,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };
    return ApiService.ApiCallAbortable(
      `${BASE_URL}?vehicleId=${vehicleId}&branchId=${branchId}&size=${size}&sort=${sort}&page=${page}&includeChildBranches=${includeChildBranches}`,
      options
    );
  }

  static createVehicleTask(
    authToken,
    vehicleId,
    title,
    category,
    description = '',
    branchId,
    assignedToId = null,
    priority = 'LOW',
    damageId = null,
    vehicleCategoryId = null
  ) {
    const data = {
      taskData: {
        title,
        category,
        description,
        taskState: 'NEW',
        priority: priority?.toUpperCase(),
        backendAuthorityRole: 'ADMIN',
        branchId,
        assignedToId,
        vehicleCategoryId,
      },
      taskObject: {
        guserId: null,
        vehicleId,
        issueId: null,
        damageId,
      },
    };
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json').then(
      response => response.json()
    );
  }

  static createVehicleTasksMulti(
    authToken,
    title,
    category,
    description,
    vehicleCategoryId,
    branchId,
    includeChildBranches,
    assignedToId,
    priority
  ) {
    const data = {
      taskData: {
        title,
        category,
        description,
        branchId,
        assignedToId,
        priority: priority?.toUpperCase(),
      },
      taskObject: {
        vehicleCategoryId,
      },
    };
    const includeChild = includeChildBranches ? '&includeChildBranches=true' : '';
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}?forAllVehiclesByBranch${includeChild}`,
      data,
      authToken,
      'application/json'
    ).then(response => response.json());
  }

  static toggleVehicleCompleted(authToken, vehicleId, task, checked) {
    const data = {
      taskData: {
        ...task.taskData,
        taskState: checked ? 'COMPLETED' : 'IN_PROGRESS',
      },
      taskObject: {
        ...task.taskObject,
      },
    };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${task.taskData.id}`,
      data,
      authToken,
      'application/json'
    ).then(response => response.json());
  }

  /**
   * Initiates a delete request for a given vehicle task.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} taskId - Id of the task to delete.
   */
  static deleteVehicleTask(authToken, taskId) {
    return ApiService.ApiCallAuth('DELETE', `${BASE_URL}/${taskId}`, authToken, 'application/json');
  }

  /**
   * Updates the task with a `PATCH` request given the key/value pair.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} taskId - The id of the task to update for.
   * @param {string} key - The key of the property we aim to update.
   * @param {any} - The value we want to update.
   */
  static updateTask(authToken, taskId, key, value) {
    let data = {};

    if (Array.isArray(key)) {
      data = key.reduce((acc, k, i) => set(acc, k, value[i]), {});
    } else {
      data = set({}, key, value);
    }

    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${taskId}`,
      data,
      authToken,
      'application/json'
    );
  }
}
