import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'nprogress/nprogress.css';

import ProtectedRoute from './ProtectedRoute';
// TODO: When new path is added, add also new path inside paths folder
import paths from './paths';

import {
  ANALYTICS_PLUGIN_NAME,
  SERVICE_OPERATIONS_PLUGIN_NAME,
} from '../../constants/settings-plugins';
import PageSkeleton from '../../components/skeleton/PageSkeleton';

export const routes = [
  {
    path: paths.DEFAULT,
    component: React.lazy(() => import('../Map/vehicle-map')),
  },
  {
    path: paths.MAP,
    component: React.lazy(() => import('../Map/vehicle-map')),
  },
  {
    path: paths.VEHICLES_MAP,
    component: React.lazy(() => import('../Map/vehicle-map')),
  },
  {
    path: paths.VEHICLES_MAP_ID,
    component: React.lazy(() => import('../Map/vehicle-map')),
  },
  {
    path: paths.CUSTOMERS,
    component: React.lazy(() => import('../Tables/customers')),
  },
  {
    path: paths.PRODUCTIVITY_INSIGHTS,
    component: React.lazy(() => import('../ServiceOperations/productivity-insights')),
    plugin: SERVICE_OPERATIONS_PLUGIN_NAME,
  },
  {
    path: paths.SERVICE_AGENTS,
    component: React.lazy(() => import('../ServiceOperations/service-agents-table')),
    plugin: SERVICE_OPERATIONS_PLUGIN_NAME,
  },
  {
    path: paths.BUSINESS_ACCOUNTS,
    component: React.lazy(() => import('../Tables/business-accounts')),
  },
  {
    path: paths.BUSINESS_ACCOUNTS_ID,
    component: React.lazy(() => import('../Tables/business-accounts')),
  },
  {
    path: paths.CUSTOMERS_ID,
    component: React.lazy(() => import('../Tables/customers')),
  },
  {
    path: paths.INVOICES,
    component: React.lazy(() => import('../Tables/invoices')),
  },
  {
    path: paths.POI,
    component: React.lazy(() => import('../Tables/poi/poi')),
    rootBranchCase: true,
  },
  {
    path: paths.POI_ID,
    component: React.lazy(() => import('../Tables/poi/poi')),
    rootBranchCase: true,
  },
  {
    path: paths.POI_CAT,
    component: React.lazy(() => import('../Tables/poi/poi-cat')),
    rootBranchCase: true,
  },
  {
    path: paths.POI_CAT_ID,
    component: React.lazy(() => import('../Tables/poi/poi-cat')),
    rootBranchCase: true,
  },
  {
    path: paths.VEHICLES,
    component: React.lazy(() => import('../Tables/vehicles/index')),
    includeChildBranches: true,
  },
  {
    path: paths.VEHICLES_ID,
    component: React.lazy(() => import('../Tables/vehicles')),
    includeChildBranches: true,
  },
  {
    path: paths.RENTALS,
    component: React.lazy(() => import('../Tables/rentals')),
    includeChildBranches: true,
  },
  {
    path: paths.RENTALS_ID,
    component: React.lazy(() => import('../Tables/rentals')),
    includeChildBranches: true,
  },
  {
    path: paths.TASKS,
    component: React.lazy(() => import('../Tables/tasks')),
    includeChildren: true,
  },
  {
    path: paths.TASKS_ID,
    component: React.lazy(() => import('../Tables/tasks')),
    includeChildren: true,
  },
  {
    path: paths.DAMAGES,
    component: React.lazy(() => import('../Tables/damages')),
  },
  {
    path: paths.DAMAGES_ID,
    component: React.lazy(() => import('../Tables/damages')),
  },
  {
    path: paths.SETTINGS_ROLES,
    component: React.lazy(() => import('../Settings/roles-and-permissions')),
  },
  {
    path: paths.SETTINGS_ADVANCED,
    component: React.lazy(() => import('../Settings/advancedSettings')),
  },
  {
    path: [paths.SETTINGS, paths.SETTINGS_SETTING],
    component: React.lazy(() => import('../Settings')),
  },
  {
    path: paths.ISSUES,
    component: React.lazy(() => import('../Tables/issues')),
  },
  {
    path: paths.ISSUES_ID,
    component: React.lazy(() => import('../Tables/issues')),
  },
  {
    path: paths.BOOKINGS,
    component: React.lazy(() => import('../Tables/bookings')),
  },
  {
    path: paths.BOOKINGS_ID,
    component: React.lazy(() => import('../Tables/bookings')),
  },
  {
    path: [paths.ANALYTICS, paths.ANALYTICS_ENTITY],
    component: React.lazy(() => import('../Analytics/analytics-page')),
    plugin: ANALYTICS_PLUGIN_NAME,
  },
  {
    path: paths.BOOKING_CALENDAR,
    component: React.lazy(() => import('../Booking/BookingCalendar/BookingCalendarContainer')),
  },
  {
    path: paths.LOGS,
    component: React.lazy(() => import('../Tables/events')),
  },
  {
    path: paths.ROLES,
    component: React.lazy(() => import('../Tables/roles/index')),
  },
  {
    path: paths.OVERVIEW,
    component: React.lazy(() => import('../overview')),
  },
  {
    path: paths.APPS,
    component: React.lazy(() => import('../Settings/plugins')),
  },
  {
    path: paths.SERVICE_AGENTS_ID,
    component: React.lazy(() => import('../ServiceOperations/details')),
    plugin: SERVICE_OPERATIONS_PLUGIN_NAME,
  },
  {
    path: paths.FORBIDDEN,
    component: React.lazy(() => import('../Page/406')),
  },
  {
    path: paths.NOT_FOUND,
    component: React.lazy(() => import('../Page/404')),
  },
];

const resolvePath = (url, path) => {
  if (Array.isArray(path)) return path.map(v => `${url}${v}`);
  return `${url}${path}`;
};

const resolveKey = path => {
  if (Array.isArray(path)) return path[0];
  return path;
};

const AppRouter = ({ url }) => {
  return (
    <React.Suspense fallback={PageSkeleton}>
      <Switch>
        {routes.map(singleRoute => {
          const { path, exact, rootBranchCase = false, plugin, ...otherProps } = singleRoute;
          return (
            <ProtectedRoute
              exact={exact !== false}
              key={resolveKey(singleRoute.path)}
              path={resolvePath(url, singleRoute.path)}
              rootBranchCase={rootBranchCase}
              plugin={plugin}
              {...otherProps}
            />
          );
        })}
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route exact path="*" render={() => <Redirect to="/dashboard/404" />} />
      </Switch>
    </React.Suspense>
  );
};

AppRouter.propTypes = {
  url: PropTypes.string.isRequired,
};

export default AppRouter;
