import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  auto_refresh: false,
  isLoading: false,
  stations: [],
};

const stationReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.REFRESH_STATIONS:
      return {
        ...state,
        auto_refresh: action.payload,
      };
    case actionStrings.UPDATE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionStrings.FETCH_STATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stations: action.payload,
      };
    case actionStrings.FETCH_STATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionStrings.ADD_STATION:
      return {
        ...state,
        stations: [action.payload, ...state.stations],
      };
    case actionStrings.UPDATE_STATION_IN_PLACE:
      return {
        ...state,
        stations: state.stations.map(station =>
          station.id === action.payload.id ? { ...action.payload } : station
        ),
      };
    case actionStrings.RESET_STATIONS:
      return { ...initState };
    default:
      return state;
  }
};

export default filterActions(stationReducer, [
  actionStrings.REFRESH_STATIONS,
  actionStrings.UPDATE_LOADING,
  actionStrings.FETCH_STATIONS_SUCCESS,
  actionStrings.FETCH_STATIONS_FAIL,
  actionStrings.ADD_STATION,
  actionStrings.UPDATE_STATION_IN_PLACE,
  actionStrings.RESET_STATIONS,
]);
