export default {
  ANALYTICS_READ: 'ANALYTICS_READ',
  // BOOKING
  BOOKING_READ: 'BOOKING_READ',
  BOOKING_LIST_READ: 'BOOKING_LIST_READ',
  BOOKING_CREATE: 'BOOKING_CREATE',
  BOOKING_UPDATE: 'BOOKING_UPDATE',
  BRANCH_CREATE: 'BRANCH_CREATE',
  BRANCH_READ: 'BRANCH_READ',
  INVOICE_READ: 'INVOICE_READ',
  // ISSUE
  ISSUE_READ: 'ISSUE_READ',
  ISSUE_LIST_READ: 'ISSUE_LIST_READ',
  ISSUE_CREATE: 'ISSUE_CREATE',
  ISSUE_UPDATE: 'ISSUE_UPDATE',
  ISSUE_INVOICE: 'ISSUE_INVOICE',
  // PAYMENT
  PAYMENT_READ: 'PAYMENT_READ',
  // RATING (CREDIT)
  RATING_READ: 'RATING_READ',
  RATING_TRIGGER: 'RATING_TRIGGER',
  RATING_UPDATE: 'RATING_UPDATE',
  // RENTAL
  RENTAL_READ: 'RENTAL_READ',
  RENTAL_LIST_READ: 'RENTAL_LIST_READ',
  RENTAL_CREATE: 'RENTAL_CREATE',
  // USER
  USER_READ: 'USER_READ',
  USER_LIST_READ: 'USER_LIST_READ',
  USER_CREATE: 'USER_CREATE',
  USER_DELETE: 'USER_DELETE',
  USER_UPDATE: 'USER_UPDATE',
  USER_ADD_EMAIL_SIGN_IN_METHOD: 'USER_ADD_EMAIL_SIGN_IN_METHOD',
  // VEHICLE
  VEHICLE_READ: 'VEHICLE_READ',
  VEHICLE_CREATE: 'VEHICLE_CREATE',
  VEHICLE_LIST_READ: 'VEHICLE_LIST_READ',
  ROLE_ASSIGNMENT_READ: 'ROLE_ASSIGNMENT_READ',
  ROLE_UPDATE: 'ROLE_UPDATE',
  TASK_READ: 'TASK_READ',
  TASK_CREATE: 'TASK_CREATE',
  VEHICLE_DAMAGE_READ: 'VEHICLE_DAMAGE_READ',
  // LOGS
  EVENT_READ: 'EVENT_READ',
  // STATIONS
  STATION_CREATE: 'STATION_CREATE',
  STATION_READ: 'STATION_READ',
  // VEHICLE CATEGORIES
  VEHICLE_CATEGORY_CREATE: 'VEHICLE_CATEGORY_CREATE',
  // MESSAGES
  MESSAGE_RESOURCE_CREATE: 'MESSAGE_RESOURCE_CREATE',
  // VOUCHERS
  VOUCHER_CREATE: 'VOUCHER_CREATE',
  VOUCHER_READ: 'VOUCHER_READ',
  PACKAGE_CREATE: 'PACKAGE_CREATE',
  PACKAGE_READ: 'PACKAGE_READ',
  TRIGGERED_VOUCHERABLE_CREATE: 'TRIGGERED_VOUCHERABLE_CREATE',
  TRIGGERED_VOUCHERABLE_READ: 'TRIGGERED_VOUCHERABLE_READ',
  CUSTOMER_CARE_VOUCHERABLE_CREATE: 'CUSTOMER_CARE_VOUCHERABLE_CREATE',
  CUSTOMER_CARE_VOUCHERABLE_READ: 'CUSTOMER_CARE_VOUCHERABLE_READ',
  SIGNUP_REFERRAL_VOUCHERABLE_CREATE: 'SIGNUP_REFERRAL_VOUCHERABLE_CREATE',
  SIGNUP_REFERRAL_VOUCHERABLE_READ: 'SIGNUP_REFERRAL_VOUCHERABLE_READ',
  VEHICLE_PROMOTION_VOUCHERABLE_CREATE: 'VEHICLE_PROMOTION_VOUCHERABLE_CREATE',
  VEHICLE_PROMOTION_VOUCHERABLE_READ: 'VEHICLE_PROMOTION_VOUCHERABLE_READ',
  VOUCHERABLE_CREATE: 'VOUCHERABLE_CREATE',
  VOUCHERABLE_READ: 'VOUCHERABLE_READ',
  PROMOTION_READ: 'PROMOTION_READ',
  PROMOTION_CREATE: 'PROMOTION_CREATE',
  PROMOTION_UPDATE: 'PROMOTION_UPDATE',
  // ADDITIONS
  RENTAL_ADDITION_CREATE: 'RENTAL_ADDITION_CREATE',
  // SUBSCRIPTIONS
  SUBSCRIPTION_CREATE: 'SUBSCRIPTION_CREATE',
  // IOT MODULES
  MODULE_READ: 'MODULE_READ',
  // POI
  POI_READ: 'POI_READ',
  POI_CREATE: 'POI_CREATE',
  POI_CATEGORY_READ: 'POI_CATEGORY_READ',
  POI_CATEGORY_CREATE: 'POI_CATEGORY_CREATE',
};
