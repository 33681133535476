export const actionStrings = {
  FETCH_ALL_ANNOTATIONS: 'FETCH_ALL_ANNOTATIONS',
};

const fetchAllAnnotations = () => ({
  type: actionStrings.FETCH_ALL_ANNOTATIONS,
});

export const actions = {
  fetchAllAnnotations,
};
