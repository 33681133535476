import { actionPayload, action } from '../common/commonActions';

export const actionStrings = {
  SAVE_VEHICLE_TASKS: 'SAVE_VEHICLE_TASKS',
  CREATE_VEHICLE_TASK: 'CREATE_VEHICLE_TASK',
  STORE_VEHICLE_TASK: 'STORE_VEHICLE_TASK',
  CHANGE_TASK_COMPLETE: 'CHANGE_TASK_COMPLETE',

  /** Action description to select a task. */
  SELECT_TASK: '@tasks/select_task',
  CREATE_VEHICLE_TASKS_MULTI: 'CREATE_VEHICLE_TASKS_MULTI',
};

const actions = {
  saveVehicleTasks: actionPayload(actionStrings.SAVE_VEHICLE_TASKS),
  createVehicleTask: action(actionStrings.CREATE_VEHICLE_TASK),
  storeVehicleTasks: action(actionStrings.STORE_VEHICLE_TASK),
  changeTasckCompleteStatus: action(actionStrings.CHANGE_TASK_COMPLETE),

  /** Set the provided task as the selected one for task-quickview consumption. */
  selectTask: payload => ({ type: actionStrings.SELECT_TASK, payload }),
  createVehicleTasksMulti: action(actionStrings.CREATE_VEHICLE_TASKS_MULTI),
};

export default actions;
