import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { actionStrings } from './actions';

const initState = {
  recurringTasks: [],
};

const sortTasksDescending = recurringTasks =>
  recurringTasks.sort(
    (a, b) => new Date(get(b, 'taskData.updatedAt')) - new Date(get(a, 'taskData.updatedAt'))
  );

export default function recurringTasksReducer(state = initState, action) {
  switch (action.type) {
    case actionStrings.SAVE_RECURRING_TASKS:
      return {
        ...state,
        recurringTasks: [...action.payload],
      };
    case actionStrings.UPDATE_RECURRING_TASKS: {
      const { recurringTasks = [] } = state;
      const existingTaskIndex = findIndex(
        recurringTasks,
        task => task.taskData.id === action.payload.taskData.id
      );
      if (existingTaskIndex !== -1) {
        recurringTasks.splice(existingTaskIndex, 1, action.payload);
        return {
          ...state,
          recurringTasks: sortTasksDescending([...recurringTasks]),
        };
      }
      return {
        ...state,
        recurringTasks: sortTasksDescending([...recurringTasks, action.payload]),
      };
    }
    case actionStrings.DELETE_RECURRING_TASK: {
      const { recurringTasks = [] } = state;
      const deletedTaskIndex = findIndex(
        recurringTasks,
        task => task.taskData.id === action.payload.id
      );
      if (deletedTaskIndex !== -1) {
        recurringTasks.splice(deletedTaskIndex, 1);
        return {
          ...state,
          recurringTasks: sortTasksDescending([...recurringTasks]),
        };
      }
      return state;
    }
    default:
      return state;
  }
}
