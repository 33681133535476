import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

export const filters = {
  BOOKED: 'BOOKED',
  APPROVED: 'APPROVED',
  DELIVERED: 'DELIVERED',
  SUCCESSFUL: 'SUCCESSFUL',
  CANCELED: 'CANCELED',
  ABORTED: 'ABORTED',
  EXPIRED: 'EXPIRED',
  DECLINED: 'DECLINED',
};

const BASE_URL = `${ApiService.BACKEND_URL}/back`;

export default class BookingService {
  static getBookings(AuthToken, branch, params) {
    // TODO Backend needs to accept array of enums instead of accepting strings one by one
    const filtersString = createStringFromFilter(params.filters);
    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/bookings?branchId=${branch}&includeChildBranches=true${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  static addBooking(AuthToken, branchId, params) {
    const data = {
      guserId: params.guserId,
      branchId,
      startTime: new Date(params.startTime),
      endTime: new Date(params.endTime),
      state: params.state,
      vehicleCategoryId: params.vehicleCategoryId,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/bookings`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static updateBooking(AuthToken, params) {
    const data = {
      guserId: params.guserId,
      startTime: new Date(params.startTime),
      endTime: new Date(params.endTime),
      state: params.state,
    };

    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/bookings/${params.rentalId}`,
      data,
      AuthToken,
      'application/json'
    ).then(response => response.json());
  }

  /**
   * Update booking with a PATCH request for each key - value property.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the booking to update for.
   * @param {string} key - The key of the property we should change.
   * @param {any} value - The value for any of the properties values we're changing.
   */
  static updateBookingByKey(AuthToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/bookings/${id}`,
      data,
      AuthToken,
      'application/json'
    ).then(response => response);
  }

  /**
   * Method used to update booking with data.
   * @param {string} authToken - Authentication bearer token.
   * @param {string|number} bookingId - id of booking to be updated.
   * @param {Object} data - used for update.
   */
  static updateBookingWithData(authToken, bookingId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/bookings/${bookingId}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used for managing booking approval.
   * @param {string} authToken - authentication bearer token.
   * @param {number} bookingId - id of the booking.
   * @param {{ rejectionReason ?: string; state: string }} data - data to be sent.
   */
  static manageBookingApproval(authToken, bookingId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/bookings/${bookingId}/approval`,
      data,
      authToken,
      'application/json'
    );
  }

  static pickupBooking(authToken, id) {
    return ApiService.ApiCallBody(
      'post',
      `${BASE_URL}/bookings/${id}?rentalAuthOverride=true`,
      {},
      authToken,
      'application/json'
    ).then(response => response.json());
  }

  static getBooking(authToken, branchId, id) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/bookings/${id}`,
      authToken,
      'application/json'
    ).then(response => response);
  }
}
