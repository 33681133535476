import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { tenantInfo, dsn, dsnFallback } from './config';
import DashApp from './dashApp';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/src/css/mapbox-gl.css';
import 'react-resizable/css/styles.css';
import 'antd/dist/antd.css';

import './config/firebase';
import { initBasicTenantInfo } from './services/coreAPI/util';

(async () => {
  try {
    await initBasicTenantInfo();
    // check if dsn contains tenantInfo.uuid and if not use fallback
    const dsnElement = dsn[tenantInfo.uuid] ? dsn[tenantInfo.uuid] : dsnFallback;

    Sentry.init({ dsn: dsnElement, denyUrls: [/\.localhost:3000/i, /staging/i] });
  } finally {
    ReactDOM.render(<DashApp />, document.getElementById('root'));
  }
})();

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./dashApp.jsx', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./dashApp').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
