import PropTypes from 'prop-types';
import { annotationsType } from '../annotations/index';

export const guserType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  creationTime: PropTypes.string,
  updateTime: PropTypes.string,
  email: PropTypes.string,
  birthdate: PropTypes.string,
  phone: PropTypes.string,
  backendNotes: PropTypes.string,
  customProperties: PropTypes.oneOfType([PropTypes.object]),
  phoneVerified: PropTypes.bool,
  emailIsVerified: PropTypes.bool,
  licenseVerificationState: PropTypes.string,
  manualVerificationState: PropTypes.string,
  paymentState: PropTypes.string,
  street: PropTypes.string,
  postcode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  referralCode: PropTypes.string,
  blockState: PropTypes.string,
  receiveNewsletter: PropTypes.bool,
  fileIds: PropTypes.arrayOf(PropTypes.number),
  annotations: PropTypes.arrayOf(annotationsType),
  creditStatus: PropTypes.string,
});

export default guserType;
