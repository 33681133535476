import { takeEvery, call, all, put } from 'redux-saga/effects';
import VerificationService from '../../services/coreAPI/VerificationService';
import actions from './actions';
import { handleNotification, getAuthToken } from '../common/commonSagas';

import responseOk from '../../utils/responseOk';

function* fetchVerificationAttempts(action) {
  try {
    const { userId } = action;
    const idToken = yield call(getAuthToken);
    const response = yield call(VerificationService.getVerificationAttempts, idToken, userId);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put(actions.fetchListVerifAttemptsSuccess(result));
    }
  } catch (error) {
    handleNotification('error', error.message);
  }
}

function* fetchMediaData(uuid, idToken) {
  const response = yield call(VerificationService.getVerificationMedia, idToken, uuid);
  const file = yield call([response, response.blob]);
  return file;
}

function* fetchMediaOfVerificationAttempts(action) {
  const { media, callBack } = action;
  try {
    const idToken = yield call(getAuthToken);
    const files = yield all(media.map(item => call(fetchMediaData, item.uuid, idToken)));
    const imgUrls = files.map((file, index) => ({
      name: media[index].name,
      url: URL.createObjectURL(file),
    }));
    yield put(actions.fetchVerificationAttemptMediaSuccess(imgUrls));
    callBack();
  } catch (error) {
    callBack();
    handleNotification('error', error.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_LIST_VERIF_ATTEMPTS, fetchVerificationAttempts),
    takeEvery(actions.FETCH_VERIFICATION_ATTEMPT_MEDIA, fetchMediaOfVerificationAttempts),
  ]);
}
