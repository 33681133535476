import { select, take } from 'redux-saga/effects';

/**
 * `generator` used to stop other generator function execution until selector is true
 * Ex. observe the changes in some part of the App state
 * @param {boolean} selector - value which serves as initial indicator if condition is satisfied
 * @returns {boolean} when condition achieved desired state
 */
export default function* waitFor(selector) {
  // `selector` value is as expected
  if (yield select(selector)) return true;

  while (true) {
    // iterate until function breaks
    yield take('*');
    if (yield select(selector)) return true;
  }
}
