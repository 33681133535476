import dayjs from 'dayjs';
import get from 'lodash/get';
import { getNumberOfDaysInMonth } from '../../helpers/utility';
import { longYearNumericMonthFmt } from '../../constants/datetime';
import { getCSSVariable } from '../../utils/cssVariables';

/**
 * Helper function used to prepare date for goals progress items
 * @param {Object} goals - goals represented by months
 * @param {*} achievedResults - achieved goals results
 * @returns {Array} filtered goals with achievements
 */
export const prepareGoalsData = (goals, achievedResults = {}) => {
  const currentMonth = dayjs().format(longYearNumericMonthFmt);
  return Object.keys(goals)
    .map(month => {
      const page = {
        month,
        numberOfDays: getNumberOfDaysInMonth(month),
      };
      Object.keys(goals[month]).forEach(category => {
        const achieved = get(achievedResults, [month, category], 0);

        page[category] = {
          goal: goals[month][category],
          achieved,
        };
      });

      return page;
    })
    .filter(
      page =>
        !dayjs(page.month, longYearNumericMonthFmt).isAfter(
          dayjs(currentMonth, longYearNumericMonthFmt)
        )
    );
};

/**
 * helper function used to set indicator color depending on percentage value
 * @param {number} percent - calculated percentage
 * @returns {String} as hex color
 */
export const getIndicatorColor = percent => {
  switch (true) {
    case percent >= 100:
      return getCSSVariable('--green-7');
    case percent >= 80:
      return getCSSVariable('--gold-6');
    default:
      return getCSSVariable('--red-7');
  }
};
