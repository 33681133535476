import {
  actionPayload,
  actionInvokerAction,
  actionInvokerActionPayload,
} from '../common/commonActions';

export const actionStrings = {
  ADD_BOOKING: 'ADD_BOOKING',
  BOOKINGS_LOADER_START: 'BOOKINGS_LOADER_START',
  BOOKINGS_LOADER_END: 'BOOKINGS_LOADER_END',
  BOOKINGS_ERROR: 'BOOKINGS_ERROR',

  UPDATE_BOOKING: 'UPDATE_BOOKING',
  UPDATE_BOOKING_TABLE: 'UPDATE_BOOKING_TABLE',
  PICKUP_BOOKING: 'PICKUP_BOOKING',
};

const actions = {
  createBooking: actionPayload(actionStrings.ADD_BOOKING),
  bookingsLoaderStart: actionInvokerAction(actionStrings.BOOKINGS_LOADER_START),
  bookingsLoaderEnd: actionInvokerAction(actionStrings.BOOKINGS_LOADER_END),
  bookingsError: actionInvokerActionPayload(actionStrings.BOOKINGS_ERROR),

  updateBooking: actionPayload(actionStrings.UPDATE_BOOKING),
  updateBookingTable: actionPayload(actionStrings.UPDATE_BOOKING_TABLE),

  pickupBooking: actionPayload(actionStrings.PICKUP_BOOKING),
};

export default actions;
