import { all, fork, takeLatest, call, put } from 'redux-saga/effects';

import actions from './actions';
import { actions as entityActions } from '../entities/actions';
import { successNotification, errorNotification, getAuthToken } from '../common/commonSagas';
import PaymentService from '../../services/coreAPI/PaymentService';
import { availableEntities } from '../../redux-config';
import invoiceStates from '../../constants/invoice-states';

function* manualPaymentRetrySuccess(id) {
  // Update the invoice item state
  yield put(
    entityActions.updatePropertyOfItemInPlace(
      availableEntities.INVOICES,
      id,
      'state',
      invoiceStates.PAID
    )
  );
  yield call(successNotification, 'notes.success.response.title', 'payment.retry.success');
}

function* manualPaymentRetryError(payload) {
  yield call(
    errorNotification,
    'feedback.alert.errorTips',
    `${payload.errorCode}: ${payload.userMessage}`
  );
}

function* manualPaymentRetry(action) {
  const {
    payload: { id, paymentId },
  } = action;
  const idToken = yield call(getAuthToken);
  if (idToken) {
    const result = yield call(PaymentService.manulRetry, idToken, paymentId);
    if (result.ok) {
      yield manualPaymentRetrySuccess(id);
    } else {
      yield manualPaymentRetryError(yield call([result, result.json]));
    }
  }
}

function* watchManulPaymentRetry() {
  yield takeLatest(actions.MANUAL_PAYMENT_RETRY, manualPaymentRetry);
}

export default function* rootSaga() {
  yield all([fork(watchManulPaymentRetry)]);
}
