import permissions from './permissions';

const { USER_READ, VEHICLE_READ, BOOKING_READ, RENTAL_READ, ISSUE_READ, ANALYTICS_READ } =
  permissions;

export const pagesPermissions = {
  users: USER_READ,
  vehicles: VEHICLE_READ,
  bookings: BOOKING_READ,
  rentals: RENTAL_READ,
  issues: ISSUE_READ,
  analytics: ANALYTICS_READ,
};

export default {
  Map: '/',
  Users: '/customers',
  Vehicles: '/vehicles',
  Bookings: '/bookings',
  Rentals: '/rentals',
  Issues: '/issues',
  Analytics: '/analytics',
  Overview: '/overview',
};
