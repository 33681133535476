import { all, fork, takeLatest, call } from 'redux-saga/effects';

import { actionStrings } from './actions';
import CSVService from '../../services/coreAPI/CSVService';
import { errorNotification, getAuthToken } from '../common/commonSagas';

import responseOk from '../../utils/responseOk';

function* getCSVExport({ entity }) {
  const idToken = yield call(getAuthToken);
  const response = yield call(
    CSVService.fetchCSV,
    idToken,
    entity.tableName,
    entity.urlQueryParams
  );

  if (!responseOk(response)) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'table.csvExport.error');
    entity.callbackFunc([]);

    return;
  }

  const result = yield call([response, response.json]);

  entity.callbackFunc(result);
}

function* watchCSVExportTrigger() {
  yield takeLatest(actionStrings.EXPORT_CSV, getCSVExport);
}

export default function* rootSaga() {
  yield all([fork(watchCSVExportTrigger)]);
}
