/* eslint-disable react/forbid-prop-types */
import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import HorizontalScroll from '../../../../components/common/horizontal-scroll';
import tableRoutes from '../table-routes';
import NavigationHeader from '../../../../components/navigation/NavigationHeader';
import { getEntitiesState } from '../../../../redux/entities/selectors';

const NavigationContainer = styled.div`
  position: relative;
  top: ${props => (props.$top ? props.$top : '87')}px;
  left: 30px;
  z-index: 2;
  width: 85%;
  height: 0;
`;

const TableNavigation = ({ tableName }) => {
  const { loading: loadingEntity } = useSelector(getEntitiesState, shallowEqual);
  const [navigationPosition, setNavigationPosition] = useState(88);

  useEffect(() => {
    // Need a table header height in order to position navigation on right place
    const headerHeight = document.getElementById('tableHeader')?.scrollHeight;
    // Repositioning after loading a new entity
    if (
      loadingEntity.length &&
      !!headerHeight &&
      !!tableName &&
      navigationPosition !== headerHeight
    )
      setNavigationPosition(headerHeight - 35);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEntity]);

  return (
    <NavigationContainer $top={navigationPosition}>
      <HorizontalScroll>
        <NavigationHeader routes={tableRoutes[tableName]} />
      </HorizontalScroll>
    </NavigationContainer>
  );
};

TableNavigation.propTypes = {
  tableName: PropTypes.object.isRequired,
};

export default memo(TableNavigation, isEqual);
