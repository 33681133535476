import { all, takeLatest, put, fork, call, getContext, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { actionStrings } from './actions';
import { availableEntities } from '../../redux-config';
import { handleMessage, getAuthToken } from '../common/commonSagas';
import { actions as entityActions } from '../entities/actions';
import AnnotationsService from '../../services/coreAPI/AnnotationsService';
import { getAnnotations } from '../entities/selectors';

/**
 * Function for fetching all tags.
 */
function* fetchAllAnnotations() {
  const stateAnnotations = yield select(getAnnotations);

  // Fetch if don't exist
  if (stateAnnotations.length) return;

  // get `token`
  const idToken = yield call(getAuthToken);
  const intl = yield getContext('intl');
  /** variable where all tags are stored */
  const data = [];
  /** current page for fetching */
  let currentPage = 0;
  try {
    let totalPages = 1;
    const response = yield call(AnnotationsService.getAllAnnotations, idToken, {
      page: 0,
      size: 500,
    });
    /** set `total pages` */
    totalPages = response.headers.get('X-Pagination-TotalPages');
    if (response.ok) {
      const responseData = yield call([response, response.json]);
      /** push first response data to array */
      data.push(...responseData);
    }

    const concurrentRequests = [];
    // eslint-disable-next-line no-plusplus
    currentPage++;
    /** if we have more pages made `concurrentRequests` */
    while (currentPage < totalPages) {
      concurrentRequests.push(
        // made requset with `different` current page
        yield call(AnnotationsService.getAllAnnotations, idToken, {
          page: currentPage,
          size: 500,
        })
      );
      // Increment the currentPage for each request made.
      // eslint-disable-next-line no-plusplus
      currentPage++;
    }
    const allResponses = yield Promise.all(concurrentRequests);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allResponses.length; i++) {
      const singleResponse = yield allResponses[i].json();
      data.push(...singleResponse);
    }

    if (!isEqual(stateAnnotations.sort(), data.sort()))
      yield put(entityActions.fetchEntitySuccess(availableEntities.ANNOTATIONS, data));
  } catch (error) {
    if (error.name !== 'AbortError') {
      /** call handle message for `failed` fetching */
      yield call(handleMessage, true, intl.formatMessage({ id: 'tags.fetch.failed' }));
    }
  }
}

function* watchFetchAllAnnotations() {
  yield takeLatest(actionStrings.FETCH_ALL_ANNOTATIONS, fetchAllAnnotations);
}

export default function* rootSaga() {
  yield all([fork(watchFetchAllAnnotations)]);
}
