import ApiService from './ApiService';
import { createStringFromParams, createStringFromFilter } from './util';

const BASE_BACK_URL = `${ApiService.BACKEND_URL}/back/vouchers`;
const BASE_BACK_CONSTRAINT_URL = `${ApiService.BACKEND_URL}/back/vouchers/constraint-vouchers`;

class VoucherService {
  /**
   * Method for getting vocuher campaigns.
   * @param {string} authToken - access token.
   * @param {object} branchId - current branch selected.
   */
  static getVouchers(authToken, branchId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_BACK_URL}/voucher-campaigns?branchId=${branchId}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used for `exact` search for voucher by code
   * @param {string} authToken - authentication bearer token.
   * @param {string} code - voucher code.
   */
  static getVoucherByCode(authToken, code) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_BACK_URL}/by-code/${code}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for getting constraint vocuher campaigns.
   * @param {string} authToken - access token.
   * @param {object} params - query params.
   */
  static getConstraintVouchers(authToken, params) {
    const paramString = createStringFromParams(params, '');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_BACK_CONSTRAINT_URL}?${paramString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating vocuhers.
   * @param {string} authToken - access token.
   * @param {object} data - data send for creation.
   */
  static createVouchers(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_BACK_URL, data, authToken, 'application/json');
  }

  /**
   * Method for canceling vocuher.
   * @param {string} authToken - access token.
   * @param {object} voucherId - id of the voucher to be canceled
   */
  static cancelVoucher(authToken, voucherId) {
    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_BACK_CONSTRAINT_URL}/${voucherId}/cancel`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating unique voucher codes.
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - data to be sent
   */
  static createUniqueVoucherCodes(authToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_BACK_URL}/redeemable/constraint`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method to retreive redeemable codes.
   * @param {string} authToken - authentication bearer token
   * @param {number|string} branchId - id of the branch
   * @returns {object} data
   */
  static retreiveUniqueVoucherCodes(authToken, branchId, params) {
    const filtersString = createStringFromFilter(params);

    // eslint-disable-next-line no-param-reassign
    delete params.filters;

    const paramString = createStringFromParams(params);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_BACK_URL}/redeemable/summary?branchId=${branchId}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }
}
export default VoucherService;
