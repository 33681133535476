import Auth from './auth/reducer';
import App from './app/reducer';
import Issue from './issues/reducer';
import Entities from './entities/reducer';
import Table from './table/reducer';
import Bookings from './bookings/reducer';
import Customers from './customers/reducer';
import Vehicles from './vehicles/reducer';
import Tasks from './tasks/reducer';
import Authority from './authority/reducer';
import Filters from './filters/reducer';
import Verification from './verification/reducer';
import Resources from './resources/reducer';
import Settings from './settings/reducer';
import RecurringTasks from './recurringTasks/reducer';
import Quickview from './quickview/reducer';
import Goals from './goals/reducer';
import Stations from './stations/reducer';
import Plugins from './plugins/reducer';
import Branches from './branches/reducer';

export default {
  Auth,
  App,
  Branches,
  Issue,
  Entities,
  Table,
  Bookings,
  Customers,
  Vehicles,
  Tasks,
  Authority,
  Filters,
  Verification,
  Resources,
  Settings,
  RecurringTasks,
  Quickview,
  Goals,
  Stations,
  Plugins,
};
