import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Switch } from 'antd';
import get from 'lodash/get';
import { guserType } from '../../types/guser';
import appActions from '../../redux/app/actions';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { getAutoRefresh, getCurrentUserDetails } from '../../redux/app/selectors';
import { actions } from '../../redux/auth/actions';
import { availableEntities } from '../../redux-config';
import { quickviewActions } from '../../redux/quickview/actions';

const { toggleAutoRefresh: changeAutoRefresh, refreshData } = appActions;

const AvatarStyled = styled(Avatar)`
  color: var(--gray-1);
  background-color: var(--blue-6);
`;

class TopbarUser extends PureComponent {
  state = {
    visible: false,
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleAutoRefresh = checked => {
    const { toggleAutoRefresh, refresh } = this.props;
    toggleAutoRefresh();
    if (checked) {
      refresh();
    }
  };

  iconImage = () => {
    const { currentUser } = this.props;
    const userAvatar = get(currentUser, ['customProperties', 'avatar'], null);
    return userAvatar ? (
      <Avatar size="large" src={userAvatar} />
    ) : (
      <AvatarStyled size="large" icon={<UserOutlined />} />
    );
  };

  handleQuickView = () => {
    const { currentUser, openQuickView } = this.props;
    openQuickView(currentUser.id);
    this.hide();
  };

  render() {
    const { visible } = this.state;
    const { userLogout, autoRefresh } = this.props;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <div
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
          className="isoDropdownLink"
        >
          <div>
            <IntlMessages id="topbar.autoRefresh" />
          </div>
          <Switch size="small" checked={autoRefresh} onChange={this.handleAutoRefresh} />
        </div>

        <button type="button" className="isoDropdownLink" onClick={this.handleQuickView}>
          <IntlMessages id="topbar.profile" />
        </button>

        <button
          type="button"
          className="isoDropdownLink"
          onClick={userLogout}
          style={{ color: 'red' }}
        >
          <IntlMessages id="topbar.logout" />
        </button>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={this.handleVisibleChange}
        arrowPointAtCenter
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">{this.iconImage()}</div>
      </Popover>
    );
  }
}

TopbarUser.propTypes = {
  toggleAutoRefresh: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
  currentUser: guserType,
  openQuickView: PropTypes.func.isRequired,
  autoRefresh: PropTypes.bool,
  refresh: PropTypes.func,
};

TopbarUser.defaultProps = {
  currentUser: {},
  autoRefresh: false,
  refresh: () => null,
};

export default connect(
  state => ({
    autoRefresh: getAutoRefresh(state),
    currentUser: getCurrentUserDetails(state),
  }),
  dispatch => ({
    userLogout: () => {
      // Trigger an action to logout user
      dispatch(actions.logoutUser());
    },
    toggleAutoRefresh: () => dispatch(changeAutoRefresh()),
    refresh: () => dispatch(refreshData()),
    openQuickView: id => dispatch(quickviewActions.selectItem(availableEntities.CUSTOMERS, id)),
  })
)(TopbarUser);
