import { useSelector } from 'react-redux';
import { getBranchId } from '../redux/app/selectors';

const useGoDashIntegration = () => {
  const goDashBaseUrl = `${window.location.origin}/v2/`;
  const activeBranchID = useSelector(getBranchId);
  const urlSearchParams = new URLSearchParams();

  if (activeBranchID) {
    urlSearchParams.set('activeBranchID', activeBranchID);
  }

  /**
   * @param path: string - sub-path of URL
   * @param urlParams: object with shape { [key]: 'value' } defining url search params to append to link
   ** */
  const buildGoDashUrl = (path = '', urlParams = []) => {
    Object.entries(urlParams).forEach(([key, value]) => {
      urlSearchParams.set(key, value);
    });
    return `${goDashBaseUrl}${path}?${urlSearchParams.toString()}`;
  };

  const redirectToGoDash = (path, { urlParams = [], newWindow = true } = {}) => {
    const redirectUrl = buildGoDashUrl(path, urlParams);
    if (newWindow) {
      window.open(redirectUrl, '_blank');
    } else {
      window.location.href = redirectUrl;
    }
  };

  return { redirectToGoDash };
};

export default useGoDashIntegration;
