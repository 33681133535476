import * as config from './containers/config';

// TODO: add this part into documentation!!!
// here we add all new entities
export const availableEntities = {
  DETAILS: 'details',
  POI: 'poi',
  POI_CAT: 'poi-cat',
  CUSTOMERS: 'customers',
  VEHICLES: 'vehicles',
  RENTALS: 'rentals',
  TASKS: 'tasks',
  INVOICES: 'invoices',
  BRANCHES: 'branches',
  ISSUES: 'issues',
  BOOKINGS: 'bookings',
  BOOKING_CALENDAR: 'booking-calendar',
  ANNOTATIONS: 'annotations',
  HEATDATAS: 'heatdatas',
  NOTES: 'notes',
  BALANCES: 'balances',
  DAMAGES: 'damages',
  STATIONS: 'stations',
  CUSTOMERS_OVERVIEW: 'customers_overview',
  GOALS: 'goals',
  NOTIFICATION: 'notification',
  PACKAGES: 'packages',
  ROLES: 'roles',
  VEHICLE_CATEGORIES: 'settings/vehicle-categories',
  RECURRING_TASKS: 'recurring_tasks',
  LOGS: 'logs',
  FUNNEL: 'funnel',
  REVENUES: 'revenues',
  SUBSCRIPTIONS: 'subscriptions',
  ADDITIONS: 'additions',
  EXPORT_SERVICES: 'exports',
  IOT_MODULES: 'iot_modules',
  VOUCHERS: 'vouchers',
  TUTORIALS: 'tutorialInfo',
  SERVICE_AGENTS: 'service-agents',
  // changed for the consistency with the `AppRouter` urls and for the quickview opening
  BUSINESS_ACCOUNTS: 'business-accounts',
  VOUCHERABLES: 'voucherables',
  TRIGGERED_VOUCHERABLE: 'triggered_voucherable',
  CASHBACK_VOUCHERABLE: 'cashback_voucherable',
  CUSTOMER_CARE_VOUCHERABLE: 'customer_care_voucherable',
  VEHICLE_PROMOTION_VOUCHERABLE: 'vehicle_promotion_voucherable',
  GEOFENCE_PROMOTION_VOUCHERABLE: 'geofence_promotion_voucherable',
  GEOFENCE_SURCHARGE_PROMOTION_VOUCHERABLE: 'geofence_surcharge_promotion_voucherable',
  SIGNUP_REFERRAL_VOUCHERABLE: 'signup_referral_voucherable',
  PROMOTION_VOUCHERABLE: 'promotion_voucherable',
  PACKAGE_VOUCHERABLE: 'package_voucherable',
  PASSES_VOUCHERABLE: 'passes_voucherable',
  RIDE_PASSES_VOUCHERABLE: 'ride_passes_voucherable',
  PRODUCTIVITY_INSIGHTS: 'productivity-insights',

  VERIFICATION_ATTEMPTS: 'verification_attempts',

  ANALYTICS_CUSTOMERS: 'analytics_customers',
  ANALYTICS_RENTALS: 'analytics_rentals',
  ANALYTICS_RENTALS_OVERVIEW: 'analytics_rentals_overview',
  ANALYTICS_CUSTOMERS_REGISTRATIONS: 'customers_registrations',
  ANALYTICS_BALANCES: 'analytics_balances',
  ANALYTICS_BALANCES_OVERVIEW: 'analytics_balances_overview',
  ANALYTICS_FIRST_RENTALS: 'analytics_first_rentals',
  ANALYTICS_VEHICLES_BY_SERVICE_STATE: 'analytics_vehicles_by_service_state',
  ANALYTICS_VEHICLES_AVAILABILITY: 'analytics_vehicles_availability',
  ANALYTICS_VEHICLES_UNAVAILABILITY_REASONS: 'analytics_vehicles_unavailability_reasons',
  ANALYTICS_VEHICLES_AVAILABILITY_OF_BATTERY_LEVEL:
    'analytics_vehicles_availability_of_battery_level',
};

const defaultBaseAjaxParams = (sortColumn, sortDirection, size = config.pageSize) => ({
  sort: `${sortColumn},${sortDirection}`,
  page: 0,
  size,
  // search: {}
});

export const defaultAjaxParams = {
  [availableEntities.CUSTOMERS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.VEHICLES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.RENTALS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.TASKS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.INVOICES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.ISSUES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.BOOKINGS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  // Since Notes are not used as classic table
  // the current solution to get all notes is to have great size of items
  [availableEntities.NOTES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER, 500),
  [availableEntities.STATIONS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.ROLES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.ADDITIONS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.IOT_MODULES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER, 500),
  [availableEntities.VOUCHERS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.TUTORIALS]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.VOUCHERABLES]: defaultBaseAjaxParams(config.sort.key, config.sort.ORDER),
  [availableEntities.SERVICE_AGENTS]: defaultBaseAjaxParams('latestActivityAt', config.sort.ORDER),
};
