/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import TopbarHelp from './topbarHelp';
import TopbarGoals from './topbarGoals';
import TopbarBranchSelect from './TopbarBranchSelect';
import themes from '../../config/themes';
import { themeConfig, sizes } from '../../config';
import { actions } from '../../redux/entities/actions';
import { quickviewActions } from '../../redux/quickview/actions';
import tableFilterActions from '../../redux/filters/actions';
import { actions as goalsActions } from '../../redux/goals/actions';
import { getBranch, getAutoRefresh, getApp } from '../../redux/app/selectors';
import { getWholeGoalsState } from '../../redux/goals/selectors';
import { getPermissions } from '../../redux/authority/selectors';
import { branchType } from '../../types/branch';
import settingType from '../../types/settings';
import permissions from '../../constants/permissions';
import { GOALS_PLUGIN_NAME } from '../../constants/settings-plugins';
import { getPluginState } from '../../redux/plugins/selectors';

const { Header } = Layout;
const { toggleCollapsed, selectAndSaveBranch, refreshData, toggleAll } = appActions;
const customizedTheme = themes[themeConfig.theme];
const { ANALYTICS_READ } = permissions;

class Topbar extends Component {
  componentDidMount() {
    const { autoRefresh, refresh } = this.props;

    if (autoRefresh) {
      refresh();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { goals, goalsAnalytics, pluginActivated, mainGoal } = this.props;
    return (
      !isEqual(nextProps.goals, goals) ||
      !isEqual(nextProps.goalsAnalytics, goalsAnalytics) ||
      !isEqual(nextProps.pluginActivated, pluginActivated) ||
      !isEqual(nextProps.mainGoal, mainGoal)
    );
  }

  /**
   * Branch select metod with checking route and fetching all vehicles data.
   * @param {number} branchId id of new selected branch.
   */
  onBranchSelect = branchId => {
    const {
      selectBranch,
      refresh,
      resetFilters,
      location: { pathname },
      removeAllItemsFromQuickview,
    } = this.props;
    // TODO: on change branch , check current view/path and refresh data if needed
    const isRootRoute = pathname.endsWith('/dashboard');
    selectBranch(branchId, isRootRoute);
    if (isRootRoute) refresh();
    resetFilters();
    removeAllItemsFromQuickview();
  };

  isPluginActivated = pluginName => {
    const { pluginActivated } = this.props;
    return get(pluginActivated, ['value', pluginName], false);
  };

  render() {
    const { app, toogle, goals, mainGoal, goalsAnalytics, fetchGoalsAnalytics, analyticsRead } =
      this.props;
    const collapsed =
      app.view !== 'DesktopView' ? cloneDeep(app.collapsed) && cloneDeep(app.openDrawer) : true;
    const styling = {
      background: customizedTheme.backgroundColor,
      width: '100%',
      height: sizes.topbar,
    };

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}
        >
          <div className="isoLeft">
            <TopbarBranchSelect onBranchSelect={this.onBranchSelect} />
            {
              // max mobile display width
              !collapsed ? (
                <button
                  type="button"
                  className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
                  style={{ color: customizedTheme.textColor }}
                  onClick={() => toogle()}
                />
              ) : null
            }
          </div>

          <ul className="isoRight">
            {/* Show goals component only if user has read analytics permission and active plugins exist */}
            {collapsed && analyticsRead && this.isPluginActivated(GOALS_PLUGIN_NAME) ? (
              <li className="isoGoals">
                <TopbarGoals
                  goals={goals}
                  mainGoal={mainGoal}
                  goalsAnalytics={goalsAnalytics}
                  fetchGoalsAnalytics={fetchGoalsAnalytics}
                />
              </li>
            ) : null}
            {collapsed && (
              <li className="isoHelp">
                <TopbarHelp />
              </li>
            )}
            <li className="isoUser">
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

Topbar.propTypes = {
  selectBranch: PropTypes.func,
  refresh: PropTypes.func,
  autoRefresh: PropTypes.bool,
  app: PropTypes.shape({
    autoRefresh: PropTypes.bool,
    branch: branchType,
    collapsed: PropTypes.bool,
    current: PropTypes.arrayOf(PropTypes.string),
    openKeys: PropTypes.arrayOf(PropTypes.string),
    loadingData: PropTypes.number,
    openDrawer: PropTypes.bool,
    view: PropTypes.string,
  }),
  toogle: PropTypes.func,
  resetFilters: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  goals: PropTypes.objectOf(PropTypes.object).isRequired,
  mainGoal: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  goalsAnalytics: PropTypes.object.isRequired,
  fetchGoalsAnalytics: PropTypes.func.isRequired,
  removeAllItemsFromQuickview: PropTypes.func.isRequired,
  analyticsRead: PropTypes.bool,
  pluginActivated: PropTypes.shape(settingType),
};

Topbar.defaultProps = {
  selectBranch: () => {},
  refresh: () => {},
  autoRefresh: false,
  toogle: () => {},
  app: {},
  location: {
    pathname: undefined,
  },
  analyticsRead: false,
  pluginActivated: null,
};

export default connect(
  state => {
    const GoalsState = getWholeGoalsState(state);

    const pluginActivated = getPluginState(state).activePlugins;

    return {
      app: getApp(state),
      branch: getBranch(state),
      autoRefresh: getAutoRefresh(state),
      goals: GoalsState?.goals?.value?.months,
      mainGoal: GoalsState?.mainGoal?.value?.goal,
      goalsAnalytics: GoalsState?.analytics,
      analyticsRead: getPermissions(state)?.[ANALYTICS_READ],
      pluginActivated,
    };
  },
  {
    toogle: toggleCollapsed,
    selectBranch: selectAndSaveBranch,
    refresh: refreshData,
    toggleAll,
    fetchEntity: actions.fetchEntity,
    resetFilters: tableFilterActions.resetFilters,
    fetchGoalsAnalytics: payload => goalsActions.fetchGoalsAnalytics(payload),
    removeAllItemsFromQuickview: quickviewActions.removeAllItems,
  }
)(withRouter(Topbar));
