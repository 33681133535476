import basicStyle from './config/basicStyle';

export const tenantName = 'TENANT_NAME';
export const subdomain = window.location.hostname.split('.')[0];

export const tenantInfo = {
  uuid: '',
};

// IMPORTANT TO ADD HERE SENTRY AND ALSO DASHBOARD TAB NAME when new client is added

/** `dsn` used for sentry */
export const dsn = {
  elmovo: 'https://965448d726d64651a46813f7b83ab622@o369452.ingest.sentry.io/5390571',
  fred: 'https://9e90cc6f479945de83e381400d0c7098@o369452.ingest.sentry.io/5390588',
  greenmo: 'https://0b838ad0133c4543af23ff0fb9ff8d21@o369452.ingest.sentry.io/5390595',
  'niu-rental': 'https://f155824e08274328985051f0e43fbd2d@o369452.ingest.sentry.io/5390596',
  tribe: 'https://b5b1ba34b60c4fb289cd4e913c884524@o369452.ingest.sentry.io/5390606',
  whizascoot: 'https://e934ecf7fb95412ea02ace136a4def1f@o369452.ingest.sentry.io/5390611',
  ceslia: 'https://5b0859db68fe4ce4a32ee77ecce46785@o369452.ingest.sentry.io/5390886',
  akota: 'https://b216c2afcb774bf0bfb8b3deb5b72613@o369452.ingest.sentry.io/5390889',
  foama: 'https://9a543af1479c4a93b95a8b7dc577939c@o369452.ingest.sentry.io/5390892',
  mobilityv: 'https://a11dabc39a444083a6980e633984f007@o369452.ingest.sentry.io/5390903',
  egaudi: 'https://5fc5f185d96447c99d4cff6cf0b23a46@o369452.ingest.sentry.io/5390904',
  trip: 'https://349e48362eaa45c1825c156e90cd4573@o369452.ingest.sentry.io/5390906',
  trotti: 'https://dd2b49babae24cc89b17f791f308cc4f@o369452.ingest.sentry.io/5390909',
  demo: 'https://44e5213e7a2745e08f29b9c18d794511@o369452.ingest.sentry.io/5392770',
};

export const dsnFallback =
  'https://c9b140647bf20ce5987b6a304c196469@o369452.ingest.sentry.io/4506780258336768';

const mapConfig = {
  accessToken: process.env.REACT_APP_MAPBOX_ACCESSTOKEN,
  showRentalButton: true,
  style: 'mapbox://styles/alesas/ck2bopfga02511cpmdxe9ax3d',
  styleSatellite: 'mapbox://styles/mapbox/satellite-v9',
};

const vehicleServiceState = {
  OPERATIONAL: 'OPERATIONAL',
  LOW_SOC: 'LOW_SOC',
  CRITICAL_SOC: 'CRITICAL_SOC',
  OUT_OF_ORDER: 'OUT_OF_ORDER',
  RETIRED: 'RETIRED',
};

const vehicleBatteryLevel = {
  UNKNOWN: 'UNKNOWN',
  SUFFICIENT: 'SUFFICIENT',
  LOW: 'LOW',
  CRITICAL: 'CRITICAL',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

const vehicleRentableState = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const gMaps = {
  url: 'http://maps.google.com/',
  route: {
    single: '?q=',
    multi: 'maps/dir',
    waypoint: {
      api: 1,
      travelmode: 'bicycling',
    },
  },
};

const sizes = {
  topbar: 64,

  appHeightModifier: {
    heatmap: 120,
    table: 150,
    map: 71,
  },
};

const heatmap = {
  map: {
    circleZoomTreshold: 16,
    defaultDateRange: 30,
    // Configuration object for heatmap layer
    mapPaint: {
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        basicStyle.heatmapColours['0'],
        0.1,
        basicStyle.heatmapColours['0.1'],
        0.3,
        basicStyle.heatmapColours['0.3'],
        0.5,
        basicStyle.heatmapColours['0.5'],
        0.7,
        basicStyle.heatmapColours['0.7'],
        1,
        basicStyle.heatmapColours['1'],
      ],
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
      'heatmap-intensity': 0.7,
      'heatmap-weight': {
        type: 'identity',
        property: 'point_count',
      },
    },

    // Configuration object for heatmap circle layer (when zoomed in)
    circlePaint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7,
        ['interpolate', ['linear'], ['get', 'point_count'], 1, 1, 6, 4],
        16,
        ['interpolate', ['linear'], ['get', 'point_count'], 1, 5, 6, 50],
      ],
      'circle-color': '#1890ff',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 1,
    },
  },
};

export {
  mapConfig,
  vehicleServiceState,
  gMaps,
  heatmap,
  sizes,
  themeConfig,
  vehicleBatteryLevel,
  vehicleRentableState,
};
