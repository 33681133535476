import get from 'lodash/get';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams, resolveTagsAndLabelsParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/customers`;
const APPJSON_CONTENT_TYPE = 'application/json';

class CustomersService {
  static createCustomer(reqBody, AuthToken) {
    return ApiService.ApiCallBody('POST', BASE_URL, reqBody, AuthToken, APPJSON_CONTENT_TYPE);
  }

  static addRFIDCard(reqBody, AuthToken, RFID) {
    return ApiService.ApiCallBody(
      'POST',
      `${ApiService.BACKEND_URL}/back/customer-identity-cards/${RFID}/assign`,
      reqBody,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static removeRFIDCard(reqBody, AuthToken, RFID) {
    return ApiService.ApiCallBody(
      'POST',
      `${ApiService.BACKEND_URL}/back/customer-identity-cards/${RFID}/unassign`,
      reqBody,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static updateCustomer(AuthToken, customer, key, value) {
    const data = {
      [key]: value,
    };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${customer.guser.id}`,
      data,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static updateCustomerById(AuthToken, customerId, key, value) {
    const data = {
      [key]: value,
    };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${customerId}`,
      data,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static updateCustomerMultipleFields(AuthToken, customer, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${customer.guser.id}`,
      data,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static getGroupsOfCustomer(AuthToken, guserId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${guserId}/groups`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static getRFIDCards(branchID, AuthToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${ApiService.BACKEND_URL}/back/customer-identity-cards?branchId=${branchID}&state=NEW&state=UNASSIGNED&size=5000&sort=cardNumber,ASC`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static searchRFIDCards(branchID, AuthToken, search) {
    return ApiService.ApiCallAuth(
      'GET',
      `${ApiService.BACKEND_URL}/back/customer-identity-cards?branchId=${branchID}&searchText=${search}&state=NEW&state=UNASSIGNED`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Retrieves all the customers for a selected branch, to be consumed by the CSV export
   * component inside of the `Customers` table.
   *
   * @param {string} AuthToken - the authentication token necessary to make requests.
   * @param {number} branch - the branch id for which to make a request.
   */
  static getAllCustomers(AuthToken, branch, filters) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${filters}`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static getCustomers(AuthToken, branch, params) {
    // `+` should be encoded
    const filtersString = createStringFromFilter(resolveTagsAndLabelsParams(params)).replace(
      '+',
      '%2B'
    );
    // TODO the backend should separate the search for labels and tags. Labels and lags are annotations. We are currently merging labels and tags(resolveTagsAndLabelsParams method above) and searching as annotations

    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${paramString}${filtersString}`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static getCustomerDetail(AuthToken, branch, params) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${params.id}/details${branch ? `?branchId=${branch}` : ''}`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Fetch customer credit rating information.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static getCustomerCreditRating(AuthToken, customerId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${customerId}/ratings`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Approve customer credit rating.
   * @param {string} AuthToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static approveCustomerCreditRating(AuthToken, customerId) {
    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_URL}/${customerId}/ratings/approve`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Reject customer credit rating.
   * @param {string} AuthToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static rejectCustomerCreditRating(AuthToken, customerId) {
    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_URL}/${customerId}/ratings/reject`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Trigger customer credit rating.
   * @param {string} AuthToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static triggerCustomerCreditRating(AuthToken, customerId) {
    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_URL}/${customerId}/ratings/queue`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Reset password for customer.
   * @param {string} AuthToken Access user token.
   * @param {number} id id of customer for reset password.
   */
  static resetPassword(AuthToken, id) {
    return ApiService.ApiCallAuth(
      'POST',
      `${BASE_URL}/${id}/password-reset-email`,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Reset password for customer befor login.
   * @param {string} enteredEmail email on which we sent reset password email.
   */
  static resetForgotPassword(enteredEmail) {
    return ApiService.ApiCallNoAuth(
      'POST',
      `${BASE_URL}/reset/${enteredEmail}`,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Method for creating voucher for user
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   * @param {object} data - data for create.
   */
  static createVoucherForUser(authToken, customerId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${customerId}/voucher`,
      data,
      authToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static createVoucher(AuthToken, branchId, params) {
    const data = {
      branchId,
      description: params.voucher.description,
      value: params.voucher.value,
    };

    /** `indicator` is signup true */
    const signup = get(params, 'signup', false) ? '?signup=true' : '';
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${params.customerId}/voucher${signup}`,
      data,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static removeCustomer(AuthToken, id, reason, force) {
    const data = {
      reason,
      force,
    };
    return ApiService.ApiCallBody(
      'DELETE',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      APPJSON_CONTENT_TYPE
    );
  }

  static updateCustomerTags(AuthToken, customerId, tags, branchId) {
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}/${customerId}/tags?branchId=${branchId}`,
      tags,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetch current logged in user.
   * @param {string} AuthToken Access user token.
   */
  static getCurrentUserDetails(AuthToken) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/current`, AuthToken, APPJSON_CONTENT_TYPE);
  }

  /**
   * Method for fetching customer id.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of customer whih detail are gonna be fetched.
   */
  static fetchCustomer(authToken, customerId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${customerId}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for searching for customers via search string
   * @param authToken
   * @param query
   */
  static searchCustomers(authToken, query) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?simple&excludeImpersonatedUsers=true&searchText=${query}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for updating customer with data.
   * @param {string} authToken - access token.
   * @param {number} customerId - id of customer to be updated.
   * @param {object} data - data to customer be updated with.
   */
  static updateCustomerWithData(authToken, customerId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${customerId}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for disconnect all users with certain role.
   * @param {string} authToken - Authentication bearer token.
   * @param {string} branchId - id of current selected branch.
   * @param {string} roleId - id of role by which users will be disconnected.
   */
  static logoutUsersByRole(authToken, branchId, roleId) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/logout?roleId=${roleId}&branchId=${branchId}`,
      {},
      authToken,
      'application/json'
    );
  }

  /**
   * Method to fetch customer details.
   * @param {string} authToken - authentication bearer token.
   * @param {string|number} id - customer id.
   */
  static getCustomerPaymentSources(authToken, id) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${id}/payment-sources`,
      authToken,
      APPJSON_CONTENT_TYPE
    );
  }

  /**
   * Add email sign in method for the user.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   * @param {object} data -data to be send.
   */
  static addEmailSignInMethod(authToken, customerId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${customerId}/add-email-sign-in-method`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Add phone sign in method for the user.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   * @param {object} data -data to be send.
   */
  static addPhoneSignInMethod(authToken, customerId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${customerId}/add-phone-sign-in-method`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching customer by auth_id.
   * @param {string} authToken - authentication bearer token.
   * @param {number} params - auth_id of customer and current branch.
   */
  static fetchCustomerByAuthId(authToken, params) {
    // TODO Backend should be able to featch the user by auth_id without sending a branch id
    const paramString = createStringFromParams(params, '?');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}${paramString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Add gaiyo sign in method for the user.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   * @param {object} data -data to be send.
   */
  static removeSignInMethod(authToken, customerId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${customerId}/remove-sign-in-method`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Remove name mismatch label.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static removeNameMismatchLabel(authToken, customerId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${customerId}/name-mismatch-label`,
      authToken,
      'application/json'
    );
  }

  /**
   * Reset MFA method for the user.
   * @param {string} authToken - authentication bearer token.
   * @param {number} customerId - id of the customer.
   */
  static reset2fa(authToken, customerId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${customerId}/mfa`,
      authToken,
      'application/json'
    );
  }
}
export default CustomersService;
