import ApiService from './ApiService';
import { createStringFromParams, createStringFromFilter } from './util';

import permissions from '../../constants/permissions';

const BASE_URL = `${ApiService.BACKEND_URL}/back/roles`;

class AuthorityService {
  static addRole(AuthToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/assignments`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for creating system role
   * @param {string} authToken - authentication bearer token
   * @param {object} data - data for create
   */
  static createRole(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json');
  }

  /**
   * Method for deleting role.
   * @param {string} authToken - authentication bearer token.
   * @param {number} roleId - id of the role we want to delete.
   */
  static deleteRole(authToken, roleId) {
    return ApiService.ApiCallAuth('DELETE', `${BASE_URL}/${roleId}`, authToken, 'application/json');
  }

  /**
   * Method for updating role.
   * @param {string} authToken - authentication bearer token
   * @param {number} roleId - id of the role for update
   * @param {object} data - data for update.
   */
  static updateRole(authToken, roleId, data) {
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}/${roleId}`,
      data,
      authToken,
      'application/json'
    );
  }

  static getRoles(AuthToken) {
    return ApiService.ApiCallAuth('GET', BASE_URL, AuthToken, 'application/json');
  }

  /**
   * Method to retireve permissions of the system.
   * @param {string} authToken - authentication bearer token
   */
  static getPermissions(authToken) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/permissions`, authToken, 'application/json');
  }

  static getRolesAssignment(AuthToken, params, signal) {
    const filterString = createStringFromFilter(params.filters);
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramsString = `?${createStringFromParams(params).substring(1)}`;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/assignments${paramsString}${filterString}`,
      AuthToken,
      'application/json',
      signal
    );
  }

  static removeRole(AuthToken, roleId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/assignments/${roleId}`,
      AuthToken,
      'application/json'
    );
  }

  static getUserPersmissions(AuthToken, branchId) {
    const paramsString = `permission=${Object.values(permissions).join(',')}`;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/assignments/exists?branchId=${branchId}&${paramsString}`,
      AuthToken,
      'application/json'
    );
  }

  static getUserRolesAssignment(AuthToken, branchId, params) {
    const paramsString = `?${createStringFromParams({ ...params, branchId })}`;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/assignments-detailed${paramsString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Retreive all roles available in the system.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} options - Request cancellation signal, as well as query parameters.
   */
  static getRolesAbortable(authToken, { signal }) {
    const options = {
      method: 'GET',
      signal,
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
    };
    return ApiService.ApiCallAbortable(`${BASE_URL}`, options);
  }

  /**
   * Method for fetching matrix of roles.
   * @param {string} authToken - Authentication bearer token.
   * @param {string} queryString - query for fetching roles.
   */
  static getRolesAssignmentMatrix(authToken, queryString) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/assignments-detailed${queryString}`,
      authToken,
      'application/json'
    );
  }
}

export default AuthorityService;
