import { all, fork, call, put, takeLatest, select, delay, takeLeading } from 'redux-saga/effects';
import range from 'lodash/range';
import { message } from 'antd';
import { actionStrings, actions as stationActions } from './actions';
import { actions } from '../entities/actions';
import { getIsStationsLoading } from './selectors';
import StationsService from '../../services/coreAPI/StationsService';
import { submitData } from '../common/commonFlowSagas';
import { getAuthToken } from '../common/commonSagas';
import responseOk from '../../utils/responseOk';
import { availableEntities } from '../../redux-config';

const { addItem } = actions;

function* createStationSuccess(payload) {
  yield put(stationActions.addStation(payload));
  // Action for adding item (station) in table of stations when creating new station
  yield put(addItem(availableEntities.STATIONS, payload));
  message.success('Station created successfully');
}

function createStationError(error) {
  message.error(error);
}

function* createStation(action) {
  yield call(
    submitData,
    StationsService.createStation,
    createStationSuccess,
    createStationError,
    action.payload
  );
}

function* fetchStations(action) {
  try {
    yield delay(500);
    const isStationsLoading = yield select(getIsStationsLoading);
    const idToken = yield call(getAuthToken);
    const { branchId, type } = action.payload;
    if (!isStationsLoading && branchId) {
      yield put(stationActions.updateLoading(true));
      const response = yield call(
        StationsService.getAllStations,
        idToken,
        branchId,
        {
          page: 0,
          size: 500,
        },
        type
      );

      const pages = response.headers.get('X-Pagination-TotalPages');
      const responses = [response];

      const restPages = pages > 1 ? range(1, pages) : [];

      /* we send all requests for all pages at one time, if the size is more than 500 stations */
      const restResponses = yield all(
        restPages.map(page =>
          call(
            StationsService.getAllStations,
            idToken,
            branchId,
            {
              page,
              size: 500,
            },
            type
          )
        )
      );

      responses.push(...restResponses);
      if (!responses.some(res => !responseOk(res))) {
        const addData = yield all(responses.map(res => call([res, 'json'])));
        const result = [];
        addData.forEach(d => result.push(...d));
        yield put(stationActions.fetchStationsSuccess(result));
        return;
      }
      yield put({ type: actionStrings.FETCH_STATIONS_FAIL });
    }
  } catch (error) {
    yield put({ type: actionStrings.FETCH_STATIONS_FAIL });
  }
}

function* watchCreateStation() {
  yield takeLatest(actionStrings.CREATE_STATION, createStation);
}

function* watchFetchStation() {
  yield takeLeading(actionStrings.FETCH_STATIONS, fetchStations);
}

export default function* rootSaga() {
  yield all([fork(watchCreateStation), fork(watchFetchStation)]);
}
