import antdDe from 'antd/lib/locale-provider/de_DE';

import deMessages from '../locales/de.json';

const DeLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDe,
  locale: 'de',
};

export default DeLang;
