import styled from 'styled-components';
import WithDirection from '../../config/withDirection';
import sidebarImage from '../../assets/img/sidebar_bg.png';
import { styledScrollbar } from '../../helpers/utility';

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    ${styledScrollbar({
      width: '4px',
      background: 'var(--gray-5)',
      hoverBackground: 'var(--gray-6)',
    })}
    overflow-y: auto;
    height: 100vh;

    background-image: url(${sidebarImage});
    display: flex;
    justify-content: center;
    position: relative;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item-selected {
        background-color: white !important;
        span {
          color: rgba(0, 66, 198, 0.8) !important;
        }
      }
    }

    .isoLogoWrapper {
      width: min-content;
      margin: 25px auto 0 auto;
    }

    .isoDashboardMenu {
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto 0;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        display: flex;
        align-items: center;
        padding: 0px 4px 0 11px !important;
        margin: 8px 0px;
        border-radius: 4px;
        position: relative;
        width: 40px;

        .anticon {
          color: #fff;
          font-size: 18px;

          position: absolute;
          left: 11px;
          top: 2px;
        }
      }

      .isoMenuHolder {
        color: white;
      }

      .ant-menu-item-selected {
        background-color: #fff;
        .anticon {
          color: rgba(0, 66, 198, 0.8);
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
