import { createSelector } from 'reselect';

const getPermissionsState = state => state.Authority.permissions;
const getUserRolesState = state => state.Authority.userRoles;
const getRolesState = state => state.Authority.roles;
const getRoleAssignmentsUserState = state => state.Authority.roleAssignmentsUser;
const getStationReadPermissionState = state => state.Authority.permissions.STATION_READ;

/**
 * Selector to get user permissions.
 * @param {object} state - Redux state.
 * @returns {object} object with keys of permissions.
 */
export const getPermissions = createSelector(getPermissionsState, permissions => permissions);

/**
 * Selector to get user roles
 * @param {object} state - Redux state
 * @returns {Array} array of user roles.
 */
export const getUserRoles = createSelector(getUserRolesState, roles => roles);

/**
 * Selector to get roles
 * @param {object} state - Redux state
 * @returns {Array} array of roles.
 */
export const getRoles = createSelector(getRolesState, roles => roles);

/**
 * Selector to get roles assignemnts user
 * @param {object} state - Redux state
 * @returns {Array} array of roles.
 */
export const getRoleAssignmentsUser = createSelector(getRoleAssignmentsUserState, rau => rau);

/**
 * Selector to get station read permission
 * @param {object} state - Redux state
 * @returns {Boolean} true/false
 */
export const getStationReadPermission = createSelector(
  getStationReadPermissionState,
  stationReadPermission => stationReadPermission
);
