import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';
import { defaultAjaxParams, availableEntities as _ } from '../../redux-config';

export const confKey = ({ search = {}, ...params }) =>
  Object.entries({ ...params, ...search })
    .sort((a, b) => a[0] > b[0])
    .map(([key, value]) => `${key}_${value}`)
    .join('|');

export const getDefaultSort = () => ({
  columnKey: null,
  order: null,
});

export const getDefaultSearch = () => ({
  columnKey: null,
  searchText: null,
});

const getDefaultTable = entity => ({
  current: defaultAjaxParams[entity],
  total: 0,
  sort: getDefaultSort(),
  search: getDefaultSearch(),
});

const initState = {
  [_.RENTALS]: getDefaultTable(_.RENTALS),
  [_.TASKS]: getDefaultTable(_.TASKS),
  [_.CUSTOMERS]: getDefaultTable(_.CUSTOMERS),
  [_.VEHICLES]: getDefaultTable(_.VEHICLES),
  [_.INVOICES]: getDefaultTable(_.INVOICES),
  [_.ISSUES]: getDefaultTable(_.ISSUES),
  [_.BOOKINGS]: getDefaultTable(_.BOOKINGS),
  [_.DAMAGES]: getDefaultTable(_.DAMAGES),
  [_.STATIONS]: getDefaultTable(_.STATIONS),
  [_.ROLES]: getDefaultTable(_.ROLES),
  [_.ADDITIONS]: getDefaultTable(_.ADDITIONS),
  [_.IOT_MODULES]: getDefaultTable(_.IOT_MODULES),
  [_.VOUCHERS]: getDefaultTable(_.VOUCHERS),
  [_.SERVICE_AGENTS]: getDefaultTable(_.SERVICE_AGENTS),
};

const tableReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.CLEAR_TABLE:
      return {
        ...state,
        [action.entity]: getDefaultTable(action.entity),
      };

    case actionStrings.SET_TABLE:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.key]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default filterActions(tableReducer, [actionStrings.CLEAR_TABLE, actionStrings.SET_TABLE]);
