import styled from 'styled-components';

const ThreeDots = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: var(--gray-6);
  color: var(--gray-6);
  animation: dotFlashing 0.5s infinite linear alternate;
  animation-delay: 0.25s;

  margin-right: ${props => props?.$marginRight ?? '0'}px;
  margin-left: ${props => props?.$marginLeft ?? '0'}px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: var(--gray-6);
    color: var(--gray-6);
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: var(--gray-6);
    color: var(--gray-6);
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0.5s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--gray-6);
    }
    50%,
    100% {
      background-color: var(--gray-4);
    }
  }
`;

export default ThreeDots;
