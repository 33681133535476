import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  verificationAttemptsList: [],
  verificationMedia: [],
};

const verificationReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_LIST_VERIF_ATTEMPTS_SUCCESS:
      return {
        ...state,
        verificationAttemptsList: action.payload,
      };
    case actionStrings.FETCH_VERIFICATION_ATTEMPT_MEDIA_SUCCESS:
      return {
        ...state,
        verificationMedia: action.payload,
      };
    case actionStrings.CLEAR_VERIFICATION_ATTEMPT_MEDIA:
      return {
        ...state,
        verificationMedia: [],
      };
    case actionStrings.CLEAR_LIST_VERIF_ATTEMPTS:
      return {
        ...state,
        verificationAttemptsList: [],
      };
    default:
      return state;
  }
};

export default filterActions(verificationReducer, [
  actionStrings.FETCH_LIST_VERIF_ATTEMPTS_SUCCESS,
  actionStrings.FETCH_VERIFICATION_ATTEMPT_MEDIA_SUCCESS,
  actionStrings.CLEAR_VERIFICATION_ATTEMPT_MEDIA,
  actionStrings.CLEAR_LIST_VERIF_ATTEMPTS,
]);
