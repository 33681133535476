export default {
  roles: ['ROAD_SERVICE', 'ADMIN', 'CUSTOMER_SERVICE', 'BILLING'],
};

export const ROLES = {
  ROOT: 'Root',
  ADMIN: 'Admin',
  FLEET_MANAGER: 'Fleet manager',
  SERVICE_AGENT: 'Service agent',
  CUSTOMER_SUPPORT: 'Customer support',
  ACCOUNTING: 'Accounting',
  MARKETING: 'Marketing',
  DEALERSHIP: 'Dealership',
};
