/* eslint-disable no-unused-vars */
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/service-workers`;
const CUSTOMER_BASE_URL = `${ApiService.BACKEND_URL}/back/customers`;

class ServiceAgentsService {
  static getAgents(authToken, branchId, params) {
    const filtersString = createStringFromFilter(params.filters);

    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching agent
   * @param {string} authToken - authentication bearer token.
   * @param {number} agentId - id of agent
   */
  static getAgent(authToken, agentId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${CUSTOMER_BASE_URL}/${agentId}`,
      authToken,
      'application/json'
    );
  }
}

export default ServiceAgentsService;
