/* eslint-disable no-param-reassign */
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/rentals`;

export const filters = {
  ALL: 'ALL',
  RESERVATION_OR_ACTIVE: 'RESERVATION_OR_ACTIVE',
  EXPIRED_OR_ENDED_OR_CANCELLED: 'EXPIRED_OR_ENDED_OR_CANCELLED',
  RESERVATION: 'RESERVATION',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  ENDED_NO_MOVEMENT: 'ENDED_NO_MOVEMENT',
  ENDED: 'ENDED',
};

/**
 * Helper method to remove active deposit from filter array in case of YES NO selected
 * @param {Array} filtersArr - array of the filters
 * @returns {Array} filtered array.
 */
const checkActiveDepositFilter = (filtersArr = []) =>
  filtersArr.filter(filter => {
    const { activeDeposit = null } = filter;
    return !(activeDeposit && activeDeposit.length === 2);
  });

class RentalService {
  static getRentals(AuthToken, branchId, params) {
    const resolvedFilters = checkActiveDepositFilter(params.filters);

    // TODO Backend needs to accept array of enums insted of accepting strings one by one
    const filtersString = createStringFromFilter(resolvedFilters);
    // avoid filters in params string
    delete params.filters;
    const paramString = createStringFromParams(params);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId + paramString + filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Retrieves all the rentals for a selected branch, to be consumed by the CSV export
   * component inside of the `Rentals` table.
   *
   * @param {string} AuthToken - the authentication token necessary to make requests.
   * @param {number} branch - the branch id for which to make a request.
   */
  static getAllRentalsForCSV(AuthToken, branch, queryParams) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${queryParams}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Retrieve rentals for vehicle with provided filters through query params.
   *
   * If we need to sort by a different order or paginate, pass along `size`, `page`.
   *
   * @param {string} authToken - Authentication bearer token.
   * @param {object} options - Request cancellation signal, as well as query parameters
   * that signify which notes for which vehicle we're retrieving the tasks.
   */
  static getVehicleRentalsAbortable(
    authToken,
    { signal, queryParams: { size = 10, page = 0, branchId, vehicleId } }
  ) {
    const options = {
      method: 'GET',
      signal,
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
    };
    return ApiService.ApiCallAbortable(
      `${BASE_URL}?branchId=${branchId}&vehicleId=${vehicleId}&size=${size}&page=${page}`,
      options
    );
  }

  static getRentalRoutes(AuthToken, rentalId, signal) {
    const options = {
      method: 'GET',
      signal,
      headers: {
        Authorization: `Bearer ${AuthToken}`,
        'Content-Type': 'application/json',
      },
    };
    return ApiService.ApiCallAbortable(`${BASE_URL}/${rentalId}/routes`, options);
  }

  static getRentalDetails(AuthToken, branch, params) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${params.id}`, AuthToken, 'application/json');
  }

  static getActiveRentals(AuthToken, branch) {
    return RentalService.getRentals(AuthToken, branch, filters.RESERVATION_OR_ACTIVE);
  }

  static getReservationOrActiveRentals(AuthToken, branch) {
    return RentalService.getRentals(AuthToken, branch, filters.RESERVATION_OR_ACTIVE);
  }

  static getEndedRentals(AuthToken, branch) {
    return RentalService.getRentals(AuthToken, branch, filters.EXPIRED_OR_ENDED_OR_CANCELLED);
  }

  static getAllRentals(AuthToken, branch, page = '') {
    return RentalService.getRentals(AuthToken, branch, filters.ALL, page);
  }

  static addRental(vehicleId, AuthToken) {
    const data = {
      vehicleId,
      startRentalState: 'RESERVATION',
    };
    return ApiService.ApiCall('POST', BASE_URL, data, AuthToken, 'application/json');
  }

  static createNewRental(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json');
  }

  static deleteReservation(rentalId, AuthToken) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${rentalId}`,
      AuthToken,
      'application/json'
    );
  }

  static rentalOperation(
    rentalId,
    AuthToken,
    operationType,
    time,
    ignoreBusinessArea,
    ignoreSpeed,
    ignoreCommunicationFailure,
    ignoreOperationFailure
  ) {
    const data = {
      operationType,
      ignoreBusinessArea,
      endTime: time || null,
      ignoreSpeed,
      ignoreCommunicationFailure,
      ignoreOperationFailure,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${rentalId}/operation`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method used to release money deposit for specified rental.
   * @param {string} authToken - authentication bearer token.
   * @param {number} rentalId - id of the rental.
   */
  static releaseMoneyDeposit(authToken, rentalId) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${rentalId}/release-deposit`,
      {},
      authToken,
      'application/json'
    );
  }

  /**
   * Retrieve rental events for specific rental by id.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} rentalId - Id of the rental to fetch for.
   * @param {AbortContoler.signal} - Request aborting signal.
   * @param {queryParams} - Query parameters used in the url
   */
  static getRentalEventsAbortable(authToken, rentalId, signal, queryParams = { size: 150 }) {
    const filtersString = createStringFromParams(queryParams, '');
    const options = {
      method: 'GET',
      signal,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };
    return ApiService.ApiCallAbortable(`${BASE_URL}/${rentalId}/events?${filtersString}`, options);
  }

  /**
   * Retrieves all the rental files from current rental
   *
   * @param {string} AuthToken - the authentication token necessary to make requests.
   * @param {number} rentalId - current rental.
   */
  static getAllRentalFiles(AuthToken, rentalId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${rentalId}/files`,
      AuthToken,
      'application/json'
    );
  }
}
export default RentalService;
