import get from 'lodash/get';

import { handleResponsePromise, handleTenantUrl } from './util';

class ApiService {
  static async ApiCallBase(method, url, contentType, data = false, headers = {}, signal) {
    /** `resolved` url from tenant */
    const resolvedUrl = await handleTenantUrl(url, headers.Authorization);

    const base = data === false ? {} : { body: JSON.stringify(data) };
    /**
     * `response` promise in case when url is resolved normally
     * if url is not found return empty string as array
     * and dummy response promise is sent
     */
    const responsePromise = resolvedUrl
      ? fetch(resolvedUrl, {
          ...base,
          method,
          signal,
          headers: new Headers({
            'Content-Type': contentType,
            ...headers,
          }),
        })
      : new Promise(resolve =>
          resolve(
            new Response(JSON.stringify({}), {
              status: 401,
              statusText: 'Unauthorized',
              headers: new Headers({
                'Content-Type': contentType,
                '**Accept**': 'application/json',
              }),
            })
          )
        );
    return handleResponsePromise(responsePromise, headers.Authorization);
  }

  static ApiCallNoAuth(method, url, data, contentType) {
    return ApiService.ApiCallBase(method, url, contentType, data);
  }

  static ApiCallBody(method, url, data, AuthToken, contentType) {
    return ApiService.ApiCallBase(method, url, contentType, data, {
      Authorization: `Bearer ${AuthToken}`,
    });
  }

  static async ApiCallBodyFormData(method, url, data, AuthToken) {
    /** `resolved` url from tenant */
    const resolvedUrl = await handleTenantUrl(url, `Bearer ${AuthToken}`);

    const responsePromise = resolvedUrl
      ? fetch(resolvedUrl, {
          body: data,
          method,
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        })
      : new Promise(resolve =>
          resolve(
            new Response(JSON.stringify({}), {
              status: 401,
              statusText: 'Unauthorized',
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
            })
          )
        );
    return handleResponsePromise(responsePromise, `Bearer ${AuthToken}`);
  }

  static ApiCallAuth(method, url, AuthToken, contentType, headers = {}, signal) {
    return ApiService.ApiCallBase(
      method,
      url,
      contentType,
      false,
      {
        Authorization: `Bearer ${AuthToken}`,
        ...headers,
      },
      signal
    );
  }

  /**
   * ApiCallAbortable is a function that keeps it's arity low in order
   * to be flexible, use this wherever we need to pass down low level
   * fetch options arguments.
   *
   * In order for this function to properly be abortable we need to pass down
   * an abortable signal from the `AbortSignal`'s `signal` property.
   *
   * If used in conjunction with `react-async` we can use it's provided signal
   * to integrate with this function easily.
   *
   * @param {string} url - The url for which to make a request.
   * @param {object} options - fetch options object.
   */
  static async ApiCallAbortable(url, options) {
    /** `resolved` url from tennant */
    const resolvedUrl = await handleTenantUrl(url, get(options, ['headers', 'Authorization'], ''));

    /**
     * `response` promise in case when url is resolved normally
     * if url is not found return empty string as array
     * and dummy response promise is sent
     */
    const responsePromise = resolvedUrl
      ? fetch(resolvedUrl, {
          headers: new Headers({
            ...options.headers,
          }),
          ...options,
        })
      : new Promise(resolve =>
          resolve(
            new Response(JSON.stringify({}), {
              status: 401,
              statusText: 'Unauthorized',
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
            })
          )
        );
    return handleResponsePromise(
      responsePromise,
      options?.headers?.get?.('Authorization') || options?.headers?.Authorization || ''
    );
  }
}

export default ApiService;
// This values are later replaced with real tennant urls
ApiService.BACKEND_URL = '{coreUrl}';
ApiService.AUTH_URL = '{authUrl}';
ApiService.ANALYTICS_URL = '{analyticsUrl}';
ApiService.EXPORT_URL = '{exportUrl}';
ApiService.MAINTENANCE_URL = '{maintenanceUrl}';
ApiService.PLATFORM_URL = '{platformUrl}';
