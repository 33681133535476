import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { getAllBranches } from '../../redux/branches/selectors';
import { getSelectedBranch } from '../../redux/app/selectors';
import { getAllActivePlugins } from '../../redux/plugins/selectors';

/**
 * A component that handles protected routes. Routes can be protected depending on different conditions.
 * For now, logic is implemented that protects the route for components in case it is not the root branch.
 * @param {node} component - component which are rendering
 * @param {boolean} rootBranchCase - Should we protect the route for not root branches
 * @param {React.Props<NavigationHeader.propTypes>} restOfProps - rest props of component.
 */
const ProtectedRoute = ({
  component: Component,
  rootBranchCase = false,
  plugin,
  ...restOfProps
}) => {
  const allBranches = useSelector(getAllBranches);
  const selectedBranch = useSelector(getSelectedBranch);

  const activePlugins = useSelector(getAllActivePlugins);

  const shouldNotRenderComponent = () => {
    let notRender = false;
    if (rootBranchCase)
      notRender = allBranches.find(branch => branch?.id === 1)?.id !== selectedBranch?.id;
    // check plugin case
    if (plugin) notRender = !activePlugins?.[plugin];
    return notRender;
  };

  const isReadyForRender = () => {
    if (rootBranchCase) {
      return allBranches?.length && selectedBranch?.id;
    }
    if (plugin) {
      return !isEmpty(activePlugins);
    }
    return true;
  };

  return (
    <Route
      {...restOfProps}
      render={props =>
        isReadyForRender() &&
        (!shouldNotRenderComponent() ? (
          <Component {...restOfProps} {...props} />
        ) : (
          <Redirect to="/dashboard/406" />
        ))
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  rootBranchCase: PropTypes.bool.isRequired,
  plugin: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  plugin: null,
};

export default React.memo(ProtectedRoute, isEqual);
