import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { support } from '../../constants/urls';

const TopbarHelp = () => (
  <a href={support} target="_blank" rel="noopener noreferrer">
    <span className="isoMenuHolder">
      <QuestionCircleOutlined />
    </span>
  </a>
);

export default TopbarHelp;
