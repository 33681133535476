import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/price-calcs`;
const BASE_URL_SIMULATOR = `${ApiService.BACKEND_URL}/back/pricing-simulator`;

class PricingService {
  static index(AuthToken) {
    return ApiService.ApiCallGet('GET', BASE_URL, AuthToken, 'application/json');
  }

  static simulatePricing(AuthToken, data) {
    return ApiService.ApiCallBody('POST', `${BASE_URL}/test`, data, AuthToken, 'application/json');
  }

  static simulateFreeFloatingPricing(AuthToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL_SIMULATOR}/free-floating`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static simulateBookingPricing(AuthToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL_SIMULATOR}/booking`,
      data,
      AuthToken,
      'application/json'
    );
  }
}

export default PricingService;
