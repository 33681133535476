import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/resources`;
class ResourceService {
  /**
   * Method for adding language to the specified country.
   * @param {string} AuthToken access token for service
   * @param {string} locale to be added
   */
  static addLanguage(AuthToken, locale, area = 'CORE') {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/messages-management/languages?locale=${locale}&area=${area}`,
      {},
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for fetching all languages in system.
   * @param {string} AuthToken access token for service
   */
  static fetchLanguages(AuthToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/messages-management/languages`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for searching messages by filter
   * @param {string} AuthToken access token for service
   * @param {string} searchText filter value for search
   */
  static searchMessages(AuthToken, params) {
    // Create query params as string, but remove only `undefined` values
    const paramsString = Object.keys(omitBy(params, isNil))
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/messages-management?${paramsString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for creating message resource.
   * @param {string} AuthToken access token for service
   * @param {object} data request body containing code, vakue, locale
   */
  static createMessageResource(AuthToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/messages-management`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for updating message resource.
   * @param {string} AuthToken access token for service
   * @param {object} data request body containing code, value, locale
   * @param {number} messageId id of message resource to be updated
   */
  static updateMessageResource(AuthToken, data, messageId) {
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}/messages-management/${messageId}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for publishing changes.
   * @param {string} AuthToken access token for service
   */
  static publishChanges(AuthToken) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/messages-management/reload`,
      {},
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for fetching default messages.
   *
   * @param {string} AuthToken access token for service
   * @param {string} basename messages basename
   */
  static fetchDefaultMessages(AuthToken, basename) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/messages/defaults?basename=${basename}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for getting translations
   * @param {string} AuthToken - authentication bearer token.
   * @param {string} locale - locale for translated messages.
   */
  static fetchTranslations(AuthToken, locale) {
    const localeData = locale || null;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/messages?locale=${localeData}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for removing translation
   * @param {string} authToken - authentication bearer token.
   * @param {number} id - id for translated message.
   */
  static removeTranslation(authToken, id) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/messages-management/${id}`,
      authToken,
      'application/json'
    );
  }
}

export default ResourceService;
