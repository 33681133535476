import styled from 'styled-components';

import { AutoComplete, Button, Form, InputNumber, Radio, Select } from 'antd';
import { DatePicker } from '../pickers';

const RadioGroup = Radio.Group;

export const StyledOptionSubtext = styled('div')`
  font-size: 11px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    img {
      ${props => props.hideSelectedImage && 'display: none;'};
    }

    ${StyledOptionSubtext} {
      display: none;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      min-height: 36px;
      border-radius: 4px;
    }
  }

  &.ant-select {
    min-height: 36px;
    width: 100%;
    font-size: 13px;
    font-style: normal;

    &.ant-select-show-arrow.ant-select-has-feedback {
      .ant-select-selection-item {
        padding-right: 40px;
      }
    }

    .ant-select-selection--single {
      height: 36px;
    }

    .ant-select-selection__rendered {
      line-height: 36px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      font-size: 13px;
      height: 36px;
    }

    .ant-select-arrow {
      width: auto;
      height: 12px;
      color: var(--gray-8);
      i {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    align-items: center;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    width: 100%;
  }
`;

export const StyledMonthPicker = styled(DatePicker.MonthPicker)`
  &.ant-picker {
    width: 100%;
  }
`;

export const SubmitButton = styled(Button)`
  ${props => props.margin && `margin: ${props.margin}`} !important;
  &.ant-btn {
    width: 100%;
    height: 40px;
    background-color: var(--blue-6);
    border-radius: 4px;
    font-weight: normal !important;
    color: var(--gray-1);
    margin-top: 4px;
    font-size: 13px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-btn-danger {
    background-color: var(--red-5);
  }

  &[disabled] {
    background-color: ${props =>
      props.disabledBackgroundColor ? props.disabledBackgroundColor : 'var(--gray-3)'};
    color: ${props => (props.disabledColor ? props.disabledColor : 'var(--gray-5)')};
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  &.ant-radio-group {
    display: flex;
    height: 36px;
    width: 100%;

    .ant-radio-wrapper {
      width: auto;
    }

    .ant-radio-button-wrapper {
      display: inline-flex;
      flex: 1;
      text-align: center;
      height: 36px;
      font-size: 12px;
      line-height: unset;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    height: 36px;
    width: ${props => (props.width ? props.width : '100%')};
    ${props => props.border && 'border-right: white;'}
    border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
    .ant-input-number-input {
      font: var(--font-r-2);
      height: 36px;
    }
  }

  &.ant-input-number-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-input-number:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const StyledErrorMessage = styled.p`
  font-size: 11px;
  color: var(--red-7);
`;

export const StyledAutoComplete = styled(AutoComplete)`
  &.ant-select {
    height: 36px;
    width: 100%;
    font-size: 12px;
    font-style: normal;

    .ant-select-selection--single {
      height: 36px;
    }

    .ant-select-selection__rendered {
      line-height: 36px;
    }

    .ant-select-selector {
      height: 36px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 34px;

          .ant-select-selection-search-input {
            height: 34px;
          }
        }
      }
    }
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border-radius: 4px;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder
    .ant-select-selection-search {
    line-height: 36px;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  height: fit-content;
  font-family: 'Inter', sans-serif;
  font-style: normal;

  .ant-input-affix-wrapper {
    padding: 0;
    height: 36px;
    box-sizing: border-box;
    border-radius: 4px;

    .ant-input {
      padding: 4px 11px;
      background: transparent;
    }
  }

  .ant-form-item-has-feedback {
    .ant-input {
      padding-right: 24px;
    }
  }

  .ant-input-suffix {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    justify-content: center;
  }

  .ant-input-number-affix-wrapper {
    width: 100%;
    border-color: var(--gray-5);
    height: 36px;
    width: 100%;
    font-size: 13px;
    border-radius: 4px;
    .ant-input-number-input {
      height: 36px;
    }
  }

  .ant-input-number-affix-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input-number-affix:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input-number {
    background: transparent;
  }

  span.option-label {
    font-size: 12px;
    line-height: 14px;
  }

  .ant-form-item {
    margin-bottom: 12px;

    .ant-form-item-control {
      line-height: 36px;

      .ant-form-item-control-input-content {
        line-height: 3;
        ${props => (props.$flexInputContent ? 'display: flex;' : '')}
        display: flex;
        align-items: flex-start;
        min-height: 42px;
      }

      .ant-input {
        height: 36px;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 13px;
      }

      .ant-input-textarea {
        width: 100%;
        display: flex;
      }

      .ant-input-number-suffix {
        margin-right: 8px;
      }

      .ant-input-textarea-suffix {
        right: 9px;
      }
    }

    &.hide-star {
      .ant-form-item-label .ant-form-item-required:after {
        content: '';
      }
    }

    &.login-password-label {
      .ant-form-item-control .ant-input {
        height: 28px;
      }
      .ant-input-affix-wrapper {
        max-height: 100px;
        height: 36px;
        border-radius: 4px;
      }
    }

    &.add-user-password {
      .ant-form-item-control {
        .ant-input-password {
          max-height: 36px;
          height: 36px;
          border-radius: 4px;

          &.ant-input-affix-wrapper-focused {
            box-shadow: none;
          }

          .ant-input {
            height: 100%;
          }
        }
      }
    }

    .ant-form-explain {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;

      color: var(--red-6);
    }
  }

  .ant-form-item-label {
    label {
      height: 28px;
      display: flex;
      align-items: center;
      color: var(--gray-9);
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
    }

    .ant-form-item-required:before {
      display: none;
    }

    & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    .ant-form-item-required:after {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 12px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  .ant-form-item-label-usersAuth {
    padding: 0px 0px 20px 0px;
  }
`;

export const FormItemExtra = styled.div`
  font: var(--font-r-2);
  color: var(--gray-7);
`;
