import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.EXPORT_URL}/api`;
class ExportService {
  /**
   * Method for fetching exports with `specified` params.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} params - object containing `params` and `filter` values.
   */
  static getAllExports(authToken, _, params) {
    const newParams = { ...params };
    const filtersString = createStringFromFilter(newParams.filters);
    const updatedParams = omit(newParams, 'filters');
    const paramString = createStringFromParams(updatedParams);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/exports?${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating new export.
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - payload data as follows: notifyEmail, queryId and expirationPeriod.
   */
  static createExport(authToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/exports`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for downloading an export (for now we are not using this one).
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - payload data as follows: uuid
   */
  static downloadExport(authToken, data) {
    return ApiService.ApiCallBody(
      'GET',
      `${BASE_URL}/exports/download`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching queries with `specified` params.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} params - object containing `params` and `filter` values.
   */
  static getAllQueries(authToken, params) {
    const filtersString = createStringFromFilter(params.filters);
    const updatedParams = omit(params, 'filters');
    const paramString = createStringFromParams(updatedParams);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/queries?${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }
}

export default ExportService;
