import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Pagination } from 'antd';

import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {
  GoalsDropdown,
  GoalsPagination,
  GoalsDropdownHeader,
  GoalsTopBar,
  GoalsTopBarIndicator,
} from './TopbarGoals.style';

import { longYearNumericMonthFmt, longMonthYearFmt } from '../../constants/datetime';
import { calculatePercentage, calculateExpectedResult } from '../../helpers/analyticsUtility';
import GoalsProgress from '../../components/goals-progress';
import { getIndicatorColor, prepareGoalsData } from '../../components/goals-progress/util';

const goalCategories = ['rentals', 'subscriptions', 'revenue'];

const TopbarGoals = ({ goals, mainGoal, goalsAnalytics, fetchGoalsAnalytics }) => {
  const [isVisible, updateIsVisible] = useState(false);
  const [pages, updatePages] = useState('');
  const [currentPageIndex, updateCurrentPageIndex] = useState(0);
  const [didInitializePageIndex, updateDidInitializePageIndex] = useState(false);
  const [shouldShowGoals, updateShouldShowGoals] = useState(false);
  const [currentMonth] = useState(dayjs().format(longYearNumericMonthFmt));

  useEffect(() => {
    // If the goal for the current month is deleted, stop showing it in top bar.
    if (!goals[currentMonth]) {
      updateShouldShowGoals(false);
      return;
    }

    const updatedPages = prepareGoalsData(goals, goalsAnalytics);
    updatePages(updatedPages);
  }, [goals, currentMonth, goalsAnalytics]);

  useEffect(() => {
    if (currentMonth && pages.length) {
      pages.forEach((page, index) => {
        if (page.month === currentMonth && !didInitializePageIndex) {
          updateCurrentPageIndex(index);
          updateDidInitializePageIndex(true);
        }
      });
      updateShouldShowGoals(true);
    } else {
      updateShouldShowGoals(false);
    }
  }, [currentMonth, isVisible, pages, didInitializePageIndex]);

  if (!pages.length) {
    return null;
  }

  const page = pages[currentPageIndex];

  const getGoalToShow = currentMonthPage => {
    // If there's a goal which is same as the main goal, show it.
    if (currentMonthPage[mainGoal]) {
      return mainGoal;
    }

    // If there isn't, show revenue by default.
    if (currentMonthPage.revenue) {
      return 'revenue';
    }

    // If neither the revenue goal is set, show rentals (which are required).
    return 'rentals';
  };

  const getMainGoalColor = () => {
    const currentMonthPage = pages.find(p => p.month === currentMonth);
    const goalToShow = getGoalToShow(currentMonthPage);
    const { achieved, goal } = currentMonthPage[goalToShow];
    const percent = calculatePercentage(
      achieved,
      calculateExpectedResult(dayjs().date(), goal, currentMonthPage.numberOfDays)
    );

    return getIndicatorColor(percent);
  };

  const getTitle = () => {
    const currentMonthPage = pages.find(p => p.month === currentMonth);
    const goalToShow = getGoalToShow(currentMonthPage);
    const value = Math.round(currentMonthPage[goalToShow].achieved).toLocaleString();

    return (
      <p className="isoTitle">
        <IntlMessages id={`settings.goals.${goalToShow}.lowercase`} values={{ value }} />
      </p>
    );
  };

  const content = page ? (
    <TopbarDropdownWrapper className="isoGoalsDropdown">
      <GoalsDropdownHeader>
        <GoalsPagination>
          <Pagination
            pageSize={1}
            total={pages.length}
            current={currentPageIndex + 1}
            onChange={nextPage => {
              const { month } = pages[nextPage - 1];

              // Fetch if not already in store.
              if (!goalsAnalytics[month]) {
                fetchGoalsAnalytics(month);
              }

              updateCurrentPageIndex(nextPage - 1);
            }}
          />
        </GoalsPagination>
        <p className="dropdownText">
          {dayjs(page.month, longYearNumericMonthFmt).format(longMonthYearFmt)}
        </p>
      </GoalsDropdownHeader>
      <GoalsProgress currentGoal={page} categories={goalCategories} />
    </TopbarDropdownWrapper>
  ) : (
    <div />
  );

  return (
    shouldShowGoals && (
      <GoalsDropdown
        overlay={content}
        trigger={['click']}
        open={isVisible}
        onOpenChange={() => updateIsVisible(!isVisible)}
      >
        <GoalsTopBar>
          {getTitle()}
          <GoalsTopBarIndicator color={getMainGoalColor()} />
        </GoalsTopBar>
      </GoalsDropdown>
    )
  );
};

TopbarGoals.propTypes = {
  goals: PropTypes.objectOf(PropTypes.object).isRequired,
  mainGoal: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  goalsAnalytics: PropTypes.object.isRequired,
  fetchGoalsAnalytics: PropTypes.func.isRequired,
};

export default TopbarGoals;
