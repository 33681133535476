import { actionPayload } from '../common/commonActions';

const actionStrings = {
  FETCH_GOALS_SUCCESS: 'FETCH_GOALS_SUCCESS',
  FETCH_MAIN_GOAL_SUCCESS: 'FETCH_MAIN_GOAL_SUCCESS',

  FETCH_GOALS_ANALYTICS: 'FETCH_GOALS_ANALYTICS',
  FETCH_GOALS_ANALYTICS_SUCCESS: 'FETCH_GOALS_ANALYTICS_SUCCESS',
  RESET_GOALS_ANALYTICS: 'RESET_GOALS_ANALYTICS',

  ADD_GOAL: 'ADD_GOAL',
  ADD_GOAL_SUCCESS: 'ADD_GOAL_SUCCESS',

  UPDATE_GOAL: 'UPDATE_GOAL',
  UPDATE_GOAL_SUCCESS: 'UPDATE_GOAL_SUCCESS',

  UPDATE_MAIN_GOAL: 'UPDATE_MAIN_GOAL',
  UPDATE_MAIN_GOAL_SUCCESS: 'UPDATE_MAIN_GOAL_SUCCESS',

  RESET_GOALS: 'RESET_GOALS',
};

const actions = {
  fetchGoalsSuccess: actionPayload(actionStrings.FETCH_GOALS_SUCCESS),
  fetchMainGoalSuccess: actionPayload(actionStrings.FETCH_MAIN_GOAL_SUCCESS),

  fetchGoalsAnalytics: actionPayload(actionStrings.FETCH_GOALS_ANALYTICS),
  fetchGoalsAnalyticsSuccess: actionPayload(actionStrings.FETCH_GOALS_ANALYTICS_SUCCESS),

  addGoal: actionPayload(actionStrings.ADD_GOAL),
  addGoalSuccess: actionPayload(actionStrings.ADD_GOAL_SUCCESS),

  updateGoal: actionPayload(actionStrings.UPDATE_GOAL),
  updateGoalSuccess: actionPayload(actionStrings.UPDATE_GOAL_SUCCESS),

  updateMainGoal: actionPayload(actionStrings.UPDATE_MAIN_GOAL),
  updateMainGoalSuccess: actionPayload(actionStrings.UPDATE_MAIN_GOAL_SUCCESS),

  resetGoals: actionPayload(actionStrings.RESET_GOALS),
  resetGoalsAnalytics: () => ({ type: actionStrings.RESET_GOALS_ANALYTICS }),
};

export { actions, actionStrings };
