import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/invoices`;

class InvoiceService {
  static getInvoiceByID(AuthToken, invoiceID) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${invoiceID}`, AuthToken, 'application/json');
  }

  static getInvoices(AuthToken, branchId, params) {
    const filtersString = createStringFromFilter(params.filters);
    const paramString = createStringFromParams(omit(params, ['filters']));
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  static updateInvoice(AuthToken, data) {
    const body = {
      title: data.data.title,
      description: data.data.description,
    };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${data.data.invoiceId}`,
      body,
      AuthToken,
      'application/json'
    );
  }

  static downloadInvoices(AuthToken, invoiceId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${invoiceId}/download`,
      AuthToken,
      'application/json'
    );
  }

  static downloadInvoiceByUrl(url) {
    return ApiService.ApiCallNoAuth('GET', url);
  }

  /**
   * Method to refund invoice with specified reason and amount.
   * @param {string} AuthToken access token for method.
   * @param {number} invoiceId identifier of invoice to be refund.
   * @param {object} data object containing amount and reason info.
   */
  static refundInvoice(AuthToken, invoiceId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${invoiceId}/refund`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to fetch statement report for year and month on billable branch.
   * @param {string} AuthToken access token for method.
   * @param {number} branchId identifier of branch.
   * @param {string} yearAndMonth year and month param.
   */
  static downloadInvoiceStatementCsv(AuthToken, branchId, yearAndMonth) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/reports?branchId=${branchId}&yearMonth=${yearAndMonth}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to resend invoice with specified mail message.
   * @param {string} AuthToken access token for method.
   * @param {number} invoiceId identifier of invoice to be resend.
   * @param {object} params object containing amount and reason info.
   */
  static resendInvoice(AuthToken, invoiceId, params) {
    // Must be query params, as endpoint does not allow sending message in request payload
    const paramString = createStringFromParams(params, '?');
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${invoiceId}/resend${paramString}`,
      {},
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to update invoice line item.
   * @param {string} AuthToken access token for method.
   * @param {number} invoiceId identifier of invoice.
   * @param {number} lineItemId identifier of invoice line item.
   * @param {object} data object containing line item related infos.
   */
  static updateInvoiceLineItem(AuthToken, invoiceId, lineItemId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${invoiceId}/line-item/${lineItemId}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to mark invoice as paid externally.
   * @param {string} AuthToken access token for method.
   * @param {number} invoiceId identifier of invoice.
   */
  static markInvoiceAsPaid(AuthToken, invoiceId) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${invoiceId}/set-paid-externally`,
      {},
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to mark invoice as moved to collection.
   * @param {string} AuthToken access token for method.
   * @param {number} invoiceId identifier of invoice.
   */
  static markInvoiceAsCollection(AuthToken, invoiceId) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${invoiceId}/mark-for-collection`,
      {},
      AuthToken,
      'application/json'
    );
  }
}
export default InvoiceService;
