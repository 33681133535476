import { all, fork, takeEvery, getContext, put, call, select } from 'redux-saga/effects';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import difference from 'lodash/difference';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import { actionStrings, actions } from './actions';
import {
  successNotification,
  errorNotification,
  getAuthToken,
  handleMessage,
} from '../common/commonSagas';
import SettingsService from '../../services/coreAPI/SettingsService';
import {
  PLUGIN_KEY_AVAILABLE,
  PLUGIN_KEY_ACTIVATED,
  PLUGINS,
  ACTIVE_PLUGINS,
  SETTING_VALUE_TYPE,
  PUBLIC_PLUGINS,
  GROUPED_BOOKINGS_PLUGIN,
  USER_APP,
  PLUGIN_TOGGLE_SETTING,
  ANALYTICS_PLUGIN_NAME,
  PHONE_NUMBER_BLACKLIST_PLUGIN_NAME,
} from '../../constants/settings-plugins';
import availableAreas from '../../constants/areas';

import responseOk from '../../utils/responseOk';
import { getPluginState } from './selectors';
import { getBranchId } from '../app/selectors';

/** `helper` to check are all responses okay */
const allResponseOk = (responses = []) =>
  responses.map(response => responseOk(response)).every(v => v);

/**
 * Check what should the default initial state of the plugin be
 * @param pluginName {String} - plugin name
 * @returns {boolean}
 */
const getPluginActiveState = pluginName => {
  switch (pluginName) {
    case USER_APP:
    case ANALYTICS_PLUGIN_NAME:
    case PHONE_NUMBER_BLACKLIST_PLUGIN_NAME:
      return true;
    default:
      return false;
  }
};

/**
 * `helper` function used to sync available plugins constants and exiting setting values
 * @param {string} idToken - authentication bearer token.
 * @param {Object} plugins - setting with values as plugins
 * @param {boolean} defaultValue - default value for the plugin
 * @returns {Object} original setting value or updated setting
 */
const syncPluginValues = async (idToken, plugins, defaultValue = true) => {
  const missingPlugins = {};

  // Check missing available plugins
  ACTIVE_PLUGINS.forEach(plugin => {
    if (!Object.keys(get(plugins, 'value')).includes(plugin)) {
      // Assign value to missing plugin
      missingPlugins[plugin] = defaultValue;
    }
    return true;
  });

  // TODO: Keep it hidden for now
  // TODO: When it is ready for customers remove this part of the code
  if (!isNil(missingPlugins[GROUPED_BOOKINGS_PLUGIN])) {
    // hide grouped booking plugin for now
    missingPlugins[GROUPED_BOOKINGS_PLUGIN] = false;
  }

  if (!isNil(missingPlugins[USER_APP])) {
    // Set user app plugin true by default
    missingPlugins[USER_APP] = true;
  }

  // If plugin value and constants are not synced
  if (!isEmpty(missingPlugins)) {
    // Update available setting with missing plugins
    const response = await SettingsService.updateSetting(idToken, plugins.id, {
      value: {
        ...get(plugins, 'value', {}),
        ...missingPlugins,
      },
    });
    const result = await response.json();

    return result;
  }
  return plugins;
};

function* deletePlugins(plugins, areas = [], key) {
  const idToken = yield call(getAuthToken);
  const ids = [...plugins]
    // .filter(plugin => areas.indexOf(plugin.area) === -1 && plugin.key === `plugins.${key}`)
    .filter(plugin => areas.indexOf(plugin.area) === -1 && plugin.key === key)
    .map(value => value.id);
  yield all(ids.map(id => call(SettingsService.deleteSetting, idToken, id)));
}

/**
 * Helper generator function for fetching settings.
 * @param {object} params - query param object `eg. { page: 0, size: 300 }`.
 * @param {string} idToken - access token.
 */
function* fetchSettings(params, idToken) {
  const response = yield call(SettingsService.getAllSettings, idToken, params);
  if (responseOk(response)) {
    const settings = yield call([response, response.json]);
    return settings;
  }
  // return object inidcating `error`
  return { error: true };
}

function* fetchAllSettings() {
  const params = { page: 0, size: 500 };

  const intl = yield getContext('intl');
  const idToken = yield call(getAuthToken);
  const data = [];

  const response = yield call(SettingsService.getAllSettings, idToken, params);

  /** `returned` data from fetching */
  const totalPages = response.headers.get('X-Pagination-TotalPages');

  if (responseOk(response)) {
    const firstSettingsData = yield call([response, response.json]);
    data.push(...firstSettingsData);
  } else {
    handleMessage(intl.formatMessage({ id: 'settings.plugins.fetchPluginsError' }), true, 2);
    return { response, data: [] };
  }
  /** array of rest pages for `settings` to be fetched */
  const restPages = totalPages > 1 ? range(1, totalPages) : [];
  const restSettings = yield all(
    restPages.map(page => call(fetchSettings, { ...params, page }, idToken))
  );

  if (restSettings.find(settingData => get(settingData, 'error', false))) {
    handleMessage(intl.formatMessage({ id: 'settings.plugins.fetchPluginsError' }), true, 2);
    return { response: '', data: [] };
  }
  restSettings.forEach(settingData => data.push(...settingData));

  return { response, data };
}

function* fetchPlugins() {
  const idToken = yield call(getAuthToken);
  let response;
  let result = [];

  const { response: firstResponse, data } = yield call(fetchAllSettings);

  response = firstResponse;
  result = data;

  let fetchPluginsAgain = false;
  let enableAgainFetch = true;
  // if we get 403, we fetch settings using query strings
  if (!responseOk(response)) {
    const branchId = yield select(getBranchId);
    const pluginKeys = Object.keys(PLUGINS)
      // .map(pluginName => `plugins.${pluginName}`)
      .join(',');
    const queryString = `?keys=${PLUGIN_KEY_AVAILABLE},${PLUGIN_KEY_ACTIVATED},${pluginKeys}&branchIds=${branchId}`;
    response = yield call(SettingsService.fetchSettingByQueryString, idToken, queryString);
    enableAgainFetch = false;
  }
  // testing new response, if new response don't work show notification
  if (!responseOk(response)) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'settings.plugins.fetchPluginsError');
    return [];
  }

  // case when first response is not okay and second one is okay
  // use second result for getting data
  if (!responseOk(firstResponse) && responseOk(response)) {
    result = yield call([response, response.json]);
  }

  /** `setting` for available plugin */
  let availablePlugins = yield call(
    find,
    result,
    settings => settings.key === PLUGIN_KEY_AVAILABLE
  );
  if (!availablePlugins) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'settings.plugins.fetchPluginsError');
    return [];
  }

  let activePlugins = yield call(find, result, settings => settings.key === PLUGIN_KEY_ACTIVATED);

  // if there aren't active plugins, create them
  if (!activePlugins) {
    yield call(SettingsService.createSetting, idToken, {
      area: availableAreas.SHARED,
      key: PLUGIN_KEY_ACTIVATED,
      branchIds: [0],
      value: fromPairs(ACTIVE_PLUGINS.map(pluginName => [pluginName, false])),
      settingValueType: PLUGIN_TOGGLE_SETTING,
    });
    fetchPluginsAgain = true && enableAgainFetch;
  }
  // if active plugis exist, but user plugins listed by user are different from active ones
  // for example, new plugin is added in PLUGINS constant
  if (!isEqual(Object.keys(activePlugins.value).sort(), ACTIVE_PLUGINS.sort())) {
    const pluginsDiff = difference(ACTIVE_PLUGINS.sort(), Object.keys(activePlugins.value).sort());
    const updateValue = {
      ...activePlugins.value,
      ...fromPairs(pluginsDiff.map(pluginName => [pluginName, getPluginActiveState(pluginName)])),
    };

    // case when there are added new plugins
    if (pluginsDiff.length)
      yield call(SettingsService.updateSetting, idToken, activePlugins.id, { value: updateValue });
    // indicator to fetch plugins again
    fetchPluginsAgain = pluginsDiff.length > 0 && enableAgainFetch;
  }
  // Going through available plugins, and if no plugin create it
  const userPlugins = Object.keys(PLUGINS);
  const extractedPlugins = {};
  for (let i = 0; i < userPlugins.length; i += 1) {
    /** `areas` where plugins exists */
    const pluginAreas = Object.keys(PLUGINS[userPlugins[i]]);
    for (let j = 0; j < pluginAreas.length; j += 1) {
      const currentPlugin = yield call(
        find,
        result,
        settings => settings.key === `${userPlugins[i]}` && settings.area === pluginAreas[j]
      );
      yield call(deletePlugins, result, pluginAreas, userPlugins[i]);
      if (!currentPlugin && enableAgainFetch) {
        if (enableAgainFetch) {
          const dataForCreate = {
            area: pluginAreas[j],
            key: userPlugins[i],
            value: PLUGINS[userPlugins[i]][pluginAreas[j]],
            branchIds: [0],
          };

          if (PUBLIC_PLUGINS.includes(userPlugins[i])) dataForCreate.isPublic = true;

          if (SETTING_VALUE_TYPE[userPlugins[i]]) {
            dataForCreate.settingValueType = SETTING_VALUE_TYPE[userPlugins[i]];
          }
          yield call(SettingsService.createSetting, idToken, dataForCreate);
          fetchPluginsAgain = true;
        }
      } else {
        extractedPlugins[userPlugins[i]] = {
          ...extractedPlugins[userPlugins[i]],
          [pluginAreas[j]]: currentPlugin,
        };
      }
    }
  }
  if (fetchPluginsAgain) {
    yield call(fetchPlugins);
    return [];
  }

  // Sync available plugins with defined plugins in settings constants
  availablePlugins = yield call(syncPluginValues, idToken, availablePlugins);
  // Sync active plugins with defined plugins in settings constants
  activePlugins = yield call(syncPluginValues, idToken, activePlugins, false);

  yield put(
    actions.storePlugins({
      availablePlugins,
      activePlugins,
      plugins: extractedPlugins,
    })
  );

  return [];
}

function* updatePluginActivity(action) {
  const idToken = yield call(getAuthToken);
  const pluginsState = yield select(getPluginState);
  const { activePlugins, availablePlugins, plugins } = pluginsState;
  const updateValue = { ...activePlugins.value, [action.payload.pluginName]: action.payload.value };
  const response = yield call(SettingsService.updateSetting, idToken, activePlugins.id, {
    value: updateValue,
  });
  if (!responseOk(response)) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'settings.plugins.fetchPluginsError');
  }
  const result = yield call([response, response.json]);
  yield put(
    actions.storePlugins({
      availablePlugins,
      activePlugins: result,
      plugins,
    })
  );
  yield call(successNotification, 'settings.businessArea.titleSuccess', 'setting.update.success');
}

function* updatePlugin(action) {
  const idToken = yield call(getAuthToken);
  const { pluginName, value, areas = [], settingValueType, callBack, branchIds } = action.payload;
  const pluginsState = yield select(getPluginState);
  const { activePlugins, availablePlugins, plugins } = pluginsState;
  /** `array` of plugins to be updated */
  const affectedPlugins = Object.keys(plugins[pluginName])
    .map(key => plugins[pluginName][key])
    .filter(plugin => areas.includes(plugin.area));

  /** `responses` of updated plugins */
  const responses = yield all(
    [...affectedPlugins].map(x => {
      // remove branchIds from the value
      const updatedValue = omit({ ...x.value, ...value }, 'branchIds');
      const data = { value: updatedValue };
      if (settingValueType) {
        data.settingValueType = settingValueType;
      }
      if (branchIds) {
        data.branchIds = branchIds;
      }
      return call(SettingsService.updateSetting, idToken, x.id, data);
    })
  );

  if (!allResponseOk(responses)) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'settings.plugins.fetchPluginsError');
    return;
  }

  /** `array` of updated plugins */
  const pluginsJsonUpdated = yield all(responses.map(response => call([response, response.json])));

  const storePlugins = {
    availablePlugins,
    activePlugins,
    plugins: {
      ...plugins,
    },
  };

  /** `store` plugin forEach area */
  pluginsJsonUpdated.forEach(plugin => {
    storePlugins.plugins[pluginName][plugin.area] = plugin;
  });

  yield put(actions.storePlugins(storePlugins));

  /** `check` `onesignal-overview-pane` for this implementation */
  if (callBack) callBack(storePlugins);

  yield call(successNotification, 'settings.businessArea.titleSuccess', 'setting.update.success');
}

function* watchFetchPlugins() {
  yield takeEvery(actionStrings.FETCH_PLUGINS, fetchPlugins);
}

function* watchUpdatePluginActivity() {
  yield takeEvery(actionStrings.UPDATE_PLUGIN_ACTIVITY, updatePluginActivity);
}

function* watchUpdatePlugin() {
  yield takeEvery(actionStrings.UPDATE_PLUGIN, updatePlugin);
}

export default function* rootSaga() {
  yield all([fork(watchFetchPlugins), fork(watchUpdatePluginActivity), fork(watchUpdatePlugin)]);
}
