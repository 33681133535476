import { filterActions } from 'redux-ignore';
import get from 'lodash/get';
import actions from './actions';

const initState = {
  issue: {},
  loading: false,
};

const issueReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_ISSUE:
      return {
        ...state,
        issue: action.issue,
      };
    case actions.UPDATE_USER_INVOICE:
      return {
        ...state,
        issue: {
          ...state.issue,
          invoice: {
            ...state.issue.invoice,
            title: get(action, 'data.data.title'),
            description: get(action, 'data.data.description'),
          },
        },
      };
    case actions.ISSUE_LOADER_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ISSUE_LOADER_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default filterActions(issueReducer, [
  actions.SET_ISSUE,
  actions.UPDATE_USER_INVOICE,
  actions.ISSUE_LOADER_START,
  actions.ISSUE_LOADER_END,
]);
