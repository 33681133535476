import React from 'react';

import IntlMessages from '../../../components/utility/intlMessages';
import { availableEntities } from '../../../redux-config';
import permissions from '../../../constants/permissions';
import { BUSINESS_ACCOUNTS_NAME } from '../../../constants/settings-plugins';
import TenantFeatures from '../../../constants/features';

/** object that contains `routes` for all tables and permission for that routes */
const tableRoutes = {
  [availableEntities.VEHICLES]: [
    {
      route: '/dashboard/vehicles',
      routeName: <IntlMessages id="sidebar.vehicles" />,
      selected: true,
      permission: permissions.VEHICLE_READ,
    },
    {
      route: '/dashboard/tasks',
      routeName: <IntlMessages id="sidebar.tasks" />,
      selected: false,
      permission: permissions.TASK_READ,
      externalUrl: 'maintenance/task-management',
      feature: TenantFeatures.MAINTENANCE_TOOLS,
    },
    {
      route: '/dashboard/damages',
      routeName: <IntlMessages id="sidebar.damages" />,
      selected: false,
      permission: permissions.VEHICLE_DAMAGE_READ,
    },
  ],
  [availableEntities.TASKS]: [
    {
      route: '/dashboard/vehicles',
      routeName: <IntlMessages id="sidebar.vehicles" />,
      selected: false,
      permission: permissions.VEHICLE_READ,
    },
    {
      route: '/dashboard/tasks',
      routeName: <IntlMessages id="sidebar.tasks" />,
      selected: true,
      permission: permissions.TASK_READ,
      feature: TenantFeatures.MAINTENANCE_TOOLS,
    },
    {
      route: '/dashboard/damages',
      routeName: <IntlMessages id="sidebar.damages" />,
      selected: false,
      permission: permissions.VEHICLE_DAMAGE_READ,
    },
  ],
  [availableEntities.DAMAGES]: [
    {
      route: '/dashboard/vehicles',
      routeName: <IntlMessages id="sidebar.vehicles" />,
      selected: false,
      permission: permissions.VEHICLE_READ,
    },
    {
      route: '/dashboard/tasks',
      routeName: <IntlMessages id="sidebar.tasks" />,
      selected: false,
      permission: permissions.TASK_READ,
      feature: TenantFeatures.MAINTENANCE_TOOLS,
    },
    {
      route: '/dashboard/damages',
      routeName: <IntlMessages id="sidebar.damages" />,
      selected: true,
      permission: permissions.VEHICLE_DAMAGE_READ,
    },
  ],
  [availableEntities.CUSTOMERS]: [
    {
      route: '/dashboard/customers',
      routeName: <IntlMessages id="sidebar.users" />,
      selected: true,
      permission: permissions.USER_READ,
    },
    {
      route: '/dashboard/business-accounts',
      routeName: <IntlMessages id="business.accounts.title" />,
      selected: false,
      plugin: BUSINESS_ACCOUNTS_NAME,
    },
    {
      route: '/dashboard/invoices',
      routeName: <IntlMessages id="sidebar.invoices" />,
      selected: false,
      permission: permissions.INVOICE_READ,
    },
    {
      route: '/dashboard/roles',
      routeName: <IntlMessages id="customer.title.roles" />,
      selected: false,
      // TODO Corresponding permission needs to be implemented
      permission: permissions.EVENT_READ,
    },
    {
      route: '/dashboard/logs',
      routeName: <IntlMessages id="sidebar.logs" />,
      selected: false,
      permission: permissions.EVENT_READ,
    },
  ],
  [availableEntities.BUSINESS_ACCOUNTS]: [
    {
      route: '/dashboard/customers',
      routeName: <IntlMessages id="sidebar.users" />,
      selected: false,
      permission: permissions.USER_READ,
    },
    {
      route: '/dashboard/business-accounts',
      routeName: <IntlMessages id="business.accounts.title" />,
      selected: true,
      plugin: BUSINESS_ACCOUNTS_NAME,
    },
    {
      route: '/dashboard/invoices',
      routeName: <IntlMessages id="sidebar.invoices" />,
      selected: false,
      permission: permissions.INVOICE_READ,
    },
    {
      route: '/dashboard/roles',
      routeName: <IntlMessages id="customer.title.roles" />,
      selected: false,
      // TODO Corresponding permission needs to be implemented
      permission: permissions.EVENT_READ,
    },
    {
      route: '/dashboard/logs',
      routeName: <IntlMessages id="sidebar.logs" />,
      selected: false,
      permission: permissions.EVENT_READ,
    },
  ],
  [availableEntities.INVOICES]: [
    {
      route: '/dashboard/customers',
      routeName: <IntlMessages id="sidebar.users" />,
      selected: false,
      permission: permissions.USER_READ,
    },
    {
      route: '/dashboard/business-accounts',
      routeName: <IntlMessages id="business.accounts.title" />,
      selected: false,
      plugin: BUSINESS_ACCOUNTS_NAME,
    },
    {
      route: '/dashboard/invoices',
      routeName: <IntlMessages id="sidebar.invoices" />,
      selected: true,
      permission: permissions.INVOICE_READ,
    },
    {
      route: '/dashboard/roles',
      routeName: <IntlMessages id="customer.title.roles" />,
      selected: false,
      // TODO Corresponding permission needs to be implemented
      permission: permissions.EVENT_READ,
    },
    {
      route: '/dashboard/logs',
      routeName: <IntlMessages id="sidebar.logs" />,
      selected: false,
      permission: permissions.EVENT_READ,
    },
  ],
  [availableEntities.ROLES]: [
    {
      route: '/dashboard/customers',
      routeName: <IntlMessages id="sidebar.users" />,
      selected: false,
      permission: permissions.USER_READ,
    },
    {
      route: '/dashboard/business-accounts',
      routeName: <IntlMessages id="business.accounts.title" />,
      selected: false,
      plugin: BUSINESS_ACCOUNTS_NAME,
    },
    {
      route: '/dashboard/invoices',
      routeName: <IntlMessages id="sidebar.invoices" />,
      selected: false,
      permission: permissions.INVOICE_READ,
    },
    {
      route: '/dashboard/roles',
      routeName: <IntlMessages id="customer.title.roles" />,
      selected: true,
      permission: permissions.ROLE_ASSIGNMENT_READ,
    },
    {
      route: '/dashboard/logs',
      routeName: <IntlMessages id="sidebar.logs" />,
      selected: false,
      permission: permissions.EVENT_READ,
    },
  ],
  [availableEntities.LOGS]: [
    {
      route: '/dashboard/customers',
      routeName: <IntlMessages id="sidebar.users" />,
      selected: false,
      permission: permissions.USER_READ,
    },
    {
      route: '/dashboard/business-accounts',
      routeName: <IntlMessages id="business.accounts.title" />,
      selected: false,
      plugin: BUSINESS_ACCOUNTS_NAME,
    },
    {
      route: '/dashboard/invoices',
      routeName: <IntlMessages id="sidebar.invoices" />,
      selected: false,
      permission: permissions.INVOICE_READ,
    },
    {
      route: '/dashboard/roles',
      routeName: <IntlMessages id="customer.title.roles" />,
      selected: false,
      // TODO Corresponding permission needs to be implemented
      permission: permissions.EVENT_READ,
    },
    {
      route: '/dashboard/logs',
      routeName: <IntlMessages id="sidebar.logs" />,
      selected: true,
      permission: permissions.EVENT_READ,
    },
  ],
  [availableEntities.BOOKINGS]: [
    {
      route: '/dashboard/bookings',
      routeName: <IntlMessages id="bookings.tab.table" />,
      selected: true,
      permission: permissions.BOOKING_READ,
    },
    {
      route: '/dashboard/booking-calendar',
      routeName: <IntlMessages id="sidebar.calendar" />,
      selected: false,
      permission: permissions.BOOKING_READ,
    },
  ],
  [availableEntities.BOOKING_CALENDAR]: [
    {
      route: '/dashboard/bookings',
      routeName: <IntlMessages id="bookings.tab.table" />,
      selected: false,
      permission: permissions.BOOKING_READ,
    },
    {
      route: '/dashboard/booking-calendar',
      routeName: <IntlMessages id="sidebar.calendar" />,
      selected: true,
      permission: permissions.BOOKING_READ,
    },
  ],
  [availableEntities.SERVICE_AGENTS]: [
    {
      route: '/dashboard/productivity-insights',
      routeName: <IntlMessages id="productivity.insights.tab" />,
      selected: false,
    },
    {
      route: '/dashboard/service-agents',
      routeName: <IntlMessages id="service.agents.tab" />,
      selected: true,
    },
  ],
  [availableEntities.PRODUCTIVITY_INSIGHTS]: [
    {
      route: '/dashboard/productivity-insights',
      routeName: <IntlMessages id="productivity.insights.tab" />,
      selected: true,
    },
    {
      route: '/dashboard/service-agents',
      routeName: <IntlMessages id="service.agents.tab" />,
      selected: false,
    },
  ],
};

export default tableRoutes;
