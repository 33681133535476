import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  GoalItem,
  GoalProgress,
  ProgressIndicator,
  ProgressIndicatorDot,
  Indicator,
  ProgressBarWrapper,
  DividerWrapper,
} from './index.style';

import IntlMessages from '../utility/intlMessages';
import { longYearNumericMonthFmt } from '../../constants/datetime';
import { calculatePercentage, calculateExpectedResult } from '../../helpers/analyticsUtility';

import { getIndicatorColor } from './util';

const GoalsProgress = ({ categories, currentGoal }) => {
  const [currentMonth] = useState(dayjs().format(longYearNumericMonthFmt));
  return (
    <>
      {categories.map(category => {
        // Return null if there are no goals for category
        if (!currentGoal[category]) return null;
        const {
          numberOfDays,
          month,
          [category]: { goal = 0, achieved = 0 },
        } = currentGoal;
        const currentNumberOfDays = month === currentMonth ? dayjs().date() : numberOfDays;
        const color = getIndicatorColor(
          calculatePercentage(
            achieved,
            calculateExpectedResult(currentNumberOfDays, goal, numberOfDays)
          )
        );
        return (
          <div className="progress-item-wrapper" key={`${category}`}>
            <DividerWrapper />
            <GoalItem className="progress-item">
              <div className="itemHeader">
                <p className="itemText">
                  <IntlMessages id={`settings.goals.${category}`} />
                </p>
                <p className="itemText">
                  {Math.round(achieved)}{' '}
                  <span className="itemText goalText">
                    <IntlMessages
                      className="itemText goalText"
                      id="settings.goals.achieved.goal"
                      values={{
                        goal,
                      }}
                    />
                  </span>
                </p>
              </div>
              <ProgressBarWrapper>
                <Indicator percent={calculatePercentage(currentNumberOfDays, numberOfDays) - 1}>
                  <ProgressIndicator color={color}>
                    <ProgressIndicatorDot />
                  </ProgressIndicator>
                </Indicator>
                <GoalProgress
                  percent={calculatePercentage(achieved, goal)}
                  showInfo={false}
                  strokeWidth={6}
                  strokeColor={color}
                />
              </ProgressBarWrapper>
            </GoalItem>
          </div>
        );
      })}
    </>
  );
};

GoalsProgress.propTypes = {
  currentGoal: PropTypes.shape({
    month: PropTypes.string,
    numberOfDays: PropTypes.number,
    rentals: PropTypes.shape({
      goal: PropTypes.number,
      achieved: PropTypes.number,
    }),
    revenue: PropTypes.shape({
      goal: PropTypes.number,
      achieved: PropTypes.number,
    }),
    subscriptions: PropTypes.shape({
      goal: PropTypes.number,
      achieved: PropTypes.number,
    }),
  }),
  categories: PropTypes.arrayOf(PropTypes.string),
};

GoalsProgress.defaultProps = {
  currentGoal: {},
  categories: ['rentals', 'subscriptions', 'revenue'],
};

export default GoalsProgress;
