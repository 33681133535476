const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};
const colStyle = {
  marginBottom: '16px',
};
const gutter = 16;

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
  colours: {
    businessArea: 'rgb(77, 175, 0)',
    restrictedArea: 'rgb(226, 136, 0)',
    prohibitedArea: 'rgb(175, 32, 0)',
    playgroundArea: 'rgb(68, 130, 255)',
  },
  analyticsColors: [
    '#85A5FF',
    '#95DE64',
    '#FFD666',
    '#FF9C6E',
    '#B37FEB',
    '#FF85C0',
    '#5CDBD3',
    '#FFF566',
    '#D3F261',
    '#FF7875',
    '#69C0FF',
    '#CBD4E1',
    '#D3ADF7',
    '#FFADD2',
    '#FFFB8F',
    '#B7EB8F',
    '#FFE58F',
  ],
  clusterColors: [
    '#003A8C',
    '#69C0FF',
    '#08979C',
    '#006D75',
    '#389E0D',
    '#237804',
    '#AD8B00',
    '#FA8C16',
    '#D46B08',
    '#FF4D4F',
    '#A8071A',
  ],
  heatmapColours: {
    0: 'rgba(0, 0, 255, 0)',
    0.1: 'hsla(225, 79%, 64%, 0.6)',
    0.3: 'hsla(186, 88%, 55%, 0.7)',
    0.5: 'hsla(120, 87%, 63%, 0.75)',
    0.7: 'hsla(60, 100%, 51%, 0.8)',
    1: 'hsla(0, 99%, 61%, 0.8)',
  },
  breakpoints: {
    mobile: 767,
  },
};

export default basicStyle;
