import { Dropdown } from 'antd';
import styled from 'styled-components';

export const GoalsDropdown = styled(Dropdown)`
  border: 1px solid var(--gray-4);
  border-radius: 100px;

  .isoTitle {
    padding: 11px;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: right;
    color: var(--gray-8);
  }
`;

export const GoalsTopBar = styled.div`
  display: flex;
  align-items: center;
`;

export const GoalsTopBarIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: ${props => props.color};
  margin: 14px 15px 14px 0px;
`;

export const GoalsDropdownHeader = styled.div`
  display: flex;
  align-items: center;

  .dropdownText {
    margin-bottom: 0px !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    color: var(--gray-8);
  }
`;

export const GoalsPagination = styled.div`
  margin: 10px 20px;

  .ant-pagination-item,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: none;
  }

  .ant-pagination-next > .ant-pagination-item-link {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .ant-pagination-prev {
    margin-right: 0;
    .ant-pagination-item-link {
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
`;
