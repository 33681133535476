/* eslint-disable func-names */
import { takeEvery, put, call, all, getContext, select } from 'redux-saga/effects';
import range from 'lodash/range';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import merge from 'lodash/merge';
import VehicleService from '../../services/coreAPI/VehicleService';
import createNotification from '../../components/notification';
import vehicleActions, { commands as vehicleCommands } from './actions';
import { actions as entityActions, actionStrings } from '../entities/actions';
import { fetchData } from '../common/commonFlowSagas';
import responseOk from '../../utils/responseOk';
import { availableEntities } from '../../redux-config';
import {
  getFetchEntityErrorSaga,
  getFetchEntityStartSaga,
  getFetchEntityEndSaga,
  successNotification,
  errorNotification,
  handleMessage,
  getAuthToken,
} from '../common/commonSagas';
import { uploadFile } from '../issues/sagas';

import { getCurrent, getSelectedBranch } from '../app/selectors';
import { getAreVehiclesFetched, getAreVehiclesFetching, getLastTimeFetched } from './selectors';

import { getActiveQuickViews, isQuickViewActive, quickViewPayload } from '../quickview/selectors';
import { quickviewActionTypes } from '../quickview/actions';
import waitFor from '../common/waitFor';

const commandMap = {
  [vehicleCommands.SET_OPERATIONAL]: VehicleService.setOperational,
  [vehicleCommands.SET_OUT_OF_ORDER]: VehicleService.setOutOfOrder,
  [vehicleCommands.UPDATE_STATUS]: VehicleService.updateStatus,
  [vehicleCommands.RETIRED]: VehicleService.setRetired,
  [vehicleCommands.REBOOT]: VehicleService.vehicleReboot,
};

const doCommand = command => (idToken, branchId, id) => commandMap[command](idToken, id);

function* mergeVehicle(id) {
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(VehicleService.getVehicleDetails, idToken, id);
    if (responseOk(response)) {
      const result = yield call([response, response.json]);
      yield put({
        type: actionStrings.MERGE_ENTITY_SUCCESS,
        entity: availableEntities.VEHICLES,
        payload: result,
      });
    } else {
      const result = yield call([response, response.json]);
      if (result.userMessage) {
        yield call(errorNotification, result.errorCode, result.userMessage);
      }
    }
  } catch (error) {
    // error block
  }
}

function updateCommandSuccess(id, callBack) {
  return function* (result) {
    if (result) {
      yield put(entityActions.mergeEntitySuccess(availableEntities.VEHICLES, result));
    } else {
      yield call(mergeVehicle, id);
    }
    // Callback function for solving loading button in the Vehicle table
    yield call(callBack);
    yield put(vehicleActions.finishedCommand(id));
    yield call(getFetchEntityEndSaga(availableEntities.DETAILS, null));
  };
}

function* vehicleCommand(action) {
  const { id, command, callBack } = action;
  yield put(vehicleActions.runningCommand(id));

  yield call(
    fetchData,
    doCommand(command),
    // callback used for stoping loading buttons
    updateCommandSuccess(id, callBack),

    getFetchEntityErrorSaga(availableEntities.DETAILS, action.type, callBack),
    getFetchEntityStartSaga(availableEntities.DETAILS, action.type),
    getFetchEntityEndSaga(availableEntities.DETAILS, action.type),

    {
      entity: availableEntities.VEHICLES,
      payload: id,
    },
    false
  );
}

function updateVehicle(idToken, branchId, payload) {
  return VehicleService.updateVehicle(idToken, payload.id, payload.key, payload.value);
}

function updateVehicleWithData(idToken, branchId, payload) {
  return VehicleService.updateVehicleWithData(idToken, payload.id, payload.data);
}

function* onVehicleUpdate(result) {
  yield put(entityActions.mergeEntitySuccess(availableEntities.VEHICLES, result));
  yield put(entityActions.updateDetails('vehicle', result));
  const intl = yield getContext('intl');
  yield call(
    createNotification,
    'success',
    intl.formatMessage({ id: 'feedback.alert.loadingdata.vehicleUpdateSuccess' })
  );
}

function* vehicleUpdate(action) {
  const { id, key, value } = action;
  yield call(
    fetchData,
    updateVehicle,
    onVehicleUpdate,
    getFetchEntityErrorSaga(availableEntities.DETAILS, action.type),
    getFetchEntityStartSaga(availableEntities.DETAILS, action.type),
    getFetchEntityEndSaga(availableEntities.DETAILS, action.type),
    {
      entity: availableEntities.DETAILS,
      payload: {
        id,
        key,
        value,
      },
    }
  );
}

function* vehicleUpdateWithData(action) {
  const { id, data } = action;
  yield call(
    fetchData,
    updateVehicleWithData,
    onVehicleUpdate, // Success handler
    getFetchEntityErrorSaga(availableEntities.DETAILS, action.type),
    getFetchEntityStartSaga(availableEntities.DETAILS, action.type),
    getFetchEntityEndSaga(availableEntities.DETAILS, action.type),
    {
      entity: availableEntities.DETAILS,
      payload: {
        id,
        data,
      },
    }
  );
}

function* updateVehicleTags(action) {
  const { id, data } = action;
  const idToken = yield call(getAuthToken);

  const response = yield call(VehicleService.updateVehicleTags, idToken, id, data);
  if (response.errorCode) {
    yield call(errorNotification, response.errorCode, response.userMessage);
  } else {
    yield call(
      successNotification,
      'tags.vehicleTagsModal.success',
      'tags.vehicleTagsModal.successMessage'
    );
  }
}

/**
 * File upload function that receives an array of files to upload
 * to the REST api, attaching the uploaded files to a particular vehicle.
 *
 * @param {Array|File} files - an array of file (blob) types
 * @param {number} vehicleId - the id of the vehicle to connect the files to
 * @param {boolean} isPublic - query param to set the files as private or public
 * @returns {Array|object} - response messages for the requests
 */
function* uploadVehicleFiles(files, vehicleId, isPublic = false) {
  // The authentication bearer token for making an authenticated request.
  const idToken = yield call(getAuthToken);

  // An array of file upload responses.
  const filesResponses = yield all(
    files.map(file => call(uploadFile, idToken, file.data, isPublic))
  );

  // Handle each file response.
  const uploadedFiles = yield all(
    filesResponses.map(fileResponse => {
      // Convert fileResponse to a valid object
      const file = call([fileResponse, fileResponse.json]);
      return file;
    })
  );

  const fileIds = uploadedFiles.map(file => file.id);

  // Link files to vehicle.
  const patchResponse = yield call(VehicleService.updateVehicleWithData, idToken, vehicleId, {
    fileIds,
  });

  // Handle each file response
  const vehicleFiles = yield call([patchResponse, patchResponse.json]);

  return vehicleFiles;
}

/**
 * An upload files watcher that calls the `uploadFiles` saga, while creating
 * notification for the success or failure of them.
 */
function* uploadCustomerFilesSaga(action) {
  // Files passed along from the action creator.
  const filesToUpload = action.payload;

  if (filesToUpload.length > 0) {
    // Begin file upload, `uploadFiles` handles notifications
    yield call(uploadVehicleFiles, action.payload, action.id, false);
  }
}

/**
 * Helper generator function for fetching vehicles.
 * @param {string} branchId - id of branch that vehicles are fetched.
 * @param {object} params - query param object `eg. { page: 0, size: 300 }`.
 * @param {string} idToken - access token.
 */
function* fetchVehicles(branchId, params, idToken) {
  const response = yield call(VehicleService.getAllVehiclesMap, idToken, branchId, params);
  if (responseOk(response)) {
    const vehicles = yield call([response, response.json]);
    return vehicles;
  }
  // return object inidcating `error`
  return { error: true };
}

/**
 * Generator function for fetching all vehicles.
 * @param {number|string} branchId - id of selected branch.
 * @param {object} params - params for vehicles to be fetched.
 * @param {string} idToken - access token.
 * @returns {object} fetched vehicles dictionary.
 */
function* fetchAllVehicles(branchId, params, idToken) {
  const intl = yield getContext('intl');
  try {
    const response = yield call(VehicleService.getAllVehiclesMap, idToken, branchId, params);
    if (responseOk(response)) {
      /** `returned` data from fetching */
      const data = {};
      const totalPages = response.headers.get('X-Pagination-TotalPages');
      const size = response.headers.get('X-Pagination-Size');

      if (responseOk(response)) {
        const firstVehiclesData = yield call([response, response.json]);
        firstVehiclesData.forEach(vehicle => {
          data[vehicle.id] = vehicle;
        });
      } else {
        handleMessage(
          intl.formatMessage({ id: 'feedback.alert.loadingdata.fetchVehiclesFailed' }),
          true,
          2
        );
        return {};
      }
      /** array of rest pages for `vehicles` to be fetched */
      const restPages = totalPages > 1 ? range(1, totalPages) : [];
      const restVehicles = yield all(
        restPages.map(page => call(fetchVehicles, branchId, { ...params, page, size }, idToken))
      );
      if (restVehicles.find(vehicleData => get(vehicleData, 'error', false))) {
        handleMessage(
          intl.formatMessage({ id: 'feedback.alert.loadingdata.fetchVehiclesFailed' }),
          true,
          2
        );
        return {};
      }
      restVehicles.forEach(vehicles =>
        vehicles.forEach(vehicle => {
          data[vehicle.id] = vehicle;
        })
      );
      return data;
    }
  } catch (error) {
    console.error(error);
  }
  return {};
}

function* fetchChangedVehicles(branchId, idToken) {
  // used utc because of date in database, for `updatedAt`
  try {
    /** `indicator` if vehicle quick view is active */
    const isVehicleQuickViewActive = yield select(state =>
      isQuickViewActive(state, availableEntities.VEHICLES)
    );
    const lastTimeFetched = yield select(getLastTimeFetched);
    /** `before` time in case that focus is lost on dashboard */
    const updatedAfter = dayjs(lastTimeFetched).isBefore(dayjs().subtract(15, 'seconds'))
      ? `${lastTimeFetched}`
      : `${dayjs().subtract(15, 'seconds').toISOString()}`;

    /** `params` for fetching depending is branch billable */
    const params = {
      page: 0,
      size: 5000,
      updatedAfter,
      updatedBefore: dayjs().toISOString(),
    };
    const data = yield call(fetchAllVehicles, branchId, params, idToken);
    if (!isEmpty(data)) {
      yield put({ type: vehicleActions.FETCH_CHANGED_VEHICLES_SUCCESFUL, payload: data });
      // Check is vehicle quick view is active
      if (isVehicleQuickViewActive) {
        // Current quick view vehicle payload data on the state
        const vehicleQuickViewPayload = yield select(state =>
          quickViewPayload(state, availableEntities.VEHICLES)
        );
        // if we have some vehicle
        if (vehicleQuickViewPayload) {
          // Get updated item from data
          const updatedVehicleData = data[vehicleQuickViewPayload.id];
          if (updatedVehicleData) {
            yield put({
              type: quickviewActionTypes.UPDATE_ITEM,
              entity: availableEntities.VEHICLES,
              payload: updatedVehicleData,
            });
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Fetch all vehicles, when there's more than 500;
 */
function* fetchAllVehiclesRefresh({ branchId }) {
  const intl = yield getContext('intl');
  try {
    const areVehiclesFetched = yield select(getAreVehiclesFetched);
    const areVehiclesFetching = yield select(getAreVehiclesFetching);

    // if vehicles are fetched don't do anything
    if (areVehiclesFetching) return;

    // Retrieve the authentication token to be able to access the REST api.
    const idToken = yield call(getAuthToken);

    // We will wait on branch in redux state
    yield call(waitFor, state => !isEmpty(getSelectedBranch(state)));
    // Retrieve the branch to inject the branch.id as a query parameter in the HTTP call.
    const branch = yield select(getSelectedBranch);
    if (branch.id && idToken) {
      // case when all vehicles are fetched
      // and branch are not changed
      // then fetch only updated vehicles
      if (areVehiclesFetched && !branchId) {
        yield call(fetchChangedVehicles, branch.id, idToken);
        return;
      }

      yield put({ type: vehicleActions.START_OR_STOP_FETCHING_VEHICLES, payload: true });

      /** `params` for fetching depending is branch billable */
      const params = {
        page: 0,
        size: 5000,
      };
      /**
       *  `id of` selected branch.
       *  If branch is changed or selected for first time there's branchId in payload.
       *  If there's not branchId we're just refreshing with same branch vehicles.
       */
      const selectedBranchId = !branchId ? branch.id : branchId;
      const data = yield call(fetchAllVehicles, selectedBranchId, params, idToken);

      const [currentPage] = yield select(getCurrent);
      const activeQuickview = yield select(getActiveQuickViews);

      // Vehicles are needed only on map, vehicles table, service agents and in the booking quick-view
      // TODO: Try to find better solution in future.
      if (
        ['map', 'vehicles', 'service-agents'].includes(currentPage) ||
        activeQuickview.includes('bookings')
      ) {
        yield put({ type: vehicleActions.FETCH_ALL_VEHICLES_SUCCESSFUL, payload: data });
      }
      yield put({ type: vehicleActions.START_OR_STOP_FETCHING_VEHICLES, payload: false });
    }
  } catch (error) {
    handleMessage(
      intl.formatMessage({ id: 'feedback.alert.loadingdata.fetchVehiclesFailed' }),
      true,
      2
    );
    yield put({ type: vehicleActions.START_OR_STOP_FETCHING_VEHICLES, payload: false });
  }
}

function* fetchVehicleCommands() {
  const intl = yield getContext('intl');
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(VehicleService.getVehicleCommands, idToken);
    if (responseOk(response)) {
      const commands = yield call([response, response.json]);
      return commands;
    }
  } catch (error) {
    handleMessage(
      intl.formatMessage({ id: 'feedback.alert.loadingdata.fetchCommandsFailed' }),
      true,
      2
    );
  }
  return [];
}

/**
 * Fetching all vehicles categories, used in `Booking` modal.
 */
function* fetchVehicleCategories() {
  const intl = yield getContext('intl');
  try {
    const idToken = yield call(getAuthToken);
    const response = yield call(VehicleService.getVehicleCategories, idToken);
    if (responseOk(response)) {
      const categories = yield call([response, response.json]);
      const commands = yield call(fetchVehicleCommands);
      yield put({
        type: vehicleActions.FETCH_VEHICLES_CATEGORIES_SUCCESSFUL,
        payload: categories.map(category =>
          merge(category, { vehicleCommands: commands[category.id] })
        ),
      });
    }
  } catch (error) {
    handleMessage(
      intl.formatMessage({ id: 'feedback.alert.loadingdata.fetchCategoriesFailed' }),
      true,
      2
    );
  }
}

function* sendVehicleCommand({ payload: { commandData, vehicleId, callBack = () => {} } }) {
  const intl = yield getContext('intl');
  try {
    const { executionType, data, returnType, command, customCommandName } = commandData;

    // TODO: ADD SUPPORT FOR CUSTOM COMMANDS
    const idToken = yield call(getAuthToken);
    const response = yield call(
      VehicleService.sendCommand,
      idToken,
      vehicleId,
      executionType,
      returnType,
      command === 'CUSTOM_COMMAND' ? { ...data, customCommandName } : data
    );
    callBack();
    if (responseOk(response)) {
      yield call(handleMessage, intl.formatMessage({ id: 'vehicle.command.success' }));
      try {
        const vehicleCommandData = yield call([response, response.json]);
        yield put({
          type: vehicleActions.SEND_COMMAND_SUCCESS,
          payload: vehicleCommandData,
        });
      } catch {
        // catch block if response don't have json body
      }
      return;
    }
    const result = yield call([response, response.json]);
    if (result.userMessage) {
      yield call(errorNotification, result.errorCode, result.userMessage);
    } else {
      yield call(
        handleMessage,
        `${!result.status ? '' : `${result.status} -`} ${
          result.statusMessage ||
          intl.formatMessage({
            id: 'vehicle.command.error',
          })
        }`,
        true
      );
    }
  } catch (error) {
    yield call(handleMessage, intl.formatMessage({ id: 'vehicle.command.error' }), true);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(vehicleActions.VEHICLE_COMMAND, vehicleCommand),
    takeEvery(vehicleActions.UPDATE_VEHICLE, vehicleUpdate),
    takeEvery(vehicleActions.UPDATE_VEHICLE_W_DATA, vehicleUpdateWithData),
    takeEvery(vehicleActions.UPDATE_TAGS, updateVehicleTags),
    takeEvery(vehicleActions.UPLOAD_FILES, uploadCustomerFilesSaga),
    takeEvery(vehicleActions.FETCH_ALL_VEHICLES_REFRESH, fetchAllVehiclesRefresh),
    takeEvery(vehicleActions.FETCH_VEHICLES_CATEGORIES, fetchVehicleCategories),
    takeEvery(vehicleActions.SEND_COMMAND, sendVehicleCommand),
  ]);
}
