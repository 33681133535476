import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import reactNodeType from '../../types/react-node';
import { getIsMobileView } from '../../redux/app/selectors';

const ScrollContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-right: ${props => (props.$noMargin ? '0px' : '20px')};

  // Setting fading on scroll at the begining of container
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, transparent, var(--gray-1) 60%);
    width: ${({ leftFade = false }) => (leftFade ? 30 : 0)}px;
  }
  // Setting fading on scroll at the end of container
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, transparent, var(--gray-1) 60%);
    width: ${({ rightFade = false }) => (rightFade ? 30 : 0)}px;
  }

  .content {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
  }
`;

const ControlButton = styled.button`
  border: 1px solid var(--gray-5);
  color: var(--gray-7);
  font-size: 14px;
  background-color: var(--gray-3);
  height: 36px;
  width: fit-content;
  border-radius: 4px;
  ${props => props.$isMobileView && 'display: none'};
  margin-right: 20px;
`;

const HorizontalScroll = ({ children }) => {
  /** scroll node ref */
  const scrollRef = useRef(null);
  /** `scrollX` scroll value on x-axis */
  const [scrollX, setScrollX] = useState(0);
  /** `scrollEnd` indicator if scroller is at the end */
  const [scrollEnd, setScrollEnd] = useState(false);
  const isMobileView = useSelector(getIsMobileView);
  /**
   * `onSlide` method which handles scroll buttons click
   * @param {number} shift - value to scroll left or right
   */
  const onSlide = shift => {
    scrollRef.current.scrollLeft += shift;
    setScrollX(scrollX + shift);
  };

  /** `onScroll` method which handles scroll on scroller */
  const onScroll = () => {
    setScrollX(scrollRef?.current?.scrollLeft);
    setScrollEnd(
      Math.floor(scrollRef?.current?.scrollWidth - scrollRef?.current?.scrollLeft) <=
        scrollRef?.current?.offsetWidth
    );
  };

  /** `useEffect` triggers each time when scrolling */
  useEffect(() => {
    setScrollEnd(
      Math.floor(scrollRef?.current?.scrollWidth - scrollRef?.current?.scrollLeft) <=
        scrollRef?.current?.offsetWidth
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current?.scrollWidth, scrollRef?.current?.offsetWidth]);

  return (
    <>
      {scrollX ? (
        <ControlButton onClick={() => onSlide(-100)} $isMobileView={isMobileView}>
          <LeftOutlined />
        </ControlButton>
      ) : null}
      <ScrollContainer
        leftFade={scrollX}
        rightFade={!scrollEnd}
        $isMobileView={isMobileView}
        $noMargin={!scrollEnd}
      >
        <div ref={scrollRef} onScroll={onScroll} className="content">
          {children}
        </div>
      </ScrollContainer>
      {!scrollEnd && (
        <ControlButton onClick={() => onSlide(+100)} $isMobileView={isMobileView}>
          <RightOutlined />
        </ControlButton>
      )}
    </>
  );
};

HorizontalScroll.propTypes = {
  children: reactNodeType,
};

HorizontalScroll.defaultProps = {
  children: <></>,
};

export default HorizontalScroll;
