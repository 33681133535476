// TODO: custom commands are included in vehicleCategories/commands. Remove everything.
import omit from 'lodash/omit';

import ApiService from './ApiService';
import {
  createStringFromFilter,
  createStringFromParams,
  resolveTagsAndLabelsParams,
  multipleFiltersWithSameKey,
} from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/vehicles`;
// TODO: Add a centralized place to choose a branch
const BRANCH = '/branch';
const MANAGEMENT_URL = `${ApiService.BACKEND_URL}/back/vehicles-management`;
const VEHICLE_CARDS = `${ApiService.BACKEND_URL}/back/vehicle-cards`;

class VehicleService {
  /**
   * HTTP request that calls all the vehicles by branch.
   *
   * @param {string} AuthToken - authentication token to be sent as Authentication Bearer.
   * @param {number} branch - the id of the branch for which to request the vehicles.
   */
  static getAllVehiclesUnfiltered(AuthToken, branch) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}&sort=id,DESC`,
      AuthToken,
      'application/json'
    );
  }

  static getAllVehicles(AuthToken, branchId, params) {
    // TODO the backend should separate the search for labels and tags. Labels and lags are annotations. We are currently merging labels and tags(resolveTagsAndLabelsParams method below) and searching as annotations
    const filtersString = createStringFromFilter(resolveTagsAndLabelsParams(params));

    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);

    const branch = branchId ? `branchId=${branchId}` : '';
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${branch}${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    );
  }

  static getAllVehiclesMap(authToken, branchId, params) {
    const filtersString = createStringFromFilter(resolveTagsAndLabelsParams(params));

    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);

    const branch = branchId ? `branchId=${branchId}` : '';
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/map?${branch}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  static getVehiclesByIds(AuthToken, params) {
    const filtersString = createStringFromFilter(params?.filters);

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${filtersString.slice(1)}`,
      AuthToken,
      'application/json'
    );
  }

  static setVehicleState(AuthToken, vehicleId, nextState) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL + BRANCH}/${vehicleId}/${nextState}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to return simple details of vehicle.
   * @param {string} authToken - access token.
   * @param {string|number} id - identifier.
   */
  static getVehicleDetails(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json');
  }

  /**
   * Fetches vehicles while also implementing an AbortController signal.
   * This method tries to match the Fetch API call signature.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - The id of the branch to fetch for.
   * @param {object} params - Request params to fetch for.
   * @param {AbortController.signal} signal - Request aborting signal.
   */
  static getAllVehiclesAbortable(authToken, branchId, params, signal) {
    const filtersString = createStringFromFilter(params.filters);
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);
    const url = `${BASE_URL}?branchId=${branchId}${paramString}${filtersString}`;
    const requestOptions = {
      method: 'GET',
      signal,
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
    };
    return ApiService.ApiCallAbortable(url, requestOptions);
  }

  /**
   * Method for bulk updating
   * @param {string} authToken - authentication `bearer` token.
   * @param {object} data - object to send containing `name...`
   */
  static bulkUpdate(authToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/bulk-update`,
      data,
      authToken,
      'application/json'
    );
  }

  static setServiceState(AuthToken, vehicleId, state) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${vehicleId}`,
      { serviceStateV2: state },
      AuthToken,
      'application/json'
    );
  }

  static setOperational(AuthToken, vehicleId) {
    return VehicleService.setServiceState(AuthToken, vehicleId, 'OPERATIONAL');
  }

  static setOutOfOrder(AuthToken, vehicleId) {
    return VehicleService.setServiceState(AuthToken, vehicleId, 'OUT_OF_ORDER');
  }

  static updateStatus(AuthToken, vehicleId) {
    return VehicleService.vehicleCommand(AuthToken, vehicleId, 'updateState');
  }

  /**
   * Update vehicle with a PATCH request for each key - value property.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the vehicle to update for.
   * @param {string} key - The key of the property we should change.
   * @param {any} value - The value for any of the properties values we're changing.
   */
  static updateVehicle(AuthToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static deleteVehicleLegalInspectionDate(authtoken, id) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${id}/legalInspectionDate`,
      authtoken,
      'application/json'
    );
  }

  static updateVehicleWithData(AuthToken, id, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static updateVehicleTags(AuthToken, vehicleId, data) {
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}/${vehicleId}/tags`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static setRetired(AuthToken, vehicleId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${vehicleId}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for getting vehicles categories.
   * @param {string} authToken authentication bearer token.
   */
  static getVehicleCategories(authToken) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/categories`, authToken, 'application/json');
  }

  /**
   * Method for getting vehicles commands.
   * @param {string} authToken authentication bearer token.
   */
  static getVehicleCommands(authToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/categories/commands`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method to get list of all commands for vehicles.
   * @param {string} authToken - authentication bearer token.
   */
  static getAllCommands(authToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${MANAGEMENT_URL}/custom-commands`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for getting vehicles categories, only users with permissions can use that.
   * @param {string} authToken authentication bearer token.
   */
  static getVehicleCategoriesManagement(authToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/categories-management`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for deleting vehicle categoriy by id
   * @param {string} authToken authentication bearer token.
   * @param {number} vehicleCategoryId id of vehicle category which we are deleting
   */
  static deleteVehicleCategory(authToken, vehicleCategoryId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/categories-management/${vehicleCategoryId}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Reboot the vehicle.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} vehicleId - The id of the vehicle to update.
   */
  static vehicleReboot(authToken, { vehicleId }) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${vehicleId}/command?process=async&return=none`,
      {
        command: 'REBOOT',
      },
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching logs of vehicle.
   * @param {string} authToken - `authentication` bearer token.
   * @param {number} vehicleId - id of vehicles that logs are fetched.
   * @param {object} params - default params that are sent for fetch.
   */
  static getVehicleLogs(authToken, vehicleId, params) {
    const filtersString = createStringFromFilter(params.filters);
    const paramsString = createStringFromParams(omit(params, 'filters'));

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${vehicleId}/log-entries?${paramsString.substring(1)}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating vehicle category
   * @param {string} authToken - authentication `bearer` token.
   * @param {object} data - object to send containing `name...`
   */
  static createVehicleCategory(authToken, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/categories-management`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Update vehicle with a PATCH request for each key - value property.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the vehicle to update for.
   * @param {string} key - The key of the property we should change.
   * @param {any} value - The value for any of the properties values we're changing.
   */
  static updateVehicleCategory(AuthToken, id, key, value) {
    const data = {
      [key]: value,
    };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/categories-management/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Update vehicle with a PATCH request sending data object for update.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the vehicle to update for.
   * @param {object} data - data that is gonna be updated.
   */
  static updateVehicleCategoryWithData(AuthToken, id, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/categories-management/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static sendCommand(authToken, vehicleId, executionType, returnType, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${vehicleId}/command?process=${executionType}&return=${returnType}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used to assign iot module to vehicle.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} vehicleId - The id of the vehicle to update for.
   * @param {object} data - payload data.
   */
  static assignIotModule(authToken, vehicleId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${vehicleId}/iot-module`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used to unlink iot module from vehicle.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} vehicleId - The id of the vehicle to update for.
   */
  static unlinkIotModule(authToken, vehicleId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${vehicleId}/iot-module`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used to retrieve telemetry for a vehicle.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} vehicleId - The id of the vehicle to update for.
   */
  static retrieveTelemetry(authToken, vehicleId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${vehicleId}/telemetry`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used to create new vehicle.
   * @param {string} authToken - Authentication bearer token.
   * @param {Object} values - The request payload data for new vehicle.
   */
  static createVehicle(authToken, values) {
    return ApiService.ApiCallBody('POST', `${BASE_URL}`, values, authToken, 'application/json');
  }

  /**
   * Method for fetching vehicle activities.
   * @param {string} authToken - `authentication` bearer token.
   * @param {number} vehicleId - id of vehicles that logs are fetched.
   * @param {object} params - default params that are sent for fetch.
   */
  static getVehicleActivities(authToken, vehicleId, params) {
    const filtersString = multipleFiltersWithSameKey(params.filters, 'filters');
    const paramsString = createStringFromParams(omit(params, 'filters'), '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${vehicleId}/activity${paramsString}&${filtersString}`,
      authToken,
      'application/json'
    );
  }

  static getVehicleCards(vehicleId, AuthToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${VEHICLE_CARDS}?assignedVehicle=${vehicleId}`,
      AuthToken,
      'application/json'
    );
  }

  static searchUnassignedVehicleCards(AuthToken, searchText) {
    return ApiService.ApiCallAuth(
      'GET',
      `${VEHICLE_CARDS}?state=NEW&state=UNASSIGNED&searchText=${searchText}&size=5000&sort=cardNumber,ASC`,
      AuthToken,
      'application/json'
    );
  }

  static assignCard(id, vehicleId, AuthToken) {
    return ApiService.ApiCallBody(
      'POST',
      `${VEHICLE_CARDS}/${id}/assign`,
      { vehicleId },
      AuthToken,
      'application/json'
    );
  }

  static unassignCard(id, state, AuthToken) {
    return ApiService.ApiCallBody(
      'POST',
      `${VEHICLE_CARDS}/${id}/unassign`,
      { state },
      AuthToken,
      'application/json'
    );
  }
}

export default VehicleService;
