/* eslint-disable linebreak-style */
import { put, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { availableEntities, defaultAjaxParams } from '../../redux-config';
import { actions as entityActions } from '../entities/actions';
import { actions as tableActions } from './actions';

const paths = {
  '/dashboard/issues': availableEntities.ISSUES,
  '/dashboard/rentals': availableEntities.RENTALS,
  '/dashboard/customers': availableEntities.CUSTOMERS,
  '/dashboard/vehicles': availableEntities.VEHICLES,
  '/dashboard/bookings': availableEntities.BOOKINGS,
  '/dashboard/service-agents': availableEntities.SERVICE_AGENTS,
};

function getTableName(path) {
  if (path in paths) {
    return paths[path];
  }
  return null;
}

function* setDefaultTable(action) {
  const tableName = getTableName(action?.payload?.location?.pathname);

  if (tableName !== null && action.payload.search === '') {
    // set default table behavior
    yield put.resolve(tableActions.clearTable(tableName));
    yield put(entityActions.fetchEntity(tableName, defaultAjaxParams[tableName]));
  }
}

export default function* rootSaga() {
  yield takeEvery(LOCATION_CHANGE, setDefaultTable);
}
