import { actionPayload } from '../common/commonActions';

export const actionStrings = {
  FETCH_LIST_VERIF_ATTEMPTS: 'FETCH_LIST_VERIF_ATTEMPTS',
  FETCH_LIST_VERIF_ATTEMPTS_SUCCESS: 'FETCH_LIST_VERIF_ATTEMPTS_SUCCESS',
  CLEAR_LIST_VERIF_ATTEMPTS: 'CLEAR_LIST_VERIF_ATTEMPTS',
  FETCH_VERIFICATION_ATTEMPT_MEDIA: 'FETCH_VERIFICATION_ATTEMPT_MEDIA',
  FETCH_VERIFICATION_ATTEMPT_MEDIA_SUCCESS: 'FETCH_VERIFICATION_ATTEMPT_MEDIA_SUCCESS',
  CLEAR_VERIFICATION_ATTEMPT_MEDIA: 'CLEAR_VERIFICATION_ATTEMPT_MEDIA',
};

const fetchListVerifAttempts = userId => ({
  type: actionStrings.FETCH_LIST_VERIF_ATTEMPTS,
  userId,
});

const fetchListVerifAttemptsSuccess = actionPayload(
  actionStrings.FETCH_LIST_VERIF_ATTEMPTS_SUCCESS
);

const fetchVerificationAttemptMedia = (media, callBack) => ({
  type: actionStrings.FETCH_VERIFICATION_ATTEMPT_MEDIA,
  media,
  callBack,
});

const fetchVerificationAttemptMediaSuccess = actionPayload(
  actionStrings.FETCH_VERIFICATION_ATTEMPT_MEDIA_SUCCESS
);

const actions = {
  fetchListVerifAttempts,
  fetchListVerifAttemptsSuccess,
  fetchVerificationAttemptMedia,
  fetchVerificationAttemptMediaSuccess,
};

export default { ...actionStrings, ...actions };
