import { createSelector } from 'reselect';
import get from 'lodash/get';

const getVehicleCategoriesState = state => state.Vehicles?.categories;
const getVehicleCategoriesForIdState = (state, categoryId) =>
  get(
    (state.Vehicles?.categories || []).find(v => v.id === categoryId),
    'vehicleCommands',
    []
  );
const getAreVehiclesFetchedState = state => state.Vehicles.allVehiclesFetched;
const getAreVehiclesFetchingState = state => state.Vehicles.isFetchingAllVehicles;
const getVehiclesState = state => Object.values(state.Vehicles.allVehicles);
const getLastTimeFetchedState = state => state.Vehicles.lastTimeFetched;
const getIsBulkUpdatingState = state => state?.Vehicles?.isBulkUpdating;

export const getVehicleCategories = createSelector(
  getVehicleCategoriesState,
  vehicleCategories => vehicleCategories
);

export const getCommandsForCategory = createSelector(
  getVehicleCategoriesForIdState,
  vehicleCategories => vehicleCategories
);

/**
 * Selector to get are vehicles fetched.
 * @param {object} state - Redux state.
 */
export const getAreVehiclesFetched = createSelector(
  getAreVehiclesFetchedState,
  vehicles => vehicles
);

/**
 * Selector to get are vehicles currently fetched.
 * @param {object} state - Redux state.
 */
export const getAreVehiclesFetching = createSelector(
  getAreVehiclesFetchingState,
  areFetching => areFetching
);

/**
 * Selector to get all vehicles from state.
 * @param {object} state - Redux state.
 * @returns {Array} array of vehicles.
 */
export const getVehicles = createSelector(getVehiclesState, vehicles => vehicles);

/**
 * Helper method for resolving map vehicles
 * @param {object} state - Redux state
 * @returns {Array} array of the vehicles
 */
export const getMapVehicles = createSelector(getVehiclesState, vehicles => Object.values(vehicles));

/**
 * Helper method to get last time fetched.
 * @param {object} state - Redux state.
 */
export const getLastTimeFetched = createSelector(getLastTimeFetchedState, lastTime => lastTime);

/**
 * Helper method to get is bulk updating.
 * @param {object} state - Redux state.
 */
export const getIsBulkUpdating = createSelector(
  getIsBulkUpdatingState,
  isBulkUpdating => isBulkUpdating
);
