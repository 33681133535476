import omit from 'lodash/omit';

import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/{entity}/{entityId}/{field}`;

class EntityService {
  /**
   * Method to audit for data for specific antity and field
   * @param {string} authToken - authentication bearer token
   * @param {string} entity - entity type like `module, branch ...`
   * @param {number} entityId - id of the entity
   * @param {string} field - field to audit eg. `/back/module/{id}/revisions` revision in this case
   * @param {objecty} params - object containing `params` and `filter` values.
   */
  static auditForEntities(authToken, entity, entityId, field, params) {
    const URL = BASE_URL.replace('{entity}', entity)
      .replace('{entityId}', entityId)
      .replace('{field}', field);

    const filterString = createStringFromFilter(params.filters);
    const paramString = createStringFromParams(omit(params, 'filters'), '?');

    return ApiService.ApiCallAuth(
      'GET',
      `${URL}${paramString}${filterString}`,
      authToken,
      'application/json'
    );
  }
}

export default EntityService;
