import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import get from 'lodash/get';
import { CaretDownOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import {
  BranchInformation,
  ParentBranches,
  BranchNameContainer,
  BillableBadge,
  StyledTreeSelect,
  BranchItem,
  BranchName,
  DotsContainer,
} from './TopbarBranchSelect.style';
import { branchStates } from '../../constants/branch';
import IntlMessages from '../../components/utility/intlMessages';
import ThreeDots from '../../components/skeleton/ThreeDots';

import { getAllBranches } from '../../redux/branches/selectors';
import { getSelectedBranch } from '../../redux/app/selectors';
import { getCSSVariable } from '../../utils/cssVariables';

const parentBranchesInfo = (branch, allBranches, returnValue = []) => {
  const parentId = branch?.parentId;
  if (!parentId) return returnValue;

  const parent = allBranches.find(b => b.id === parentId);
  returnValue.push(parent);
  return parentBranchesInfo(parent, allBranches, returnValue);
};

/**
 * Helper `recursive` method to prepare `branches` for `antd` tree select.
 * @param {Array} branches - Array of `branch` tree structure.
 * @returns {Array} array of parsed and sorted branches by name.
 */
const prepareBranchesForTree = (branches, allBranches) => {
  // Sort branches by name
  const sortedBranches = branches.sort((b1, b2) => {
    const bName1 = get(b1, 'name', '').toLowerCase();
    const bName2 = get(b2, 'name', '').toLowerCase();
    if (bName1 > bName2) return 1;
    if (bName1 < bName2) return -1;
    return 0;
  });

  return sortedBranches
    .map(branch => {
      if (branch.state === branchStates.OUT_OF_BUSINESS) return null;
      const childrenIds = get(branch, 'children', []);
      let children = allBranches.filter(b => childrenIds.includes(b.id));
      if (childrenIds.length) {
        children = prepareBranchesForTree(children, allBranches);
      }
      return {
        title: (
          <BranchItem className="branch-selected">
            <BranchName>{`${branch.name}`}</BranchName>
            {branch.billable && (
              <BillableBadge className="badge">
                <IntlMessages id="settings.branch.billable" />
              </BillableBadge>
            )}
          </BranchItem>
        ),
        value: branch.id,
        children,
        name: branch.name,
      };
    })
    .filter(Boolean);
};

/**
 * Helper method to resolve selected keys depeding on the branch selection.
 * @param {object} branch - selected branch.
 * @param {Array} allBranches - list of the all branches in the system
 * @returns {string[]} array of the keys for the selection.
 */
const resolveKeys = (branch, allBranches = []) => {
  // if branch is not selected return
  if (!branch?.id) return [];

  /** `buffer` array for starting */
  const buffer = [branch];
  /** `return` array */
  const retArr = [];

  // while buffer has items to do
  // resolve keys
  while (buffer.length) {
    const br = buffer.pop();
    retArr.push(br?.id);
    // if branch has parent
    // put item to the buffer for the next resolve
    if (br?.parentId) buffer.push(allBranches.find(item => +item.id === +br?.parentId));
  }

  return retArr;
};

const TopbarBranchSelect = ({ onBranchSelect }) => {
  const allBranches = useSelector(getAllBranches);
  const branch = useSelector(getSelectedBranch);
  const mainBranch = allBranches.length ? allBranches.filter(b => !b?.parentId) : [{}];
  const selectedBranch = allBranches.find(b => b.id === branch.id);

  const [dataForTree, setDataForTree] = React.useState([]);
  const [parentInfo, setPrentInfo] = React.useState([]);

  const [treeExpandedKeys, updateTreeExpandedKeys] = React.useState([]);

  const [searchValue, updateSearchValue] = React.useState('');

  React.useEffect(() => {
    setDataForTree(prepareBranchesForTree(mainBranch, allBranches));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBranches]);

  React.useEffect(() => {
    setPrentInfo(parentBranchesInfo(selectedBranch, allBranches));
    updateTreeExpandedKeys(resolveKeys(selectedBranch, allBranches));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  return (
    <BranchInformation>
      <ParentBranches className="parentBranches">
        {parentInfo
          .sort((p1, p2) => p2.id - p1.id)
          .map(parent => parent?.name)
          .reverse()
          .join(' / ')}
      </ParentBranches>
      <BranchNameContainer>
        {!has(branch, 'id') ? (
          <DotsContainer>
            <ThreeDots />
          </DotsContainer>
        ) : (
          <StyledTreeSelect
            style={{ width: '100%' }}
            value={branch.id}
            dropdownStyle={{ maxHeight: 'auto', overflow: 'visible', minWidth: 350 }}
            treeData={dataForTree}
            placeholder="Please select"
            size="small"
            bordered={false}
            listHeight={600}
            treeNodeFilterProp="name"
            showSearch
            suffixIcon={<CaretDownOutlined style={{ color: getCSSVariable('--gray-8') }} />}
            onChange={value => onBranchSelect(value)}
            treeExpandedKeys={!searchValue ? treeExpandedKeys : undefined}
            onTreeExpand={!searchValue ? updateTreeExpandedKeys : undefined}
            // when search value exists
            // expand all
            treeDefaultExpandAll={!!searchValue}
            onSearch={updateSearchValue}
            searchValue={searchValue}
          />
        )}
      </BranchNameContainer>
    </BranchInformation>
  );
};

TopbarBranchSelect.propTypes = {
  onBranchSelect: PropTypes.func.isRequired,
};

export default TopbarBranchSelect;
