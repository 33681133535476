import get from 'lodash/get';
import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/groups`;
const CUSTOMERS_BASE_URL = `${ApiService.BACKEND_URL}/back/customers`;

class BusinessAccountsService {
  /**
   * Method to get list of business accounts with query params.
   * @param {string} authToken bearer access token.
   * @param {number} branchId identifier of selecte branch.
   * @param {number} queryParams corresponding parmas to applied to business accounts list.
   */
  static getBusinessAccounts(AuthToken, branchId, queryParams) {
    // Create valid query from array of values as filters
    const filtersString = createStringFromFilter(get(queryParams, 'filters', []));
    // Create valid query params from rest columns
    const paramString = queryParams ? createStringFromParams(omit(queryParams, ['filters'])) : '';

    // Ascending should be the default
    const filtersStringWithSort = queryParams?.sort
      ? filtersString
      : `${filtersString}&sort=id,DESC`;

    const branchIdString = branchId ? `?branchId=${branchId}` : '';
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}${branchIdString}${paramString}${filtersStringWithSort}`,
      AuthToken,
      'application/json'
    );
  }

  static searchBusinessAccounts(AuthToken, branchId, searchText) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}&searchText=${searchText}`,
      AuthToken,
      'application/json'
    );
  }

  static getGroupById(AuthToken, groupId) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${groupId}`, AuthToken, 'application/json');
  }

  static updateUserGroupById(AuthToken, userGroupId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${userGroupId}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static getMembersByGroupId(AuthToken, userGroupId, params) {
    const paramString = createStringFromParams(omit(params, ['filters']));
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${userGroupId}/members?${paramString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to get list of all group files
   * @param {string} AuthToken - authentication bearer token
   * @param {number} userGroupId - id of selected group
   */
  static getFilesByGroupId(AuthToken, userGroupId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${CUSTOMERS_BASE_URL}/${userGroupId}/files`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method to create new group.
   * @param {string} authToken - authentication bearer token
   * @param {Object} payload - request payload with data
   */
  static createGroup(authToken, payload) {
    return ApiService.ApiCallBody('POST', `${BASE_URL}`, payload, authToken, 'application/json');
  }

  /**
   * Method to create new member.
   * @param {string} authToken - authentication bearer token
   * @param {number} groupId - user id for business account member
   * @param {Object} payload - request payload with data
   */
  static addGroupMember(authToken, groupId, payload) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${groupId}/add-members`,
      payload,
      authToken,
      'application/json'
    );
  }

  /**
   * Method to remove member from business account.
   * @param {string} authToken - authentication bearer token
   * @param {number} groupId - user id for member to remove
   * @param {Object} payload - request payload with data
   */
  static removeGroupMember(authToken, groupId, payload) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${groupId}/remove-members`,
      payload,
      authToken,
      'application/json'
    );
  }

  /**
   * Method to add credits to business account.
   * @param {string} authToken - authentication bearer token
   * @param {number} groupId - group id where we want to add credit
   * @param {Object} payload - payload data
   */
  static addGroupCredit(authToken, groupId, queryParams = {}, payload) {
    const paramString = createStringFromParams(queryParams, '?');
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${groupId}/voucher${paramString}`,
      payload,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating voucher for the user group
   * @param {string} authToken - authentication bearer token
   * @param {number} id - id of the group
   * @param {object} data - data to be sent
   */
  static createVoucherForGroup(authToken, id, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${id}/voucher`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for payment methods for the user group
   * @param {string} authToken - authentication bearer token
   * @param {number} id - id of the group
   */
  static getGroupPaymentSources(authToken, id) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${id}/payment-sources`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for removing user group
   * @param {string} authToken - authentication bearer token
   * @param {number} id - id of the group
   */
  static deleteGroup(authToken, id) {
    return ApiService.ApiCallAuth('DELETE', `${BASE_URL}/${id}`, authToken, 'application/json');
  }
}

export default BusinessAccountsService;
