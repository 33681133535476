import ApiService from './ApiService';
import { tenantInfo } from '../../config';

// hack because tenant info is mutated at the start of app
// to retrieve proper uuid from subdomain
const getAuthBaseURL = () => `${ApiService.AUTH_URL}/api/${tenantInfo.uuid}`;
const getBaseURL = () => `${ApiService.BACKEND_URL}`;

export default class AuthService {
  static loginUser(email, password) {
    const data = {
      email,
      password,
    };
    return ApiService.ApiCallNoAuth(
      'POST',
      `${getAuthBaseURL()}/sign-in-email`,
      data,
      'application/json'
    ).then(response => response.json());
  }

  /**
   * Method for refreshing token of current logged user.
   * @param {string} refreshToken - refresh token of the user.
   */
  static refreshTokenUser(refreshToken) {
    return ApiService.ApiCallNoAuth(
      'POST',
      `${getAuthBaseURL()}/refresh-token`,
      { refreshToken },
      'application/json'
    );
  }

  static logoutUserDevices(userID, authToken) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${getBaseURL()}/back/customers/${userID}/logout`,
      {},
      authToken,
      'application/json'
    );
  }

  /**
   * Method of fetching a secret
   * @param {string} password - users's password
   * @param {string} authToken - access token
   */
  static getSecret2fa(authToken, password) {
    return ApiService.ApiCallBody(
      'POST',
      `${getAuthBaseURL()}/user/2fa-secret`,
      { password },
      authToken,
      'application/json'
    );
  }

  /**
   * Method for fetching a qr-code
   * @param {string} password - users's password
   * @param {string} authToken - access token
   */
  static getQRCode2fa(authToken, password) {
    return ApiService.ApiCallBody(
      'POST',
      `${getAuthBaseURL()}/user/2fa-qr-code`,
      { password },
      authToken,
      'application/json'
    );
  }

  /**
   * Method for enabling 2fa
   * @param {string} code - The 6-digit code we got using secret or qr-code
   * @param {string} authToken - access token
   */
  static enable2fa(authToken, code) {
    return ApiService.ApiCallBody(
      'POST',
      `${getAuthBaseURL()}/user/2fa-enable`,
      { code },
      authToken,
      'application/json'
    );
  }

  /**
   * Method for validation user from 6-digit code (2fa)
   * @param {string} code - The code we got using google-authenticator
   * @param {string} authToken - access token
   */
  static verified2fa(authToken, code) {
    return ApiService.ApiCallBody(
      'POST',
      `${getAuthBaseURL()}/user/2fa-code-validate`,
      { code },
      authToken,
      'application/json'
    );
  }
}
