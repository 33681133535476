import omit from 'lodash/omit';
import unset from 'lodash/unset';
import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';

const initState = {
  languages: [],
  messages: {},
  shouldReload: true,
};

const resourcesReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_LANGUAGES_SUCCESS:
      return { ...state, languages: action.payload };

    case actionStrings.SEARCH_MESSAGES_SUCCESS:
      return { ...state, messages: action.payload };

    case actionStrings.MERGE_MESSAGE_SUCCESS: {
      const { messages } = state;
      const { code, locale, area } = action.payload;

      const mergeEntity = {
        ...messages,
        [code]: {
          ...messages?.[code],
          [locale]: {
            ...messages?.[code]?.[locale],
            [area]: action.payload,
          },
        },
      };
      return { ...state, messages: mergeEntity };
    }

    case actionStrings.REMOVE_RESOURCE_MESSAGES_BY_TAG_SUCCESS: {
      const { key, area } = action.payload;
      const { messages } = state;

      try {
        const changedMessage = JSON.parse(JSON.stringify(messages?.[key]));

        // For each local delete area
        Object.keys(changedMessage).forEach(locale => {
          unset(changedMessage, [locale, area]);
        });

        // If there is no area then delete whole message else delete only area part of it
        const newMessages = area
          ? { ...messages, [key]: { ...changedMessage } }
          : omit(messages, key);

        return { ...state, messages: newMessages };
      } catch (e) {
        return { ...state };
      }
    }

    case actionStrings.SHOULD_RELOAD_MESSAGES:
      return { ...state, shouldReload: action.payload };
    default:
      return state;
  }
};

export default filterActions(resourcesReducer, [
  actionStrings.FETCH_LANGUAGES_SUCCESS,
  actionStrings.SEARCH_MESSAGES_SUCCESS,
  actionStrings.MERGE_MESSAGE_SUCCESS,
  actionStrings.REMOVE_RESOURCE_MESSAGES_BY_TAG_SUCCESS,
  actionStrings.SHOULD_RELOAD_MESSAGES,
]);
