import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import emojiRegex from 'emoji-regex';
import durationPlugin from 'dayjs/plugin/duration';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst';
import inRange from 'lodash/inRange';
import isEmpty from 'lodash/isEmpty';
import Resizer from 'react-image-file-resizer';

import { longYearNumericMonthFmt } from '../constants/datetime';
import { getCSSVariable } from '../utils/cssVariables';
import responseOk from '../utils/responseOk';

// Since `isBetween` is dayjs plugin, it needs to extend the dayjs library
dayjs.extend(isBetween);
dayjs.extend(durationPlugin);

export const setTitle = branch => {
  document.title = get(branch, 'uiSettings.dashboardTitle', 'goUrban Dashboard');
};

export const setFavicon = branch => {
  const link = document.querySelector("link[rel*='icon']");
  const href = `${process.env.PUBLIC_URL}/favicon.png`;
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = get(branch, 'uiSettings.faviconUrl', href);

  document.head.removeChild(link);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const setGoogleAnalyticsTrackingCode = () => {
  // Register gtag script
  const gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}`;

  // Custom js script
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}');
  `;

  // Append scripts to <head> element
  document.getElementsByTagName('head')[0].appendChild(gtagScript);
  document.getElementsByTagName('head')[0].appendChild(script);
};

export function setSelectedBranchId(branch) {
  localStorage.setItem('selected_branch_id', branch.id);
  setTitle(branch);
  setFavicon(branch);
  setGoogleAnalyticsTrackingCode();
}

/** `util` function used to get selected branch id
 * @param {Array} branches - list of all available branches
 * @returns {number} - current selected branch id
 */
export const getSelectedBranchId = () => JSON.parse(localStorage.getItem('selected_branch_id'));

export function durationToDate(duration) {
  return JSON.stringify(duration * 1000 + new Date().getTime());
}

export function dateToDuration(date) {
  const then = new Date(date);
  const now = new Date();
  return Math.abs(then - now);
}

export function millisecondsToHoursMinutes(ms) {
  let s = Math.floor(ms / 1000);
  let m = Math.floor(s / 60);
  s %= 60;
  let h = Math.floor(m / 60);
  m %= 60;
  const d = Math.floor(h / 24);
  h %= 24;
  return {
    d,
    h,
    m,
    s,
  };
}

// Get month name by month num
export const getMonthName = month => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[month];
};

export function timeDifference(givenTime) {
  // eslint-disable-next-line no-param-reassign
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => (number > 1 ? 's' : '');
  const number = num => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const month = getMonthName(givenTime.getUTCMonth());
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

/**
 * Method for extracting info from `Duration` string.
 * In the pattern, T is the time designator,
 * so the part that follows defines a duration specified in hours, minutes and seconds.
 * Period is PnYnMnD, where n defines the number of years, months or days present within the period.
 * This means that P1Y2M3D defines a period of 1 year, 2 months, and 3 days.
 * The 'P' in the pattern is the period designator,
 * which tells us that the following format represents a period.
 * @param {string} duration string param for `totalDuration` of rental.
 * @param {boolean} HHmmSS boolean param for type of extraction
 */
export const extractDuration = (duration = '', HHmmSS = false) => {
  if (!isEmpty(duration)) {
    const hoursRegex = /[0-9]{1,4}[H]/g;
    const minutesRegex = /[0-9]{1,2}[M]/g;
    const secondsRegex = /[0-9]{1,2}[S]/g;
    const hours = duration.match(hoursRegex);
    const minutes = duration.match(minutesRegex);
    const seconds = duration.match(secondsRegex);
    if (!HHmmSS) {
      return `${hours ? `${hours[0].slice(0, -1)}h ` : ''}
      ${minutes ? `${minutes[0].slice(0, -1)}m ` : ''}
      ${seconds ? `${seconds[0].slice(0, -1)}s ` : ''}`;
    }
    let hoursExtracted = hours ? Number(hours[0].slice(0, -1)) : 0;
    let minutesExtracted = minutes ? Number(minutes[0].slice(0, -1)) : 0;
    let secondsExtracted = seconds ? Number(seconds[0].slice(0, -1)) : 0;
    hoursExtracted = inRange(hoursExtracted, 0, 10) ? `0${hoursExtracted}` : hoursExtracted;
    minutesExtracted = inRange(minutesExtracted, 0, 10) ? `0${minutesExtracted}` : minutesExtracted;
    secondsExtracted = inRange(secondsExtracted, 0, 10) ? `0${secondsExtracted}` : secondsExtracted;
    return `${hoursExtracted}:${minutesExtracted}:${secondsExtracted}`;
  }
  return duration;
};

export const formatEntities = entities =>
  entities && Array.isArray(entities)
    ? entities
    : Object.keys(entities).reduce((acc, tally) => {
        acc.push(entities[tally]);
        return acc;
      }, []);

export const getInitials = (name, email) => {
  let initials = name ? name.match(/\b\w/g) || [] : [email.charAt(0), email.charAt(1)];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

// timeString is in format hh:mm:ss
export const timeToSeconds = timeString => {
  const timeArr = timeString.split(':');
  return Math.round(
    parseFloat(timeArr[0]) * 3600 + parseFloat(timeArr[1]) * 60 + parseFloat(timeArr[2])
  );
};

export const getObjectFromArray = (arr, value, arrKey = 'children', valueKey = 'id') => {
  let obj = {};
  const iterator = item => {
    // Recursive function
    if (item[valueKey] === value) {
      obj = item;
      return true;
    }
    // Recutsively call iterator if arrKey is valid
    return Array.isArray(item[arrKey]) && item[arrKey].some(iterator);
  };
  arr.some(iterator);
  return obj;
};

export const extractUserName = user => {
  if (!user) {
    return '-';
  }
  return `${user.firstName || '-'} ${user.lastName || ''}`;
};

export const validPasswordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g;

// timeString is in format `days hh:mm:ss`
export const convertDHMSToMinutes = (timeString = '') => {
  let days = null;
  let time = null;
  if (timeString.match('days')) {
    // Check if timeString has days
    days = timeString.split('days')[0].trim();
    time = timeString.split('days')[1].replace(/[ ,]/g, '');
  } else if (timeString.match('day')) {
    // Check if timeString has day
    days = timeString.split('day')[0].trim();
    time = timeString.split('day')[1].replace(/[ ,]/g, '');
  } else {
    time = timeString;
  }

  // Calculate minutes
  const result = (days ? parseInt(days, 10) * 24 * 60 : 0) + dayjs.duration(time).asMinutes();
  return Math.round(result);
};

/**
 * Method used for removing duplicate objects from array by some key.
 * @param {Array} arr - array of objects.
 * @param {key} key - property name by which duplicaes will be removed.
 * @returns {Array} - clean array without duplicates and falsy items
 */
export const removeDuplicateObjectsFromArray = (arr = [], key = 'id') =>
  Array.from(new Set(arr.map(a => a[key])))
    .map((propName = [key]) => arr.find(a => a[key] === propName))
    .filter(Boolean);

/**
 * Method to resolve children of branch.
 * @param {object} branchId - current branch.
 * @param {Array} branches - array of all branches.
 * @returns {Array} children - only `array` of all children.
 */
export const childrenOfCurrentBranch = (branches = [], allBranches) => {
  const returnBranches = [];
  branches.forEach(brnch => {
    const childrenIds = brnch?.children;
    returnBranches.push(brnch);
    if (childrenIds.length) {
      const childrenOfBranch = allBranches.filter(b => childrenIds.includes(b.id));
      returnBranches.push(...childrenOfCurrentBranch(childrenOfBranch, allBranches));
    }
  });

  return returnBranches;
};

/**
 * Method that is making branches to array of objects.
 * @param {Array} branches - array of branches, modified like `tree` of branches.
 * @param {Number} parentId - id of parent to be added to branch.
 */
export const branchesToArrayForState = (branches = [], parentId, parentName) => {
  const sortedBranches = branches.sort((b1, b2) => {
    const bName1 = b1?.name.toLowerCase();
    const bName2 = b2?.name.toLowerCase();
    if (bName1 > bName2) return 1;
    if (bName1 < bName2) return -1;
    return 0;
  });
  const returnBranches = [];
  sortedBranches.forEach(branch => {
    const children = get(branch, 'children', []);
    returnBranches.push({ ...branch, parentId, parentName, children: children.map(c => c.id) });
    if (children.length > 0) {
      returnBranches.push(...branchesToArrayForState(children, branch?.id, branch?.name));
    }
  });
  return returnBranches;
};

/**
 * Helper method for resolving currencies of all branches.
 * @param {Array} branches - array of the branches in the system.
 * @returns {Array} array of all branches in the system
 */
export const resolveCurrenciesForBranches = (branches = []) => {
  return branches.map(branch => {
    if (branch?.billable) return { ...branch };
    return {
      ...branch,
      currency: branches.find(({ id }) => id === branch?.billableBranchId)?.currency,
    };
  });
};

/**
 * Some analytics page need branch data like (for all children):
 * {... , `Austria Staging`: [Austria Staging.id, idChildBranch1, idChildBranch2....] , ...}
 * @param {object} selectedBranch - Current selected branch from state.
 * @param {array} allBranches - All branches for current env from state.
 */
export const findAllChildrenForSelectedBranch = (selectedBranch, allBranches) => {
  if (isEmpty(selectedBranch) || !allBranches.length) return [];

  const currentSelectedBranch = allBranches.find(b => b.id === selectedBranch.id);
  const childrenIdsOfSelectedBranch = currentSelectedBranch?.children || [];

  // All children branches of `selectedBranch` with current selected branch
  const branches = [
    ...allBranches.filter(b => childrenIdsOfSelectedBranch.includes(b.id)),
    currentSelectedBranch,
  ];

  // Result is array of children for selectedBranch. Result will containt id of selectedBranch as well
  const result = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < branches.length; i++) {
    const childrenIdsOfBranch = allBranches.find(b => b?.id === branches[i]?.id)?.children || [];
    const childrenObjectsOfBranch = allBranches.filter(b => childrenIdsOfBranch.includes(b?.id));
    // Step by step we are looking for children for each child of `selectedBrancb`
    const allChildren = [
      ...childrenOfCurrentBranch(childrenObjectsOfBranch, allBranches).map(b => b?.id),
      branches[i].id,
    ];
    result[branches[i]?.name] = allChildren;
  }

  return result;
};

/**
 * Calculates the duration between two dates and formats the result to a string
 * which includes the number of days, hours, minutes and seconds between the two dates.
 *
 * @param {string} date1 The first date.
 * @param {string} date2 The second date.
 */
export const getDurationBetween = (date1, date2 = '') => {
  const start = date1 ? dayjs(date1) : dayjs();
  const end = date2 ? dayjs(date2) : dayjs();

  const format = (value, unit) => (value ? value + (value > 1 ? ` ${unit}s ` : ` ${unit} `) : '');
  const { d, h, m, s } = millisecondsToHoursMinutes(Math.abs(start.diff(end)));

  return `${format(d, 'day')}${format(h, 'hour')}${format(m, 'minute')}${format(
    s,
    'second'
  )}`.trim();
};

/**
 * Calculates the duration between two Date.
 * @param {String} date1 The first data.
 * @param {String} date2 The second data.
 * @returns date format as eg. `12 H 33 M 16 S`
 */
export const getDurationTimeBetween = (date1, date2 = '') => {
  const start = date1 ? dayjs(date1) : dayjs();
  const end = date2 ? dayjs(date2) : dayjs();

  const format = (value, unit) => (value ? `${value} ${unit} ` : '');
  const { d, h, m } = millisecondsToHoursMinutes(Math.abs(start.diff(end)));

  return `${format(d, 'D')}${format(h, 'H')}${format(m, 'M')}`.trim();
};

/**
 * Format time
 * @param {String} data //Time in format{HH:mm:ss}
 * @returns time format 12 H 33 M
 */
export const formatTime = data => {
  if (!data) return '';
  const dateValue = dayjs(data, 'HH:mm');

  const format = (value, unit) => (value ? `${value} ${unit} ` : '');
  const h = dateValue.hour();
  const m = dateValue.minute();

  return `${format(h, 'H')}${format(m, 'M')}`.trim();
};

/**
 * Method for sorting data by key
 * @param {string} key - used for data sort.
 * @param {Array} data - data that is sort.
 * @param {Function} transformer - transformer for value at specified key.
 */
export const sortDataByKey = (key, data = [], transformer = undefined) => {
  if (Array.isArray(data)) {
    if (transformer) return data.sort((a, b) => transformer(b[key]) - transformer(a[key]));
    return data.sort((a, b) => b[key] - a[key]);
  }
  return data;
};

/**
 * Method for filtering zero values in the array
 * @param {string} key - for the filtering
 * @param {Array} data - data to be filtered
 * @returns {Array} array of the filtered values
 */
export const filterZeroValues = (key, data = []) => data.filter(item => item[key]);

/**
 * Returns the number of days in month for the given date.
 *
 * @param {string} date Data.
 */
export const getNumberOfDaysInMonth = date => dayjs(date, longYearNumericMonthFmt).daysInMonth();

/**
 * Returns the string withhout any whitespaces for given value.
 *
 * @param {string} value.
 */
export const removeAllWhiteSpacesFromString = (value = '') => value.replace(/ /g, '');

/**
 * Returns the integer value calculated as percentage out of total.
 *
 * @param {number} value - numeric value used to calculate percentage.
 * @param {number} total - total amount of items.
 */
export const calculatePercentage = (value, total) => (value / total) * 100;

/**
 * Returns the integer value calculated by all key values in the object.
 *
 * @param {object} obj.
 */
export const calculateTotalObjectValues = (obj = {}) =>
  Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);

/**
 * Helper method to extract duration from `duration` string.
 * @param {string} durationStr - current string from duration.
 * @returns {object} object that contains `h`, `m`, `s`.
 */
export const calculateDuration = (durationStr = '') => {
  if (!durationStr) return { h: 0, m: 0, s: 0 };
  const duration = durationStr.replace(/-/g, '');
  const hoursRegex = /[1-9]\d*(\.\d+)?[Hh]/g;
  const minutesRegex = /[1-9]\d*(\.\d+)?[Mm]/g;
  const secondRegex = /[1-9]\d*(\.\d+)?[Ss]/g;

  const hours = duration.match(hoursRegex);
  const minutes = duration.match(minutesRegex);
  const seconds = duration.match(secondRegex);

  const h = hours ? Number(hours[0].slice(0, -1)) : 0;
  const m = minutes ? Number(minutes[0].slice(0, -1)) : 0;
  const s = seconds ? Number(seconds[0].slice(0, -1)) : 0;

  return { h, m, s };
};

/**
 * Helper method to replace URL.
 * @param {String} entity - entity part from url.
 * @param {String} id - id part from url.
 */
export const replaceUrl = (entity, id) => {
  window.history.replaceState(null, null, `/dashboard/${entity}/${id}`);
};

/**
 * Helper method to replace URL.
 * @param {String} entity - first entity part from url.
 * @param {String} entity - second entity part from url.
 * @param {String} id - id part from url.
 */
export const replaceEntities = (firstEntity, secondEntity, id) => {
  window.history.replaceState(null, null, `/dashboard/${firstEntity}/${secondEntity}/${id}`);
};

/**
 * Helper method uppercase and replace spaces with underscores.
 * @param {String} status - status that we want to modify.
 * @returns {String} UPPERCASE and underscored label.
 */
export const setStatusLabel = status => upperCase(status).replace(/_/g, ' ');

/**
 * Helper method which set all words capitalized and remove spaces.
 * @param {String} status - status that we want to modify.
 * @returns {String} Capitalized and remove spaces.
 */
export const capitalizeWords = str =>
  str.replace(/(?:^|\s)\S/g, a => a.toUpperCase()).replace(/ /g, '');

/**
 * Helper method for preparing code as name.
 * @param {string} code - code in format as `FRONT_LIGHT`
 * @returns {string} resolved code for eg. `Front Light`
 */
export const prepareCodeAsName = (code = '') =>
  code
    .toLowerCase()
    .split(/_{1,}/g)
    .map(c => upperFirst(c))
    .join(' ');

/**
 * Helper function for preparing tag name.
 * @param {string} value - value which name is prepared.
 * @returns {string} prepared name eg. 'You are welcome' => `youAreWelcome`
 */
export const prepareTagName = (value = '') => {
  return value
    .split(/\s+/)
    .map((item, index) => (index > 0 ? capitalize(item) : item.toLowerCase()))
    .join('');
};

/**
 * Helper method for validating slot time in branch-quickview - opening-hours-pane.
 * @param {{ time: string; state: string }} updateSlot - slot to be updated.
 * @param {{ time: string; state: string }} oppositeSlot - opposite slot.
 * @param { string } day - chosen day.
 * @returns {boolean} indicator is slot valid.
 */
export const validateHours = (updateSlot, oppositeSlot, day, openingTimeEntries) => {
  /** `initalValues` for first slot */
  const initialValuesForFirstSlot = openingTimeEntries.filter(
    v => v.day === day && v.slotNumber === 1
  );

  /** `initialValues` for second slot */
  const initialValuesForSecondSlot = openingTimeEntries.filter(
    v => v.day === day && v.slotNumber === 2
  );

  let firstSlideFirstTime = '';
  let firstSlideSecondTime = '';
  let secondSlideFirstTime = '';
  let secondSlideSecondTime = '';

  if (get(initialValuesForFirstSlot[0], 'state') === 'CLOSED')
    [firstSlideSecondTime] = initialValuesForFirstSlot;
  if (get(initialValuesForFirstSlot[0], 'state') === 'OPEN') {
    [firstSlideFirstTime, firstSlideSecondTime] = initialValuesForFirstSlot;
  }
  if (get(initialValuesForSecondSlot[0], 'state') === 'CLOSED')
    [secondSlideSecondTime] = initialValuesForSecondSlot;
  if (get(initialValuesForSecondSlot[0], 'state') === 'OPEN') {
    [secondSlideFirstTime, secondSlideSecondTime] = initialValuesForSecondSlot;
  }

  if (get(updateSlot, 'slotNumber') === 1 && get(updateSlot, 'state') === 'CLOSED')
    firstSlideSecondTime = updateSlot;
  if (get(oppositeSlot, 'slotNumber') === 1 && get(oppositeSlot, 'state') === 'CLOSED')
    firstSlideSecondTime = oppositeSlot;
  if (get(updateSlot, 'slotNumber') === 2 && get(updateSlot, 'state') === 'OPEN')
    secondSlideFirstTime = updateSlot;
  if (get(oppositeSlot, 'slotNumber') === 2 && get(oppositeSlot, 'state') === 'OPEN')
    secondSlideFirstTime = oppositeSlot;

  if (get(updateSlot, 'slotNumber') === 1 && get(updateSlot, 'state') === 'OPEN')
    firstSlideFirstTime = updateSlot;
  if (get(oppositeSlot, 'slotNumber') === 1 && get(oppositeSlot, 'state') === 'OPEN')
    firstSlideFirstTime = oppositeSlot;
  if (get(updateSlot, 'slotNumber') === 2 && get(updateSlot, 'state') === 'CLOSED')
    secondSlideSecondTime = updateSlot;
  if (get(oppositeSlot, 'slotNumber') === 2 && get(oppositeSlot, 'state') === 'CLOSED')
    secondSlideSecondTime = oppositeSlot;

  if (firstSlideFirstTime && firstSlideSecondTime)
    if (firstSlideFirstTime.time >= firstSlideSecondTime.time) return false;
  if (secondSlideFirstTime && secondSlideSecondTime)
    if (secondSlideFirstTime.time >= secondSlideSecondTime.time) return false;

  if (
    get(firstSlideFirstTime, 'time', '1') === get(firstSlideSecondTime, 'time', '2') ||
    get(secondSlideFirstTime, 'time', '1') === get(secondSlideSecondTime, 'time', '2') ||
    get(firstSlideFirstTime, 'time', '1') === get(secondSlideFirstTime, 'time', '2') ||
    get(firstSlideFirstTime, 'time', '1') === get(secondSlideSecondTime, 'time', '2') ||
    get(firstSlideSecondTime, 'time', '1') === get(secondSlideFirstTime, 'time', '2') ||
    get(firstSlideSecondTime, 'time', '1') === get(secondSlideSecondTime, 'time', '2')
  )
    return false;

  if (
    (!firstSlideFirstTime && !firstSlideSecondTime) ||
    (!secondSlideFirstTime && !secondSlideSecondTime)
  )
    return true;

  if (
    get(secondSlideFirstTime, 'time', '1') >= get(secondSlideSecondTime, 'time', '2') &&
    get(secondSlideSecondTime, 'time', '1') >= get(firstSlideSecondTime, 'time', '2')
  )
    return false;

  if (secondSlideFirstTime && secondSlideSecondTime && firstSlideFirstTime && firstSlideSecondTime)
    if (
      (dayjs(get(secondSlideFirstTime, 'time'), 'HH:mm:ss').isBetween(
        dayjs(get(firstSlideFirstTime, 'time'), 'HH:mm:ss'),
        dayjs(get(firstSlideSecondTime, 'time'), 'HH:mm:ss')
      ) &&
        dayjs(get(secondSlideSecondTime, 'time'), 'HH:mm:ss').isBetween(
          dayjs(get(firstSlideFirstTime, 'time'), 'HH:mm:ss'),
          dayjs(get(firstSlideSecondTime, 'time'), 'HH:mm:ss')
        )) ||
      (dayjs(get(firstSlideFirstTime, 'time'), 'HH:mm:ss').isBetween(
        dayjs(get(secondSlideFirstTime, 'time'), 'HH:mm:ss'),
        dayjs(get(secondSlideSecondTime, 'time'), 'HH:mm:ss')
      ) &&
        dayjs(get(firstSlideSecondTime, 'time'), 'HH:mm:ss').isBetween(
          dayjs(get(secondSlideFirstTime, 'time'), 'HH:mm:ss'),
          dayjs(get(secondSlideSecondTime, 'time'), 'HH:mm:ss')
        ))
    )
      return false;

  if (
    firstSlideFirstTime &&
    firstSlideSecondTime &&
    get(updateSlot, 'slotNumber', 0) === 2 &&
    get(updateSlot, 'state', '') === 'OPEN'
  ) {
    return !dayjs(secondSlideFirstTime.time, 'HH:mm:ss').isBetween(
      dayjs(firstSlideFirstTime.time, 'HH:mm:ss'),
      dayjs(firstSlideSecondTime.time, 'HH:mm:ss')
    );
  }
  if (
    firstSlideFirstTime &&
    firstSlideSecondTime &&
    get(updateSlot, 'slotNumber', 0) === 2 &&
    get(updateSlot, 'state', '') === 'CLOSED'
  ) {
    return !dayjs(secondSlideSecondTime.time, 'HH:mm:ss').isBetween(
      dayjs(firstSlideFirstTime.time, 'HH:mm:ss'),
      dayjs(firstSlideSecondTime.time, 'HH:mm:ss')
    );
  }
  if (
    secondSlideFirstTime &&
    secondSlideSecondTime &&
    get(updateSlot, 'slotNumber', 0) === 1 &&
    get(updateSlot, 'state', '') === 'OPEN'
  ) {
    return !dayjs(firstSlideFirstTime.time, 'HH:mm:ss').isBetween(
      dayjs(secondSlideFirstTime.time, 'HH:mm:ss'),
      dayjs(secondSlideSecondTime.time, 'HH:mm:ss')
    );
  }
  if (
    secondSlideFirstTime &&
    secondSlideSecondTime &&
    get(updateSlot, 'slotNumber', 0) === 1 &&
    get(updateSlot, 'state', '') === 'CLOSED'
  ) {
    return !dayjs(firstSlideSecondTime.time, 'HH:mm:ss').isBetween(
      dayjs(secondSlideFirstTime.time, 'HH:mm:ss'),
      dayjs(secondSlideSecondTime.time, 'HH:mm:ss')
    );
  }

  return true;
};

/**
 * Helper method for generating alpha numeric code with length and custom source.
 * @param {number} len - length of code we want.
 * @param {string} chars - source string for code.
 * @returns {string} generated n-length code.
 */
export const generateAlphaNumericCode = (
  len = 10,
  chars = '0123456789abcdefghijklmnopqrstuvwxyz'
) => {
  let code = '';

  for (let i = len; i > 0; i -= 1) code += chars[Math.floor(Math.random() * chars.length)];
  return code;
};

/**
 * Helper method for convert Array to Object
 * @param {array} array - array which we converting
 * @param {string} key - parameter from object which be key in new object.
 * @returns {object} - converted object
 */
export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

/**
 * Helper method for obtaining permissions from access token
 * @param {array} accessToken - access token from state
 * @param {string} permission - type of permission which there is(not) in access token
 * @returns {boolean}
 */
export const getPermisionFromAccessToken = (accessToken, permission) => {
  const scopeFromDecodedToken = get(JSON.parse(atob(accessToken.split('.')[1])), 'scope', []);
  return !!scopeFromDecodedToken.find(v => v === permission);
};

/**
 * Helper method for validate the coordinates array
 * @param {array} coordinates - coordinates
 * @returns {Array} - valid coordinates values
 */
export const validateCoordinates = (coordinates = []) => {
  // Check if coordinates array has 2 items
  if (coordinates.length !== 2) return null;
  // Longitude coordinate
  const lng = coordinates[0];
  // Latitude coordinate
  const lat = coordinates[1];
  // Longitude range goes between -180 and 180
  if (!lng || (lng >= -180 && lng >= 180)) return null;
  // Latitude range goes between -90 and 90
  if (!lat || (lat >= -90 && lat >= 90)) return null;

  return coordinates;
};

/**
 * Args for styled scrollbar
 * @typedef {Object} StyledScrollbarArgs
 * @property {string} background - background color
 * @property {string} hoverBackground - background color when hovered
 * @property {string} width - scrollbar width
 * @property {string} height - scrollbar height
 */

/**
 * Used for styled components
 * Exports pseudo selector for styled scrollbar.
 * @param {StyledScrollbarArgs} args - object containing args for styled scrollbar
 * @returns {string} with selectors for the scrollbar
 */
export const styledScrollbar = ({
  background = getCSSVariable('--gray-5'),
  hoverBackground = getCSSVariable('--gray-6'),
  width,
  height,
} = {}) => {
  return `
  ::-webkit-scrollbar {
    ${height ? `height: ${height};` : ''}
    ${width ? `width: ${width};` : ''}
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${background};
    border-radius: 100px;
    height: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${hoverBackground};
  }
  `;
};

/**
 * Used for generating translated filters on table columns
 * @param {Array} filters - list of tag labels
 * @param {Function} formatMessage - intl callback function
 * @param {string} translationPrefix - if translation has other prefix than default
 * @returns {Array} objects with translated text and value
 */
export const getColumnFilters = (filters = [], formatMessage, translationPrefix = 'status.tag') =>
  filters.map(cat => ({
    text: formatMessage({ id: `${translationPrefix}.${cat.toLowerCase()}` }),
    value: cat,
  }));

/** `promiseFn` for fetching map-box address by coordinates */
export const getMapAddress = async ({ lng, lat }) => {
  const MAPBOX_API_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

  try {
    const response = await fetch(
      `${MAPBOX_API_URL + lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESSTOKEN}`
    );
    if (responseOk(response)) {
      const result = await response.json();

      // eslint-disable-next-line camelcase
      return result?.features?.[0]?.place_name || '';
    }
  } catch (e) {
    console.error(e);
  }
  return {};
};

// Image resizing
export const imageResizer = async (file, w = 38, h = 38, imgType = 'PNG') => {
  const resizeFile = () =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        w,
        h,
        imgType,
        100,
        0,
        uri => {
          resolve(uri);
        },
        'file'
      );
    });

  try {
    return await resizeFile(file);
  } catch (err) {
    console.error(err);
  }
  return null;
};

const emojiReg = emojiRegex();
/**
 * `removeEmoji` is the util function used to remove all emojis from the text
 * @param {string} str - text to remove all emojis from it
 * @returns {string} clean text
 */
export const removeEmoji = (str = '') => str.replace(emojiReg, '');
