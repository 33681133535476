import antdFr from 'antd/lib/locale-provider/fr_FR';
import enMessages from '../locales/fr_FR.json';

const FrLang = {
  messages: {
    ...enMessages,
  },
  antd: antdFr,
  locale: 'fr-FR',
};
export default FrLang;
