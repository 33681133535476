import React from 'react';
import styled from 'styled-components';

import ThreeDots from './ThreeDots';

const Container = styled.div`
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageSkeleton = () => {
  return (
    <Container>
      <ThreeDots />
    </Container>
  );
};

export default PageSkeleton;
