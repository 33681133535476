import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const intlType = PropTypes.shape({
  locale: PropTypes.string,
  formatMessage: PropTypes.func,
  messages: PropTypes.object,
  defaultLocale: PropTypes.string,
  textComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]),
});
