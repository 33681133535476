/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromParams, createStringFromFilter } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/voucherables`;

class VoucherableService {
  /**
   * Method for getting voucher constraints.
   * @param {string} authToken - access token.
   * @param {object} branchId - current branch selected id.
   * @param {object} params - query params.
   */
  static getVoucherables(authToken, branchId, params) {
    // Generate query params from filters param
    const filtersString = createStringFromFilter(get(params, 'filters', []));
    // Generate query params from params except filters
    const paramString = createStringFromParams({ ...omit(params, 'filters') });
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branchId}${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating vocuher constraints.
   * @param {string} authToken - access token.
   * @param {object} data - data send for creation.
   */
  static createVoucherablesConstraint(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json');
  }

  /**
   * Method for updating voucher constraint.
   * @param {string} authToken - access token.
   * @param {object} id - voucher id.
   * @param {object} data - query params.
   */
  static updateVoucherablesConstraint(AuthToken, id, data) {
    /* code, id and branchId is not editable */
    const paramsData = {
      ...omit(data, ['code', 'id', 'branchId']),
      // this is needed so the backend doesn't overwrite the raw values with the new name, description
      name: data.rawName,
      description: data.rawDescription,
      invoiceDescription: data.rawInvoiceDescription,
    };
    return ApiService.ApiCallBody(
      'PUT',
      `${BASE_URL}/${id}`,
      paramsData,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method used for `exact` search for voucherable by code
   * @param {string} authToken - authentication bearer token.
   * @param {string} code - voucherable code.
   */
  static getVoucherableByCode(authToken, code) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/by-code/${code}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method used to fetch voucherable by id
   * @param {string} authToken - authentication bearer token.
   * @param {string} id - voucherable id.
   */
  static getVoucherableById(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json');
  }

  /**
   * Method used to delete voucherable.
   * @param {string} authToken - Access token for identification.
   * @param {number} id - id of voucherable for being deleted
   */
  static deleteVoucherable(authToken, id) {
    return ApiService.ApiCallAuth('DELETE', `${BASE_URL}/${id}`, authToken, 'application/json');
  }
}
export default VoucherableService;
