import { actionPayload } from '../common/commonActions';

const actionStrings = {
  FETCH_PLUGINS: 'FETCH_PLUGINS',
  STORE_PLUGINS: 'STORE_PLUGINS',
  UPDATE_PLUGIN_ACTIVITY: 'UPDATE_PLUGIN_ACTIVITY',
  UPDATE_PLUGIN: 'UPDATE_PLUGIN',
};

const actions = {
  fetchPlugins: actionPayload(actionStrings.FETCH_PLUGINS),
  storePlugins: actionPayload(actionStrings.STORE_PLUGINS),
  updatePluginActivity: actionPayload(actionStrings.UPDATE_PLUGIN_ACTIVITY),
  updatePlugin: actionPayload(actionStrings.UPDATE_PLUGIN),
};

export { actions, actionStrings };
