import { createSelector } from 'reselect';
import get from 'lodash/get';

const getMessagesState = state => state.Resources.messages || [];
const getShouldReloadState = state => state.Resources.shouldReload || false;
const getLanguagesState = state => state.Resources.languages || [];
/**
 * Selector used to get all messages in the system.
 * @param {object} state - Redux state object.
 */
export const getMessages = createSelector(getMessagesState, messages => messages);

/**
 * Selector used to get all languages in the system.
 * @param {object} state - Redux state object.
 */
export const getLanguages = createSelector(getLanguagesState, languages => languages);

/**
 * Selector used to get shouldReload state for messages.
 * @param {object} state - Redux state object.
 */
export const getShouldReloadMessages = createSelector(getShouldReloadState, value => value);

/**
 * Selector used to get all message translation ids by tag.
 * @param {object} state - Redux state object.
 */
export const getMessageIdsByKey = (state, key, area = '') => {
  const message = get(state.Resources, ['messages', key], {});

  const ids = [];

  Object.keys(message).forEach(locale => {
    Object.keys(message[locale]).forEach(msgArea => {
      if (msgArea === area || !area) ids.push(message?.[locale]?.[msgArea]?.id);
    });
  });

  return ids.filter(Boolean);
};
