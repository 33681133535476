export const actionStrings = {
  FETCH_ALL_BRANCHES_SUCCESS: 'FETCH_ALL_BRANCHES_SUCCESS',
  UPDATE_BRANCH: 'UPDATE_BRANCH',
};

export const actions = {
  fetchAllBranchesSuccess: branches => ({
    type: actionStrings.FETCH_ALL_BRANCHES_SUCCESS,
    payload: branches,
  }),
  updateBranch: newBranch => {
    return { type: actionStrings.UPDATE_BRANCH, payload: newBranch };
  },
};

export default actions;
