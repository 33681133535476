import { useAsync } from 'react-async';
import { message } from 'antd';

import responseOk from '../utils/responseOk';

import useAuthToken from './useAuthToken';

import SettingsService from '../services/coreAPI/SettingsService';

/**
 * Param object for fetchSettings function.
 * @typedef {Object} ArgsObject
 * @property {string} authToken - authentication bearer token.
 * @property {Function} formatMessage - `intl` function used for formatting messages.
 * @property {string} type - setting `type` for fetching.
 * @property {string} key - setting key
 * @property {string} area - setting area
 */

/**
 * Promise function used for fetching setting.
 * Using pagination if there's more than `500` per size.
 * @param {ArgsObject} args args used for setting function to fetch
 * @returns {Array} array of fetched settings.
 */
const fetchSettings = async ({ authToken, formatMessage, type, key, area }) => {
  try {
    const response = await SettingsService.fetchSettings(authToken, {
      page: 0,
      size: 500,
      settingValueType: type,
      key,
      area,
    });
    const pages = response.headers.get('X-Pagination-TotalPages');
    const requests = [response];

    for (let i = 1; i < pages; i += 1) {
      requests.push(
        SettingsService.fetchSettings(authToken, {
          page: i,
          size: 500,
          settingValueType: type,
        })
      );
    }

    /** `list` of all paginated responses */
    const responses = await Promise.all(requests);

    // if some response is not okay return empty array.
    if (responses.some(res => !responseOk(res))) return [];

    const json = await Promise.all(responses.map(res => res.json()));
    // concat all arrays into one
    return json.reduce((prev, curr) => [...prev, ...curr], []);
  } catch (error) {
    message.error(formatMessage({ id: 'fetch.fail.settings.all' }));
  }
  // return empty array if error happened while fetching
  return [];
};

/** `hook` for fetching settings depending on type */
const useFetchSettings = ({
  type = '',
  formatMessage,
  watch = null,
  onResolve = () => {},
  key = '',
  area = '',
}) => {
  /** `authentication` bearer token */
  const authToken = useAuthToken();

  const settingsState = useAsync({
    promiseFn: fetchSettings,
    authToken,
    formatMessage,
    type,
    key,
    area,
    watch,
    onResolve,
  });

  return settingsState;
};

export default useFetchSettings;
