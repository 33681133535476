import styled from 'styled-components';
import { Select } from 'antd';

export const TabsWrapper = styled.div`
  .ant-tabs-bar.ant-tabs-top-bar {
    background: #ffff;
    padding-right: 20px;
    margin-bottom: 0;
  }

  .period-select {
    width: 8rem;
    margin-right: 10px;
  }

  .customers_rentals_table_wrapper {
    background: #ffff;
    padding: 2em;
    margin-top: 1em !important;
  }

  .graph_wrapper {
    .title {
      text-transform: lowercase;
    }
    .title::first-letter {
      text-transform: uppercase;
    }
    background: #ffff;
    padding: 2em;
  }
`;

export const CountBoxWrapper = styled.div`
  padding: 10px 20px;
  background: ${props => props.color};
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .title {
    font-size: 22px;
    width: 100%;
    color: #ffff;
    font-weight: bold;
  }

  .description {
    width: 100%;
    color: #ffff;
  }
`;

export const AnalyticsTabWrapper = styled.div`
  width: 100%;
  padding-left: ${props => (props.elementWidth > 992 ? `${props.elementWidth / 150}em` : '0em')};
  padding-right: ${props => (props.elementWidth > 992 ? `${props.elementWidth / 200}em` : '0em')};

  .count-box-wrapper:last-child {
    padding-right: 0px !important;
  }
  .count-box-wrapper:first-child {
    padding-left: 0px !important;
  }
`;

export const AnalyticsPageWrapper = styled.div`
  background-color: var(--gray-1);
`;

export const AnalyticsPageContainer = styled.div`
  padding: ${props => (props.$isMobileView ? '20px 0 0' : '30px')};
  min-height: calc(100vh - 64px + (2 * 30px));
`;

export const AnalyticsDateSelect = styled(Select)`
  &.ant-select {
    margin-right: 8px;
  }
`;

export const Header = styled.h2`
  font: var(--font-sb-4);
  margin-bottom: ${props => (props.$margin ? '25px' : '0px')};
`;
