import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/rentals/additions`;

class AdditionsService {
  /**
   * Method for fetching additions with `specified` params.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} _ - id of branch that is curently sent as param.
   * @param {object} params - object containing `params` and `filter` values.
   */
  static getAdditions(authToken, _, params) {
    // TODO Backend needs to accept array of enums insted of accepting strings one by one
    const filtersString = createStringFromFilter(params.filters);
    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${paramString}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for creating addition.
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - payload data as folllows: name, code and description.
   */
  static createAddition(authToken, data) {
    return ApiService.ApiCallBody('POST', `${BASE_URL}`, data, authToken, 'application/json');
  }

  /**
   * Method for updating addition.
   * @param {string} authToken - authentication bearer token.
   * @param {number} additionId - id of the addition we wish to update.
   * @param {object} data - payload data as folllows: name, code and description.
   */
  static updateAddition(authToken, additionId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${additionId}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Method for deleting addition.
   * @param {string} authToken - authentication bearer token.
   * @param {number} additionId - id of the addition we wish to update.
   */
  static deleteAddition(authToken, additionId) {
    return ApiService.ApiCallAuth(
      'DELETE',
      `${BASE_URL}/${additionId}`,
      authToken,
      'application/json'
    );
  }
}

export default AdditionsService;
