import { useDispatch } from 'react-redux';
import { actionStrings } from '../redux/app/actions';

/**
 * `useNotification` exposes two functions for displaying notifications,
 * `notifySuccess` and `notifyError`. Pass them title and body messages
 * to display both.
 */
const useNotification = () => {
  const dispatch = useDispatch();

  const notifySuccess = (title, body) =>
    dispatch({
      type: actionStrings.SUCCESS_NOTIFICATION,
      payload: { title, body },
    });

  const notifyError = (title, body) =>
    dispatch({
      type: actionStrings.ERROR_NOTIFICATION,
      payload: { title, body },
    });

  return { notifySuccess, notifyError };
};

export default useNotification;
