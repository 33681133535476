// TODO: figure out better naming convention for actions, make it standardized or generic
export const action = type => (entity, payload) => ({ type, entity, payload });
export const actionWithKey = type => (entity, key, payload) => ({
  type,
  entity,
  key,
  payload,
});
export const actionWithInvokerAction = type => (entity, invokerAction, payload) => ({
  type,
  entity,
  invokerAction,
  payload,
});

export const actionInvokerAction = type => invokerAction => ({ type, invokerAction });
export const actionInvokerActionPayload = type => (invokerAction, payload) => ({
  type,
  invokerAction,
  payload,
});

export const actionEntity = type => entity => ({ type, entity });
export const actionPayload =
  type =>
  (payload, onSuccess = null, onError = null) => ({ type, payload, onSuccess, onError });

export const actionId = type => id => ({ type, id });
export const actionPayloadId = type => (id, payload) => ({ type, id, payload });
