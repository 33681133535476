import { createSelector } from 'reselect';
import get from 'lodash/get';
import { availableEntities } from '../../redux-config';

const getAllQuickViewState = state => state.Quickview;
const getSelectedDamagesState = state => get(state.Quickview, 'damages', {});
const getQuickViewOfVehicleState = state => get(state, ['Quickview', 'vehicles']);
const getActiveQuickViewsState = state => get(state.Quickview, 'active', []);
const quickViewPayloadState = (state, entity) => get(state.Quickview, entity, {});
const isQuickViewActiveState = (state, entity) =>
  get(state.Quickview, 'active', []).includes(entity);
const getActiveEntityQuickviewIdState = (state, entity) => {
  // Vehicle categories is only different from other
  // this is edge case quickview
  if (entity === availableEntities.VEHICLE_CATEGORIES) {
    return state?.Quickview?.[entity]?.category?.id;
  }

  return (
    get(state, ['Quickview', entity, 'id']) ||
    (typeof get(state, ['Quickview', entity]) === 'string'
      ? +get(state, ['Quickview', entity])
      : get(state, ['Quickview', entity]))
  );
};

export const getQuickViewState = createSelector(getAllQuickViewState, quickview => quickview);
export const getSelectedDamages = createSelector(getSelectedDamagesState, damages => damages);
export const getQuickViewOfVehicle = createSelector(
  getQuickViewOfVehicleState,
  vehicleQuickview => vehicleQuickview
);

/**
 * Selector to get quick view payload from state.
 * @param {object} state - Redux state.
 * @param {string} entity - Available entity for quickview.
 * @returns {Object} payload data for provided entity.
 */
export const quickViewPayload = createSelector(quickViewPayloadState, payload => payload);

/**
 * Selector to get active quick view from state.
 * @param {object} state - Redux state.
 * @param {string} entity - Available entity for quickview.
 * @returns {Boolean} is quick view active.
 */
export const isQuickViewActive = createSelector(isQuickViewActiveState, isActive => isActive);

/**
 * Selector to get active quick view from state.
 * @param {object} state - Redux state.
 * @returns {object} quick view which is active.
 */
export const getActiveQuickViews = createSelector(
  getActiveQuickViewsState,
  activeQuickviews => activeQuickviews
);
/**
 * Helper method to get active quickview entity id.
 * @param {object} state - redux state object.
 * @param {string} entity - entity
 */
export const getActiveEntityQuickviewId = createSelector(
  getActiveEntityQuickviewIdState,
  entityId => entityId
);
