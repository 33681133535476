import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/issues`;
const BASE_INVOICE_URL = `${ApiService.BACKEND_URL}/back/invoices`;

class IssuesService {
  static getIssues(AuthToken, branch, params) {
    const filtersString = createStringFromFilter(params.filters);
    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const paramString = createStringFromParams(params);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${paramString}${filtersString}&includeChildBranches=true`,
      AuthToken,
      'application/json'
    );
  }

  static createIssue(AuthToken, branchId, data) {
    const body = {
      branchId,
      category: data.category || data.category !== '' ? data.category : 'FINE',
      state: data.state || data.state !== '' ? data.state : 'CREATED',
      description: data.description,
      title: data.title,
      rentalId: data.rentalId || '',
      vehicleId: data.vehicleId || '',
      bookingId: data.bookingId || '',
      accountId: data.userId || data.groupId || '',
      costCenterId: data.costCenterId || '',
    };
    return ApiService.ApiCallBody('POST', BASE_URL, body, AuthToken, 'application/json');
  }

  static getIssueDetails(AuthToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, AuthToken, 'application/json');
  }

  static createComment(AuthToken, issueId, state, text) {
    const data = {
      state: state || state !== '' ? state : 'CREATED',
      text,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${issueId}/comments`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static createUserInvoice(AuthToken, data) {
    const body = {
      title: data.data.title,
      description: data.data.description,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${data.issueId}/invoice/create`,
      body,
      AuthToken,
      'application/json'
    );
  }

  static billInvoice(AuthToken, issueId, { message }) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${issueId}/invoice/bill`,
      message ? { message } : {},
      AuthToken,
      'application/json'
    ).catch(e => {
      throw e;
    });
  }

  static createInvoiceLineItem(AuthToken, data) {
    const { lineItemTemplateId, description, netPrice, grossPrice, taxPercentage } = data.data;
    const body = { lineItemTemplateId, description, netPrice, grossPrice, taxPercentage };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${data.issueId}/invoice/add`,
      body,
      AuthToken,
      'application/json'
    );
  }

  static getInvoiceLineItemTemplates(AuthToken) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_INVOICE_URL}/line-item-templates`,
      AuthToken,
      'application/json'
    );
  }

  static linkFileToComment(AuthToken, commentId, fileId) {
    const body = {
      id: fileId,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/comments/${commentId}/file`,
      body,
      AuthToken,
      'application/json'
    );
  }

  static downloadFile(AuthToken, data) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/comment/${data.commentId}/file/${data.fileId}`,
      AuthToken,
      'application/json'
    );
  }

  static downloadFileByUrl(url) {
    return ApiService.ApiCallNoAuth('GET', url);
  }

  /**
   * Update issue with a PATCH request for each key - value property.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the issue to update for.
   * @param {string} key - The key of the property we should change.
   * @param {any} value - The value for any of the properties values we're changing.
   */
  static updateIssue(authToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      authToken,
      'application/json'
    );
  }
}

export default IssuesService;
