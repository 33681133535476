import { all, takeEvery, call, put } from 'redux-saga/effects';

import actions, { actionStrings } from './actions';
import { actionStrings as entityActionStrings } from '../entities/actions';
import TaskService from '../../services/coreAPI/TasksService';
import { successNotification, getAuthToken } from '../common/commonSagas';
import { availableEntities } from '../../redux-config';
import { getBranch } from '../common/sagaHelpers';

function* createVehicleTask(action) {
  const { callback = () => {}, values = {} } = action?.entity;
  const {
    vehicleId,
    title,
    description,
    category,
    assignedToId,
    priority,
    vehicleCategoryId,
    damageId,
  } = values;

  const accessToken = yield call(getAuthToken);
  const { id: selectedBranchId } = yield call(getBranch);

  const response = yield call(
    TaskService.createVehicleTask,
    accessToken,
    vehicleId,
    title,
    category,
    description,
    selectedBranchId,
    assignedToId,
    priority,
    damageId,
    vehicleCategoryId
  );

  if (response) {
    yield put(actions.storeVehicleTasks(response));
    yield call(callback);
    yield call(
      successNotification,
      'tasks.vehicleTasksModal.success',
      'tasks.vehicleTasksModal.successMessage'
    );
  }
}

function* createVehicleTasksMulti(action) {
  const {
    title,
    category,
    description,
    vehicleCategoryId,
    includeChildBranches = false,
  } = action.entity;
  const accessToken = yield call(getAuthToken);
  const { id: selectedBranchId } = yield call(getBranch);

  const response = yield call(
    TaskService.createVehicleTasksMulti,
    accessToken,
    title,
    category,
    description,
    vehicleCategoryId,
    selectedBranchId,
    includeChildBranches
  );

  if (response) {
    yield put({
      type: entityActionStrings.APPEND_ENTITIES,
      entity: availableEntities.TASKS,
      payload: response,
    });
    yield call(
      successNotification,
      'tasks.vehicleTasksModal.success',
      'tasks.vehicleTasksModal.successMessage'
    );
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionStrings.CREATE_VEHICLE_TASK, createVehicleTask),
    yield takeEvery(actionStrings.CREATE_VEHICLE_TASKS_MULTI, createVehicleTasksMulti),
  ]);
}
