import omit from 'lodash/omit';

import ApiService from './ApiService';
import EntityService from './EntityService';
import { createStringFromParams, createStringFromFilter } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/iot-modules`;

class IotModuleService {
  /**
   * Method for fetching iot modules with `specified` params.
   * @param {string} authToken - Authentication bearer token.
   * @param {number} branchId - id of branch that is curently sent as param.
   * @param {object} params - object containing `params` and `filter` values.
   */
  static getIotModules(AuthToken, branchId, params) {
    const includeChildBranches = params.includeChildren ? '&includeChildBranches=true' : '';
    // eslint-disable-next-line no-param-reassign
    delete params.includeChildren;

    // TODO Backend needs to accept array of enums insted of accepting strings one by one
    const filtersString = createStringFromFilter(params.filters);
    // avoid filters in params string
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    const queryParams = createStringFromParams(params, branchId ? '&' : '');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${
        branchId ? `&branchId=${branchId}` : ''
      }${queryParams}${filtersString}${includeChildBranches}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for updating IOT module.
   * @param {string} authToken - authentication bearer token.
   * @param {number} moduleId - id of module to be updated.
   * @param {string} data - data for update.
   */
  static updateIotModule(authToken, moduleId, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${moduleId}`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Retire iot module.
   * @param {string} authToken - authentication bearer token.
   * @param {number} moduleId - id of module to be retired.
   * @param {object} data - data to be sent.
   */
  static retireIotModule(authToken, moduleId, data) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${moduleId}/retire`,
      data,
      authToken,
      'application/json'
    );
  }

  /**
   * Helper method to get module revisions history.
   * @param {string} authToken - authentication bearer token.
   * @param {string|number} moduleId - id of specified module.
   * @param {object} params - params
   */
  static getModuleRevisions(authToken, moduleId, params) {
    return EntityService.auditForEntities(authToken, 'module', moduleId, 'revisions', params);
  }

  /**
   * Helper method to get module revisions history.
   * @param {string} authToken - authentication bearer token.
   * @param {object} params - params
   */
  static getModuleRetirements(authToken, params) {
    const filtersString = createStringFromFilter(params.filters);

    // remove filters from the query
    const queryParams = createStringFromParams(omit(params, 'filters'), '?');
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/retired${queryParams}${filtersString}`,
      authToken,
      'application/json'
    );
  }

  /**
   * Create new iot module.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} data - The request payload data for new iot module.
   */
  static createIotModule(authToken, data) {
    return ApiService.ApiCallBody('POST', `${BASE_URL}`, data, authToken, 'application/json');
  }
}
export default IotModuleService;
