import { jwtDecode } from 'jwt-js-decode';
import upperFirst from 'lodash/upperFirst';

import { subdomain, tenantInfo } from '../../config';
import responseOk from '../../utils/responseOk';

const authEnv = 'AUTH_ENV';
const environment = subdomain.indexOf('staging') !== -1 ? 'staging' : 'prod';

/** `urls` object used to contain urls of the tenant, to avoid unnecessary fetching of the tenant info  */
export const urls = {
  authUrl: '',
  coreUrl: '',
  analyticsUrl: '',
  exportUrl: '',
  maintenanceUrl: '',
  platformUrl: '',
};

export const valuesCb = (filtersString, name) => value => {
  if (value) {
    // eslint-disable-next-line no-param-reassign
    filtersString += `&${name}=${value}`;
  }
};

export const createStringFromFilter = filters => {
  let filtersString = '';
  if (!filters) return filtersString;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < filters.length; i++) {
    const names = Object.keys(filters[i]);
    const name = names[0];
    const isDateCell = names[1];
    const values = filters[i][name];

    if (isDateCell && filters[i][isDateCell] === true) {
      const date = new Date(values);
      filtersString += `&${name}=${date.toISOString()}`;
    } else if (name && values && values.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const v of values) {
        filtersString += `&${name}=${v}`;
      }
    }
  }
  return filtersString;
};

export const createStringFromParams = (params, initialChar = '&') =>
  `${initialChar}${Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;

export const multipleFiltersWithSameKey = (filters = [], key = '') => {
  let filtersString = '';
  for (let i = 0; i < filters.length; i += 1) {
    filtersString += `&${key}=${filters[i]}`;
  }
  return filtersString;
};

/**
 * We merge tags and labels when searching annotations
 * @param {object} params
 */
export const resolveTagsAndLabelsParams = params => {
  let newAnnotationsName = [];
  let filterData = [];

  if (params.filters) {
    const filteredData = params.filters.filter(
      v => !v['annotations.label'] && !v['annotations.name']
    );
    // eslint-disable-next-line array-callback-return
    params.filters.forEach(value => {
      if (value['annotations.name'])
        newAnnotationsName = [...newAnnotationsName, ...value['annotations.name']];

      if (value['annotations.label'])
        newAnnotationsName = [...newAnnotationsName, ...value['annotations.label']];
    });
    filterData = [...filteredData, { 'annotations.id': newAnnotationsName }];
  }
  return filterData;
};
const sleep = async msec => new Promise(resolve => setTimeout(resolve, msec));

/** `helper` method for checking is token valid */
const isTokenValid = exp => exp * 1000 > new Date().getTime();

/** `url` to look for info of specified tenant */
const tenantUrl = `${process.env.REACT_APP_TENANT_SERVICE_URL.replace(
  authEnv,
  environment
)}/api/tenant`;

export const handleResponsePromise = async (responsePromise, authToken = '') => {
  const response = await responsePromise;

  if (response.status === 401 || response.status === 403) {
    // if we get 401 from wrong credentials on login page
    // also route of login page
    // so there's no need to refetch again
    if (response.url.endsWith('sign-in-email') || window.location.pathname === '/') {
      return response;
    }

    // if authToken exists check is valid
    // because 401 or 403 maybe is returned for user that have not specified permissions
    // for REST resources
    if (authToken) {
      const decodedToken = jwtDecode(authToken.split(' ')[1]);
      if (isTokenValid(decodedToken.payload.exp)) {
        return response;
      }

      let refreshToken = '';

      // extract refresh token from the localStorage
      try {
        const { user } = JSON.parse(localStorage.getItem('persist:Auth') || { user: {} });
        refreshToken = JSON.parse(user || { refreshToken: '' }).refreshToken;
      } catch (error) {
        console.error(error);
      }

      // when there's no refresh token go back to the login
      if (!refreshToken) {
        // redirect to the login
        localStorage.clear();
        global.location.pathname = '/';
        return response;
      }

      // case when there is refresh token
      // try to renew authtoken
      const userResponse = await fetch(`${urls.authUrl}/api/${tenantInfo.uuid}/refresh-token`, {
        body: JSON.stringify({ refreshToken }),
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      if (responseOk(userResponse)) {
        try {
          const userJson = await userResponse.json();
          const persistAuth = JSON.parse(localStorage.getItem('persist:Auth'));
          // refresh localStorage
          // with new pesist:Auth items
          localStorage.setItem(
            'persist:Auth',
            JSON.stringify({
              ...persistAuth,
              decodedJWT: JSON.stringify(jwtDecode(userJson.accessToken)),
              user: JSON.stringify(userJson),
            })
          );
          await sleep(500);
          // reload active route with new stuff in the localStorage
          global.location.reload();
          return response;
        } catch (error) {
          console.error(error);
        }
      }
    }
    // case when there's no authToken, clear localStorage and navigate to the start
    // // Redirect to default route
    localStorage.clear();
    global.location.pathname = '/';
  }

  return response;
};

export const initBasicTenantInfo = async () => {
  try {
    const tenantInfoResponse = await fetch(`${tenantUrl}?lookup=${subdomain}`, {
      method: 'GET',
      headers: new Headers({
        'Content-type': 'application/json',
      }),
    });
    if (responseOk(tenantInfoResponse)) {
      const result = await tenantInfoResponse.json();
      // set the auth and core url
      urls.authUrl = result.authUrl;
      urls.coreUrl = result.coreUrl;
      urls.platformUrl = result.platformUrl;
      // set tenant uuid
      tenantInfo.uuid = result.uuid;
      document.title = upperFirst(`${result.name || 'GoUrban'} Dashboard`);

      window.tenantInfo = result;

      return result;
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};

/**
 * Helper method for resolving `tenant` url.
 * @param {string} url - url which first part should be resolved from the tenant.
 * @param {string} authToken - authentication bearer token.
 * @return {string} resolved url eg `{authUrl}/.....` will be `https://staging.auth.gourban.services/...`
 */
export const handleTenantUrl = async (url = '', authToken = '') => {
  // case when url is normal
  // return url
  if (
    ![
      '{authUrl}',
      '{coreUrl}',
      '{analyticsUrl}',
      '{exportUrl}',
      '{maintenanceUrl}',
      '{platformUrl}',
    ].some(v => url.startsWith(v))
  ) {
    return url;
  }

  /** `find` key of the url */
  const foundUrlKey = Object.keys(urls).find(u => url.startsWith(`{${u}}`));

  // if url already fetched
  if (foundUrlKey && urls[foundUrlKey]) {
    return url.replace(`{${foundUrlKey}}`, urls[foundUrlKey]);
  }

  // if some starts with auth or core or platform
  // we can fetch it's own info without authtoken
  if (['{authUrl}', '{coreUrl}', '{platformUrl}'].some(v => url.startsWith(v))) {
    try {
      const result = await initBasicTenantInfo();

      return url
        .replace('{authUrl}', result.authUrl)
        .replace('{coreUrl}', result.coreUrl)
        .replace('{platformUrl}', result.platformUrl);
    } catch (error) {
      console.error(error);
    }
  } else if (
    ['{exportUrl}', '{analyticsUrl}', '{maintenanceUrl}'].some(v => v.startsWith(v)) &&
    authToken
  ) {
    try {
      const tenantInfoResponse = await fetch(`${tenantUrl}/${tenantInfo.uuid}`, {
        method: 'GET',
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: authToken,
        }),
      });
      if (responseOk(tenantInfoResponse)) {
        const result = await tenantInfoResponse.json();
        // set the auth and core url
        urls.authUrl = result.authUrl;
        urls.coreUrl = result.coreUrl;
        urls.analyticsUrl = result.analyticsUrl;
        urls.exportUrl = result.exportUrl;
        urls.maintenanceUrl = result.maintenanceUrl;
        urls.platformUrl = result.platformUrl;
        document.title = upperFirst(`${result.name || 'GoUrban'} Dashboard`);

        window.tenantInfo = result;

        return url
          .replace('{analyticsUrl}', result.analyticsUrl)
          .replace('{exportUrl}', result.exportUrl)
          .replace('{maintenanceUrl}', result.maintenanceUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }
  // empty url in the case when everything is failed
  return '';
};
