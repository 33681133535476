import React from 'react';
import {
  EnvironmentOutlined,
  TeamOutlined,
  CarOutlined,
  BookOutlined,
  ExclamationCircleOutlined,
  PieChartOutlined,
  CalendarOutlined,
  SettingOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  ToolOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import {
  CUSTOM_DASHBOARD_PAGE_NAME,
  ISSUES_PLUGIN_NAME,
  SERVICE_OPERATIONS_PLUGIN_NAME,
  POI_PLUGIN_NAME,
  ANALYTICS_PLUGIN_NAME,
} from '../../constants/settings-plugins';
import permissions from '../../constants/permissions';
import { ROLES } from '../../constants/roles';

const { ROOT, ADMIN, FLEET_MANAGER } = ROLES;

const options = [
  {
    key: 'overview',
    label: 'sidebar.overview',
    leftIcon: <DashboardOutlined />,
    url: '/dashboard/overview',
    plugin: CUSTOM_DASHBOARD_PAGE_NAME,
    onlyRootBranch: false,
  },
  {
    key: 'map',
    label: 'sidebar.map',
    leftIcon: <EnvironmentOutlined />,
    url: '/dashboard',
    permission: [permissions.VEHICLE_LIST_READ],
    onlyRootBranch: false,
  },
  {
    key: 'customers',
    label: 'sidebar.users',
    leftIcon: <TeamOutlined />,
    url: '/dashboard/customers?sort=id,DESC',
    permission: [permissions.USER_LIST_READ],
    onlyRootBranch: false,
  },
  {
    key: 'serviceOperations',
    label: 'sidebar.service.operations',
    leftIcon: <ToolOutlined />,
    url: '/dashboard/productivity-insights',
    plugin: SERVICE_OPERATIONS_PLUGIN_NAME,
    onlyRootBranch: false,
    roles: [ROOT, ADMIN, FLEET_MANAGER],
  },
  {
    key: 'vehicles',
    label: 'sidebar.vehicles',
    leftIcon: <CarOutlined />,
    url: '/dashboard/vehicles',
    permission: [permissions.VEHICLE_LIST_READ],
    onlyRootBranch: false,
  },
  {
    key: 'bookings',
    label: 'sidebar.bookings',
    leftIcon: <CalendarOutlined />,
    url: '/dashboard/bookings',
    permission: [permissions.BOOKING_LIST_READ],
    onlyRootBranch: false,
  },
  {
    key: 'rentals',
    label: 'sidebar.rentals',
    leftIcon: <BookOutlined />,
    url: '/dashboard/rentals',
    permission: [permissions.RENTAL_LIST_READ],
    onlyRootBranch: false,
  },
  {
    key: 'issues',
    label: 'sidebar.issues',
    leftIcon: <ExclamationCircleOutlined />,
    url: '/dashboard/issues',
    permission: [permissions.ISSUE_LIST_READ],
    plugin: ISSUES_PLUGIN_NAME,
    onlyRootBranch: false,
  },
  {
    key: 'analytics',
    label: 'sidebar.analytics',
    leftIcon: <PieChartOutlined />,
    url: '/dashboard/analytics',
    permission: [permissions.ANALYTICS_READ],
    onlyRootBranch: false,
    plugin: ANALYTICS_PLUGIN_NAME,
  },
  {
    key: 'poi-cat',
    label: 'sidebar.poi',
    leftIcon: <PushpinOutlined />,
    url: '/dashboard/poi-cat',
    plugin: POI_PLUGIN_NAME,
    permission: [permissions.POI_READ, permissions.POI_CATEGORY_READ],
    onlyRootBranch: true,
  },
];

const updateOptions = () => {
  options.push({
    // To get it the latest in the list
    key: 'settings',
    label: 'sidebar.settings',
    leftIcon: <SettingOutlined />,
    url: '/dashboard/settings',
    onlyRootBranch: false,
  });

  options.push({
    key: 'plugins',
    label: 'sidebar.plugins',
    leftIcon: <AppstoreAddOutlined />,
    url: '/dashboard/apps',
    permission: [permissions.BRANCH_CREATE],
    onlyRootBranch: false,
  });

  return options;
};

export default updateOptions();
