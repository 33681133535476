import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';
import { availableEntities as _ } from '../../redux-config';

const initState = {
  [_.CUSTOMERS]: {},
  [_.VEHICLES]: {},
  [_.ISSUES]: {},
  [_.RENTALS]: {},
  [_.TASKS]: {},
  [_.BOOKINGS]: {},
  [_.DAMAGES]: {},
  [_.INVOICES]: {},
  [_.LOGS]: {},
  [_.SERVICE_AGENTS]: {},
};

const filtersReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.SET_TABLE_FILTERS:
      return {
        ...state,
        [action.entity]: action.payload,
      };
    case actionStrings.RESET_TABLE_FILTERS:
      return initState;
    default:
      return state;
  }
};

export default filterActions(filtersReducer, [
  actionStrings.SET_TABLE_FILTERS,
  actionStrings.RESET_TABLE_FILTERS,
]);
