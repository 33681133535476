// TODO: why
export function getView(width) {
  let newView = 'MobileView';
  if (width > 1024) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

export const actionStrings = {
  REFRESH_DATA: 'REFRESH_DATA', // Move to getVehicles

  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  SCREEN_RESIZE: 'SCREEN_RESIZE',

  FETCH_BRANCHES: 'FETCH_BRANCHES',
  FETCH_BRANCHES_START: 'FETCH_BRANCHES_START',
  FETCH_BRANCHES_SUCCESS: 'FETCH_BRANCHES_SUCCESS',
  FETCH_BRANCHES_ERROR: 'FETCH_BRANCHES_ERROR',
  SELECT_BRANCH: 'SELECT_BRANCH',
  SELECT_AND_SAVE_BRANCH: 'SELECT_AND_SAVE_BRANCH',
  TOGGLE_AUTO_REFRESH: 'TOGGLE_AUTO_REFRESH',

  // used when tab is not active
  UPDATE_FOCUS: 'update_focus',

  SUCCESS_NOTIFICATION: '@notification/success',
  ERROR_NOTIFICATION: '@notification/error',

  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_SUCCESS: 'FETCH_CURRENT_USER_SUCCESS',

  FETCH_ALL_VEHICLE_FILTERS: 'FETCH_ALL_VEHICLE_FILTERS',
  FETCH_ALL_VEHICLE_FILTERS_SUCCESS: 'FETCH_ALL_VEHICLE_FILTERS_SUCCESS',

  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  UPDATE_DASHBOARD_MENU_ICON: 'UPDATE_DASHBOARD_MENU_ICON',

  RESET_ALL: 'RESET_ALL',

  URL_CHANGED: 'URL_CHANGED',
  FETCH_RFID_CARDS: 'FETCH_RFID_CARDS',
  FETCH_RFID_CARDS_SUCCESS: 'FETCH_RFID_CARDS_SUCCESS',
};

// TODO: use common actions from : ./common/commonActions.js
export const actions = {
  refreshData: () => ({
    type: actionStrings.REFRESH_DATA,
  }),
  toggleCollapsed: () => ({
    type: actionStrings.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actionStrings.TOGGLE_ALL,
      collapsed,
      view,
      height,
      width,
    };
  },
  toggleOpenDrawer: () => ({
    type: actionStrings.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: openKeys => ({
    type: actionStrings.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: current => ({
    type: actionStrings.CHANGE_CURRENT,
    current,
  }),
  screenResize: (width, height) => ({
    type: actionStrings.SCREEN_RESIZE,
    width,
    height,
  }),
  selectAndSaveBranch: (selectedBranchId, shouldFetchData = true) => ({
    type: actionStrings.SELECT_AND_SAVE_BRANCH,
    selectedBranchId,
    shouldFetchData,
  }),
  selectBranch: selectedBranch => ({
    type: actionStrings.SELECT_BRANCH,
    selectedBranch,
  }),
  fetchBranches: () => ({
    type: actionStrings.FETCH_BRANCHES,
  }),
  fetchBranchesStart: () => ({
    type: actionStrings.FETCH_BRANCHES_START,
  }),
  fetchBranchesSuccess: branches => ({
    type: actionStrings.FETCH_BRANCHES_SUCCESS,
    branches,
  }),
  fetchBranchesError: error => ({
    type: actionStrings.FETCH_BRANCHES_ERROR,
    error,
  }),
  toggleAutoRefresh: () => ({
    type: actionStrings.TOGGLE_AUTO_REFRESH,
  }),
  uploadFiles: files => ({
    type: actionStrings.UPLOAD_FILES,
    payload: files,
  }),
  fetchCurrentUser: () => ({
    type: actionStrings.FETCH_CURRENT_USER,
  }),
  fetchCurrentUserSuccess: payload => ({
    type: actionStrings.FETCH_CURRENT_USER_SUCCESS,
    payload,
  }),
  fetchAllVehiclesFilters: () => ({
    type: actionStrings.FETCH_ALL_VEHICLE_FILTERS,
  }),
  fetchAllVehiclesFiltersSuccess: payload => ({
    type: actionStrings.FETCH_ALL_VEHICLE_FILTERS_SUCCESS,
    payload,
  }),
  updateFocus: payload => ({
    type: actionStrings.UPDATE_FOCUS,
    payload,
  }),
  changeLanguage: payload => ({
    type: actionStrings.CHANGE_LANGUAGE,
    payload,
  }),
  updateDashboardMenuIcon: payload => ({
    type: actionStrings.UPDATE_DASHBOARD_MENU_ICON,
    payload,
  }),
  resetAll: () => ({
    type: actionStrings.RESET_ALL,
  }),
  urlChange: payload => ({
    type: actionStrings.URL_CHANGED,
    payload,
  }),
  fetchRFIDCards: () => ({
    type: actionStrings.FETCH_RFID_CARDS,
  }),
};
export default actions;
