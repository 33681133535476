import { filterActions } from 'redux-ignore';

import actions from './actions';

const initState = {
  allCustomers: [],
  loading: false,
  error: null,
};

const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CREATE_CUSTOMER_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CREATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CREATE_CUSTOMER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actions.FETCH_ALL_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_ALL_CUSTOMERS_SUCCESSFUL:
      return {
        ...state,
        allCustomers: action.payload,
        loading: false,
      };
    case actions.FETCH_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default filterActions(customerReducer, [
  actions.CREATE_CUSTOMER_REQ,
  actions.CREATE_CUSTOMER_SUCCESS,
  actions.CREATE_CUSTOMER_ERROR,
  actions.FETCH_ALL_CUSTOMERS,
  actions.FETCH_ALL_CUSTOMERS_SUCCESSFUL,
  actions.FETCH_ALL_CUSTOMERS_FAILED,
]);
