import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromFilter, createStringFromParams, multipleFiltersWithSameKey } from './util';
import objectToQueryString from '../../utils/objectToQueryString';

const BASE_URL = `${ApiService.BACKEND_URL}/back/damages`;

class DamageService {
  /**
   * Method for fetching damages with `specified` params.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} branch - id of branch.
   * @param {object} params - object containing `params` and `filter` values.
   */
  static getAllDamages(AuthToken, branch, params) {
    const filterString = createStringFromFilter(params.filters);
    const paramString = createStringFromParams(omit(params, 'filters'));
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?branchId=${branch}${paramString}${filterString}`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Fetch all damages for a vehicle with the provided id as query parameter.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} queryParams - Object with query parameters to be converted to a query string.
   * @param {AbortController.signal} signal - Signal for aborting subsequent requests.
   */
  static getVehicleDamagesAbortable(authToken, queryParams, filters, signal) {
    const queryString = objectToQueryString(queryParams, true);
    const queryFilters = multipleFiltersWithSameKey(filters, 'state');
    const options = {
      method: 'GET',
      signal,
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
    };
    return ApiService.ApiCallAbortable(`${BASE_URL}?${queryString}${queryFilters}`, options);
  }

  /**
   * Update damage with a PATCH request for each key - value property.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} id - The id of the vehicle to update for.
   * @param {string} key - The key of the property we should change.
   * @param {any} value - The value for any of the properties values we're changing.
   */
  static updateDamage(AuthToken, id, key, value) {
    const data = { [key]: value };
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static updateDamageWithData(AuthToken, id, data) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}/${id}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Request that retrieves damage files/
   * @param {string} AuthToken - authentication token to be sent as Authentication Bearer.
   * @param {number} damageId - the id of damage which files are retreived.
   */
  static retreiveDamageFiles(AuthToken, damageId) {
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}/${damageId}/files`,
      AuthToken,
      'application/json'
    );
  }

  /**
   * Method for creating damage.
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - passed data for creating.
   */
  static createDamage(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json');
  }

  /**
   * Method to get damage by id
   * @param {string} authToken - authentication bearer token.
   * @param {number} id - damage id.
   */
  static getDamage(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json');
  }
}

export default DamageService;
