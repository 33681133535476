import {
  all,
  call,
  select,
  put,
  takeLatest,
  delay,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { createIntl, createIntlCache } from 'react-intl';

import { CustomersService } from '../../services/coreAPI';
import EnumsService from '../../services/coreAPI/EnumsService';

import { actionStrings as appActions } from './actions';
import { actionStrings as vehicleActions } from '../vehicles/actions';
import { actions as stationActions } from '../stations/actions';

import { setSelectedBranchId } from '../../helpers/utility';
import { getAutoRefresh, getIsFocused, getBranchId } from './selectors';
import { getIsPluginActive, getIsPluginAvailable } from '../plugins/selectors';
import { STATION_PLUGIN_NAME } from '../../constants/settings-plugins';
import { selectBranch } from '../entities/sagas';
import {
  handleNotification,
  successNotification,
  errorNotification,
  getAuthToken,
} from '../common/commonSagas';

import AppLocale from '../../languageProvider';
import { getAreVehiclesFetched } from '../vehicles/selectors';
import waitFor from '../common/waitFor';

function* selectAndSaveBranch({ selectedBranchId, shouldFetchData }) {
  const oldBranchId = JSON.parse(localStorage.getItem('selected_branch_id'));
  setSelectedBranchId({ id: selectedBranchId });
  if (oldBranchId !== selectedBranchId) {
    yield selectBranch();
    if (shouldFetchData) {
      yield put({
        type: vehicleActions.FETCH_ALL_VEHICLES_REFRESH,
        branchId: selectedBranchId,
      });
    } else {
      // handle case when branch is changed
      // to restart vehicles
      yield put({
        type: vehicleActions.RESTART_VEHICLES,
      });
    }
  }
}

function* autoRefreshVehicles() {
  const state = yield select();
  const enabledVehicleAutoRefresh = state.Vehicles.auto_refresh;
  if (enabledVehicleAutoRefresh) {
    yield put({
      type: vehicleActions.FETCH_ALL_VEHICLES_REFRESH,
    });
  }
}

function* autoRefreshStations() {
  const state = yield select();
  const refreshStations = get(state, 'Stations.auto_refresh', false);
  const branchId = yield select(getBranchId);
  const stationsAvailable =
    getIsPluginActive(state, STATION_PLUGIN_NAME) &&
    getIsPluginAvailable(state, STATION_PLUGIN_NAME);
  if (refreshStations && stationsAvailable) {
    yield put(stationActions.fetchStations({ branchId }));
  }
}

function* refereshVehicles(timeout) {
  yield call(waitFor, state => getAreVehiclesFetched(state) === true);
  const vehiclesAreFetched = yield select(getAreVehiclesFetched);
  while (true && vehiclesAreFetched) {
    yield delay(timeout);
    /** `indicator` is tab focused */
    const isFocused = yield select(getIsFocused);

    // TODO: here add all entities/calls that should be auto-refreshed
    // if tab is focused refetch data
    if (isFocused) yield call(autoRefreshVehicles);

    const state = yield select();

    if (!getAutoRefresh(state)) {
      return;
    }
  }
}

function* refereshStations(timeout) {
  while (true) {
    yield delay(timeout);
    /** `indicator` is tab focused */
    const isFocused = yield select(getIsFocused);

    // TODO: here add all entities/calls that should be auto-refreshed
    // if tab is focused refetch data
    if (isFocused) yield call(autoRefreshStations);

    const state = yield select();

    if (!getAutoRefresh(state)) {
      return;
    }
  }
}

function* successNotificationWatcher({ payload }) {
  yield call(successNotification, payload.title, payload.body);
}

function* errorNotificationWatcher({ payload }) {
  yield call(errorNotification, payload.title, payload.body);
}

function* fetchCurrentUser() {
  try {
    const idToken = yield call(getAuthToken);
    if (idToken) {
      const response = yield call(CustomersService.getCurrentUserDetails, idToken);
      const responseJson = yield call([response, response.json]);
      if (responseJson) {
        yield put({
          type: appActions.FETCH_CURRENT_USER_SUCCESS,
          payload: responseJson,
        });
      }
    }
  } catch (error) {
    handleNotification('error', error.message);
  }
}

function* fetchRFIDCards() {
  try {
    const state = yield select();
    const idToken = yield call(getAuthToken);
    const branch = yield call(getBranchId, state);

    if (idToken) {
      const response = yield call(CustomersService.getRFIDCards, branch, idToken);
      const responseJson = yield call([response, response.json]);
      if (responseJson) {
        yield put({
          type: appActions.FETCH_RFID_CARDS_SUCCESS,
          payload: responseJson,
        });
      }
    }
  } catch (error) {
    handleNotification('error', error.message);
  }
}

function* fetchVehicleFilters() {
  try {
    const authToken = yield call(getAuthToken);
    if (authToken) {
      const response = yield call(EnumsService.getEnums, authToken, {
        filters: [{ type: ['serviceState', 'batteryLevel', 'ioTBatteryLevel', 'helmetsState'] }],
      });
      const responseJson = yield call([response, response.json]);
      if (responseJson) {
        const {
          serviceState,
          batteryLevel = [],
          ioTBatteryLevel = [],
          helmetsState = [],
        } = responseJson;
        yield put({
          type: appActions.FETCH_ALL_VEHICLE_FILTERS_SUCCESS,
          payload: {
            batteryLevel: batteryLevel.sort((a, b) => a.order - b.order),
            serviceState: serviceState.sort((a, b) => a.order - b.order),
            ioTBatteryLevel: ioTBatteryLevel.sort((a, b) => a.order - b.order),
            helmetsState: helmetsState.sort((a, b) => a.order - b.order),
          },
        });
      }
    }
  } catch (error) {
    handleNotification('error', error.message);
  }
}

/**
 * `Function` generator used to change context from localization in sagas
 */
function* changeLanguage({ payload }) {
  try {
    const contextIntl = yield getContext('intl');
    const { locale, messages } = AppLocale[payload];
    const cache = createIntlCache();
    const intl = createIntl(
      {
        locale,
        messages,
      },
      cache
    );
    // mutation from context object to adapt for sagas
    // to use selected language
    // eslint-disable-next-line no-return-assign
    Object.keys(contextIntl).forEach(key => (contextIntl[key] = intl[key]));
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(appActions.SELECT_AND_SAVE_BRANCH, selectAndSaveBranch),
    takeLatest(appActions.REFRESH_DATA, refereshVehicles, 15000),
    takeLatest(appActions.REFRESH_DATA, refereshStations, 240000),
    takeEvery(appActions.SUCCESS_NOTIFICATION, successNotificationWatcher),
    takeEvery(appActions.ERROR_NOTIFICATION, errorNotificationWatcher),
    takeEvery(appActions.FETCH_CURRENT_USER, fetchCurrentUser),
    takeEvery(appActions.FETCH_ALL_VEHICLE_FILTERS, fetchVehicleFilters),
    takeEvery(appActions.CHANGE_LANGUAGE, changeLanguage),
    takeEvery(appActions.FETCH_RFID_CARDS, fetchRFIDCards),
  ]);
}
