import get from 'lodash/get';
import omit from 'lodash/omit';
import { filterActions } from 'redux-ignore';

import { actionStrings } from './actions';
import { actionStrings as appActions } from '../app/actions';
import { actionStrings as bookingActionStrings } from '../bookings/actions';
import { availableEntities as _ } from '../../redux-config';
import { actionStrings as vehicleActions } from '../vehicles/actions';

export const confKey = ({ search = {}, ...params }) =>
  Object.entries({ ...params, ...search })
    .sort((a, b) => a[0] > b[0])
    .map(([key, value]) => `${key}_${value}`)
    .join('|');

const getLoadingObject = (entity, invokerAction) => ({
  startTime: new Date().getTime(),
  entity,
  invokerAction,
  loading: true,
});

const mergeEntity = (entity, entities = []) =>
  entities.map(mapEntity => (mapEntity.id === entity.id ? entity : mapEntity));

const initState = {
  [_.CUSTOMERS]: [],
  [_.VEHICLES]: [],
  [_.RENTALS]: [],
  [_.TASKS]: [],
  [_.INVOICES]: [],
  [_.ISSUES]: [],
  [_.HEATDATAS]: [],
  [_.BOOKINGS]: [],
  [_.NOTES]: [],
  [_.BALANCES]: [],
  [_.DAMAGES]: [],
  [_.STATIONS]: [],
  [_.LOGS]: [],
  [_.ROLES]: [],
  [_.ADDITIONS]: [],
  [_.IOT_MODULES]: [],
  [_.VOUCHERS]: [],
  [_.ANNOTATIONS]: [],
  [_.VOUCHERABLES]: [],
  [_.SERVICE_AGENTS]: [],

  [_.ANALYTICS_CUSTOMERS]: [],
  [_.ANALYTICS_RENTALS]: [],
  [_.ANALYTICS_RENTALS_OVERVIEW]: [],
  [_.CUSTOMERS_REGISTRATIONS]: [],
  [_.DETAILS]: {},

  loading: [], // Set loading data { loading: true, startTime: time, entity: name, action? }
  error: [], // holds errors     { errorObject, entity: name, action? }

  refresh: [],
};

const entitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_ENTITY_START:
      return {
        ...state,
        loading: [...state.loading, getLoadingObject(action.entity, action.invokerAction)],
      };

    case actionStrings.FETCH_ENTITY_SUCCESS: {
      const filteredLoading = state.loading.filter(l => l.entity !== action.entity);
      return {
        ...state,
        [action.entity]: action.payload,
        loading: filteredLoading,
      };
    }

    case actionStrings.CLEAR_ENTITY:
      return {
        ...state,
        [action.entity]: null,
      };

    case actionStrings.CLEAR_ENTITY_DATA:
      return {
        ...state,
        [action.entity]: [],
      };

    case actionStrings.MERGE_ENTITY_SUCCESS: {
      return {
        ...state,
        [action.entity]: mergeEntity(action.payload, state[action.entity]),
      };
    }

    case actionStrings.FETCH_ENTITY_ERROR: {
      const filteredLoading = state.loading.filter(l => l.entity !== action.entity);
      return {
        ...state,
        error: [
          ...state.error,
          { error: action.payload, entity: action.entity, invokerAction: action.invokerAction },
        ],
        loading: filteredLoading,
      };
    }

    case actionStrings.CLEAR_ERRORS:
      return {
        ...state,
        error: [],
      };

    case actionStrings.CLEAR_LOADING:
      return {
        ...state,
        loading: [],
      };

    case appActions.SELECT_BRANCH: {
      if (action.selectedBranch === null) {
        return state;
      }

      // TODO: loop through available entities and set default value
      return {
        ...state,
        ...omit(initState, ['loading', 'error', _.DETAILS, _.BRANCHES, _.ANNOTATIONS]),
      };
    }

    case actionStrings.UPDATE_DETAILS: {
      const updatedDetail = state[_.DETAILS];
      return {
        ...state,
        [_.DETAILS]: {
          ...updatedDetail,
          [action.entity]: action.payload,
        },
      };
    }

    case bookingActionStrings.UPDATE_BOOKING_TABLE: {
      const updatedBooking = action.payload.response;
      const updatedBookings = state[_.BOOKINGS].map(booking =>
        booking.id === updatedBooking.id ? updatedBooking : booking
      );
      return {
        ...state,
        [_.BOOKINGS]: updatedBookings,
      };
    }

    case actionStrings.ADD_ITEM: {
      const newItem = action.payload;
      const items = state[action.entity];

      return {
        ...state,
        // Cover both cases: If we want to add a array of items, or we just want to add one object in list of items
        [action.entity]: Array.isArray(newItem) ? [...newItem, ...items] : [newItem, ...items],
      };
    }

    case actionStrings.UPDATE_ITEM: {
      const updatedItem = action.payload;
      const items = state[action.entity]
        .map(item => (item.id === updatedItem.id ? updatedItem : item))
        .filter(Boolean);
      return {
        ...state,
        [action.entity]: [...items],
      };
    }

    case actionStrings.UPDATE_ITEM_IN_PLACE: {
      const { updatedItem, idKey } = action.payload;

      const items = state[action.entity].map(item =>
        get(item, idKey || 'id') === get(updatedItem, idKey || 'id') ? updatedItem : item
      );

      return {
        ...state,
        [action.entity]: items,
      };
    }

    case actionStrings.APPEND_ENTITIES: {
      return {
        ...state,
        [action.entity]: [...action.payload, ...state[action.entity]],
      };
    }

    /**
     * Action that removes an entity by providing the `id` in the payload.
     * Also provide the `idKey` property inside of the payload to signify by which
     * property to do the comparison.
     */
    case actionStrings.REMOVE_ITEM: {
      const { entity, payload } = action;
      const { id, idKey } = payload;
      const newState = state[entity].filter(item => get(item, idKey || 'id') !== id);
      return {
        ...state,
        [entity]: newState,
      };
    }

    case actionStrings.UPDATE_PROPERTY_OF_ITEM_IN_PLACE: {
      const { id, entity, property, value } = action;
      const newState = state[entity].map(item => {
        if (item.id === id || (value?.id && item[property]?.id === value?.id)) {
          return {
            ...item,
            [property]: value,
          };
        }
        return item;
      });
      return {
        ...state,
        [entity]: newState,
      };
    }

    case actionStrings.REFRESH_ENTITY_TABLE: {
      const { entity, payload } = action;
      return {
        ...state,
        refresh: { ...state?.refresh, [entity]: payload },
      };
    }

    case vehicleActions.SEND_COMMAND_SUCCESS: {
      const {
        payload: { vehicle },
      } = action;
      const vehicles = state[_.VEHICLES];
      return {
        ...state,
        [_.VEHICLES]: mergeEntity(vehicle, vehicles),
      };
    }

    default:
      return state;
  }
};

export default filterActions(entitiesReducer, [
  actionStrings.FETCH_ENTITY_START,
  actionStrings.FETCH_ENTITY_SUCCESS,
  actionStrings.CLEAR_ENTITY,
  actionStrings.CLEAR_ENTITY_DATA,
  actionStrings.MERGE_ENTITY_SUCCESS,
  actionStrings.FETCH_ENTITY_ERROR,
  actionStrings.CLEAR_ERRORS,
  actionStrings.CLEAR_LOADING,
  appActions.SELECT_BRANCH,
  actionStrings.UPDATE_DETAILS,
  bookingActionStrings.UPDATE_BOOKING_TABLE,
  actionStrings.ADD_ITEM,
  actionStrings.UPDATE_ITEM,
  actionStrings.UPDATE_ITEM_IN_PLACE,
  actionStrings.APPEND_ENTITIES,
  actionStrings.REMOVE_ITEM,
  actionStrings.UPDATE_PROPERTY_OF_ITEM_IN_PLACE,
  actionStrings.REFRESH_ENTITY_TABLE,
  vehicleActions.SEND_COMMAND_SUCCESS,
]);
