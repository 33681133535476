import React from 'react';
import styled from 'styled-components';

import { SubmitButton } from '../components/common/form.style';

const Container = styled.div`
  background: var(--gray-1);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: var(--gray-10);
  }

  .info-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: var(--gray-7);
  }

  .ant-btn {
    width: auto;
    font-weight: normal;
    margin-top: 10px;
  }
`;

/**
 * `FallBack component`
 */
const FallBack = () => {
  /** `onClick` handler for navigation to the map */
  const onClick = () => {
    window.location.href = `${window.location.origin}/dashboard`;
  };

  return (
    <Container>
      <InfoContainer>
        <h1>Unexpected error.</h1>
        <div className="info-text">Something went wrong, please try again.</div>
        <SubmitButton onClick={onClick}>Try again</SubmitButton>
      </InfoContainer>
    </Container>
  );
};

export default FallBack;
