import { actionId, actionPayload } from '../common/commonActions';

export const actionStrings = {
  DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
  // FETCH_CUSTOMER_DETAILS: 'FETCH_CUSTOMER_DETAILS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',

  CREATE_CUSTOMER_REQ: 'CREATE_CUSTOMER_REQ',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'CREATE_CUSTOMER_ERROR',
  /**
   * This action describes that we're updating `Customers` piece of redux state,
   * with an action of `fetch_all` customers.
   */
  FETCH_ALL_CUSTOMERS: '@customers/fetch_all',
  FETCH_ALL_CUSTOMERS_SUCCESSFUL: '@customers/fetch_all_successful',
  FETCH_ALL_CUSTOMERS_FAILED: '@customers/fetch_all_failed',
  UPLOAD_FILES: '@customers/upload_files',
};

const updateCustomer = (id, key, value, callback) => ({
  type: actionStrings.UPDATE_CUSTOMER,
  id,
  key,
  value,
  callback,
});

const actions = {
  downloadInvoice: actionId(actionStrings.DOWNLOAD_INVOICE),
  updateCustomer,
  createCustomer: actionPayload(actionStrings.CREATE_CUSTOMER_REQ),
  createCustomerSuccess: actionPayload(actionStrings.CREATE_CUSTOMER_SUCCESS),
  createCustomerError: actionPayload(actionStrings.CREATE_CUSTOMER_ERROR),
};

export default { ...actionStrings, ...actions };
