import { TreeSelect } from 'antd';
import styled from 'styled-components';

export const StyledTreeSelect = styled(TreeSelect)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
    padding: 0px;

    input {
      cursor: pointer;
    }
  }
  &.ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
  }

  .ant-select-arrow .anticon:not(.anticon-down) {
    pointer-events: none;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    .badge {
      display: none;
    }

    .branch-selected {
      margin-right: 5px;
      font-weight: bold;
    }
  }

  .ant-select-arrow {
    right: 6px;
    top: 12px;
  }
`;

export const BranchInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  line-height: normal;
`;

export const ParentBranches = styled.div`
  font-size: 11px;
  line-height: 13px;

  color: var(--gray-7);
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const BranchNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100px;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4.125px 0 4.125px;
  border-color: var(--gray-8) transparent transparent transparent;

  margin-top: 9px;
  margin-left: 5px;
`;

export const BillableBadge = styled.div`
  display: flex;
  float: right;
  font-size: 11px;
  font-weight: bold;
  color: var(--gray-1);
  border-radius: 4px;
  background: var(--blue-6);
  padding: 0 6px;
  margin-left: 5px;
`;

export const BranchDash = styled.hr`
  border-top: 1px solid;
  width: ${({ width }) => width}px;
  height: 100%;
  margin: 11px 6px 0 0;
  float: left;
`;

export const BranchName = styled.div`
  color: var(--gray-10);
`;

export const BranchItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DotsContainer = styled.div`
  margin-left: 40px;
`;
