import ApiService from './ApiService';

const BASE_URL = `${ApiService.BACKEND_URL}/back/terms-and-conditions`;

class TermsAndConditionsService {
  /**
   * Method to get T&C by id
   * @param {string} authToken authentication bearer token.
   * @param {string|number} id - id of T&C
   */
  static findTermsAndConditionById(authToken, id) {
    return ApiService.ApiCallAuth('GET', `${BASE_URL}/${id}`, authToken, 'application/json');
  }

  /**
   * Method to create T&C
   * @param {string} authToken - authentication bearer token.
   * @param {object} data - T&C data for the create.
   */
  static createTermsAndCondition(authToken, data) {
    return ApiService.ApiCallBody('POST', BASE_URL, data, authToken, 'application/json');
  }

  /**
   * Method for updating exsiting T&C
   * @param {string} authToken - authentication bearer token.
   * @param {number|string} id - id of the T&C to be updated
   * @param {object} data - data sent for update.
   */
  static updateTermsAndCondition(authToken, id, data) {
    return ApiService.ApiCallBody('PUT', `${BASE_URL}/${id}`, data, authToken, 'application/json');
  }
}
export default TermsAndConditionsService;
