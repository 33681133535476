import dayjs from 'dayjs';
import get from 'lodash/get';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { actions, actionStrings } from './actions';

import AnalyticsService from '../../services/coreAPI/AnalyticsService';
import SettingsService from '../../services/coreAPI/SettingsService';
import { errorNotification, getAuthToken, successNotification } from '../common/commonSagas';
import { getBranch } from '../common/sagaHelpers';

import { longYearNumericMonthFmt } from '../../constants/datetime';
import responseOk from '../../utils/responseOk';

function* createGoal(idToken, data) {
  const response = yield call(SettingsService.createSetting, idToken, data);

  // Check if response is valid
  const resOk = yield call(responseOk, response);

  if (!resOk) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.goalCreateFailed');
    return;
  }

  // Success message
  yield call(
    successNotification,
    'feedback.alert.successTitle',
    'feedback.alert.goalCreateSuccess'
  );

  const result = yield call([response, response.json]);
  yield put(actions.addGoalSuccess(result));
}

function* updateGoal(idToken, settingId, data) {
  const response = yield call(SettingsService.updateSetting, idToken, settingId, data);

  // Check if response is valid
  const resOk = yield call(responseOk, response);

  if (!resOk) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.goalUpdateFailed');
    return;
  }

  // Success message
  yield call(
    successNotification,
    'feedback.alert.successTitle',
    'feedback.alert.goalUpdateSuccess'
  );

  const result = yield call([response, response.json]);
  yield put(actions.updateGoalSuccess(result));
}

function* createMainGoal(idToken, data) {
  const response = yield call(SettingsService.createSetting, idToken, data);

  if (response.status !== 200) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.errorTips');
    return;
  }

  const result = yield call([response, response.json]);
  yield put(actions.updateMainGoalSuccess(result));
}

function* updateMainGoal(idToken, settingId, data) {
  const response = yield call(SettingsService.updateSetting, idToken, settingId, data);

  if (response.status !== 200) {
    yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.errorTips');
    return;
  }

  const result = yield call([response, response.json]);
  yield put(actions.updateMainGoalSuccess(result));
}

export function* createOrUpdateGoal({ payload }) {
  const idToken = yield call(getAuthToken);
  const { id: branchId } = yield call(getBranch);

  const { goalsSettingId, value } = payload;

  const data = {
    area: 'DASHBOARD',
    key: 'metrics.goals.monthly',
    branchIds: [branchId],
    value: {
      months: value,
    },
  };

  // Check if there is no goals setting for this branch.
  if (goalsSettingId === -1) {
    yield call(createGoal, idToken, data);
  } else {
    yield call(updateGoal, idToken, goalsSettingId, data);
  }
}

export function* createOrUpdateMainGoal({ payload }) {
  const idToken = yield call(getAuthToken);

  const { mainGoalSettingId, value } = payload;

  const data = {
    area: 'DASHBOARD',
    key: 'metrics.goals.monthly.main',
    value: {
      goal: value,
    },
  };

  // Check if there is no main goal setting.
  if (mainGoalSettingId === -1) {
    yield call(createMainGoal, idToken, data);
  } else {
    yield call(updateMainGoal, idToken, mainGoalSettingId, data);
  }
}

export function* fetchGoalsAnalytics({ payload }) {
  try {
    const idToken = yield call(getAuthToken);
    const { id: branchId } = yield call(getBranch);

    if (idToken && branchId) {
      const startTime = `${dayjs(payload).startOf('month').toISOString()}`;

      const endTime = `${dayjs(payload).endOf('month').toISOString()}`;

      const rentalsResponse = yield call(AnalyticsService.getAnalyticsByQuery, idToken, 'goal', {
        startTime,
        endTime,
        state: 'ENDED',
        branchId,
      });

      if (rentalsResponse.status !== 200) {
        yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.errorTips');
        return;
      }

      const rentalsResult = yield call([rentalsResponse, rentalsResponse.json]);
      const {
        signups,
        rentals,
        totalRevenueGross: revenue,
      } = get(rentalsResult, '0', {
        signups: 0,
        rentals: 0,
        revenue: 0,
      });

      const subscriptionsResponse = yield call(
        AnalyticsService.getAnalyticsByQuery,
        idToken,
        'customers/registrations',
        {
          startTime,
          endTime,
          branchId,
        }
      );

      if (subscriptionsResponse.status !== 200) {
        yield call(errorNotification, 'feedback.alert.errorTips', 'feedback.alert.errorTips');
        return;
      }

      const { totalCount: subscriptions } = yield call([
        subscriptionsResponse,
        subscriptionsResponse.json,
      ]);

      yield put(
        actions.fetchGoalsAnalyticsSuccess({
          month: payload || dayjs().format(longYearNumericMonthFmt),
          value: {
            signups: signups || 0,
            rentals: rentals || 0,
            revenue: revenue || 0,
            subscriptions: subscriptions || 0,
          },
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionStrings.ADD_GOAL, createOrUpdateGoal),
    yield takeEvery(actionStrings.UPDATE_GOAL, createOrUpdateGoal),
    yield takeEvery(actionStrings.UPDATE_MAIN_GOAL, createOrUpdateMainGoal),
    yield takeEvery(actionStrings.FETCH_GOALS_ANALYTICS, fetchGoalsAnalytics),
  ]);
}
