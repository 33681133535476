import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { actions } from '../../redux/app/actions';
import { getPermissions } from '../../redux/authority/selectors';
import { getIsPluginActive } from '../../redux/plugins/selectors';
import { NavigationButton } from './navigation.styles';
import useGoDashIntegration from '../../hooks/useGoDashIntegration';
import useTenantInfo from '../../hooks/useTenantInfo';

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * Component that is rendering top navigation of table depend on `permissions` and `routes`.
 * @param {React.Props<NavigationHeader.propTypes>} props - props of component.
 */
const NavigationHeader = ({ permissions = {}, routes = [], isPluginActive, dispatch }) => {
  const { includesFeature } = useTenantInfo();

  const { redirectToGoDash } = useGoDashIntegration();

  return (
    <NavigationContainer>
      {routes.map((value, index) => {
        const { route, routeName, selected, permission, plugin, feature } = value;
        /** does user have permission for `route` */
        const hasPermission = permission ? get(permissions, permission, false) : true;
        const hasFeature = feature ? includesFeature(feature) : true;
        /** if any app plugin is required for table navigation */
        const activePlugin = plugin ? isPluginActive(plugin) : true;
        return (
          hasPermission &&
          hasFeature &&
          activePlugin && (
            <Link
              key={route}
              to={route}
              onClick={e => {
                if (value.externalUrl) {
                  e.preventDefault();
                  redirectToGoDash(value.externalUrl);
                  return;
                }
                dispatch(actions.urlChange(true));
              }}
            >
              {' '}
              <NavigationButton
                type="link"
                className={`${selected && 'selected'} ${index > 0 && 'right'}`}
              >
                {routeName}
              </NavigationButton>
            </Link>
          )
        );
      })}
    </NavigationContainer>
  );
};

NavigationHeader.propTypes = {
  /** object that contains `permissions` of user  */
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.object.isRequired,
  /** array of `route` object */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      permission: PropTypes.string,
      selected: PropTypes.bool,
      routeName: PropTypes.node,
    })
  ).isRequired,
  isPluginActive: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

NavigationHeader.defaultProps = {
  isPluginActive: () => false,
};

const mapStateToProps = state => ({
  permissions: getPermissions(state),
  isPluginActive: pluginName => getIsPluginActive(state, pluginName),
});

export default connect(mapStateToProps)(NavigationHeader);
