import { APPLE_PAY_SETTING } from './settings';

// plugins settings constants
export const PLUGIN_KEY_AVAILABLE = 'plugins.available';
export const PLUGIN_KEY_ACTIVATED = 'plugins.activated';

export const PLUGIN_KEY_FRESHDESK = 'plugins.freshdesk';
export const FRESHDESK_PLUGIN_NAME = 'freshdesk';
export const USER_APP = 'userApp';
export const PLUGIN_KEY_APAYA = 'payment.apaya';
export const APAYA_PLUGIN_NAME = 'apaya';
export const PLUGIN_KEY_ZENDESK = 'zendesk';
export const ZENDESK_PLUGIN_NAME = 'zendesk';
export const ONE_SIGNAL_PLUGIN_NAME = 'onesignal';
export const STATION_PLUGIN_NAME = 'stations';
export const POI_PLUGIN_NAME = 'poi';
export const ANALYTICS_PLUGIN_NAME = 'analytics';
export const VOUCHER_PLUGIN_NAME = 'vouchers';
export const PLUGIN_KEY_ONE_SIGNAL = 'plugins.onesignal';
export const SUBSCRIPTIONS_PLUGIN_NAME = 'subscriptions';
export const PLUGIN_KEY_EXTENDED_RESERVATION = 'reservation.extension';
export const EXTENDED_RESERVATION_PLUGIN_NAME = 'reservation';
export const PHONE_NUMBER_BLACKLIST_PLUGIN_NAME = 'phoneNumberBlacklist';
export const PHONE_NUMBER_BLACKLIST_KEY = 'user.registration.phone-number-filter';
export const USER_ACCOUNTS_PLUGIN_KEY = 'user.accounts';
export const ADJUST_PLUGIN_KEY = 'plugins.adjust';
export const ADJUST_PLUGIN_NAME = 'adjust';
export const SERVICE_OPERATIONS_PLUGIN_NAME = 'serviceOperations';

export const CASHBACK_PLUGIN_NAME = 'cashback';
export const ISSUES_PLUGIN_NAME = 'issues';
export const VERIFF_PLUGIN_NAME = 'veriff';
export const TWILIO_PLUGIN_NAME = 'twilio';
export const GOALS_PLUGIN_NAME = 'goals';
export const RECURRING_TASKS_PLUGIN_NAME = 'recurringTasks';
export const PAY_PAL_PLUGIN_NAME = 'payPal';
export const BANCONTACT_PLUGIN_NAME = 'bancontact';
export const BUSINESS_ACCOUNTS_NAME = 'businessAccounts';
export const CUSTOM_DASHBOARD_PAGE_NAME = 'customDashboardPage';
export const GROUPED_BOOKINGS_PLUGIN = 'groupedBookings';

export const SIMPLE_STRING_SETTING = 'SIMPLE_STRING_SETTING';
export const FEATURE_TOGGLE = 'FEATURE_TOGGLE';
export const PLUGIN_TOGGLE_SETTING = 'PLUGIN_TOGGLE_SETTING';
export const PAYMENT_SETTING_APAYA = 'PAYMENT_SETTING_APAYA';
export const APPLE_PAY_PLUGIN = 'applepay';

export const PLUGIN_EXTERNAL_ONE_SIGNAL_API_KEY = 'external.onesignal.api-key';
export const PLUGIN_EXTERNAL_ONE_SIGNAL_API_ID = 'external.onesignal.api-id';
export const PLUGIN_EXTERNAL_ONE_SIGNAL_PAID_PLAN = 'external.onesignal.paid-plan';

// available plugins in format: {[pluginName]: plugin default value}
// TODO: add new plugins here, default will be created with info from here;
export const PLUGINS = {
  [PLUGIN_KEY_FRESHDESK]: {
    DASHBOARD: { apikey: '', domain: '' },
  },
  [PLUGIN_KEY_APAYA]: {
    CORE: {
      productId: '',
      enabled: true,
    },
  },
  [PLUGIN_KEY_ZENDESK]: {
    APP: {
      showOfflineForm: false,
      showAgentAvailability: false,
      showChatTranscriptPrompt: false,
      key: '',
    },
  },
  [PLUGIN_EXTERNAL_ONE_SIGNAL_API_KEY]: {
    CORE: { value: '' },
  },
  [PLUGIN_EXTERNAL_ONE_SIGNAL_API_ID]: {
    SHARED: { value: '' },
  },
  [PLUGIN_EXTERNAL_ONE_SIGNAL_PAID_PLAN]: {
    CORE: { enabled: false },
  },
  [PLUGIN_KEY_EXTENDED_RESERVATION]: {
    APP: { active: false, extensionTime: 0 },
  },
  [USER_ACCOUNTS_PLUGIN_KEY]: {
    APP: { subscriptions: { active: false } },
  },
  [ADJUST_PLUGIN_KEY]: {
    APP: { token: '' },
  },
  [APPLE_PAY_SETTING]: {
    APP: { supportedNetworks: [] },
  },
  [POI_PLUGIN_NAME]: {
    SHARED: { value: '' },
  },
  [ANALYTICS_PLUGIN_NAME]: {
    SHARED: { value: '' },
  },
  [PHONE_NUMBER_BLACKLIST_KEY]: {
    CORE: {},
  },
};

export const ACTIVE_PLUGINS = [
  FRESHDESK_PLUGIN_NAME,
  USER_APP,
  APAYA_PLUGIN_NAME,
  ZENDESK_PLUGIN_NAME,
  ONE_SIGNAL_PLUGIN_NAME,
  POI_PLUGIN_NAME,
  ANALYTICS_PLUGIN_NAME,
  VOUCHER_PLUGIN_NAME,
  STATION_PLUGIN_NAME,
  SUBSCRIPTIONS_PLUGIN_NAME,
  EXTENDED_RESERVATION_PLUGIN_NAME,
  ISSUES_PLUGIN_NAME,
  VERIFF_PLUGIN_NAME,
  TWILIO_PLUGIN_NAME,
  GOALS_PLUGIN_NAME,
  RECURRING_TASKS_PLUGIN_NAME,
  PAY_PAL_PLUGIN_NAME,
  BANCONTACT_PLUGIN_NAME,
  BUSINESS_ACCOUNTS_NAME,
  ADJUST_PLUGIN_NAME,
  CUSTOM_DASHBOARD_PAGE_NAME,
  CASHBACK_PLUGIN_NAME,
  APPLE_PAY_PLUGIN,
  GROUPED_BOOKINGS_PLUGIN,
  SERVICE_OPERATIONS_PLUGIN_NAME,
  PHONE_NUMBER_BLACKLIST_PLUGIN_NAME,
];

/** `setting` value type for some plugin, used for backend JSON deserialization */
export const SETTING_VALUE_TYPE = {
  [PLUGIN_EXTERNAL_ONE_SIGNAL_API_KEY]: SIMPLE_STRING_SETTING,
  [PLUGIN_EXTERNAL_ONE_SIGNAL_API_ID]: SIMPLE_STRING_SETTING,
  [PLUGIN_EXTERNAL_ONE_SIGNAL_PAID_PLAN]: FEATURE_TOGGLE,
  [PLUGIN_KEY_APAYA]: PAYMENT_SETTING_APAYA,
};

// Alwayys add there to set isPublic true
export const PUBLIC_PLUGINS = [ZENDESK_PLUGIN_NAME];
