import ApiService from './ApiService';
import { createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/payments`;

class PaymentService {
  /**
   * Method for removing payment source with specified query params.
   * @param {string} paymentId - payment source id.
   * @param {string} authToken - Authentication bearer token.
   * @param {object} params - object which contains query param values.
   */
  static delete(paymentId, AuthToken, params) {
    const queryParams = createStringFromParams(params, '?');
    const data = {
      sourceId: paymentId,
    };
    return ApiService.ApiCallBody(
      'DELETE',
      `${BASE_URL}${queryParams}`,
      data,
      AuthToken,
      'application/json'
    );
  }

  static manulRetry(AuthToken, paymentId) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}/${paymentId}/retry`,
      {},
      AuthToken,
      'application/json'
    )
      .then(response => response)
      .catch(err => err);
  }
}
export default PaymentService;
