import omit from 'lodash/omit';
import ApiService from './ApiService';
import { createStringFromParams, createStringFromFilter } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/events`;

class LogsService {
  static getLogs(AuthToken, _, params) {
    const filtersString = createStringFromFilter(params.filters);
    // eslint-disable-next-line no-param-reassign
    delete params.filters;
    // TODO filtering logs between start and end dates need to send `createdAt` twice
    const paramString = `${createStringFromParams(omit(params, ['endTime']))}${
      params.endTime ? `&createdAt=${params.endTime}` : ''
    }`;
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}?${paramString}${filtersString}`,
      AuthToken,
      'application/json'
    )
      .then(response => response)
      .catch(err => err);
  }
}
export default LogsService;
