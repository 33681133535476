/* eslint global-require: 0 */

import Enlang from './entries/en-US';
import DeLang from './entries/de';
import FrLang from './entries/fr-FR';

const AppLocale = {
  en: Enlang,
  de: DeLang,
  fr: FrLang,
};

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/de');
  require('@formatjs/intl-pluralrules/locale-data/fr');
}
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  require('@formatjs/intl-relativetimeformat/locale-data/de');
  require('@formatjs/intl-relativetimeformat/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/polyfill');
}

export default AppLocale;
