/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { subdomain } from '../config';

/** `indicator` if there's localhost started instead of production env */
const isLocalHost =
  window?.location?.hostname?.includes('localhost') || window.location.hostname === '127.0.0.1';

/** `indicator` is staging env */
const isStaging = subdomain?.includes('staging');
/** `indicator` is demo env */
const isDemo = subdomain?.startsWith('demo');
/** `indicator` is some of goUrban envs */
const isGoEnv = subdomain?.startsWith('go-');

const skipLogging = isLocalHost || isStaging || isDemo || isGoEnv;

const firebaseConfig = {
  apiKey: 'AIzaSyAUvRJo5vjP-4H5wtqeQGYRoT6CMTfMeBw',
  authDomain: 'gourban-dashboard.firebaseapp.com',
  projectId: 'gourban-dashboard',
  storageBucket: 'gourban-dashboard.appspot.com',
  messagingSenderId: '381747242543',
  appId: '1:381747242543:web:2410e58838db1d5cf988da',
  measurementId: 'G-W8VQB68EPV',
};

// Initialize Firebase
(() => {
  if (!skipLogging) {
    initializeApp(firebaseConfig);
  }
})();

export const logEvtToFirebase = (evtName, evtProps) => {
  if (!skipLogging) {
    const analytics = getAnalytics();
    logEvent(analytics, evtName, evtProps);
  }
};

// #region
// Custom event names
export const FILTER_CHECK = 'filter_check';
export const NAVIGATION = 'navigation';
export const TABLE_FEATURE = 'table_feature';
export const PAGE_FEATURE = 'page_feature';
// #endregion

// #region
// custom table features names
export const TABLE_COLUMN = 'table_column';
export const TABLE_PAGINATION = 'table_pagination';
export const TABLE_SORT = 'table_sort';
export const TABLE_FILTER = 'table_filter';
export const CUSTOM_TEXT_SEARCH = 'custom_text_search';
export const CSV_BUTTON = 'csv_button';
export const BULK_BUTTON = 'bulk_button';
export const ADD_NEW = 'add_new';
// #endregion
