import ApiService from './ApiService';
import { createStringFromParams } from './util';

const BASE_URL = `${ApiService.BACKEND_URL}/back/`;

class NoteService {
  static getNotes(AuthToken, branch, payload) {
    const paramString = createStringFromParams(payload.queryParams).substr(1);
    return ApiService.ApiCallAuth(
      'GET',
      `${BASE_URL}${payload.entity}/${payload.entityId}/notes?${paramString}`,
      AuthToken,
      'application/json'
    )
      .then(response => response)
      .catch(err => err);
  }

  /**
   * Retrieve notes for entity with provided filters through query params.
   *
   * If we need to sort by a different order or paginate, pass along `size`, `page`, `sort`.
   *
   * @param {string} authToken - Authentication bearer token.
   * @param {object} options - Request cancellation signal, as well as query parameters
   * that signify which notes for which entity we're retrieving the notes.
   */
  static getNotesAbortable(
    authToken,
    {
      entity,
      entityId,
      signal,
      queryParams: { size = 20, sort = 'id,DESC', page = 0, state = 'ACTIVE' },
    }
  ) {
    const options = {
      method: 'GET',
      signal,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };
    return ApiService.ApiCallAbortable(
      `${BASE_URL}${entity}/${entityId}/notes?&size=${size}&sort=${sort}&page=${page}&state=${state}`,
      options
    );
  }

  /**
   * Initiate `POST` request to create a new note for a given vehicle.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} vehicleId - The id of the vehicle to create task for.
   * @param {string} title - The title of the note.
   * @param {string} content - The notes content body.
   */
  static createVehicleNote(AuthToken, vehicleId, title, content) {
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}vehicles/${vehicleId}/notes`,
      { title, content },
      AuthToken,
      'application/json'
    );
  }

  /**
   * Run a `PATCH` request updating the state of the note to either 'ACTIVE' or 'INACTIVE'.
   * @param {string} AuthToken - Authentication bearer token.
   * @param {number} noteId - The id of the note to run request for.
   * @param {'INACTIVE' | 'ACTIVE'} state - Value to change the note status to.
   */
  static updateNote(AuthToken, noteId, state) {
    return ApiService.ApiCallBody(
      'PATCH',
      `${BASE_URL}notes/${noteId}`,
      { state },
      AuthToken,
      'application/json'
    );
  }

  static createNote(AuthToken, branchId, payload) {
    const body = {
      title: payload.data.title,
      content: payload.data.content,
    };
    return ApiService.ApiCallBody(
      'POST',
      `${BASE_URL}${payload.entity}/${payload.entityId}/notes`,
      body,
      AuthToken,
      'application/json'
    )
      .then(response => response)
      .catch(err => err);
  }
}
export default NoteService;
