import PropTypes from 'prop-types';

export const dashboardPropertiesType = PropTypes.shape({
  imgMapCluster: PropTypes.string,
  zoomLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

// eslint-disable-next-line import/prefer-default-export
export const branchType = PropTypes.shape({
  backendAuthorities: PropTypes.array,
  businessArea: PropTypes.shape({ type: PropTypes.string, coordinates: PropTypes.array }),
  code: PropTypes.string,
  contactInfo: PropTypes.shape({ email: PropTypes.string, phone: PropTypes.string }),
  id: PropTypes.number,
  mapOverlayProhibited: PropTypes.shape({
    type: PropTypes.string,
    coordinates: PropTypes.array,
  }),
  mapOverlayProhibitedColor: PropTypes.string,
  mapOverlayWarning: PropTypes.shape({ type: PropTypes.string, coordinates: PropTypes.array }),
  mapOverlayWarningColor: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.number,
  position: PropTypes.shape({ type: PropTypes.string, coordinates: PropTypes.array }),
  priceCalcType: PropTypes.string,
  state: PropTypes.string,
  timeZoneId: PropTypes.string,
  type: PropTypes.string,
  vehicleTypeAuthorization: PropTypes.object,
  dashboardProperties: dashboardPropertiesType,
});
