/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import get from 'lodash/get';

const getAppState = state => state.App;
const getBranchState = state => state.App?.branch;
const getAutoRefreshState = state => state.App?.autoRefresh;

export const getApp = createSelector(getAppState, app => app);
export const getBranch = createSelector(getBranchState, branch => branch);
export const getAutoRefresh = createSelector(getAutoRefreshState, ar => ar);

const getIsBranchBillableState = state => get(getBranch(state), 'selected.isBillable');
const getBranchIdState = state => get(getBranch(state), 'selected.id');
const getSelectedBranchNameState = state => get(getBranch(state), 'selected.name');
const getCurrentUserDetailsState = state => (state.App?.current_user || { guser: {} }).guser;
const getCurrentUserState = state => state.App?.current_user;
const getSelectedBranchState = state => state.App?.branch?.selected;
const getIsFocusedState = state => state.App.focused;
const getCurrentHeightState = state => state.App.height;
const getCurrentWidthState = state => state.App.width;
const getCurrentViewState = state => state.App.view;
const getIsMobileViewState = state => state.App.view === 'MobileView';
const getCurrentMenuIconState = state => state.App.dashboardMenuIcon;
const getDrawerState = state => state.App.openDrawer;
const getOpenKeysState = state => state.App.openKeys;
const getCollapsedState = state => state.App.collapsed;
const getCurrentState = state => state.App.current;
const getLoadingDataState = state => state.App.loadingData;
const getAllVehicleFilters = state => state.App.vehicleFilters;
const getIsUrlChanged = state => state.App.urlChanged;

const getRFIDs = state => state.App.RFIDs;

/**
 * Selector containing the path of the `isBillable` property for the selected branch.
 * @param {object} state - Redux state object.
 */
export const getIsBranchBillable = createSelector(
  getIsBranchBillableState,
  isBillable => isBillable
);

export const getRFIDCards = createSelector(getRFIDs, cards => cards);

/**
 * Selector that returns the id of the currently selected branch.
 * @param {object} state - Redux state object.
 */
export const getBranchId = createSelector(getBranchIdState, branchId => branchId);

/**
 * Selector that returns the name of the currently selected branch.
 * @param {object} state - Redux state object.
 */
export const getSelectedBranchName = createSelector(
  getSelectedBranchNameState,
  selectedBranchName => selectedBranchName
);

/**
 * Selector that returns current logged in user details.
 * @param {object} state - Redux state object.
 */
export const getCurrentUserDetails = createSelector(
  getCurrentUserDetailsState,
  currentUserDetails => currentUserDetails
);

/**
 * Selector that returns current logged in user.
 * @param {object} state - Redux state object.
 */
export const getCurrentUser = createSelector(getCurrentUserState, currentUser => currentUser);

/**
 * Selector used to get selected branch
 * @param {object} state - Redux state object.
 */
export const getSelectedBranch = createSelector(
  getSelectedBranchState,
  selectedBranch => selectedBranch
);

/**
 * Selector to check is app tab focused.
 * @param {object} state - Redux state object
 */
export const getIsFocused = createSelector(getIsFocusedState, isFocused => isFocused);

/**
 * Selector to get current app height.
 * @param {object} state - Redux state object.
 */
export const getCurrentHeight = createSelector(
  getCurrentHeightState,
  currentHeight => currentHeight
);

/**
 * Selector to get current app height.
 * @param {object} state - Redux state object.
 */
export const getCurrentWidth = createSelector(getCurrentWidthState, currentWidth => currentWidth);

/**
 * Selector to get current app view.
 * @param {object} state - Redux state object.
 */
export const getCurrentView = createSelector(getCurrentViewState, viewState => viewState);

/**
 * Selector to get current app view.
 * @param {object} state - Redux state object.
 */
export const getIsMobileView = createSelector(getIsMobileViewState, isMobile => isMobile);

/**
 * Selector to get dashboard menu icon.
 * @param {object} state - Redux state object.
 */
export const getCurrentMenuIcon = createSelector(getCurrentMenuIconState, menuIcon => menuIcon);

/**
 * Selector to get open drawer from state
 * @param {object} state - Redux state object.
 */
export const getDrawer = createSelector(getDrawerState, drawer => drawer);

/**
 * Selector to get open drawer from state
 * @param {object} state - Redux state object.
 */
export const getOpenKeys = createSelector(getOpenKeysState, keys => keys);

/**
 * Selector to get collapsed from state
 * @param {object} state - Redux state object.
 */
export const getCollapsed = createSelector(getCollapsedState, collapsed => collapsed);

/**
 * Selector to get current from state
 * @param {object} state - Redux state object.
 */
export const getCurrent = createSelector(getCurrentState, current => current);

/**
 * Selector to get loading data from state
 * @param {object} state - Redux state object.
 */
export const getLoadingData = createSelector(getLoadingDataState, data => data);

/**
 * Selector to get service states from state
 * @param {object} state - Redux state object.
 */
export const getVehicleFilters = createSelector(getAllVehicleFilters, data => data);

/**
 * Selector to track if url changed
 * @param {object} state - Redux state object.
 */
export const isUrlChanged = createSelector(getIsUrlChanged, data => data);
