import PropTypes from 'prop-types';

export default {
  id: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdById: PropTypes.number,
  updatedById: PropTypes.number,
  area: PropTypes.string,
  key: PropTypes.string,
  value: PropTypes.object,
  branchId: PropTypes.number,
};
