/**
 * Attempts to validate a given HTTP response by checking the usual properties
 * provided and specified the the REST specification.
 * @param {HttpResponse} response - The response object to validate.
 */
const responseOk = response => {
  if (typeof response.status === 'number') {
    if (response.status >= 200 && response.status < 300) return true;
  }
  if (typeof response.statusCode === 'number') {
    if (response.statusCode >= 200 && response.statusCode < 300) return true;
  }
  return false;
};

export default responseOk;
