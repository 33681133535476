import { actionPayload } from '../common/commonActions';

const actionStrings = {
  MANUAL_PAYMENT_RETRY: 'MANUAL_PAYMENT_RETRY',
};

const actions = {
  manualPaymentRetry: actionPayload(actionStrings.MANUAL_PAYMENT_RETRY),
};

export default { ...actionStrings, ...actions };
