import { availableEntities as _ } from '../../redux-config';

import {
  RentalService,
  IssuesService,
  AnalyticsService,
  VehicleService,
  CustomersService,
  InvoiceService,
  BookingService,
  NoteService,
  AnnotationsService,
  TasksService,
  DamageService,
  StationsService,
  LogsService,
  AuthorityService,
  IotModuleService,
  VoucherService,
  BusinessAccountsService,
  VoucherableService,
  ExportService,
  BalanceService,
  SubscriptionsService,
  ServiceAgentsService,
} from '../../services/coreAPI';
import AdditionsService from '../../services/coreAPI/AdditionsService';
import PoiService from '../../services/coreAPI/PoiService';

export const fetchTable = {
  [_.VEHICLES]: VehicleService.getAllVehicles,
  [_.POI]: PoiService.getAllPoi,
  [_.CUSTOMERS]: CustomersService.getCustomers,
  [_.INVOICES]: InvoiceService.getInvoices,
  [_.RENTALS]: RentalService.getRentals,
  [_.TASKS]: TasksService.getTasks,
  [_.ISSUES]: IssuesService.getIssues,
  [_.HEATDATAS]: AnalyticsService.getAnalyticsByQuery,
  [_.BOOKINGS]: BookingService.getBookings,
  [_.NOTES]: NoteService.getNotes,
  [_.ANALYTICS_CUSTOMERS]: AnalyticsService.getCustomersRegistrations,
  [_.ANALYTICS_RENTALS]: AnalyticsService.getAnalyticsData,
  [_.ANALYTICS_RENTALS_OVERVIEW]: AnalyticsService.getRentalsOverview,
  [_.ANALYTICS_CUSTOMERS_REGISTRATIONS]: AnalyticsService.getAnalyticsData,
  [_.ANALYTICS_BALANCES]: AnalyticsService.getBalances,
  [_.ANNOTATIONS]: AnnotationsService.getAllAnnotations,
  [_.BALANCES]: BalanceService.fetchBalanceUpdates,
  [_.DAMAGES]: DamageService.getAllDamages,
  [_.LOGS]: LogsService.getLogs,
  [_.ROLES]: AuthorityService.getUserRolesAssignment,
  [_.ADDITIONS]: AdditionsService.getAdditions,
  [_.EXPORT_SERVICES]: ExportService.getAllExports,
  [_.IOT_MODULES]: IotModuleService.getIotModules,
  [_.VOUCHERS]: VoucherService.getVouchers,
  [_.BUSINESS_ACCOUNTS]: BusinessAccountsService.getBusinessAccounts,
  [_.STATIONS]: StationsService.getAllStations,
  [_.VOUCHERABLES]: VoucherableService.getVoucherables,
  [_.SERVICE_AGENTS]: ServiceAgentsService.getAgents,
  [_.SUBSCRIPTIONS]: SubscriptionsService.getSubscriptions,
};

export const fetchDetail = {
  [_.CUSTOMERS]: CustomersService.getCustomerDetail,
};

/**
 * COMMON SERVICE CALLS
 */

// TODO: add all API calls here
export const CallDownloadInvoice = (AuthToken, invoiceId) =>
  InvoiceService.downloadInvoices(AuthToken, invoiceId);

export const CallUpdateCustomer = (idToken, branchId, customer, key, value) =>
  CustomersService.updateCustomer(idToken, customer, key, value);

export const CallUpdateCustomerMultipleValues = (idToken, branchId, customer, data) =>
  CustomersService.updateCustomerMultipleFields(idToken, customer, data);

export const CallCreateVoucher = (AuthToken, branchId, data) =>
  CustomersService.createVoucher(AuthToken, branchId, data);
