import omit from 'lodash/omit';
import { filterActions } from 'redux-ignore';
import { actionStrings } from './actions';
import {
  SUPPORT_METHODS,
  AREAS,
  NAVIGATION,
  THEME,
  THEME_COLORS_MAIN,
  THEME_COLORS_MAIN_DARK,
  THEME_SPLASHSCREEN,
  SCREENS_LOGIN,
  APP_MAP,
  APP_RENTAL,
  APP_MAP_CLUSTER_IMAGE,
  THEME_COLORS_MAP,
  THEME_COLORS_MAP_DARK,
  VEHICLES,
  BOOKING_AREA,
  QR_CODE,
  DRAWER,
  USER_ACCOUNTS,
  USER_DATA_UPDATE_PERMISSIONS,
  APPLE_PAY_SETTING,
  USER_REQUIRED_FIELDS,
  USER_FEEDBACK,
} from '../../constants/settings';

const initState = {
  app: {
    isLoading: false,
    [SUPPORT_METHODS]: {
      id: -1,
      area: 'APP',
      key: SUPPORT_METHODS,
      value: {
        whatsapp: '',
        telegram: '',
        phone: '',
        messenger: '',
        email: '',
      },
      branchIds: [0],
    },
    [AREAS]: {
      id: -1,
      area: 'APP',
      key: AREAS,
      value: {
        areas: {
          discount: {
            visible: false,
          },
        },
      },
      branchIds: [0],
    },
    [NAVIGATION]: {
      id: -1,
      area: 'APP',
      key: NAVIGATION,
      value: {},
      branchIds: [0],
    },
    [THEME]: {
      id: -1,
      area: 'APP',
      key: THEME,
      value: {
        darkMode: false,
      },
      branchIds: [0],
    },
    [THEME_COLORS_MAIN]: {
      id: -1,
      area: 'APP',
      key: THEME_COLORS_MAIN,
      value: {
        primary: '',
        active: '',
        activeButton: '',
        success: '',
        danger: '',
        background: '',
      },
      branchIds: [0],
    },
    [THEME_COLORS_MAIN_DARK]: {
      id: -1,
      area: 'APP',
      key: THEME_COLORS_MAIN_DARK,
      value: {
        primary: '',
        active: '',
        activeButton: '',
        success: '',
        danger: '',
        background: '',
      },
      branchIds: [0],
    },
    [THEME_SPLASHSCREEN]: {
      id: -1,
      area: 'APP',
      key: THEME_SPLASHSCREEN,
      value: {
        type: '',
        image: '',
      },
      branchIds: [0],
    },
    [SCREENS_LOGIN]: {
      id: -1,
      area: 'APP',
      key: SCREENS_LOGIN,
      value: {
        welcomeText: false,
        welcomeImage: '',
        backgroundImage: '',
      },
      branchIds: [0],
    },
    [APP_MAP]: {
      id: -1,
      area: 'APP',
      key: APP_MAP,
      value: {
        directionType: '',
        mapDefaultZoomLvl: 0,
        mapLogo: false,
        initialLatitude: 0,
        initialLongitude: 0,
        mapboxToken: '',
      },
      branchIds: [0],
    },
    [APP_RENTAL]: {
      id: -1,
      area: 'APP',
      key: APP_RENTAL,
      value: {
        qr: false,
      },
      branchIds: [0],
    },
    [APP_MAP_CLUSTER_IMAGE]: {
      id: -1,
      area: 'APP',
      key: APP_MAP_CLUSTER_IMAGE,
      value: {
        branchImages: false,
        isVisible: false,
      },
      branchIds: [0],
    },
    [THEME_COLORS_MAP]: {
      id: -1,
      area: 'APP',
      key: THEME_COLORS_MAP,
      value: {
        mapOverlayWarning: '',
        mapOverlayProhibited: '',
        businessArea: '',
        mapStyle: '',
        mapOverlayDiscount: '',
      },
      branchIds: [0],
    },
    [THEME_COLORS_MAP_DARK]: {
      id: -1,
      area: 'APP',
      key: THEME_COLORS_MAP_DARK,
      value: {
        mapOverlayWarning: '',
        mapOverlayProhibited: '',
        businessArea: '',
        mapStyle: '',
        mapOverlayDiscount: '',
      },
      branchIds: [0],
    },
    [VEHICLES]: {
      id: -1,
      area: 'APP',
      key: VEHICLES,
      value: {
        identifier: '',
      },
      branchIds: [0],
    },
    [BOOKING_AREA]: {
      id: -1,
      area: 'APP',
      key: BOOKING_AREA,
      value: {
        showGrossPrice: false,
        properties: {
          customBookingSlots: false,
          bookingSlots: [],
          timeUnit: '',
        },
      },
      branchIds: [0],
    },
    [QR_CODE]: {
      id: -1,
      area: 'SHARED',
      key: QR_CODE,
      value: {
        qrUnlock: false,
      },
      branchIds: [0],
    },
    [DRAWER]: {
      id: -1,
      area: 'APP',
      key: DRAWER,
      value: {
        userStatsElements: [],
        navigationElements: [],
      },
      branchIds: [0],
    },
    [USER_ACCOUNTS]: {
      id: -1,
      area: 'APP',
      key: USER_ACCOUNTS,
      value: {
        businessAccounts: {
          active: false,
        },
      },
      branchIds: [0],
    },
    [APPLE_PAY_SETTING]: {
      id: -1,
      area: 'APP',
      key: APPLE_PAY_SETTING,
      value: {
        merchantIdentifier: '',
        supportedNetworks: [],
      },
      branchIds: [0],
    },
    [USER_REQUIRED_FIELDS]: {
      id: -1,
      area: 'APP',
      key: USER_REQUIRED_FIELDS,
      value: {
        profile: {
          city: {
            visible: false,
            editable: false,
            required: false,
          },
          email: {
            visible: false,
            editable: false,
            required: false,
          },
          phone: {
            visible: false,
            editable: false,
            required: false,
          },
          street: {
            visible: false,
            editable: false,
            required: false,
          },
          country: {
            visible: false,
            editable: false,
            required: false,
          },
          lastName: {
            visible: false,
            editable: false,
            required: false,
          },
          postcode: {
            visible: false,
            editable: false,
            required: false,
          },
          birthdate: {
            visible: false,
            editable: false,
            required: false,
          },
          firstName: {
            visible: false,
            editable: false,
            required: false,
          },
          helmetSize: {
            visible: false,
            editable: false,
            required: false,
          },
        },
      },
      branchIds: [0],
    },
    [USER_FEEDBACK]: {
      id: -1,
      area: 'APP',
      key: USER_FEEDBACK,
      value: {
        tags: [],
      },
      branchIds: [0],
      isPublic: true,
    },
  },
  map: {
    'map.view': [],
  },
  core: {
    [USER_DATA_UPDATE_PERMISSIONS]: {
      id: -1,
      area: 'CORE',
      key: USER_DATA_UPDATE_PERMISSIONS,
      value: {
        firstName: false,
        lastName: false,
        birthdate: false,
        phone: false,
        email: false,
        address: false,
        customProperties: false,
        newsletter: false,
        street: false,
        postcode: false,
        city: false,
        country: false,
      },
      settingValueType: 'END_USER_DATA_UPDATE_PERMISSION_SETTINGS',
      branchIds: [0],
    },
  },
  // TODO: When every new settings object is added, it should be a new key to state
  // like `app` or `map....`
  // branchIds: [0] used for default settings logic
};

const resourcesReducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.FETCH_ALL_SETTINGS_SUCCESS:
      return {
        ...state,
        app: {
          ...state.app,
          ...action.payload.app,
          isLoading: false,
        },
        core: {
          ...state.core,
          ...action.payload.core,
          isLoading: false,
        },
        map: {
          ...state.map,
          ...action.payload.map,
          isLoading: false,
        },
      };
    case actionStrings.FETCH_SETTINGS:
      return {
        ...state,
        app: {
          ...state.app,
          isLoading: true,
        },
      };
    case actionStrings.FETCH_SETTINGS_SUCCESS_CHANGE_LOADING:
      return {
        ...state,
        app: {
          ...state.app,
          isLoading: action.payload,
        },
      };
    case actionStrings.CREATE_OR_UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        [action.payload.stateKey]: {
          ...state[action.payload.stateKey],
          [action.payload.key]: omit(action.payload, 'stateKey'),
        },
      };
    default:
      return state;
  }
};

export default filterActions(resourcesReducer, [
  actionStrings.FETCH_ALL_SETTINGS_SUCCESS,
  actionStrings.FETCH_SETTINGS,
  actionStrings.FETCH_SETTINGS_SUCCESS_CHANGE_LOADING,
  actionStrings.CREATE_OR_UPDATE_SETTING_SUCCESS,
]);
