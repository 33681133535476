import { all, takeEvery, call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { actionStrings, actions } from './actions';
import AuthService from '../../services/coreAPI/AuthService';
import { handleNotification } from '../common/commonSagas';
import { getCurrentUserDetails as getCurrentUser } from '../app/selectors';
import authorityActions from '../authority/actions';
import appActions from '../app/actions';
import { ROLES } from '../../constants/roles';
import { pagesPermissions } from '../../constants/pages-name-route';
import waitFor from '../common/waitFor';
import constantsForm from '../../components/public/signin/constantsForm';
import getDefaultPath from '../../helpers/urlSync';
import { getAllBranches } from '../branches/selectors';

const { QR_CODE_OR_6DIGIT_FORM } = constantsForm;

function* redirectToPage() {
  const state = yield select();
  // Get current user details
  const currentUser = yield select(getCurrentUser);
  // If current user has set favorite branch
  const favoriteBranchId = yield call(get, currentUser, ['customProperties', 'favoriteBranchId']);
  // If user tried to access any page before login
  const redirectTo = yield call([localStorage, localStorage.getItem], 'redirect-to-url');
  // If current user has set favorite page
  const favoritePage = yield call(get, currentUser, ['customProperties', 'favoritePage'], '');
  // Get user permission from the app state
  const userPermissions = yield call(get, state.Authority, 'permissions');
  // Check if user has permission to redirect favorite page
  const canAccessFavoritePage = pagesPermissions[favoritePage.replace('/', '')]
    ? userPermissions[pagesPermissions[favoritePage.replace('/', '')]]
    : true;
  // Set selected branch id to local storage
  if (favoriteBranchId) {
    yield put(appActions.selectAndSaveBranch(favoriteBranchId));
  }

  // Set the exact redirect page
  const redirectToPageUrl = redirectTo || canAccessFavoritePage ? favoritePage : '';
  // Get the default path
  const defaultPath = yield call(getDefaultPath, redirectToPageUrl);
  // Set default selected path to dashboard sidebar
  yield put(appActions.changeCurrent(defaultPath));

  // Push to redirect or favorite page if exists
  yield put(push(`/dashboard${redirectToPageUrl}`));
}

function* successLoginAfter2FA(action) {
  const { data } = action;
  if (data) {
    yield put(actions.loginUserSuccess(data));
  }
  /* Flag indicating that the user is logged in */
  yield put(actions.loginUserSuccess2FA(true));

  // Fetch current user details
  yield put(appActions.fetchCurrentUser());
  yield put(appActions.fetchBranches());
  // Fetch all user permissions
  yield put(authorityActions.fetchUserPermissions());

  // Check if user is root
  yield put(authorityActions.checkIfUserHasRole([ROLES.ROOT]));

  // Wait for current user, branches and permissions to set on state
  const [currentUserReady, branchesReady, permissionsReady] = yield all([
    yield call(waitFor, state => !isEmpty(getCurrentUser(state))),
    yield call(waitFor, state => !isEmpty(get(state.Authority, 'permissions'))),
    yield call(waitFor, state => getAllBranches(state).length > 0, 'branches'),
  ]);
  if (currentUserReady && branchesReady && permissionsReady) {
    // State is ready, then set right redirection
    yield call(redirectToPage);
  }
}

function* loginUser(action) {
  const {
    values: { email, password },
  } = action;
  if (action.setLoading) action.setLoading(true);
  try {
    const response = yield call(AuthService.loginUser, email, password);
    if (response.errorCode) {
      yield call(handleNotification, 'error', response);
    } else {
      yield put(actions.loginUserSuccess(response));
      if (get(response, 'mfaStatus', '') === 'NOT_AVAILABLE')
        yield put(actions.successLoginAfter2FA(null));
      else if (action.setShowForm) action.setShowForm(QR_CODE_OR_6DIGIT_FORM);
    }
  } catch (error) {
    yield call(handleNotification, 'error', error);
  }
  action.setLoading(false);
}

/**
 * Refresh token function.
 * @param {object} action - object containing type and payload inside.
 */
function* refreshTokenUser(action) {
  try {
    const { refreshToken } = action;
    const response = yield call(AuthService.refreshTokenUser, refreshToken);
    if (response.ok) {
      const user = yield call([response, response.json]);
      yield put({ type: actionStrings.REFRESH_TOKEN_SUCCESS, user });

      // TODO: Remove this when we switch to new dashboard
      // If new dashboard localStorage is present, we need to update that one as well
      const newDashboardLocalStorageAuthInfo =
        localStorage.getItem('persist:auth') && JSON.parse(localStorage.getItem('persist:auth'));

      if (newDashboardLocalStorageAuthInfo) {
        localStorage.setItem(
          'persist:auth',
          JSON.stringify({
            ...newDashboardLocalStorageAuthInfo,
            authInfo: JSON.stringify(user),
          })
        );
      }

      return;
    }
    yield put({ type: actionStrings.REFRESH_TOKEN_FAIL });
  } catch (error) {
    yield put({ type: actionStrings.REFRESH_TOKEN_FAIL });
  }
}

/** Logout function used to clear local storage on user log out. */
function* logoutUser() {
  try {
    yield put(appActions.resetAll());
    yield put(actions.loginUserSuccess2FA(false));
  } catch (e) {
    handleNotification('error', e.message);
  }
}

/** Logout function used to clear local storage on user log out. */
function* logoutUser2FA() {
  try {
    yield call([localStorage, localStorage.clear]);
  } catch (e) {
    handleNotification('error', e.message);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionStrings.LOGIN, loginUser),
    yield takeEvery(actionStrings.REDIRECT_PAGE, redirectToPage),
    yield takeEvery(actionStrings.SUCCESS_LOGIN_AFTER_2FA, successLoginAfter2FA),
    yield takeLatest(actionStrings.REFRESH_TOKEN, refreshTokenUser),
    yield takeEvery(actionStrings.LOGOUT, logoutUser),
    yield takeEvery(actionStrings.LOGOUT_2FA, logoutUser2FA),
  ]);
}
