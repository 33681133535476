import { createSelector } from 'reselect';
import get from 'lodash/get';

const getPluginAllState = state => state.Plugins;

const getIsPluginAvailableState = (state, pluginName) =>
  get(state.Plugins, `availablePlugins.value[${pluginName}]`, false);

const getIsPluginActiveState = (state, pluginName) =>
  get(state.Plugins, `activePlugins.value[${pluginName}]`, false);

const getPlugByNameState = (state, pluginName) => get(state.Plugins, ['plugins', pluginName], {});

const getAllActivePluginsState = state => get(state.Plugins, ['activePlugins', 'value'], {});

export const getPluginState = createSelector(getPluginAllState, allState => allState);

export const getIsPluginAvailable = createSelector(
  getIsPluginAvailableState,
  isPluginAvailable => isPluginAvailable
);

export const getIsPluginActive = createSelector(
  getIsPluginActiveState,
  isPluginActive => isPluginActive
);
export const getPlugByName = createSelector(getPlugByNameState, pluginByName => pluginByName);

export const getAllActivePlugins = createSelector(
  getAllActivePluginsState,
  allActivePlugins => allActivePlugins
);

export const getPluginInfo = (state, pluginName, pluginAvailableName, pluginActiveName) => ({
  ...getPlugByName(state, pluginName),
  available: getIsPluginAvailable(state, pluginAvailableName),
  active: getIsPluginActive(state, pluginActiveName),
});
